import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./organization.module.scss";
import "../../../assets/Scsss/Global.scss";
import Multiselect from 'multiselect-react-dropdown';
import Editingpage from "./LocationEditing/EditingPage";
//assets
import { ReactComponent as FilterIcon } from "../../../assets/images/filter.svg";
import {
  CopyBtn,
  CustomCheckbox,
  EachCustomDropdown,
  EachCustomImageUpload, MarginEachCustomTextarea, MyEachCustomImageUpload,
  EachCustomImageUploadcustom,
  EachCustomSelectMultiple,
  EachInputCustom,
  EachCustomTextarea, MulitImage,
  EachInputsepfic, MarignEachInput,
  EachInput, EachCustomDropdownSearch,
} from "../../../components/Custom";
import { IoMdArrowDropdown } from "react-icons/io";
import { ReactComponent as SortArrows } from "../../../assets/images/icons/sort.svg";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../../../context/Globalcontext";
import { useNetworkRequest } from "../../../hooks/networkRequest";
import { deleteData, getData } from "../../../assets/functions/apiCalls";
import axios from "axios";
import { toastify } from "../../../assets/functions/toastify";
import { filterDetails } from "../../../assets/functions";
import { APP_CODE } from "../../../assets/data/constant";
import Drawer from "react-modern-drawer";
import ConsututioncateryEditing from "./ConsututioncateryEditing/EditingPage"
import ServiceEditing from './ServiceEditing/EditingPage'
const Organization = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { roleType, organizationFilterType } = useParams();
  const {
    email,
    actionBtns,
    setActionBtns,
    globalSearchQuery,
    dropDowndownvalue,
    editingToggle,
    seteditingToggle,
  } = useContext(GlobalContext);
  const [allOrganization, setAllOrganization] = useState([]);
  const [locationdata, setlocationdata] = useState([]);
  const [organizationSelected, setOrganizationSelected] = useState("");

  const { allValues: allLeads, allValuesLoading: allLeadsLoading } =
    useNetworkRequest({
      URL:
        organizationFilterType === "locations"
          ? `https://uatamma.apimachine.com/api/getlocation?teamUsername=${JSON.parse(localStorage?.getItem("orginzationdetail"))
            ?.TeamUsername || ""
          }`
          : organizationFilterType === "users"
            ? `https://uatamma.apimachine.com/api/getlist?key=${organizationFilterType === "users" ? "user" : ""
            }`
            : organizationFilterType === "serviceTypes"
              ? `https://uatamma.apimachine.com/api/getconsultationtype?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
              : organizationFilterType === "services"
                ? `https://uatamma.apimachine.com/api/getconsultationservice?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
                : "",
      organizationSelected: organizationSelected ? organizationSelected : "",
    });

  console.log("allLeads", organizationFilterType);
  //page values
  const [filterOpen, setFilterOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  // const [allLeadsLoading, setAllLeadsLoading] = useState(false);
  // const [allLeads, setAllLeads] = useState(tempLeads);
  const [allBrokeragesSearch, setAllBrokeragesSearch] = useState("");
  const [checkBoxStage, setCheckBoxStage] = useState("");
  const [tempArray, setTempArray] = useState([]);
  const [loader, setloader] = useState(false);
  const [allTimezones, setAllTimezones] = useState([]);
  const [locationsForm, setLocationsForm] = useState({
    teamUsername: JSON.parse(localStorage?.getItem("orginzationdetail"))
      ?.TeamUsername,
    name: "",
    description: "",
    //coverPhoto: "",
    additionalMedia: [{ name: "" }],
    address: "",
    expertise: "",
    calendarid: "",
    timeZone: "",
    TimeZone: "",
    TimeZoneAbbrevation: "",
    calendartemplate: "",
    CalendarNameId: "",
    country: "",
    picode: "",
    state: "",
    City: "",
    Street: "",
    coverPhotos: "",
    coverPhoto: [],
  });


  const addmorepicture = (e) => {
    e.stopPropagation();

    setLocationsForm({ ...locationsForm, coverPhoto: [...locationsForm?.coverPhoto, { name: "" }] });
    // }

  }
  console.log("sdfjfhdsfdsuhfudsi", locationsForm)
  const [serviceTypeForm, setserviceTypeForm] = useState({
    name: "",
    description: "",
    coverPhoto: "",
  });
  const [constultandData, setconstultandData] = useState([]);
  const [services, setservices] = useState({
    name: "",
    description: "",
    coverPhoto: "",
    duration: "",
    locationid: "",
    locationName: "",
    constultandid: "",
    constultandName: "",
    roomIds: [],
    cost: "",
  });
  const [labelName, setlabelName] = useState("Mins");
  console.log("serviceTypeForm", organizationFilterType);

  const [usersForm, setUsersForm] = useState({
    username: "",
    email: "",
    ref_affiliate: "",
    app_code: APP_CODE,
  });
  const [userCreated, setUserCreated] = useState("");

  // function onCloseSidebar() {
  //   if (sidebarOpen) {
  //     setSidebarOpen((prev) => !prev);
  //   }
  // }

  const filterDropdownRef = useRef(null);
  const selectedItemTable = useRef(null);
  const navRightBox = useRef(null);

  const filterItems = [];

  const filterItemsUsers = [
    { name: "Add to staff", to: "confirmAddStaff" },
    { name: "Add to patients", to: "confirmAddPatient" },
    { name: "See user profile", to: "" },
    { name: "Delete user", to: "" },
  ];

  const filterItemsUsersCnfm = [
    { name: "Yes", to: makeUserToPlayer, type: "func" },
    { name: "Never mind", to: neverMindd, type: "func" },
  ];

  const filterItemsUsersPatientCnfm = [
    { name: "Yes", to: makeUserToPatient, type: "func" },
    { name: "Never mind", to: neverMindd, type: "func" },
  ];

  const filterItemsDeleteLocations = [
    { name: "Edit location", to: "" },
    { name: "Delete location", to: "confirmDeleteLocations" },
  ];

  const filterItemsDeleteLocationsCnfm = [
    { name: "Yes", to: deleteLocations, type: "func" },
    { name: "Never mind", to: neverMindd, type: "func" },
  ];
  const [checkuserName, setcheckuserName] = useState(false);
  const [checkuserMail, setcheckuserMail] = useState(false);
  //functions

  async function deleteLocations() {
    // setCheckBoxStage("");
    if (actionBtns?.length > 0) {
      let response = await deleteData(
        `https://uatamma.apimachine.com/api/deleteLocation/${actionBtns?.length > 0 ? actionBtns[0] : ""
        }`
      );
      if (response?.success) {
        toastify("Successfully deleted location", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toastify("Error while deleting location", "error");
      }
      console.log(response, "location delete response");
    }
  }
  console.log("asdasda242423", services)
  const onSelectdata = (selectedList, selectedItem) => {


    setservices({
      ...services,
      roomIds: [...services?.roomIds, {
        name: selectedItem?.name,
        id: selectedItem?.roomid
      }]
    })

  }
  const onRemovedata = (selectedList, removedItem) => {
    // setstaffdata({
    //   ...staffdata,
    //   specialistIds: [...staffdata?.specialistIds, {
    //     name: selectedItem?.name,
    //     id: selectedItem?.consultationserviceid
    //   }]

    const filteredItems = services?.roomIds.filter(item => item.id !== removedItem?.roomid);
    setservices({
      ...services,
      roomIds: [...filteredItems]
    })

    //   filteredItems);


  }



  function neverMindd() {
    setCheckBoxStage("");
    setActionBtns([]);
  }

  useEffect(() => {
    getTimezones()
  }, [])
  async function getTimezones() {
    let response = await getData(`https://uatamma.apimachine.com/api/timezones`);
    if (response?.success) {
      setAllTimezones([...response?.data]);
    }
    console.log(response, "timezones response");
  }
  const submitservicessFucntion = () => {
    let pop = services?.roomIds?.map(item => {
      return item?.id
    })
    let sads = {



    }

    console.log("sadssads", sads)
    axios
      .post("https://uatamma.apimachine.com/api/addconsultationservice", {

        "teamusername": JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername,
        "name": services?.name,
        "icon": services?.coverPhoto,
        "description": services?.description,
        "cost": services?.cost,
        "duration": services?.duration,
        "locationId": services?.locationid,
        "roomIds": [
          ...pop
        ],
        "consultationtypeid": services?.constultandid,
      })
      .then(({ data }) => {
        if (data.status) {
          toastify("Added succesfully!", "success");

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toastify(data?.message, "error");
        }
      });
  };

  const submitserviceFucntion = () => {
    axios
      .post("https://uatamma.apimachine.com/api/addconsultationtype", {
        teamusername: JSON.parse(localStorage?.getItem("orginzationdetail"))
          ?.TeamUsername,
        name: serviceTypeForm?.name,
        icon: serviceTypeForm?.coverPhoto,
        description: serviceTypeForm?.description,
      })
      .then(({ data }) => {
        if (data.status) {
          toastify("Added succesfully!", "success");
          setCheckBoxStage("");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toastify(data?.message, "error");
        }
      });
  };

  const [calndertemp, setcalndertemp] = useState([])
  useEffect(() => {
    checkcledner()
  }, [])
  const checkcledner = () => {


    axios
      .get(`https://uatamma.apimachine.com/api/getcalendarperteam?TeamUsername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername}`)
      .then((res) => {
        if (res?.data.success) {
          setcalndertemp(res.data?.data)
        }
        else {
          setcalndertemp([])
        }

      });
  }
  async function addLocationsForm() {

    let pop = locationsForm?.coverPhoto?.map(item => {
      return item?.name
    })

    let response = await axios.post(
      "https://uatamma.apimachine.com/api/addLocation",
      {


        "teamUsername": JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername,
        "name": locationsForm?.name,
        "description": locationsForm?.description,
        "coverPhoto": locationsForm?.coverPhotos,
        "additionalMedia": pop,
        "calendarID": locationsForm?.CalendarNameId,
        "streetNumber": locationsForm?.address,
        "city": locationsForm?.City,
        "state": locationsForm?.state,
        "pincode": locationsForm?.picode,
        "country": locationsForm?.country
      }

    );
    if (
      response?.data?.status ||
      response?.data?.message === "location added"
    ) {
      toastify("Added location succesfully!", "success");
      // setCheckBoxStage("");
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    } else {
      toastify("Error while trying to add location", "error");
    }
    // console.log(response, "response from Add location");
  }
  const [coundata, setcoundata] = useState([])
  useEffect(() => {
    countryfunct()
  }, [])

  const countryfunct = () => {
    axios
      .get(`https://comms.globalxchange.io/coin/vault/countries/data/get`)
      .then((res) => {
        if (res.data.status) {
          setcoundata(res?.data?.countries)
        }
        else {
          setcoundata([])
        }
        //



      });
  }
  async function addUsersForm() {
    if (checkuserMail && checkuserName) {
      setloader(true);
      let affiliateId = await axios.get(
        `https://comms.globalxchange.io/user/profile/data/get?email=${email}`
      );
      if (
        affiliateId?.data?.usersData?.length > 0 &&
        affiliateId?.data?.usersData[0]?.hardCoded?.length > 0
      ) {
        affiliateId =
          affiliateId?.data?.usersData[0]?.hardCoded[0]?.data?.affiliate_id;
      }
      usersForm.ref_affiliate = affiliateId;
      // console.log(affiliateId, "affiliateId", usersForm);
      let response = await axios.post(
        "https://gxauth.apimachine.com/gx/user/admin/signup",
        usersForm
      );
      if (response?.data?.status || response?.data?.message === "users added") {
        toastify(response?.data?.message, "success");
        setloader(false);
        setCheckBoxStage("usersFormCompleted");
      } else {
        toastify("Error while trying to add users", "error");
      }
      setUserCreated(response?.data);
      console.log(response, "response from Add users");
    }
  }

  async function playerToTeam(teamUsername) {
    let result = await filterDetails(allLeads?.data, actionBtns?.[0]);

    let response = await axios.post(
      "https://uatamma.apimachine.com/api/addplayerstoteam",
      {
        TeamOwnerEmail: email,
        PlayerUsername: result?.[0]?.username,
        StartDate: "",
        TeamUsername: teamUsername,
      }
    );
    if (response?.data?.success) {
      toastify("Added player to the selected team", "success");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toastify("Player data not found", "error");
    }
    console.log(response, "response from make user to player");
  }

  async function patientToTeam(teamUsername) {
    let result = await filterDetails(allLeads?.data, actionBtns[0]);

    let response = await axios.post(
      "https://uatamma.apimachine.com/api/addclientstoteam",
      {
        TeamOwnerEmail: email,
        ClientUsername: result[0]?.username,
        StartDate: new Date(),
        TeamUsername: teamUsername,
      }
    );
    if (response?.data?.success) {
      toastify("Added patient to the selected team", "success");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toastify("Patient data not found", "error");
    }
    console.log(response, "response from make user to patient");
  }

  async function getOrganizations() {
    let response = await getData(
      `https://uatamma.apimachine.com/api/getteamsbyowner?TeamCreatedBy=${email}`
    );
    if (response?.success) {
      setAllOrganization([...response?.data]);
    }
    console.log(response, "organization response");
  }

  async function makeUserToPlayer() {
    let result = await filterDetails(allLeads?.data, actionBtns[0]);
    // console.log(actionBtns, "playerEmail", result);
    let response = await axios.put(
      "https://uatamma.apimachine.com/api/addplayer",
      {
        email: result[0]?.email,
      }
    );
    if (response?.data?.success) {
      toastify("Updated user to player", "success");
      setTimeout(() => {
        setCheckBoxStage("playerToSpecificTeam");
      }, 500);
    } else {
      toastify("Error while converting user to player", "error");
    }
    console.log(response, "response from make user to player");
  }

  async function makeUserToPatient() {
    let result = await filterDetails(allLeads?.data, actionBtns[0]);
    // console.log(actionBtns, "playerEmail", result);
    let response = await axios.put(
      "https://uatamma.apimachine.com/api/addclient",
      {
        email: result[0]?.email,
      }
    );
    if (response?.data?.success) {
      toastify("Updated user to patient", "success");
      setTimeout(() => {
        setCheckBoxStage("patientToSpecificTeam");
      }, 500);
    } else {
      toastify("Error while converting user to patient", "error");
    }
    console.log(response, "response from make user to patient");
  }

  useEffect(() => {
    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target)
      ) {
        setFilterOpen(false);
      }
      if (
        selectedItemTable.current &&
        !selectedItemTable.current.contains(event.target)
      ) {
        setCheckBoxStage("");
        setActionBtns([]);
      }
      // if (navRightBox.current && !navRightBox.current.contains(event.target)) {
      //   setCheckBoxStage("");
      // }
    };

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getOrganizations();
    loRooms()
  }, []);
  const [rooms, setrooms] = useState([])
  const loRooms = () => {
    axios
      // .get(`https://uatamma.apimachine.com/api/getwidget`)
      .get(
        `https://uatamma.apimachine.com/api/getroom?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
      )
      .then((res) => {
        if (res?.data?.success) {
          setrooms(res?.data?.data);

        }
        else {
          setrooms([]);
        }
        //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))

      });
  };

  useEffect(() => {
    if (actionBtns?.length > 0) {
      setCheckBoxStage("");
    }
  }, [actionBtns]);

  useEffect(() => {
    setActionBtns([]);
  }, [location]);

  useEffect(() => {
    tablefucntion();
    constultandDatafunction();
  }, [dropDowndownvalue]);
  const tablefucntion = () => {
    axios
      .get(
        `https://uatamma.apimachine.com/api/getlocation?teamUsername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
      )
      .then((res) => {
        //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
        setlocationdata(res?.data?.data);
      });
  };
  const constultandDatafunction = () => {
    axios
      .get(
        `https://uatamma.apimachine.com/api/getconsultationtype?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
      )
      .then((res) => {
        //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
        setconstultandData(res?.data?.data);
      });
  };

  let timer;
  let timer1;
  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(usersForm?.email);
    console.log("isValidEmail", isValidEmail);
    if (usersForm?.username?.length > 3) {
      clearTimeout(timer);

      // Set a new timer to make the API call after 500ms (adjust as needed)
      timer = setTimeout(() => {
        checkname(usersForm?.username);
      }, 500);
    } else {
      setcheckuserName(false);
    }
    if (isValidEmail) {
      clearTimeout(timer1);

      // Set a new timer to make the API call after 500ms (adjust as needed)
      timer1 = setTimeout(() => {
        checkmail(usersForm?.email);
      }, 500);
    } else {
      setcheckuserMail(false);
    }
  }, [usersForm?.username, usersForm?.email]);

  const checkmail = (newValue) => {
    axios
      .get(
        `https://comms.globalxchange.io/user/profile/data/get?email=${newValue}`
      )
      .then((res) => {
        if (res?.data?.count > 0) {
          setcheckuserMail(false);
        } else {
          setcheckuserMail(true);
        }
        //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
      });
  };
  const [locationinfo, setlocationinfo] = useState({})
  const checkname = (newValue) => {
    axios
      .get(
        `https://comms.globalxchange.io/user/profile/data/get?username=${newValue}`
      )
      .then((res) => {
        if (res?.data?.status) {
          setcheckuserName(false);
        } else {
          setcheckuserName(true);
        }
        //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
      });
  };
  let loc = window.location.pathname;
  let finallo = loc?.split("/");
  const localclikc = () => {
    let addlocal = localStorage?.getItem("drawershow");

    localStorage?.setItem("drawershow", Number(addlocal + 1));
  };
  console.log("allTimezones", allTimezones)

  const [ConsultationDetail, setConsultationDetail] = useState({})
  const [serviceid, setserviceid] = useState({})
  return (
    <>
      {editingToggle ? (
        <>
          {organizationFilterType === "locations" &&
            < Editingpage
              staffitem={locationinfo}
            />
          }
          {organizationFilterType === "serviceTypes" &&
            < ConsututioncateryEditing
              staffitem={ConsultationDetail}
            />
          }
          {organizationFilterType === "services" &&
            < ServiceEditing
              staffitem={serviceid}
            />
          }
        </>

      ) : (
        <div className="global-dashboard">
          <div className="label-box-header" onClick={localclikc}>
            {organizationFilterType === "serviceTypes"
              ? "Consultation Categories"
              : organizationFilterType === "services"
                ? "Consultation Services"
                : finallo?.[3]}
          </div>
          <div className="top-section-golbal">
            <div className="left-side">
              <div className="label-box">
                <svg
                  style={{
                    marginRight: "10px",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                >
                  <path
                    d="M12.8445 8.07626L10.9486 6.58668C10.8262 6.4908 10.6594 6.47293 10.5202 6.54118C10.3804 6.60888 10.2916 6.7508 10.2916 6.90626V7.90292C8.75704 8.16184 7.58325 9.49651 7.58325 11.1042V12.4583C7.58325 12.7573 7.82592 13 8.12492 13C8.42392 13 8.66658 12.7573 8.66658 12.4583V11.1042C8.66658 10.0978 9.35992 9.25655 10.2916 9.01442V9.88542C10.2916 10.0409 10.3804 10.1828 10.5202 10.2505C10.5765 10.2781 10.6372 10.2917 10.6978 10.2917C10.7872 10.2917 10.876 10.2624 10.9486 10.205L12.8445 8.71542C12.943 8.63851 12.9999 8.52042 12.9999 8.39584C12.9999 8.27126 12.943 8.15317 12.8445 8.07626Z"
                    fill="#010101"
                  />
                  <path
                    d="M9.74999 0H1.625C0.731249 0 0 0.731249 0 1.625V8.66666C0 9.5604 0.731249 10.2917 1.625 10.2917H6.58124C6.65166 9.91249 6.77082 9.54957 6.93874 9.20832H1.625C1.32708 9.20832 1.08333 8.96457 1.08333 8.66666V2.70833H10.2917V5.47624C10.6492 5.37333 11.0392 5.41124 11.375 5.57916V1.625C11.375 0.731249 10.6437 0 9.74999 0V0Z"
                    fill="#010101"
                  />
                </svg>
                Export
              </div>

              <>
                <div className="label-box">
                  <svg
                    style={{
                      marginRight: "10px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                  >
                    <path
                      d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z"
                      fill="#010101"
                    />
                  </svg>
                  Add Filter
                </div>
              </>
            </div>

            <div className="right-side">
              <div className="shortBy">Sort By</div>
              <div className="dropdownlist">
                Newest
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7"
                  height="6"
                  viewBox="0 0 7 6"
                  fill="none"
                >
                  <path
                    d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z"
                    fill="#010101"
                  />
                </svg>
              </div>
              <div
                className="addLabel"
                onClick={() => {
                  if (organizationFilterType == "locations") {
                    setCheckBoxStage("locationsForm");
                  } else if (organizationFilterType === "users") {
                    setCheckBoxStage("usersForm");
                  } else if (organizationFilterType === "serviceTypes") {
                    setCheckBoxStage("serviceTypes");
                  } else if (organizationFilterType === "services") {
                    setCheckBoxStage("services");
                  }
                  // else (
                  //   ||
                  //   organizationFilterType === "users" ||
                  //   organizationFilterType === "serviceTypes" ||
                  //   organizationFilterType === "services"
                  // ) {
                  //   setCheckBoxStage(organizationFilterType === "serviceTypes" ? "serviceTypes" : organizationFilterType === "services" ? "services" : "locations");
                  // }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                >
                  <path
                    d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z"
                    fill="white"
                  />
                </svg>
                Add New
              </div>

              <Drawer
                size={450}
                // duration={500}
                open={checkBoxStage}
                onClose={() => setCheckBoxStage("")}
                direction="right"
                className="bla bla bla"
                overlayColor="#FFF"
                overlayOpacity={0}
              >
                <>
                  {checkBoxStage === "locations" &&
                    (organizationFilterType === "locations" ||
                      // organizationFilterType === "serviceTypes" ||
                      organizationFilterType === "users") ? (
                    <div className="drowpside-right">
                      {/* <div style={{
                      position: "absolute",
                      right: "1rem"
                    }}
                      className={classNames.closeBtn}
                      onClick={() => {
                        setCheckBoxStage("");
                      }}
                    >
                      X
                    </div> */}
                      <div
                        className={classNames.eachFilterItem}
                        style={{ fontWeight: "500", pointerEvents: "none" }}
                      >
                        Add New
                      </div>
                      <div
                        className={classNames.eachFilterItem}
                        key={"locationsForm"}
                        onClick={() => {
                          setCheckBoxStage("locationsForm");
                        }}
                      >
                        Location
                      </div>
                      <div
                        className={classNames.eachFilterItem}
                        key={"usersForm"}
                        onClick={() => {
                          setCheckBoxStage("usersForm");
                        }}
                      >
                        Users
                      </div>
                    </div>
                  ) : checkBoxStage === "usersForm" &&
                    (organizationFilterType === "locations" ||
                      organizationFilterType === "users") ? (
                    <div className="drowpside-right">
                      {loader ? (
                        <div className={classNames.lodingcontent}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="159"
                            height="143"
                            viewBox="0 0 159 143"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M30.9395 36.832C28.7381 32.6653 26.4279 28.361 24.3792 24.7027C23.1165 30.582 22.1305 38.1133 21.3769 44.9338C24.4569 42.0169 27.6753 39.301 30.9395 36.832ZM156.484 24.948C160.302 11.6462 157.332 5.05595 142.576 4.00742C116.043 2.10873 76.6341 20.149 49.9281 39.8881C55.5647 50.6284 61.0941 61.4625 66.6539 72.0334C67.1926 73.0378 67.9923 74.6265 68.9171 76.3392C70.7185 71.7109 72.5063 66.6171 74.4466 62.5428C81.5454 47.4801 101.735 12.3407 120.431 13.3437C156.498 15.2879 152.448 115.244 147.875 141.111C146.674 147.933 140.337 135.019 127.185 122.486C132.928 128.227 127.722 122.677 127.185 123.927C138.241 98.6934 141.761 61.4169 127.577 36.9559C119.77 23.4668 116.088 28.4367 106.833 39.8564C91.6782 58.5607 89.3518 67.667 80.3444 88.7784C76.7868 97.1103 69.9188 101.681 62.5575 94.735C58.1535 90.5849 42.4445 58.7302 38.4404 51.152C38.2241 50.7524 37.9782 50.3046 37.7319 49.8265C35.8229 51.5709 34.035 53.2973 32.4038 54.9962C27.0903 60.5518 22.2539 66.168 19.1896 72.466C18.5588 83.0521 17.48 95.7848 14.4928 99.9983C11.6442 103.995 3.0193 103.115 1.17179 95.7545C-1.15407 86.432 0.139536 77.4649 3.66611 69.1785C5.45248 46.2304 8.31673 15.6268 14.3077 7.50992C20.3613 -0.700693 28.7076 1.87726 33.4965 10.041C37.0076 16.014 40.4108 22.1248 43.7682 28.3127C70.8875 12.4633 121.032 -6.25483 147.689 2.03157C160.442 5.98187 160.873 13.7295 156.484 24.948Z"
                              fill="#C57E50"
                            />
                          </svg>

                          <div className={classNames.loadingmessage}>
                            Inviting New User
                          </div>
                        </div>
                      ) : (
                        <>
                          {/* <div
                            className={classNames.closeBtn}
                            style={{
                              position: "absolute",
                              right: "1rem"
                            }}
                            onClick={() => {
                              setActionBtns([]);
                              setCheckBoxStage("");
                            }}
                          >
                            X
                          </div> */}
                          <div
                            className={classNames.eachFilterItem}
                            style={{
                              fontWeight: "500",
                              pointerEvents: "none",
                              paddingLeft: "0",
                              marginBottom: "2rem",
                            }}
                          >
                            Invite new user
                          </div>
                          <div className={classNames.formFields}>
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <EachInput
                                title="Create a temporary username"
                                name="username"
                                placeholder="Username..."
                                stateValue={usersForm}
                                setState={setUsersForm}
                                topTitle="true"
                              />
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  background: checkuserName ? "green" : "red",
                                  borderRadius: "10px",
                                  position: "absolute",
                                  top: "51px",
                                  right: "12px",
                                }}
                              ></div>
                            </div>
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <EachInput
                                title="What is the person’s email?"
                                name="email"
                                placeholder="Email..."
                                stateValue={usersForm}
                                setState={setUsersForm}
                                topTitle="true"
                              />
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  background: checkuserMail ? "green" : "red",
                                  borderRadius: "10px",
                                  position: "absolute",
                                  top: "51px",
                                  right: "12px",
                                }}
                              ></div>
                            </div>
                            <button
                              style={{
                                marginTop: "0rem",
                                opacity:
                                  checkuserMail && checkuserName ? "1" : "0.5",
                              }}
                              className={classNames.submitBtn}
                              onClick={addUsersForm}
                            >
                              Submit
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  ) : checkBoxStage === "usersFormCompleted" &&
                    (organizationFilterType === "locations" ||
                      organizationFilterType === "users") ? (
                    <div className="drowpside-right">
                      <div
                        style={{
                          position: "absolute",
                          right: "1rem",
                        }}
                        className={classNames.closeBtn}
                        onClick={() => {
                          setCheckBoxStage("");
                        }}
                      >
                        X
                      </div>
                      <div
                        className={classNames.eachFilterItem}
                        style={{
                          fontWeight: "500",
                          pointerEvents: "none",
                          paddingLeft: "0",
                          marginBottom: "2rem",
                        }}
                      >
                        Invitation sent successfully
                      </div>
                      <div className={classNames.formFields}>
                        <div style={{ pointerEvents: "none" }}>
                          <EachInput
                            title="Temporary password"
                            name="temp_password"
                            placeholder="temp password..."
                            stateValue={userCreated}
                            setState={setUserCreated}
                            topTitle="true"
                          />
                        </div>

                        <button
                          className={classNames.submitBtn}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              userCreated?.temp_password
                            );
                            toastify("Copied", "success");
                            setCheckBoxStage("");
                          }}
                        >
                          Copy
                        </button>
                      </div>
                    </div>
                  ) : checkBoxStage === "locationsForm" &&
                    organizationFilterType === "locations" ? (



                    <>


                      <div className="drowpside-right">
                        {/* <div style={{
                      position: "absolute",
                      right: "1rem"
                    }}
                      className={classNames.closeBtn}
                      onClick={() => {
                        setCheckBoxStage("");
                      }}
                    >
                      X
                    </div> */}
                        <div
                          className={classNames.eachFilterItem}
                          style={{
                            fontWeight: "700",
                            pointerEvents: "none",

                            fontSize: "20px"
                          }}
                        >
                          Add New Location
                        </div>


                        <div
                          style={{
                            marginTop: "2rem",
                            fontSize: "0.9rem",
                            marginBottom: "0.8rem",
                            fontWeight: "700"
                          }}
                        >Basic Information</div>
                        <div
                          style={{
                            marginBottom: "0.8rem",
                            marginTop: "2rem"
                          }}
                        >

                          <MarignEachInput
                            title="What do you want to name this location"
                            name="name"
                            placeholder="Name..."
                            stateValue={locationsForm}
                            setState={setLocationsForm}
                          //  topTitle="true"
                          />
                        </div>


                        <div
                          style={{

                            marginBottom: "0.8rem"
                          }}
                        >
                          <MarginEachCustomTextarea
                            title="Describe the location"
                            name="description"
                            placeholder="Description..."
                            stateValue={locationsForm}
                            setState={setLocationsForm}
                          // topTitle="true"
                          // topTitle="true"
                          />

                        </div>



                        <div
                          style={{
                            marginBottom: "0.8rem",

                          }}
                        >
                          <EachCustomDropdownSearch
                            dropdown={calndertemp}
                            name="calendartemplate"
                            title="Calendar template"
                            stateValue={locationsForm}
                            setState={setLocationsForm}
                            ///  topTitle="true"
                            type="single"
                          />

                        </div>





                        <div
                          style={{
                            marginBottom: "0.8rem",
                            marginTop: "2rem"
                          }}
                        >

                          <MarignEachInput
                            title="Address"
                            name="address"
                            placeholder="Street"
                            stateValue={locationsForm}
                            setState={setLocationsForm}
                            topTitle="true"
                          />
                        </div>
                        <div
                          style={{
                            marginBottom: "0.8rem"
                          }}
                        >

                          <MarignEachInput
                            title="What is the employees pincode?"
                            name="City"
                            placeholder="City"
                            stateValue={locationsForm}
                            setState={setLocationsForm}
                          //  topTitle="true"
                          />

                        </div>
                        <div
                          style={{
                            marginBottom: "0.8rem"
                          }}
                        >
                          <MarignEachInput
                            title="What is the employees state?"
                            name="state"
                            placeholder="State"
                            stateValue={locationsForm}
                            setState={setLocationsForm}
                          // topTitle="true"
                          />
                        </div>
                        <div
                          style={{
                            marginBottom: "0.8rem"
                          }}
                        >
                          <MarignEachInput
                            title="What is the employees pincode?"
                            name="picode"
                            placeholder="Pincode"
                            stateValue={locationsForm}
                            setState={setLocationsForm}
                          //  topTitle="true"
                          />
                        </div>
                        <div
                          style={{
                            marginBottom: "2rem"
                          }}
                        >
                          <EachCustomDropdownSearch
                            dropdown={coundata}
                            title="Country"
                            name="country"
                            placeholder="Country"
                            stateValue={locationsForm}
                            setState={setLocationsForm}
                            // topTitle="true"    

                            type="obj"
                          />
                        </div>


                        <div className={classNames.flexContainer}
                          style={{
                            width: "150px",
                            marginBottom: "0.8rem"
                          }}
                        >
                          <MyEachCustomImageUpload

                            title="Upload cover photo"
                            name="coverPhotos"
                            placeholder="Update"
                            stateValue={locationsForm}
                            setState={setLocationsForm}
                            topTitle="true"

                          // title="Upload"
                          // name="photo"
                          // placeholder="Profile picture "
                          // stateValue={staffdata}
                          // setState={setstaffdata} topTitle="true"
                          />

                        </div>



                        <div
                          style={{
                            marginBottom: "0rem",
                            display: "flex",
                            gap: " 1rem",
                            overflow: "scroll",
                            width: "100%"
                          }}
                        >

                          {
                            locationsForm?.coverPhoto?.map((item, i) => {
                              return (


                                <>

                                  {/* {
                                                                i == 0 && <div
                                                                    style={{
                                                                        fontSize: "0.9rem",
                                                                        marginBottom: "1rem"
                                                                    }}
                                                                >
                                                                    Upload room photo
                                                                </div>
                                                            } */}

                                  <div
                                    style={{
                                      width: "200px",
                                      marginBottom: "1rem"
                                    }}
                                  >

                                    <MulitImage
                                      key={i}

                                      title="Upload employee profile photo"
                                      name="coverPhoto"
                                      placeholder=""
                                      stateValue={locationsForm}
                                      indexx={i}
                                      setState={setLocationsForm}
                                    //  topTitle="true"
                                    />
                                  </div>

                                </>

                              )
                            })
                          }

                        </div>


                        <div
                          style={{
                            fontFamily: "Montserrat Alternates",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "24px",
                            textAlign: "left",
                            textDecoration: "underline",
                            // marginBottom: "2.2rem"
                          }}

                          onClick={addmorepicture}
                        >Add additional media</div>


                        {/* <EachCustomDropdown
                      dropdown={allOrganization}
                      name="teamUsername"
                      title="Select the organization"
                      stateValue={locationsForm}
                      setState={setLocationsForm}
                      topTitle="true"
                    /> */}
                        {/* <EachCustomDropdown
                      dropdown={staffGroupFormGroupType}
                      name="groupType"
                      title="Select the group type"
                      stateValue={locationsForm}
                      setState={setLocationsForm}
                      topTitle="true"
                    /> */}

                        {/* <EachInput
                          title="What is the expertise of this location"
                          name="expertise"
                          placeholder="Ex. Yoga..."
                          stateValue={locationsForm}
                          setState={setLocationsForm}
                          topTitle="true"
                        /> */}
                        {/* <div
                            style={{

                              marginBottom: "0.8rem"
                            }}
                          >
                            <EachCustomDropdownSearch
                              dropdown={allTimezones}
                              name="TimeZone"
                              title="What timezone is this location in?"
                              stateValue={locationsForm}
                              setState={setLocationsForm}
                              topTitle="true"
                              type="obj"
                              mapVal={{
                                name: "StandardTimeZoneName",
                                name1: "Abbreviation",
                              }}
                            />
                          </div> */}
                        {/* <EachCustomImageUpload
                            title="Upload cover photo"
                            name="coverPhoto"
                            placeholder="Update"
                            stateValue={locationsForm}
                            setState={setLocationsForm}
                            topTitle="true"
                          /> */}
                        <button
                          className={classNames.submitBtn}
                          onClick={addLocationsForm}
                        >
                          Submit
                        </button>

                      </div>

                    </>
                  ) : checkBoxStage === "serviceTypes" &&
                    organizationFilterType === "serviceTypes" ? (
                    <>
                      <div className="drowpside-right">
                        {/* <div
                        style={{
                          position: "absolute",
                          right: "1rem"
                        }}
                        className={classNames.closeBtn}
                        onClick={() => {
                          setActionBtns([]);
                          setCheckBoxStage("");
                        }}
                      >
                        X
                      </div> */}
                        <div
                          className={classNames.eachFilterItem}
                          style={{
                            fontWeight: "500",
                            pointerEvents: "none",
                            paddingLeft: "0",
                            marginBottom: "2rem",
                          }}
                        >
                          Add service type
                        </div>
                        <div className={classNames.formFields}>
                          <EachCustomImageUploadcustom
                            title="Upload service type icon"
                            name="coverPhoto"
                            placeholder="Upload cover photo"
                            stateValue={serviceTypeForm}
                            setState={setserviceTypeForm}
                          />
                          <EachInput
                            title="What do you want to name this service type"
                            name="name"
                            placeholder="Name..."
                            stateValue={serviceTypeForm}
                            setState={setserviceTypeForm}
                            topTitle="true"
                          />
                          <EachCustomTextarea
                            title="Describe the service type"
                            name="description"
                            placeholder="Description..."
                            stateValue={serviceTypeForm}
                            setState={setserviceTypeForm}
                            topTitle="true"
                          />











                          <button
                            style={{
                              marginTop: "0rem",
                            }}
                            className={classNames.submitBtn}
                            onClick={submitserviceFucntion}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </>
                  ) : checkBoxStage === "services" &&
                    organizationFilterType === "services" ? (
                    <>


                      <div
                        className="drowpside-right"

                      >

                        <div
                          className={classNames.eachFilterItem}
                          style={{
                            fontWeight: "600",
                            pointerEvents: "none",
                            paddingLeft: "0",
                            marginBottom: "2rem",
                            marginTop: "0.8rem",
                            fontSize: "18px"

                          }}
                        >
                          Add new consultation service
                        </div>
                        <div
                          style={{
                            fontWeight: "600",
                            pointerEvents: "none",
                            paddingLeft: "0",
                            marginBottom: "0.8rem",
                            fontSize: "14px"

                          }}
                        >Basic Information</div>

                        <div style={{

                          marginBottom: "0.8rem"
                        }}>
                          <MarignEachInput
                            title="What do you want to name this service?"
                            name="name"
                            placeholder="Name..."
                            stateValue={services}
                            setState={setservices}
                          //  topTitle="true"
                          //  topTitle="true"
                          />

                        </div>


                        <div
                          style={{

                            marginBottom: "0.8rem"
                          }}
                        >
                          <MarginEachCustomTextarea
                            title="Describe the Service"
                            name="description"
                            placeholder="Description..."
                            stateValue={services}
                            setState={setservices}
                          //topTitle="true"
                          // topTitle="true"
                          />

                        </div>
                        <div
                          style={{

                            marginBottom: "0.8rem"
                          }}
                        >
                          <EachCustomDropdown
                            dropdown={constultandData}
                            name="constultandName"
                            title="What is the parent service type?"
                            stateValue={services}
                            setState={setservices}
                          //   topTitle="true"
                          />

                        </div>
                        <div
                          style={{

                            marginBottom: "0.8rem"
                          }}
                        >
                          <EachInputCustom
                            title="What is the duration of this service?"
                            name="duration"
                            placeholder="Duration..."
                            label={labelName}
                            setlabelName={setlabelName}
                            stateValue={services}
                            setState={setservices}
                          // topTitle="true"
                          />
                        </div>
                        <div
                          style={{

                            marginBottom: "0.8rem"
                          }}
                        >
                          <EachInputCustom
                            title="What is the cost"
                            name="cost"
                            placeholder="Cost..."
                            label="INR"
                            stateValue={services}
                            setState={setservices}
                          // topTitle="true"
                          />

                        </div>
                        <div
                          style={{
                            marginBottom: "0.8rem"
                          }}
                        >
                          <EachCustomDropdown
                            dropdown={locationdata}
                            name="locationName"
                            title="Which location will this service be offered at?"
                            stateValue={services}
                            setState={setservices}
                          //topTitle="true"
                          />
                        </div>
                        <div
                          style={{
                            marginBottom: "0.8rem"
                          }}
                        >
                          <Multiselect
                            className="mulitselectOption"
                            options={rooms} // Options to display in the dropdown
                            placeholder="Select room "
                            //  selectedValues={selectedValue} // Preselected value to persist in dropdown
                            onSelect={onSelectdata} // Function will trigger on select event
                            onRemove={onRemovedata} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                          />
                        </div>

                        <div
                          style={{
                            marginBottom: "0.8rem",
                            width: "200px"
                          }}
                        >
                          <MyEachCustomImageUpload

                            name="coverPhoto"
                            title="Upload"
                            placeholder="Upload cover photo"
                            stateValue={services}
                            setState={setservices}
                            topTitle="true"
                          />

                        </div>

                        <button
                          className={classNames.submitBtn}
                          onClick={submitservicessFucntion}
                        >
                          Submit
                        </button>
                        {/* <div
                          style={{

                            marginBottom: "0.8rem"
                          }}
                        >
                          <MarginEachCustomTextarea
                            title="Describe the calendar"
                            name="CalendarDescription"
                            placeholder="Description..."
                            stateValue={calendarForm}
                            setState={setCalendarForm}
                          // topTitle="true"
                          />

                        </div>


                        <div
                          style={{

                            marginBottom: "0.8rem"
                          }}
                        >
                          <EachCustomDropdownSearch
                            dropdown={allTimezones}
                            name="TimeZone"
                            title="Select the timezone"
                            stateValue={calendarForm}
                            setState={setCalendarForm}
                            // topTitle="true"
                            type="obj"
                            mapVal={{
                              name: "StandardTimeZoneName",
                              name1: "Abbreviation",
                            }}
                          />
                        </div>
                        <div className={classNames.formFields}>






                          <div className={classNames.customSeperationBox}
                            style={{
                              marginBottom: "0px"
                            }}
                          >
                            <div className={classNames.title}

                              style={{
                                fontWeight: "600",
                                pointerEvents: "none",
                                paddingLeft: "0",
                                marginBottom: "0.8rem",
                                marginTop: "2rem",
                                fontSize: "14px"

                              }}
                            >
                              Add annual holidays
                            </div>
                            {calendarForm?.AnnualHolidays?.map((eachitem, index) => {
                              return (
                                <div
                                  className={classNames.flexBoxouter}
                                  key={"annualHoliday" + index}
                                >
                                  <div className={classNames.longDiv}>
                                    <EachCustomDropdown
                                      dropdown={monthsArray}
                                      style={{
                                        marginBottom: "0rem"
                                      }}
                                      name="AnnualHolidays"
                                      title="Month"
                                      stateValue={calendarForm}
                                      setState={setCalendarForm}
                                      type="twoFields"
                                      indexx={index}
                                      calendarObjField="month"
                                      eachValuee={eachitem}
                                    />
                                  </div>
                                  <div className={classNames.normalDiv}>
                                    <EachCustomDropdownDate
                                      dropdown={datesArraylist}
                                      name="AnnualHolidays"
                                      title="Date"
                                      stateValue={calendarForm}
                                      setState={setCalendarForm}
                                      type="twoFields"
                                      indexx={index}
                                      calendarObjField="date"
                                      eachValuee={eachitem}
                                      setselectedindex={setselectedindex}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>

                          <div className={classNames.customSeperationBox}>
                            <div className={classNames.title}
                              onClick={addmore}
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                                marginTop: "10px"
                              }}
                            >
                              Add another holidays
                            </div>
                          </div>


                          <button
                            className={classNames.submitBtn}
                            onClick={addCalendarForm}
                          >
                            Submit
                          </button>
                        </div> */}
                      </div>






















                    </>
                  ) : (
                    ""
                  )}
                </>
              </Drawer>

              <div
                className={classNames.filterItems}
                style={{
                  display:
                    // organizationFilterType === "locations" ||
                    //   organizationFilterType === "users"

                    //   ? ""
                    //   :
                    "none",
                }}
              >
                {/* <EachCustomDropdown
                dropdown={allOrganization}
                name="selectedAppStaff"
                stateValue={organizationSelected}
                setState={setOrganizationSelected}
              /> */}
              </div>
            </div>
          </div>

          <div className="handle-table">
            <div className="g-table-sec">
              <div className="table-section">
                <table style={{ minWidth: "100%" }}>
                  <thead>
                    <tr
                      style={
                        organizationFilterType === "serviceTypes" ||
                          organizationFilterType === "services"
                          ? {
                            gridTemplateColumns: "min-content repeat(4, 1fr)",
                          }
                          :
                          organizationFilterType === "locations" ? { gridTemplateColumns: "min-content repeat(3, 1fr)" } : { gridTemplateColumns: "min-content repeat(5, 1fr)" }

                      }
                    >
                      <th>
                        Name <SortArrows />
                      </th>
                      {
                        organizationFilterType != "serviceTypes" &&

                        < th >
                          {organizationFilterType === "locations"
                            ? "Description"
                            : organizationFilterType === "users"
                              ? "Email"
                              : organizationFilterType === "serviceTypes"
                                ? "ID"
                                : organizationFilterType === "services"
                                  ? "Category"
                                  : ""}
                          <SortArrows />
                        </th>
                      }
                      <th>
                        {organizationFilterType === "locations"
                          ? "Address"
                          : organizationFilterType === "users"
                            ? "Phone Number"
                            : organizationFilterType === "serviceTypes" ||
                              organizationFilterType === "services"
                              ? "Duration (min)"
                              : ""}
                        <SortArrows />
                      </th>
                      {
                        organizationFilterType != "locations" &&
                        <th>
                          {organizationFilterType === "locations"
                            ? "ID"
                            : organizationFilterType === "users"
                              ? "Role"
                              : organizationFilterType === "serviceTypes"
                                ? "Services"
                                : organizationFilterType === "services"
                                  ? "Cost"
                                  : ""}
                          <SortArrows />
                        </th>
                      }
                      {
                        organizationFilterType != "locations" &&
                        <>
                          {organizationFilterType === "serviceTypes" ? (
                            ""
                          ) :
                            organizationFilterType === "services" ? (
                              <>
                                <th>
                                  Location
                                </th>

                                <th>
                                  Rooms
                                </th>
                              </>
                            ) : (
                              <th>
                                {organizationFilterType === "locations"
                                  ? "Staff"
                                  : organizationFilterType === "users"
                                    ? "ID"
                                    : ""}
                                <SortArrows />
                              </th>
                            )}
                        </>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {allLeadsLoading ? (
                      <tr
                        key={"loading"}
                        style={
                          organizationFilterType === "serviceTypes" ||
                            organizationFilterType === "services"
                            ? {
                              gridTemplateColumns:
                                "min-content repeat(4, 1fr)",
                            }
                            : {
                              gridTemplateColumns:
                                "min-content repeat(5, 1fr)",
                            }
                        }
                      >
                        <td></td>
                        <td>
                          <Skeleton width={100} height={20} />
                        </td>
                        <td>
                          <Skeleton width={100} height={20} />
                        </td>
                        <td>
                          <Skeleton width={100} height={20} />
                        </td>
                        <td>
                          <Skeleton width={100} height={20} />
                        </td>
                        <td>
                          <Skeleton width={100} height={20} />
                        </td>
                      </tr>
                    ) : (
                      allLeads?.data?.length > 0 &&
                      allLeads?.data
                        ?.filter((row) => {
                          // // Convert the row object values to an array and check if any value matches the search query
                          // const values = Object?.values(row);
                          // const searchQuery = globalSearchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                          // return values?.some((value) => {
                          //   if (typeof value === "string") {
                          //     return value?.toLowerCase()?.includes(searchQuery);
                          //   }
                          //   return false;
                          // });
                          const searchQuery = globalSearchQuery?.toLowerCase();
                          return row?.name
                            ?.toLowerCase()
                            ?.includes(searchQuery);
                        })
                        ?.sort((a, b) =>
                          a?.first_name?.localeCompare(b?.first_name)
                        )
                        ?.map((row, index) => (
                          <tr
                            key={row?.brokerage_name + index}
                            style={{
                              gridTemplateColumns:
                                organizationFilterType === "serviceTypes" ||
                                  organizationFilterType === "services"
                                  ? "min-content repeat(4, 1fr)"
                                  : organizationFilterType === "locations" ? "min-content repeat(5, 1fr)" : "min-content repeat(5, 1fr)",
                              pointerEvents: sidebarOpen ? "none" : "",
                            }}
                            onClick={() => {
                              if (organizationFilterType === "locations") {


                                navigate(`/owner/staffing/locations/${row?._id}`)

                              }
                              else if (organizationFilterType === "serviceTypes") {
                                navigate(`/owner/consultation/serviceTypes/${row?.consultationtypeid}`)
                              }
                              else if (organizationFilterType === "services") {
                                navigate(`/owner/consultation/services/${row?.consultationserviceid}`)
                              }
                              // setConsultationDetail(row)
                              // setserviceid(row)
                              // seteditingToggle(true)
                            }
                            }
                          >
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {/* {organizationFilterType === "services" && (
                                  <img
                                    className="icon"
                                    style={{
                                      width: "26px",
                                      height: "26px",
                                      borderRadius: "10rem",
                                      marginRight: "12px",
                                    }}
                                    src={row?.icon}
                                  />
                                )} */}
                                {/* {organizationFilterType == "serviceTypes" && (
                                  <img
                                    className="icon"
                                    style={{
                                      width: "26px",
                                      height: "26px",
                                      borderRadius: "10rem",
                                      marginRight: "12px",
                                    }}
                                    src={row?.icon}
                                  />
                                )} */}
                                <div>
                                  {organizationFilterType === "locations"
                                    ? row?.name
                                    : organizationFilterType === "users"
                                      ? row?.name
                                      : organizationFilterType ===
                                        "serviceTypes" ||
                                        organizationFilterType === "services"
                                        ? row?.name
                                        : ""}

                                  {/* {
                                    organizationFilterType === "services" &&
                                    <div>
                                      {row?.consultationtypeid}

                                    </div>
                                  } */}
                                </div>
                              </div>
                            </td>

                            {
                              organizationFilterType != "serviceTypes" &&
                              <td
                                style={{
                                  // display: organizationFilterType === "services" ? "flex" : "flex",
                                  justifyContent:
                                    organizationFilterType === "services" &&
                                    "center",
                                  flexDirection:
                                    organizationFilterType === "services" &&
                                    "column",
                                  alignItems:
                                    organizationFilterType === "services" &&
                                    "self-start",
                                }}
                              >
                                <div>
                                  {organizationFilterType === "locations"
                                    ? row?.description
                                    : organizationFilterType === "users"
                                      ? row?.email
                                      : organizationFilterType === "serviceTypes"
                                        ? row?.consultationtypeid
                                        : organizationFilterType === "services"
                                          ? row?.ConsultationTypeDetails?.[0]?.name
                                          : ""}
                                </div>
                                {/* {organizationFilterType === "services" && (
                                  <div>{row?.duration} Hours</div>
                                )} */}
                              </td>
                            }
                            <td>
                              {organizationFilterType === "locations"
                                ? row?.streetNumber
                                : organizationFilterType === "users"
                                  ? row?.PhoneNumber
                                  : organizationFilterType === "serviceTypes" ||
                                    organizationFilterType === "services"
                                    ? row?.duration
                                    : ""}
                            </td>
                            {
                              organizationFilterType != "locations" &&
                              <>

                                <td>
                                  {/* {organizationFilterType === "services" && (
                                    <img
                                      className="icon"
                                      style={{
                                        width: "26px",
                                        height: "26px",
                                        borderRadius: "10rem",
                                        marginRight: "12px",
                                      }}
                                      src={row?.ConsultationTypeDetails?.[0]?.icon}
                                    />
                                  )} */}
                                  {organizationFilterType === "locations"
                                    ? row?._id
                                    : organizationFilterType === "users"
                                      ? row?.key
                                      : organizationFilterType === "services"
                                        ? row?.cost
                                        : organizationFilterType === "serviceTypes"
                                          ? row?.ConsultationServiceCount
                                          : ""}
                                </td>

                                {organizationFilterType === "serviceTypes" ? ("") :

                                  organizationFilterType === "services" ? (
                                    <>
                                      <td>
                                        {row?.locationDetails?.[0]?.name}
                                      </td>
                                      <td
                                        style={{
                                          display: "block"
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "block"
                                          }}
                                        >
                                          {
                                            row?.roomIds?.map(item => {
                                              return (
                                                <div
                                                  style={{
                                                    display: "block",
                                                    marginBottom: "0.4rem"
                                                  }}
                                                >
                                                  {item}

                                                </div>
                                              )
                                            })
                                          }
                                        </div>

                                      </td>
                                    </>
                                  ) : (
                                    <td>
                                      {organizationFilterType === "locations"
                                        ? ""
                                        : organizationFilterType === "users"
                                          ? row?.userid
                                          : ""}
                                    </td>
                                  )}
                              </>
                            }

                          </tr>
                        ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div >
      )}
    </>
  );
};

export default Organization;
