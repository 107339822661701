import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./staffing.module.scss"; import '../../../assets/Scsss/Global.scss'
import ClickOutside from 'react-click-outside';
import Editingpage from '../../EditingPage/EditingPage'
import StaffRoleEing from './EditingPage/EditingPage'
import './mulitselect.scss'
//assets
import { ReactComponent as FilterIcon } from "../../../assets/images/filter.svg";
import {
  CopyBtn, BreakEndDayMarignEachInput, PhoneEachInput, DateBreakDayMarignEachInput,
  CustomCheckbox, EachInputDummy, MarignEachInput, EachCustomDropdownCountry, MRsEachCustomDropdown, BreakEachCustomDropdown,
  CustomCheckboxMultiple, MyEachCustomImageUpload, DayMarignEachInput, EndDayMarignEachInput, BreakDayMarignEachInput,
  EachCustomDropdown, EachCustomDropdownSearch, EachCustomDropdownDate, MarginEachCustomTextarea,
  EachCustomImageUpload,
  EachCustomSelectMultiple, EachCustomSelectMultiples, EachCustomSelectMultiplelist,
  EachCustomTextarea,
  EachInput, StaffEachInputDatePicker, MultiDropdownNestedService,
  EachInputArray,
  EachInputDatePicker, EachInputsepfic
} from "../../../components/Custom";
import Multiselect from 'multiselect-react-dropdown';
import { IoMdArrowDropdown } from "react-icons/io";
import { ReactComponent as SortArrows } from "../../../assets/images/icons/sort.svg";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../../../context/Globalcontext";
import { useNetworkRequest } from "../../../hooks/networkRequest";
import ClanderEditing from "./ClendarEditing/EditingPage"
import {
  deleteData,
  getData,
  updateData,
} from "../../../assets/functions/apiCalls";

import axios from "axios";
import { toastify } from "../../../assets/functions/toastify";
import { filterDetails, getCurrentDate } from "../../../assets/functions";
import {
  staffGroupFormGroupType,
  staffGroupFormModules,
} from "../../../assets/data/mapItems";
import { useHover } from "@uidotdev/usehooks";
import {
  amArray,
  datesArray,
  daysOfWeek,
  monthsArray,
  pmArray,
} from "../../../assets/data/constant";
import { FaAlignJustify } from "react-icons/fa";
import { useClickAway } from "use-click-away";
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import Loading from '../../../assets/images/loadingicon.svg'
import StafGroupEditing from './StaffGroupEditing/EditingPage'
const Staffing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { roleType } = useParams();
  const { email, actionBtns, setActionBtns, globalSearchQuery, editingToggle, seteditingToggle } =
    useContext(GlobalContext);
  const [allOrganization, setAllOrganization] = useState([]);
  const [allTimezones, setAllTimezones] = useState([]);
  const [allStaffRoles, setAllStaffRoles] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [calendarinfo, setcalendarinfo] = useState({})
  const [organizationSelected, setOrganizationSelected] = useState("");

  const { allValues: allLeads, allValuesLoading: allLeadsLoading } =
    useNetworkRequest({
      URL:
        roleType === "staffgroups" && JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername
          ? `https://uatamma.apimachine.com/api/getStaff?teamUsername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername
            ? JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername
            : "Vitality"
          }`
          : roleType === "staffroles" && JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername
            ? `https://uatamma.apimachine.com/api/getStaffRole?teamUsername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername
              ? JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername
              : "Vitality"
            }`
            : roleType === "staff" && JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername
              ? `https://uatamma.apimachine.com/api/getallplayersforteam/${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername
                ? JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername
                : "Vitality"
              }`
              : roleType === "users"
                ? `https://uatamma.apimachine.com/api/getlist?key=${roleType === "users" ? "user" : ""
                }`
                : roleType === "calendar" && JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername
                  ? `https://uatamma.apimachine.com/api/getcalendarperteam?TeamUsername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername
                    ? JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername
                    : "Vitality"
                  }`
                  : "",

    });

  //page values
  let history = useNavigate();
  const [loadingdata, setloadingdata] = useState(false)
  const [filterOpen, setFilterOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  // const [allLeadsLoading, setAllLeadsLoading] = useState(false);
  // const [allLeads, setAllLeads] = useState(tempLeads);
  const [allBrokeragesSearch, setAllBrokeragesSearch] = useState("");
  const [checkBoxStage, setCheckBoxStage] = useState("");
  const [drawerObject, setDrawerObject] = useState("");
  const [tempArray, setTempArray] = useState([]);
  const [calendardrawer, setcalendardrawer] = useState(false)
  const [staffGroupForm, setStaffGroupForm] = useState({
    teamUsername: JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername,
    groupType: "",
    groupName: "",
    groupDesc: "",
    modules: [],
  });
  const [staffProfileForm, setStaffProfileForm] = useState({
    TeamPlayerID: "",
    name: "",
    email: "",
    phoneNumber: "",
    address: "",
    employeeId: "",
    employeeType: "",
    employeeStatus: "",
    location: "",
    title: "",
    role: "",
    city: "",
    dateOfJoining: "",
    dateOfRelieving: "",
    birthday: "",
    age: "",
    gender: "",
    resume: "",
    NationalId: "",
  });


  const [staffdata, setstaffdata] = useState({
    name: "",
    email: "",
    gendertype: "",
    gendersignature: "Mr",
    dob: "",
    phoneNumber: "", username: "", CalendarNameId: "",
    city: "",
    employeeId: "",
    gender: "",
    affilatemail: "",
    affilateId: "", locations: "", locationsid: "",
    address: "",
    picode: "", employeeType: "", employeeStatus: "",
    Birthday: "",
    TeamPlayerID: "",
    state: "",
    country: "",
    photo: "",
    govid: "",
    staffrole: "",
    staffroleId: "",
    calendartemplate: "",
    Dateofjoining: "",
    specialistToggle: "",
    type: "",
    specialistIds: [

    ],
    // employeeId: "",
    // employeeType: "",
    // employeeStatus: "",
    // location: "",
    // title: "",
    // role: "",
    // dateOfJoining: "",
    // dateOfRelieving: "",
    // birthday: "",
    // age: "",

    // resume: "",
    // NationalId: "",
  });




  // setDaysInMonth(days);    


  const [staffRolesForm, setStaffRolesForm] = useState({
    teamUsername: JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername,
    name: "",
    description: "",
    staffGroupIds: []
  });

  const [calendarForm, setCalendarForm] = useState({
    CalendarName: "",
    CalendarDescription: "",
    TeamUsername: "",
    AnnualHolidays: [
      {
        month: "",
        date: "",
      },
    ],
    WorkingDays: [
      {
        Day: "Monday",
        startHours: "",
        startMint: "",
        endHours: "",
        endMint: "",
        startAA: "",
        endAA: "",
        breakstart: [
          {
            startHours: "",
            startMint: "",
            endHours: "",
            endMint: "",
            startAA: "",
            endAA: "",
          }
        ],
      },
      {
        Day: "Tuesday",
        StartTime: "",
        StartTimeText: "",
        EndTime: "",
        EndTimeText: "",
        breakstart: [
          {
            startHours: "",
            startMint: "",
            endHours: "",
            endMint: "",
            startAA: "",
            endAA: "",
          }
        ],
      },
      {
        Day: "Wednesday",
        StartTime: "",
        StartTimeText: "",
        EndTime: "",
        EndTimeText: "",
        breakstart: [
          {
            startHours: "",
            startMint: "",
            endHours: "",
            endMint: "",
            startAA: "",
            endAA: "",
          }
        ],
      },
      {
        Day: "Thursday",
        StartTime: "",
        StartTimeText: "",
        EndTime: "",
        EndTimeText: "",
        breakstart: [
          {
            startHours: "",
            startMint: "",
            endHours: "",
            endMint: "",
            startAA: "",
            endAA: "",
          }
        ],
      },
      {
        Day: "Friday",
        StartTime: "",
        StartTimeText: "",
        EndTime: "",
        EndTimeText: "",
        breakstart: [
          {
            startHours: "",
            startMint: "",
            endHours: "",
            endMint: "",
            startAA: "",
            endAA: "",
          }
        ],
      },
      {
        Day: "Saturday",
        StartTime: "",
        StartTimeText: "",
        EndTime: "",
        EndTimeText: "",
        breakstart: [
          {
            startHours: "",
            startMint: "",
            endHours: "",
            endMint: "",
            startAA: "",
            endAA: "",
          }
        ],
      },
      {
        Day: "Sunday",
        StartTime: "",
        StartTimeText: "",
        EndTime: "",
        EndTimeText: "",
        breakstart: [
          {
            startHours: "",
            startMint: "",
            endHours: "",
            endMint: "",
            startAA: "",
            endAA: "",
          }
        ],
      },
    ],
    TimeZone: "",
    TimeZoneAbbrevation: "",
  });
  function pad(str, max) {
    return str.length < max ? pad("0" + str, max) : str;
  }

  console.log("calendarForm", calendarForm)

  const [selectedindex, setselectedindex] = useState(0)

  const [datesArraylist, setdatesArraylist] = useState([])


  const onSelect = (selectedList, selectedItem) => {
    console.log("calendarForm", selectedItem)

    setstaffdata({
      ...staffdata,
      specialistIds: [...staffdata?.specialistIds, {
        name: selectedItem?.name,
        id: selectedItem?.consultationserviceid
      }]



      // specialistIds: calendarForm.specialistIds.map((item, i) =>
      //   i === id ? {
      //     ...item, breakstart: [...item?.breakstart, {
      //       StartTime: "",
      //       StartTimeText: "",
      //       EndTime: "",
      //       EndTimeText: "",
      //     }]


      //   } : item
      // )
    })



    // let privous = staffdata.specialistIds
    // let reult = { ...staffdata, specialistIds: name = "asdd" }
    // console.log("selectedList", reult)

    // const newData = { ...stateValue }; // Shallow copy of the outer array

    // const newItems = [...newData?.[name]];
    // newItems[indexx].id = item?.consultationserviceid;
    // newItems[indexx].name = item?.name;
    // console.log("czxczxc", newItems);

  }


  const onRemove = (selectedList, removedItem) => {
    // setstaffdata({
    //   ...staffdata,
    //   specialistIds: [...staffdata?.specialistIds, {
    //     name: selectedItem?.name,
    //     id: selectedItem?.consultationserviceid
    //   }]

    const filteredItems = staffdata?.specialistIds.filter(item => item.id !== removedItem?.consultationserviceid);
    setstaffdata({
      ...staffdata,
      specialistIds: [...filteredItems]
    })

    //   filteredItems);


  }
  console.log("filteredItems", staffdata)
  useEffect(() => {
    cal()
    // alert()

  }, [calendarForm, selectedindex])

  const addbreak = (id) => {
    setCalendarForm({
      ...calendarForm,
      WorkingDays: calendarForm.WorkingDays.map((item, i) =>
        i === id ? {
          ...item, breakstart: [...item?.breakstart, {
            startHours: "",
            startMint: "",
            endHours: "",
            endMint: "",
            startAA: "",
            endAA: "",
          }]


        } : item
      )
    })
  }
  // return {
  //   ...prevData,
  //   WorkingDays: prevData.WorkingDays.map((item, i) =>
  //     i === id ? { ...item, StartTimeText: newText } : item
  //   )
  // }
  const addmore = () => {

    // return {
    //   ...stateValue,
    //   [name]:
    //     (calendarObjField === "date" ||
    //       calendarObjField === "month") &&
    //       areAllFieldsFilled(updatedAnnualHolidays[indexx])
    //       ? [
    //         ...updatedAnnualHolidays,
    //         { month: "", date: "" },
    //       ]
    //       : updatedAnnualHolidays,
    // };


    if (calendarForm?.AnnualHolidays?.[calendarForm?.AnnualHolidays?.length - 1]?.month != "") {
      setCalendarForm({ ...calendarForm, AnnualHolidays: [...calendarForm?.AnnualHolidays, { month: "", date: "" }] });

    }
    // calendarForm?.AnnualHolidays?.map((item, i) => {
    //   console.log(item?.month, "knxcvnxjvncx")
    //   if (item?.month == "") {
    //     alert("empty")
    //     return;
    //   }
    //   else {

    //     return
    //   }

    // })


    // let newArray = [calendarForm]?.map(item => ({ ...item, [...item?.AnnualHolidays] }));


    // let aiodaisd = [...calendarForm?.AnnualHolidays, { month: "", date: "" }]
    // console.log("aiodaisd", newArray)
    // setCalendarForm(aiodaisd)


  }

  const cal = () => {

    const monthsArray = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
    let s = 1


    s = monthsArray?.indexOf(calendarForm?.AnnualHolidays?.[selectedindex]?.month) + 1

    // s = calendarForm?.AnnualHolidays[selectedindex]
    // calendarForm?.AnnualHolidays?.map(item => {



    //   s = monthsArray?.indexOf(item?.month) + 1


    // })
    const days = new Date(2024, s, 0).getDate();

    // const randomNumber = Math?.random() * 100;


    console.log("ssss", days, s)
    let dates = Array?.from({ length: days }, (_, index) =>
      (index + 1)?.toString()
    )
    setdatesArraylist(dates)

    return;













    // datesArraylist = Array?.from({ length: 9 }, (_, index) =>
    //   (index + 1).toString()
    // )

  }


  const filterDropdownRef = useRef(null);
  const selectedItemTable = useRef(null);
  const navRightBox = useRef(null);

  const filterItems = [];

  const filterItemsUsers = [
    { name: "Add to staff", to: "confirmAddStaff" },
    { name: "Edit user profile", to: "" },
    { name: "Delete user", to: "" },
  ];

  const filterItemsUsersCnfm = [
    { name: "Yes", to: makeUserToPlayer, type: "func" },
    { name: "Never mind", to: neverMindd, type: "func" },
  ];

  const filterItemsDeleteStaffGroup = [
    {
      name: "Edit staff roles in this staff group",
      to: "editStaffRoleStaffGroup",
    },
    { name: "See staff group details", to: "staffGroupDrawer" },
    { name: "Delete staff group", to: "confirmDeleteStaffGroup" },
  ];

  const filterItemsDeleteStaffGroupCnfm = [
    { name: "Yes", to: deleteStaffGroup, type: "func" },
    { name: "Never mind", to: neverMindd, type: "func" },
  ];

  const filterItemsDeleteStaffRoles = [
    { name: "Edit staff roles", to: "" },
    { name: "Delete staff roles", to: "confirmDeleteStaffRoles" },
  ];

  const filterItemsDeleteStaffRolesCnfm = [
    { name: "Yes", to: deleteStaffRoles, type: "func" },
    { name: "Never mind", to: neverMindd, type: "func" },
  ];

  const filterItemsStaff = [
    { name: "See staff profile" },
    { name: "Add to staff" },
    { name: "Edit staff profile", to: checkUserStaffProfile, type: "func" },
    { name: "Delete staff", to: "deleteStaff" },
  ];

  const filterItemsDeleteCalendar = [
    { name: "Edit calendar", to: "" },
    { name: "Delete calendar", to: "confirmDeleteCalendar" },
  ];

  const filterItemsDeleteCalendarCnfm = [
    { name: "Yes", to: deleteCalendar, type: "func" },
    { name: "Never mind", to: neverMindd, type: "func" },
  ];

  //functions
  const hover = useHover({ backgroundColor: "red" })
  async function deleteStaff() {
    // setCheckBoxStage("");
    if (actionBtns?.length > 0) {
      let response = await deleteData(
        `https://uatamma.apimachine.com/api/removeplayerfromteam?TeamPlayerID=${actionBtns?.length > 0 ? actionBtns[0] : ""
        }`
      );
      if (response?.success) {
        toastify("Successfully deleted staff ", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toastify("Error while deleting staff ", "error");
      }
      console.log(response, "Delete staff response");
    }
  }



  const [sepcialdata, setsepcialdata] = useState([])

  useEffect(() => {
    hetconstultant()
  }, [])

  const hetconstultant = () => {

    axios
      .get(`https://uatamma.apimachine.com/api/getconsultationservice?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`)
      .then((res) => {
        if (res.data.success) {
          setsepcialdata(res?.data?.data)
          // setconstitid(res?.data?.data?.[0]?.consultationserviceid)
          // getservice(res?.data?.data?.[0]?.consultationserviceid)
        }
        else {
          setsepcialdata([])
        }
        //



      });
  }





  async function checkUserStaffProfile() {
    let response = await getData(
      `https://uatamma.apimachine.com/api/getTeamPlayerProfile?TeamPlayerID=${actionBtns?.length > 0 ? actionBtns[0] : ""
      }`
    );
    if (response?.success) {
      // setAllOrganization([...response?.data]);
      setCheckBoxStage("editStaffProfile");
    } else {
      setCheckBoxStage("createStaffProfile");
    }
    console.log(response, "checkUserStaffProfile response");
  }

  async function editStaffrolesStaffgroups() {
    const uniqueArray = [...new Set(tempArray)];

    if (actionBtns?.length > 0) {
      let response = await updateData(
        `https://uatamma.apimachine.com/api/editStaff/${actionBtns?.length > 0 ? actionBtns[0] : ""
        }`,
        {
          staffRoleIds: uniqueArray,
        }
      );
      if (response?.success) {
        toastify(
          "Successfully edited staff roles in this staff group ",
          "success"
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toastify(
          "Error while editing staff roles in this staff group ",
          "error"
        );
      }
      console.log(response, "Edit staff roles in this staff group response");
    }
  }

  async function deleteCalendar() {
    if (actionBtns?.length > 0) {
      let response = await deleteData(
        `https://uatamma.apimachine.com/api/deletecalendar/${actionBtns?.length > 0 ? actionBtns[0] : ""
        }`
      );
      if (response?.success) {
        toastify("Successfully deleted calendar", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toastify("Error while deleting calendar", "error");
      }
      console.log(response, "calendar deleted response");
    }
  }

  async function deleteStaffGroup() {
    // setCheckBoxStage("");
    if (actionBtns?.length > 0) {
      let response = await deleteData(
        `https://uatamma.apimachine.com/api/deleteStaff/${actionBtns?.length > 0 ? actionBtns[0] : ""
        }`
      );
      if (response?.success) {
        toastify("Successfully deleted staff group", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toastify("Error while deleting staff group", "error");
      }
      console.log(response, "organization response");
    }
  }

  async function deleteStaffRoles() {
    // setCheckBoxStage("");
    if (actionBtns?.length > 0) {
      let response = await deleteData(
        `https://uatamma.apimachine.com/api/deleteStaffRole/${actionBtns?.length > 0 ? actionBtns[0] : ""
        }`
      );
      if (response?.success) {
        toastify("Successfully deleted staff roles", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toastify("Error while deleting staff roles", "error");
      }
      console.log(response, "organization response");
    }
  }

  function neverMindd() {
    setCheckBoxStage("");
    setActionBtns([]);
  }

  async function addStaffProfileForm() {
    let obj = {
      ...staffProfileForm,
      TeamPlayerID: actionBtns?.length > 0 ? actionBtns[0] : "",
    };
    let response = await axios.post(
      "https://uatamma.apimachine.com/api/addTeamPlayerProfile",
      obj
    );
    if (response?.data?.status) {
      toastify("Added staff profile succesfully!", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      toastify("Error while trying to add staff profile", "error");
    }
    console.log(response, "response from Add staff profile");
    // console.log(obj, "staffProfileForm filled");
  }

  async function addStaffGroupForm() {
    let response = await axios.post(
      "https://uatamma.apimachine.com/api/addStaff",
      staffGroupForm
    );
    if (
      response?.data?.status ||
      response?.data?.message === "user staff added"
    ) {
      toastify("Added staff group succesfully!", "success");
      setCheckBoxStage("");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toastify("Error while trying to add staff group", "error");
    }
    console.log(response, "response from Add staff group");
  }

  // const addbreak = (id) => {
  //   setCalendarForm({
  //     ...calendarForm,
  //     WorkingDays: calendarForm.WorkingDays.map((item, i) =>
  //       i === id ? {
  //         ...item, breakstart: [...item?.breakstart, {
  //           StartTime: "",
  //           StartTimeText: "",
  //           EndTime: "",
  //           EndTimeText: "",
  //         }]


  //       } : item
  //     )
  //   })
  // }
  const copyforwholenexday = (e) => {
    // alert(e)
    let indexselete = e
    let addindex = e + 1
    let anser = {
      ...calendarForm,
      WorkingDays: calendarForm.WorkingDays.map((item, i) =>
        i == addindex ? {
          Day: item?.Day,


          startHours: calendarForm?.WorkingDays?.[indexselete]?.startHours,
          startMint: calendarForm?.WorkingDays?.[indexselete]?.startMint,
          endHours: calendarForm?.WorkingDays?.[indexselete]?.endHours,
          endMint: calendarForm?.WorkingDays?.[indexselete]?.endMint,
          startAA: calendarForm?.WorkingDays?.[indexselete]?.startAA,
          endAA: calendarForm?.WorkingDays?.[indexselete]?.endAA,


          // StartTime: calendarForm?.WorkingDays?.[indexselete]?.StartTime,
          // StartTimeText: calendarForm?.WorkingDays?.[indexselete]?.StartTimeText,
          // EndTime: calendarForm?.WorkingDays?.[indexselete]?.EndTime,
          // EndTimeText: calendarForm?.WorkingDays?.[indexselete]?.EndTimeText,
          breakstart: item?.breakstart?.map(eaxh => {
            return {

              startHours: calendarForm?.WorkingDays?.[indexselete]?.breakstart?.[indexselete]?.startHours,
              startMint: calendarForm?.WorkingDays?.[indexselete]?.breakstart?.[indexselete]?.startMint,
              endHours: calendarForm?.WorkingDays?.[indexselete]?.breakstart?.[indexselete]?.endHours,
              endMint: calendarForm?.WorkingDays?.[indexselete]?.breakstart?.[indexselete]?.endMint,
              startAA: calendarForm?.WorkingDays?.[indexselete]?.breakstart?.[indexselete]?.startAA,
              endAA: calendarForm?.WorkingDays?.[indexselete]?.breakstart?.[indexselete]?.endAA,


              // StartTime: calendarForm?.WorkingDays?.[indexselete]?.breakstart?.[indexselete]?.StartTime,
              // StartTimeText: calendarForm?.WorkingDays?.[indexselete]?.breakstart?.[indexselete]?.StartTimeText,
              // EndTime: calendarForm?.WorkingDays?.[indexselete]?.breakstart?.[indexselete]?.EndTime,
              // EndTimeText: calendarForm?.WorkingDays?.[indexselete]?.breakstart?.[indexselete]?.EndTimeText,
            }
          })


        } : item
      )
    }


    console.log("anser", anser)



  }


  //   useEffect(() => {
  //     {
  //       calendarForm?.WorkingDays?.map((such, i) => {




  // console.log("startingTime",startingTime)
  //         // }
  //       })
  //     }
  //   }, [calendarForm])

  const copyforwhole = () => {

    let asdas = [
      { id: 1, value: 'Initial 1' },

    ]
    let newArray = [];

    // {
    //   calendarForm?.WorkingDays?.map((such, i) => {
    //     if (calendarForm?.WorkingDays?.[i]?.startHours == "" && calendarForm?.WorkingDays?.[i]?.startMint == "" && calendarForm?.WorkingDays?.[i]?.endHours == "" && calendarForm?.WorkingDays?.[i]?.startAA == "" && calendarForm?.WorkingDays?.[i]?.startAA == "" && calendarForm?.WorkingDays?.[i]?.endMint == "") {



    //       return
    //     }
    //   })
    // }
    // if (item?.startHours == "" && item?.startMint == "" || item?.endHours == "" || item?.startAA == "" || item?.startAA == "" || item?.endMint == "") {

    // }




    newArray = newArray.concat({

      ...calendarForm, WorkingDays: calendarForm?.WorkingDays?.map(item => {

        return {
          Day: item?.Day,

          startHours: calendarForm?.WorkingDays?.[0]?.startHours,
          startMint: calendarForm?.WorkingDays?.[0]?.startMint,
          endHours: calendarForm?.WorkingDays?.[0]?.endHours,
          endMint: calendarForm?.WorkingDays?.[0]?.endMint,
          startAA: calendarForm?.WorkingDays?.[0]?.startAA,
          endAA: calendarForm?.WorkingDays?.[0]?.endAA,
          breakstart: calendarForm?.WorkingDays?.[0]?.breakstart,

          // StartTime: calendarForm?.WorkingDays?.[0]?.StartTime,
          // StartTimeText: calendarForm?.WorkingDays?.[0]?.StartTimeText,
          // EndTime: calendarForm?.WorkingDays?.[0]?.EndTime,
          // EndTimeText: calendarForm?.WorkingDays?.[0]?.EndTimeText,

        }
      })
    })





    setCalendarForm(
      ...newArray
    )


    // for (let i = 0; i < 6; i++) {
    //   newArray = newArray.concat({
    //     ...calendarForm, WorkingDays: calendarForm?.WorkingDays?.map(item => {
    //       return {
    //         Day: item?.Day,
    //         StartTime: calendarForm?.WorkingDays?.[0]?.StartTime,
    //         StartTimeText: calendarForm?.WorkingDays?.[0]?.StartTimeText,
    //         EndTime: calendarForm?.WorkingDays?.[0]?.EndTime,
    //         EndTimeText: calendarForm?.WorkingDays?.[0]?.EndTimeText,
    //         breakstart: calendarForm?.WorkingDays?.[0]?.breakstart,
    //       }
    //     })
    //   });
    // }
    console.log("newArray", newArray)
    //   let WorkingDaysasdassdeleted = {
    //     ...calendarForm, WorkingDays:
    //       calendarForm?.WorkingDays?.map(item => {
    //         return item?.StartTimeText == ''
    //       })

    //   }


    //   let newArray = [];
    //   for (let i = 0; i < times; i++) {
    //     newArray = newArray.concat(array);
    //   }
    //   return newArray;
    // };


    // let zzz = {
    //   ...calendarForm, WorkingDays:

    //     calendarForm?.WorkingDays?.map((item, i) => {

    //         return {

    //           Day: item?.Day,
    //           StartTime: calendarForm?.WorkingDays?.[0]?.StartTime,
    //           StartTimeText: calendarForm?.WorkingDays?.[0]?.StartTimeText,
    //           EndTime: calendarForm?.WorkingDays?.[0]?.EndTime,
    //           EndTimeText: calendarForm?.WorkingDays?.[0]?.EndTimeText,
    //           breakstart: item?.breakstart?.map(eaxh => {
    //             return {
    //               StartTime: calendarForm?.WorkingDays?.[0]?.breakstart?.[0]?.StartTime,
    //               StartTimeText: calendarForm?.WorkingDays?.[0]?.breakstart?.[0]?.StartTimeText,
    //               EndTime: calendarForm?.WorkingDays?.[0]?.breakstart?.[0]?.EndTime,
    //               EndTimeText: calendarForm?.WorkingDays?.[0]?.breakstart?.[0]?.EndTimeText,
    //             }
    //           })




    //         }

    //     })
    // }

    // setCalendarForm({
    //   ...calendarForm,
    //   WorkingDays: calendarForm.WorkingDays.map((item, i) => {
    //     return {
    //       ...item,
    //       breakstart: item?.breakstart?.map((eaxh, z) => {
    //         return {
    //           StartTime: "",
    //           StartTimeText: "",
    //           EndTime: "",
    //           EndTimeText: "",
    //         }
    //       })



    //     }
    //   }

    //   )
    // })


    // setCalendarForm({
    //   ...calendarForm, WorkingDays:

    //     calendarForm?.WorkingDays?.map((item, i) => {
    //       return {
    //         Day: item?.Day,
    //         StartTime: calendarForm?.WorkingDays?.[0]?.StartTime,
    //         StartTimeText: calendarForm?.WorkingDays?.[0]?.StartTimeText,
    //         EndTime: calendarForm?.WorkingDays?.[0]?.EndTime,
    //         EndTimeText: calendarForm?.WorkingDays?.[0]?.EndTimeText,
    //         breakstart: item?.breakstart?.map((eaxh,z) => {
    //           return {
    //             StartTime: calendarForm?.WorkingDays?.[0]?.breakstart?.[z]?.StartTime,
    //             StartTimeText: calendarForm?.WorkingDays?.[0]?.breakstart?.[z]?.StartTimeText,
    //             EndTime: calendarForm?.WorkingDays?.[0]?.breakstart?.[z]?.EndTime,
    //             EndTimeText: calendarForm?.WorkingDays?.[0]?.breakstart?.[z]?.EndTimeText,
    //           }
    //         })




    //       }
    //     })
    // })



    //console.log("WorkingDaysasdas", WorkingDaysasdass)
  }

  console.log("selectedindex", calendarForm)


  async function addCalendarForm() {
    calendarForm.TeamUsername = JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername;
    // console.log(calendarForm, "form value is ");

    // CalendarName: "",
    //   CalendarDescription: "",
    //     TeamUsername: "",
    //       AnnualHolidays: [
    //         {
    //           month: "",
    //           date: "",
    //         },
    //       ],
    //         WorkingDays: [
    //           {
    //             Day: "Monday",
    //             startHours: "",
    //             startMint: "",
    //             endHours: "",
    //             endMint: "",
    //             startAA: "",
    //             endAA: "",
    //             breakstart: [
    //               {
    //                 startHours: "",
    //                 startMint: "",
    //                 endHours: "",
    //                 endMint: "",
    //                 startAA: "",
    //                 endAA: "",
    //               }
    //             ],
    //           },

    let WorkingDaysasdassdeleted = {
      ...calendarForm, WorkingDays:
        calendarForm?.WorkingDays?.filter(item => {
          return item?.startHours
        })

    }
    console.log("WorkingDaysasdassdeleted", WorkingDaysasdassdeleted)


    let calendermap = [WorkingDaysasdassdeleted].map(item => {




      return {
        "CalendarName": item?.CalendarName,
        "CalendarDescription": item?.CalendarDescription,
        "TeamUsername": JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername,
        "AnnualHolidays": [...item?.AnnualHolidays
        ],
        "WorkingDays": item?.WorkingDays?.map(such => {


          return {
            "Day": such?.Day,
            "StartTime": `${pad((such?.startHours)?.toString(), 2)}:${pad((such?.startMint)?.toString(), 2)}:${such?.startAA}`,

            "BreakTimes": such?.breakstart?.map(each => {
              return {
                "breakStartTime": `${pad((each?.startHours)?.toString(), 2)}:${pad((each?.startMint)?.toString(), 2)}:${each?.startAA}`,
                "breakEndTime": `${pad((each?.endHours)?.toString(), 2)}:${pad((each?.endMint)?.toString(), 2)}:${each?.endAA}`,
              }
            }),
            "EndTime": `${pad((such?.endHours)?.toString(), 2)}:${pad((such?.endMint)?.toString(), 2)}:${such?.endAA}`,
          }
        }),
        "TimeZone": item?.TimeZone


      }
    })
    console.log("calendermap", calendermap)

    let response = await axios.post(
      "https://uatamma.apimachine.com/api/createcalendar",
      ...calendermap
    );
    if (response?.data?.success) {
      toastify("Calendar created succesfully!", "success");
      setCheckBoxStage("");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toastify("Error while trying create calendar", "error");
    }



  }

  async function addStaffRolesForm() {
    let response = await axios.post(
      "https://uatamma.apimachine.com/api/addStaffRole",
      staffRolesForm
    );
    if (
      response?.data?.status ||
      response?.data?.message === "staff role added"
    ) {
      toastify("Added staff roles succesfully!", "success");
      setCheckBoxStage("");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toastify("Error while trying to add staff roles", "error");
    }
    console.log(response, "response from Add staff roles");
  }

  async function playerToTeam(teamUsername) {
    let result = await filterDetails(allLeads?.data, actionBtns[0]);

    let response = await axios.post(
      "https://uatamma.apimachine.com/api/addplayerstoteam",
      {
        TeamOwnerEmail: email,
        PlayerUsername: result[0]?.username,
        StartDate: "",
        TeamUsername: teamUsername,
      }
    );
    if (response?.data?.success) {
      toastify("Added player to the selected team", "success");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toastify("Player data not found", "error");
    }
    console.log(response, "response from make user to player");
  }

  async function getOrganizations() {
    let response = await getData(
      `https://uatamma.apimachine.com/api/getteamsbyowner?TeamCreatedBy=${email}`
    );
    if (response?.success) {
      setAllOrganization([...response?.data]);
    }
    console.log(response, "organization response");
  }

  async function getTimezones() {
    let response = await getData(`https://uatamma.apimachine.com/api/timezones`);
    if (response?.success) {
      setAllTimezones([...response?.data]);
    }
    console.log(response, "timezones response");
  }

  async function getStaffRoles() {
    if (JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername) {
      let response = await getData(
        `https://uatamma.apimachine.com/api/getStaffRole?teamUsername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername}`
      );
      if (response?.success) {
        setAllStaffRoles(response?.data);
      }
      console.log(response, "all staff roles response");
    }
  }

  async function getallLocations() {
    if (JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername) {
      let response = await getData(
        `https://uatamma.apimachine.com/api/getlocation?teamUsername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername}`
      );
      if (response?.success) {
        setAllLocations(response?.data);
      }
      console.log(response, "location response");
    }
  }

  async function makeUserToPlayer() {
    let result = await filterDetails(allLeads?.data, actionBtns[0]);
    // console.log(actionBtns, "playerEmail", result);
    let response = await axios.put(
      "https://uatamma.apimachine.com/api/addplayer",
      {
        email: result[0]?.email,
      }
    );
    if (response?.data?.success) {
      toastify("Updated user to player", "success");
      setTimeout(() => {
        setCheckBoxStage("playerToSpecificTeam");
      }, 500);
    } else {
      toastify("Error while converting user to player", "error");
    }
    console.log(response, "response from make user to player");
  }

  useEffect(() => {
    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target)
      ) {
        setFilterOpen(false);
      }
      if (
        selectedItemTable.current &&
        !selectedItemTable.current.contains(event.target)
      ) {
        setCheckBoxStage("");
        setActionBtns([]);
      }
      // if (navRightBox.current && !navRightBox.current.contains(event.target)) {
      //   setCheckBoxStage("");
      // }
    };

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getOrganizations();
    getStaffRoles();
    getallLocations();
    getTimezones();
  }, [JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername]);
  const ref = useRef(null);
  useEffect(() => {
    console.log(checkBoxStage, "21312312312")
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        // if (checkBoxStage != "") {
        //   setCheckBoxStage("")
        // }
      }
      else {

      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref]);
  const [checkName, setcheckName] = useState(false)
  useEffect(() => {
    if (staffdata?.username != "") {

      const timer = setTimeout(() => {
        cmailName();
      }, 2000);

      // Clean up the timer to avoid memory leaks
      return () => clearTimeout(timer);


    }
    else {
      setcheckName(false)
    }

  }, [staffdata?.username])


  const [calndertemp, setcalndertemp] = useState([])
  useEffect(() => {
    checkcledner()
  }, [])
  const checkcledner = () => {


    axios
      .get(`https://uatamma.apimachine.com/api/getcalendarperteam?TeamUsername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername}`)
      .then((res) => {
        if (res?.data.success) {
          setcalndertemp(res.data?.data)
        }
        else {
          setcalndertemp([])
        }

      });
  }


  const cmailName = () => {


    axios
      .get(`https://comms.globalxchange.io/user/profile/data/get?username=${staffdata?.username}`)
      .then((res) => {
        if (res?.data.count > 0) {
          // setrefmail(res?.data?.usersData?.[0])

          setcheckName(false)
          // setshowrefbox(false)
          // setanswerref(true)
          // toastify("Updated user to patient", "success");
        }
        else {
          // setrefmail({})
          setcheckName(true)
          // toastify("error", "error");
          // setshowrefbox(true)
        }

      });
  }

  const [checkemail, setcheckemail] = useState(false)
  useEffect(() => {
    if (staffdata?.affilatemail != "") {

      const timer = setTimeout(() => {
        cmailcheck();
      }, 2000);

      // Clean up the timer to avoid memory leaks
      return () => clearTimeout(timer);


    }
    else {
      setcheckemail(false)
    }

  }, [staffdata?.affilatemail])

  const cmailcheck = () => {

    axios
      .get(`https://comms.globalxchange.io/user/profile/data/get?email=${staffdata?.affilatemail?.toLocaleLowerCase()}`)
      .then((res) => {
        if (res?.data.count > 0) {
          // setrefmail(res?.data?.usersData?.[0])
          setcheckemail(false)
          // setshowrefbox(false)
          // setanswerref(true)
          //   toastify("Updated user to patient", "success");
        }
        else {

          setcheckemail(true)
          // setrefmail({})
          // toastify("error", "error");
          // setshowrefbox(true)
        }

      });

  }
  const addmorespicalid = (e) => {
    e.stopPropagation();
    if (sepcialdata.filter(function (cv) {
      return !staffdata?.specialistIds.find(function (e) {
        return e.id == cv.consultationserviceid;
      })
    })?.length > 0) {
      if (staffdata?.specialistIds[staffdata?.specialistIds?.length - 1]?.name != "") {
        setstaffdata({ ...staffdata, specialistIds: [...staffdata?.specialistIds, { id: "", name: "" }] });
        // }
        return

      }
    }


  }
  console.log("2342342fgdfgdfgfg", staffdata)
  const submitstaff = () => {


    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + '/' + mm + '/' + yyyy;

    let specialistIds = staffdata?.specialistIds?.map(item => {
      return item?.id
    })
    //
    if (checkemail && checkName) {
      if (staffdata?.specialistToggle?.[0] == "Yes") {
        //  alert(staffdata?.injmenthod?.[0]?.toLowerCase())
        setloadingdata(true)
        axios.post(

          'https://uatamma.apimachine.com/api/registerusertoteam',
          {
            "username": staffdata?.username,
            "name": staffdata?.name,
            "icon": staffdata?.photo,
            "email": staffdata?.affilatemail,

            "YearOfBirth": staffdata?.Birthday?.split('/')?.[2],
            "ProfilePic": staffdata?.photo,
            "Country": staffdata?.country,
            "Address": staffdata?.address,
            "employeeId": staffdata?.employeeId,
            "employeeType": staffdata?.employeeType,
            "employeeStatus": staffdata?.employeeStatus,
            "location": staffdata?.locationsid,
            "title": staffdata?.gendersignature,
            "role": staffdata?.staffroleId,
            "city": staffdata?.City,
            "dateOfJoining": staffdata?.Dateofjoining,
            "age": today?.split('/')?.[2] - staffdata?.Birthday?.split('/')?.[2],
            "NationalId": staffdata?.govid,
            "pincode": staffdata?.picode,
            "state": staffdata?.state,
            "birthday": staffdata?.Birthday,
            "Gender": staffdata?.gendertype?.[0],
            "PhoneNumber": staffdata?.phoneNumber,
            "TeamCalendarID": staffdata?.CalendarNameId,
            "TeamUsername": JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername,
            "ref_affiliate": "6535e2f65958ffca65b865ef21fde26f0b8406ca",


            "specialist": staffdata?.specialistToggle?.[0] == "Yes" && true,
            // {
            //   staffdata?.specialistToggle?.[0] &&
            // },
            //          
            "serviceids": [...specialistIds],
            "type": staffdata?.injmenthod?.[0]?.toLowerCase(),

          }











        ).then(({ data }) => {
          if (data.status) {
            toastify("Added succesfully!", "success");
            setCheckBoxStage("");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
            setloadingdata(false)
          }
          else {
            setloadingdata(false)
            toastify(data?.message, "error");
          }
        })
      }
      else {
        //  alert("p")
        setloadingdata(true)
        axios.post(

          'https://uatamma.apimachine.com/api/registerusertoteam',
          {
            "username": staffdata?.username,
            "name": staffdata?.name,
            "icon": staffdata?.photo,
            "email": staffdata?.affilatemail,

            "YearOfBirth": staffdata?.Birthday?.split('/')?.[2],
            "ProfilePic": staffdata?.photo,
            "Country": staffdata?.country,
            "Address": staffdata?.address,
            "employeeId": staffdata?.employeeId,
            "employeeType": staffdata?.employeeType,
            "employeeStatus": staffdata?.employeeStatus,
            "location": staffdata?.locationsid,
            "title": staffdata?.gendersignature,
            "role": staffdata?.staffroleId,
            "city": staffdata?.City,
            "dateOfJoining": staffdata?.Dateofjoining,
            "age": today?.split('/')?.[2] - staffdata?.Birthday?.split('/')?.[2],
            "NationalId": staffdata?.govid,
            "pincode": staffdata?.picode,
            "state": staffdata?.state,
            "birthday": staffdata?.Birthday,
            "Gender": staffdata?.gendertype?.[0],
            "PhoneNumber": staffdata?.phoneNumber,
            "TeamCalendarID": staffdata?.CalendarNameId,
            "TeamUsername": JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername,
            "ref_affiliate": "6535e2f65958ffca65b865ef21fde26f0b8406ca",
          }











        ).then(({ data }) => {
          if (data.status) {
            toastify("Added succesfully!", "success");
            setCheckBoxStage("");
            setTimeout(() => {
              window.location.reload();
            }, 2000);

          }
          else {
            setloadingdata(false)
            toastify(data?.message, "error");
          }
        })
      }
    }
    else {
      toastify("Invalid Data", "error");

    }
  }


  console.log("plplp", staffdata)
  // const handleClickInside = () => {
  //   setCheckBoxStage("");
  // };
  // const handleClickOutside1 = () => {
  //   setCheckBoxStage("");
  // };
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside1 = () => {
    setIsOpen(false); // Close the div when clicked outside
  };

  const handleClickInside1 = () => {

    setIsOpen(!isOpen); // Toggle the isOpen state when clicked inside
  };
  const clickRef = React.useRef("");

  useClickAway(clickRef, () => {
    setCheckBoxStage("");
    setActionBtns([]);
  });

  useEffect(() => {
    if (actionBtns?.length > 0) {
      setCheckBoxStage("");
    }
  }, [actionBtns]);

  useEffect(() => {
    setActionBtns([]);
  }, [location]);

  useEffect(() => {
    constultandDatafunction()
    getrole()
  }, [])

  const [stafdropdown, setstafdropdown] = useState([])
  const [roledrop, setroledrop] = useState([])
  const getrole = () => {
    axios
      .get(`https://uatamma.apimachine.com/api/getStaffRole?teamUsername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`)
      .then((res) => {
        if (res?.data?.success) {
          setroledrop(res?.data?.data)
        }
        else {
          setroledrop([])
        }
        //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))


      });
  }

  const constultandDatafunction = () => {

    axios
      .get(`https://uatamma.apimachine.com/api/getStaff?teamUsername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`)
      .then((res) => {
        if (res?.data?.success) {
          setstafdropdown(res?.data?.data)
        }
        else {
          setstafdropdown([])
        }
        //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))


      });
  }
  const [itemsend, setitemsend] = useState({})
  console.log("roledrop", roledrop)
  const [swtchtoggle, setswtchtoggle] = useState("Data")
  useEffect(() => {
    setswtchtoggle("Data")
  }, [])

  let loc = window.location.pathname
  let finallo = loc?.split('/')
  const [coundata, setcoundata] = useState([])
  useEffect(() => {
    countryfunct()
  }, [])

  const countryfunct = () => {
    axios
      .get(`https://comms.globalxchange.io/coin/vault/countries/data/get`)
      .then((res) => {
        if (res.data.status) {
          setcoundata(res?.data?.countries)
        }
        else {
          setcoundata([])
        }
        //



      });
  }
  console.log("coundata", coundata)
  const localclikc = () => {
    let addlocal = localStorage?.getItem("drawershow")

    localStorage?.setItem("drawershow", Number(addlocal + 1))
  }
  const [staffitem, setstaffitem] = useState({})
  const [sendcalender, setsendcalender] = useState({})

  console.log("staffit22em", sendcalender)

  const handleChangeWorkingEndAA = (id, newText) => {


    setCalendarForm(prevData => {
      return {
        ...prevData,
        WorkingDays: prevData.WorkingDays.map((item, i) =>
          i === id ? { ...item, endAA: newText } : item
        )
      }

    }

    );


  };


  const [selectedidex, setselectedidex] = useState(0)
  const [lastindexselect, setlastindexselect] = useState(0)


  function hasDuplicates() {
    let startingTime = `${calendarForm?.WorkingDays[selectedidex]?.startHours}:${calendarForm?.WorkingDays[selectedidex]?.startMint}:${calendarForm?.WorkingDays[selectedidex]?.startAA}`
    let EndTime = `${calendarForm?.WorkingDays[selectedidex]?.endHours}:${calendarForm?.WorkingDays[selectedidex]?.endMint}:${calendarForm?.WorkingDays[selectedidex]?.endAA}`
    calendarForm?.WorkingDays?.map((item, i) => {
      if (i == selectedidex) {
        if (startingTime?.toString() !== "::" && EndTime?.toString() !== "::")
          if (startingTime?.toString() == EndTime?.toString()) {
            toastify('Start and end time canot be same ', "error");

            calendarForm?.WorkingDays?.forEach(day => {
              if (
                day.startHours == day.endHours &&
                day.startMint == day.endMint &&
                day.startAA == day.endAA

              ) {
                day.endHours = "";
                day.endMint = "";
                day.endAA = "";

              }



              // day.breakstart.forEach((breakObj, i) => {

              //   if (
              //     day.startHours === breakObj.endHours &&
              //     day.startMint === breakObj.endMint &&
              //     day.startAA === breakObj.endAA

              //   ) 
              // });
            });
          }

          else {

          }
      }
    })



    // obj.startHours?.toString() != "" && obj.endHour?.toString() != ""




    // calendarForm?.WorkingDays?.map((item, i) => {
    //   if (i == selectedidex) {
    //     item?.breakstart?.map((obj, z) => {
    //       if (obj.startHours?.toString() == obj.endHours?.toString() && obj.startAA?.toString() == obj.endAA?.toString()) {
    //         toastify('asd ', "error");
    //       }
    //     }
    //     );

    //   }
    // })

    const isEqualStartAndEnd = calendarForm?.WorkingDays?.[selectedidex]?.breakstart?.map(obj =>
      obj.startHours !== "" && obj.startHours === obj.endHours &&
      obj.startMint !== "" && obj.startMint === obj.endMint &&
      obj.startAA !== "" && obj.startAA === obj.endAA
    );

    const isEqualToBreak = calendarForm?.WorkingDays?.map((user, i) => {



      return user?.breakstart?.some(breakObj => {
        return (
          user.startHours !== "" && user.startHours == breakObj.startHours &&
          user.startMint !== "" && user.startMint == breakObj.startMint &&
          user.startAA !== "" && user.startAA == breakObj.startAA
          // user.endHours == breakObj.endHours &&
          // user.endMint == breakObj.endMint &&
          // user.startAA == breakObj.startAA &&
          // user.endAA == breakObj.endAA
        );
      });

    });




    const EndisEqualToBreak = calendarForm?.WorkingDays?.map((user, i) => {



      return user?.breakstart?.some(breakObj => {
        return (
          // user.startHours !== "" && user.startHours == breakObj.startHours &&
          // user.startMint !== "" && user.startMint == breakObj.startMint &&
          // user.startAA !== "" && user.startAA == breakObj.startAA
          user.startHours !== "" && user.startHours == breakObj.endHours &&
          user.startMint !== "" && user.startMint == breakObj.endMint &&

          user.startAA !== "" && user.startAA == breakObj.endAA

        );
      });

    });














    // const isMatching = calendarForm?.WorkingDays.some(breakObj => {
    //   return (
    //     users.startHours === breakObj.startHours &&
    //     users.startMint === breakObj.startMint &&
    //     users.endHours === breakObj.endHours &&
    //     users.endMint === breakObj.endMint &&
    //     users.startAA === breakObj.startAA &&
    //     users.endAA === breakObj.endAA
    //   );
    // });

    const isMatching = calendarForm?.WorkingDays.every(day => {
      return day.breakstart.some(breakObj => {
        console.log("czxczxqqq", breakObj.startHours)
        return (
          day.startHours?.toString() == breakObj.startHours?.toString() &&
          day.startMint?.toString() == breakObj.startMint?.toString() &&
          // day.endHours == breakObj.endHours &&
          // day.endMint == breakObj.endMint &&
          day.startAA?.toString() == breakObj.startAA?.toString()
          // day.endAA == breakObj.endAA
        );
      });
    });
    // const isEqualStartAndEndHours = calendarForm?.WorkingDays[selectedidex]?.breakstart?.map((obj, i) =>

    //   obj.startHours === obj.endHours && obj.startAA === obj.endAA
    // );


    EndisEqualToBreak?.map((item, i) => {
      if (item == true) {
        toastify(`Subsequent time cannot be before previous time `, "error");
        calendarForm?.WorkingDays?.forEach(day => {

          day.breakstart.forEach((breakObj, i) => {

            if (
              day.startHours === breakObj.endHours &&
              day.startMint === breakObj.endMint &&
              day.startAA === breakObj.endAA

            ) {
              breakObj.endHours = "";
              breakObj.endMint = "";

              breakObj.endAA = "";

            }
          });
        });

      }

    })

    isEqualStartAndEnd?.map((item, i) => {
      if (item == true) {
        toastify(`Break ${i + 1} Start and Break ${i + 1} End canot have same tiem`, "error");
        calendarForm?.WorkingDays?.forEach(day => {

          day.breakstart.forEach((breakObj, i) => {

            if (
              breakObj.startHours === breakObj.endHours &&
              breakObj.startMint === breakObj.endMint &&
              breakObj.startAA === breakObj.endAA

            ) {
              breakObj.endHours = "";
              breakObj.endMint = "";

              breakObj.endAA = "";

            }
          });
        });


      }

    })


    isEqualToBreak?.map((item, i) => {
      if (item == true) {
        toastify(`Subsequent time cannot be before previous time `, "error");
        calendarForm?.WorkingDays?.forEach(day => {

          day.breakstart.forEach((breakObj, i) => {

            if (
              day.startHours === breakObj.startHours &&
              day.startMint === breakObj.startMint &&
              day.startAA === breakObj.startAA

            ) {
              breakObj.startHours = "";
              breakObj.startMint = "";

              breakObj.startAA = "";

            }
          });
        });

      }

    })



    console.log("czxczxqqq", isEqualStartAndEnd)

  }

  useEffect(() => {
    // alert(lastindexselect)
    hasDuplicates()
    let startingTime = ""
    let EndTime = ""
    let BreakstartingTime = ""
    let BreakEndTime = ""








    if (calendarForm?.WorkingDays[selectedidex]?.startHours !== ""

    ) {
      {
        calendarForm?.WorkingDays?.map(item => {

          item?.breakstart?.map((such, i) => {

            BreakstartingTime = `${calendarForm?.WorkingDays[selectedidex]?.breakstart?.[lastindexselect]?.startHours}:${calendarForm?.WorkingDays[selectedidex]?.breakstart?.[lastindexselect]?.startMint}:${calendarForm?.WorkingDays[selectedidex]?.breakstart?.[lastindexselect]?.startAA}`
            BreakEndTime = `${calendarForm?.WorkingDays[selectedidex]?.breakstart?.[lastindexselect]?.endHours}:${calendarForm?.WorkingDays[selectedidex]?.breakstart?.[lastindexselect]?.endMint}:${calendarForm?.WorkingDays[selectedidex]?.breakstart?.[lastindexselect]?.endAA}`

            return
          })

          startingTime = `${calendarForm?.WorkingDays[selectedidex]?.startHours}:${calendarForm?.WorkingDays[selectedidex]?.startMint}:${calendarForm?.WorkingDays[selectedidex]?.startAA}`
          EndTime = `${calendarForm?.WorkingDays[selectedidex]?.endHours}:${calendarForm?.WorkingDays[selectedidex]?.endMint}:${calendarForm?.WorkingDays[selectedidex]?.endAA}`
          return
        })
      }

      // if (calendarForm?.WorkingDays[selectedidex]?.breakstart?.[lastindexselect]?.startHours !== ""
      // ) {
      //   if (BreakstartingTime?.toString() == BreakEndTime?.toString()) {
      //     toastify("Starting and ending break Time Con't Be Same", "error")
      //   }
      //   if (BreakstartingTime?.toString() == startingTime?.toString()) {
      //     toastify("Subsequent time cannot be before previous time ", "error")
      //   }

      // }

      // if (calendarForm?.WorkingDays[selectedidex]?.breakstart?.[lastindexselect]?.endHours !== ""
      // ) {
      //   if (BreakEndTime?.toString() == EndTime?.toString()) {
      //     toastify("Subsequent time cannot be before previous time ", "error")
      //   }
      //   if (BreakstartingTime?.toString() == BreakEndTime?.toString()) {
      //     toastify("Starting and ending break Time Con't Be Same", "error")
      //   }
      // }

      //   if (calendarForm?.WorkingDays.some(user => user.startHours == targetName)) {
      //     console.log(`${targetName} is in the users array.`);
      // }

      // if (startingTime?.toString() == EndTime?.toString()) {
      //   toastify("Selected Time Con't Be Same", "error")

      // }




    }

    // console.log("BreakstartingTime", BreakstartingTime,"")
  }, [calendarForm, selectedidex, lastindexselect])
  const workingcondtion = (i, f) => {

    {
      calendarForm?.WorkingDays?.map(item => {
        // let startingTime = `${calendarForm?.WorkingDays[i]?.startHours}:${calendarForm?.WorkingDays[i]?.startMint}:${calendarForm?.WorkingDays[i]?.startAA}`
        // let EndTime = `${calendarForm?.WorkingDays[i]?.endHours}:${calendarForm?.WorkingDays[i]?.endMint}:${calendarForm?.WorkingDays[i]?.endAA}`

        // if (startingTime?.toString() == EndTime?.toString()) {
        //   toastify("dasd", "error")
        // }
        // console.log("startingTime", startingTime, "EndTime", EndTime)
      })

    }


    setselectedidex(i)
    setlastindexselect(f)

  }



  const handleChangeWorkingStartAA = async (id, newText) => {


    await setCalendarForm(prevData => {
      return {
        ...prevData,
        WorkingDays: prevData.WorkingDays.map((item, i) =>
          i === id ? { ...item, startAA: newText } : item
        )
      }

    }

    );
    await workingcondtion(id, "")

  };
  const handleChangeWorkingEndMinute = async (id, newText) => {


    await setCalendarForm(prevData => {
      return {
        ...prevData,
        WorkingDays: prevData.WorkingDays.map((item, i) =>
          i === id ? { ...item, endMint: newText?.replace(/[^0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g, '') } : item
        )
      }

    }

    );
    await workingcondtion(id, "")

  };
  const handleChangeWorkingStartMinute = async (id, newText) => {


    setCalendarForm(prevData => {
      return {
        ...prevData,
        WorkingDays: prevData.WorkingDays.map((item, i) =>
          i === id ? { ...item, startMint: newText?.replace(/[^0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g, '') } : item
        )
      }

    }

    );
    await workingcondtion(id, "")

  };
  const handleChangeWorkingEndingHour = async (id, newText) => {


    setCalendarForm(prevData => {
      return {
        ...prevData,
        WorkingDays: prevData.WorkingDays.map((item, i) =>
          i === id ? { ...item, endHours: newText?.replace(/[^0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g, '') } : item
        )
      }

    }

    );
    await workingcondtion(id, "")

  };

  const handleChangeWorkingStartHour = async (id, newText) => {


    setCalendarForm(prevData => {
      return {
        ...prevData,
        WorkingDays: prevData.WorkingDays.map((item, i) =>
          i === id ? { ...item, startHours: newText?.replace(/[^0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g, '') } : item
        )
      }

    }

    );
    await workingcondtion(id, "")

  };


  const handleChangeBreakStartHour = async (id, newText, lastindex) => {

    await setCalendarForm(prevData => {
      return {
        ...prevData,
        WorkingDays: prevData.WorkingDays.map((item, i) =>
          i === id ? {
            ...item, breakstart: item?.breakstart?.map((such, z) =>
              z == lastindex ? { ...such, startHours: `${newText?.replace(/[^0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g, '')}` } : such

            )

          } : item
        )
      }
    }

    );

    await workingcondtion(id, lastindex)
  };

  const handleChangeBreakEndAA = async (id, newText, lastindex) => {

    await setCalendarForm(prevData => {
      return {
        ...prevData,
        WorkingDays: prevData.WorkingDays.map((item, i) =>
          i === id ? {
            ...item, breakstart: item?.breakstart?.map((such, z) =>
              z == lastindex ? { ...such, endAA: newText } : such

            )

          } : item
        )
      }
    }

    );

    await workingcondtion(id, lastindex)
  };


  const handleChangeBreakendMint = async (id, newText, lastindex) => {

    setCalendarForm(prevData => {
      return {
        ...prevData,
        WorkingDays: prevData.WorkingDays.map((item, i) =>
          i === id ? {
            ...item, breakstart: item?.breakstart?.map((such, z) =>
              z == lastindex ? { ...such, endMint: `${newText?.replace(/[^0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g, '')}` } : such

            )

          } : item
        )
      }
    }

    );
    await workingcondtion(id, lastindex)

  };

  const handleChangeBreakendHours = async (id, newText, lastindex) => {

    setCalendarForm(prevData => {
      return {
        ...prevData,
        WorkingDays: prevData.WorkingDays.map((item, i) =>
          i === id ? {
            ...item, breakstart: item?.breakstart?.map((such, z) =>
              z == lastindex ? { ...such, endHours: `${newText?.replace(/[^0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g, '')}` } : such

            )

          } : item
        )
      }
    }

    );

    await workingcondtion(id, lastindex)
  };

  const handleChangeBreakStartMinutue = async (id, newText, lastindex) => {

    setCalendarForm(prevData => {
      return {
        ...prevData,
        WorkingDays: prevData.WorkingDays.map((item, i) =>
          i === id ? {
            ...item, breakstart: item?.breakstart?.map((such, z) =>
              z == lastindex ? { ...such, startMint: `${newText?.replace(/[^0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g, '')}` } : such

            )

          } : item
        )
      }
    }

    );

    await workingcondtion(id, lastindex)
  };

  const handleChangeBreakStartAA = async (id, newText, lastindex) => {

    setCalendarForm(prevData => {
      return {
        ...prevData,
        WorkingDays: prevData.WorkingDays.map((item, i) =>
          i === id ? {
            ...item, breakstart: item?.breakstart?.map((such, z) =>
              z == lastindex ? { ...such, startAA: newText } : such

            )

          } : item
        )
      }
    }

    );

    await workingcondtion(id, lastindex)
  };








  return (

    <>

      {
        loadingdata &&
        <div className={classNames.loadingdiv}>
          <img className={classNames.loadingAnimation} src={Loading} />
        </div>
      }
      {
        editingToggle ?
          <>


            {
              roleType == "staffgroups" &&
              <StafGroupEditing
                itemsend={itemsend}
              />


            }
            {
              roleType == "staffroles" &&
              <StaffRoleEing
                itemsend={itemsend}
              />


            }
            {
              roleType == "staff" &&
              <Editingpage
                staffitem={staffitem}
                setstaffitem={setstaffitem}
              />
            }
            {
              roleType == "calendar" &&
              <ClanderEditing
                staffitem={sendcalender}
              />
            }
          </>
          :
          <div className="global-dashboard">
            <div className="label-box-header">
              {finallo?.[3]}
            </div>
            <div className="top-section-golbal">
              <div className="left-side">


                <div className="label-box">
                  <svg
                    style={{
                      marginRight: "10px"
                    }}
                    xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                    <path d="M12.8445 8.07626L10.9486 6.58668C10.8262 6.4908 10.6594 6.47293 10.5202 6.54118C10.3804 6.60888 10.2916 6.7508 10.2916 6.90626V7.90292C8.75704 8.16184 7.58325 9.49651 7.58325 11.1042V12.4583C7.58325 12.7573 7.82592 13 8.12492 13C8.42392 13 8.66658 12.7573 8.66658 12.4583V11.1042C8.66658 10.0978 9.35992 9.25655 10.2916 9.01442V9.88542C10.2916 10.0409 10.3804 10.1828 10.5202 10.2505C10.5765 10.2781 10.6372 10.2917 10.6978 10.2917C10.7872 10.2917 10.876 10.2624 10.9486 10.205L12.8445 8.71542C12.943 8.63851 12.9999 8.52042 12.9999 8.39584C12.9999 8.27126 12.943 8.15317 12.8445 8.07626Z" fill="#010101" />
                    <path d="M9.74999 0H1.625C0.731249 0 0 0.731249 0 1.625V8.66666C0 9.5604 0.731249 10.2917 1.625 10.2917H6.58124C6.65166 9.91249 6.77082 9.54957 6.93874 9.20832H1.625C1.32708 9.20832 1.08333 8.96457 1.08333 8.66666V2.70833H10.2917V5.47624C10.6492 5.37333 11.0392 5.41124 11.375 5.57916V1.625C11.375 0.731249 10.6437 0 9.74999 0V0Z" fill="#010101" />
                  </svg>

                  Export

                </div>



                <>

                  <div className="label-box">
                    <svg
                      style={{
                        marginRight: "10px"
                      }}
                      xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                      <path d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z" fill="#010101" />
                    </svg>
                    Add Filter
                  </div></>


              </div>


              <div className="right-side">
                <div className="shortBy">Sort By</div>
                <div className="dropdownlist">
                  Newest

                  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="6" viewBox="0 0 7 6" fill="none">
                    <path d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z" fill="#010101" />
                  </svg>

                </div>
                <div className="addLabel"
                  onClick={() => {
                    if (roleType === "staffroles") {
                      setCheckBoxStage("staffRolesForm");
                    } else if (roleType === "staffgroups") {
                      setCheckBoxStage("staffGroupsForm");
                    } else if (roleType === "calendar") {
                      setCheckBoxStage("calendarTemplateForm");

                    }
                    else if (roleType === "staff") {
                      setCheckBoxStage("staff");
                    }
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                    <path d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z" fill="white" />
                  </svg>
                  Add New
                </div>

                <Drawer
                  size={450}
                  // duration={500}
                  open={checkBoxStage}
                  onClose={() => setCheckBoxStage("")}
                  direction='right'
                  className='bla bla bla'
                  overlayColor="#FFF"
                  overlayOpacity={0}
                >

                  <div className={classNames.navRightBox}>
                    {
                      loadingdata ?
                        <img className={classNames.loadingAnimation} src={Loading} /> :

                        <>




                          {/*  */}
                          {checkBoxStage === "staff" && roleType === "staff" && (
                            <div
                              className="drowpside-right"

                            >

                              <div
                                className={classNames.eachFilterItem}
                                style={{
                                  fontWeight: "700",
                                  pointerEvents: "none",

                                  fontSize: "20px"
                                }}
                              >
                                Add new staff
                              </div>
                              <div className={classNames.formFields}>
                                <div
                                  style={{
                                    marginTop: "2rem",
                                    fontSize: "0.9rem",
                                    marginBottom: "0.8rem",
                                    fontWeight: "700"
                                  }}
                                >Basic Information</div>
                                <div style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  marginBottom: "0.8rem"
                                }}>
                                  <div>
                                    <MRsEachCustomDropdown
                                      dropdown={[
                                        "Mr",
                                        "Mrs",
                                        "Ms",
                                        "Dr",
                                        "Prof"
                                      ]}
                                      name="gendersignature"
                                      title="Employment typekl"
                                      stateValue={staffdata}
                                      setState={setstaffdata}
                                      // topTitle="true"
                                      type="single"
                                    />
                                  </div>
                                  <MarignEachInput
                                    title="cutom?"
                                    name="name"
                                    placeholder="Name..."
                                    stateValue={staffdata}
                                    setState={setstaffdata}
                                  //  topTitle="true"
                                  />
                                </div>
                                <div style={{

                                  marginBottom: "0.8rem"
                                }}>
                                  <StaffEachInputDatePicker
                                    title="Select birthday"
                                    placeholder="Birthday"
                                    stateValue={staffdata}
                                    setState={setstaffdata}
                                    name="Birthday"
                                  // topTitle="true"
                                  />
                                </div>

                                <div
                                  style={
                                    {
                                      position: "relative",
                                      marginBottom: "0.8rem",
                                      width: "100%"
                                    }
                                  }
                                >
                                  <MarignEachInput
                                    title="Enter the affiliate’s email"
                                    name="username"
                                    placeholder="Username..."
                                    stateValue={staffdata}
                                    setState={setstaffdata}
                                  // topTitle="true"
                                  />
                                  <div
                                    //onClick={cmailName}
                                    style={{
                                      width: "109px",
                                      height: "32px",
                                      top: "7px",
                                      borderRadius: "5px",
                                      //  border: "0.5px solid #C57E50",
                                      cursor: "pointer",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      position: "absolute",
                                      right: "10px",
                                      color: "#ffff",
                                      background: checkName ? " #010101" : "#C51E25",
                                      fontSize: "12px",
                                      fontWeight: "600",



                                    }}
                                  >
                                    {checkName ? "Available" : "Not Available"}
                                  </div>
                                </div>



                                <div
                                  style={{
                                    // marginTop: "2rem",
                                    marginBottom: "2rem"
                                  }}
                                >

                                  <EachCustomSelectMultiples
                                    title="What is the injection method?"
                                    name="gendertype"
                                    stateValue={staffdata}
                                    setState={setstaffdata}
                                    //   topTitle="true"
                                    mapItems={["Male", "Female",]}
                                  />
                                </div>






                                <div
                                  style={
                                    {
                                      position: "relative",
                                      marginBottom: "0.8rem",
                                      width: "100%"
                                    }
                                  }
                                >
                                  <MarignEachInput
                                    title="Contact information"
                                    name="affilatemail"
                                    placeholder="Email..."
                                    stateValue={staffdata}
                                    setState={setstaffdata}
                                    topTitle="true"
                                  />
                                  <div
                                    //onClick={cmailName}
                                    style={{
                                      width: "109px",
                                      height: "32px",
                                      top: "40px",
                                      borderRadius: "5px",
                                      //  border: "0.5px solid #C57E50",
                                      cursor: "pointer",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      position: "absolute",
                                      right: "10px",
                                      color: "#ffff",
                                      background: checkemail ? " #010101" : "#C51E25",
                                      fontSize: "12px",
                                      fontWeight: "600",



                                    }}
                                  >
                                    {checkemail ? "Available" : "Not Available"}
                                  </div>
                                </div>
                                <div style={{

                                  marginBottom: "2rem"
                                }}>
                                  <PhoneEachInput
                                    title="What is the employee’s phone number?"
                                    name="phoneNumber"
                                    placeholder="Number..."
                                    stateValue={staffdata}
                                    setState={setstaffdata}
                                  //topTitle="true"
                                  />


                                </div>



                                <div
                                  style={{
                                    marginBottom: "0.8rem",
                                    marginTop: "2rem"
                                  }}
                                >

                                  <MarignEachInput
                                    title="Address"
                                    name="address"
                                    placeholder="Street"
                                    stateValue={staffdata}
                                    setState={setstaffdata}
                                    topTitle="true"
                                  />
                                </div>
                                <div
                                  style={{
                                    marginBottom: "0.8rem"
                                  }}
                                >

                                  <MarignEachInput
                                    title="What is the employees pincode?"
                                    name="City"
                                    placeholder="City"
                                    stateValue={staffdata}
                                    setState={setstaffdata}
                                  //  topTitle="true"
                                  />

                                </div>
                                <div
                                  style={{
                                    marginBottom: "0.8rem"
                                  }}
                                >
                                  <MarignEachInput
                                    title="What is the employees state?"
                                    name="state"
                                    placeholder="State"
                                    stateValue={staffdata}
                                    setState={setstaffdata}
                                  // topTitle="true"
                                  />
                                </div>
                                <div
                                  style={{
                                    marginBottom: "0.8rem"
                                  }}
                                >
                                  <MarignEachInput
                                    title="What is the employees pincode?"
                                    name="picode"
                                    placeholder="Pincode"
                                    stateValue={staffdata}
                                    setState={setstaffdata}
                                  //  topTitle="true"
                                  />
                                </div>
                                <div
                                  style={{
                                    marginBottom: "2rem"
                                  }}
                                >
                                  <EachCustomDropdownSearch
                                    dropdown={coundata}
                                    title="Country"
                                    name="country"
                                    placeholder="Country"
                                    stateValue={staffdata}
                                    setState={setstaffdata}
                                    // topTitle="true"    

                                    type="obj"
                                  />
                                </div>


                                <div
                                  style={{
                                    marginBottom: "0.8rem",
                                    marginTop: "2rem"
                                  }}
                                >

                                  <MarignEachInput
                                    title="Employment information"
                                    name="employeeId"
                                    placeholder="Employee id"
                                    stateValue={staffdata}
                                    setState={setstaffdata}
                                    topTitle="true"
                                  />
                                </div>


                                <div
                                  style={{
                                    marginBottom: "0.8rem",

                                  }}
                                >
                                  <EachCustomDropdown
                                    dropdown={[
                                      "Permanent",
                                      "On Contract",
                                      "Temporary",
                                      "Trainee",
                                    ]}
                                    name="employeeType"
                                    title="Employment type"
                                    stateValue={staffdata}
                                    setState={setstaffdata}
                                    // topTitle="true"
                                    type="single"
                                  />
                                </div>
                                <div
                                  style={{
                                    marginBottom: "0.8rem",

                                  }}
                                >

                                  <EachCustomDropdown
                                    dropdown={[
                                      "Active",
                                      "Terminated",
                                      "Resigned",
                                      "Deceased",
                                      "Long term leave",
                                    ]}
                                    name="employeeStatus"
                                    title="Employment status"
                                    stateValue={staffdata}
                                    setState={setstaffdata}
                                    ///  topTitle="true"
                                    type="single"
                                  />
                                </div>

                                <div
                                  style={{
                                    marginBottom: "0.8rem",

                                  }}
                                >
                                  <EachCustomDropdownSearch

                                    dropdown={roledrop}
                                    name="staffrole"
                                    title="Staff role"
                                    stateValue={staffdata}
                                    setState={setstaffdata}
                                    ///  topTitle="true"
                                    type="single"
                                  />
                                </div>


                                <div
                                  style={{
                                    marginBottom: "0.8rem",

                                  }}
                                >

                                  <EachCustomDropdownSearch
                                    dropdown={allLocations}
                                    name="locations"
                                    title="Location"
                                    stateValue={staffdata}
                                    setState={setstaffdata}
                                    ///  topTitle="true"
                                    type="single"
                                  />
                                </div>

                                <div
                                  style={{
                                    marginBottom: "0.8rem",

                                  }}
                                >
                                  <EachCustomDropdownSearch
                                    dropdown={calndertemp}
                                    name="calendartemplate"
                                    title="Calendar template"
                                    stateValue={staffdata}
                                    setState={setstaffdata}
                                    ///  topTitle="true"
                                    type="single"
                                  />

                                </div>

                                <div
                                  style={{
                                    marginBottom: "2rem",

                                  }}
                                >
                                  <StaffEachInputDatePicker
                                    title="Select birthday"
                                    placeholder="Date of joining"
                                    stateValue={staffdata}
                                    setState={setstaffdata}
                                    name="Dateofjoining"
                                  // topTitle="true"
                                  />

                                </div>

                                <div className={classNames.flexContainer}
                                  style={{
                                    width: "150px",
                                    marginBottom: "0.8rem"
                                  }}
                                >
                                  <MyEachCustomImageUpload
                                    title="Upload"
                                    name="photo"
                                    placeholder="Profile picture "
                                    stateValue={staffdata}
                                    setState={setstaffdata} topTitle="true"
                                  />

                                </div>
                                <div className={classNames.flexContainer}
                                  style={{
                                    width: "150px"
                                  }}
                                >
                                  <MyEachCustomImageUpload
                                    title="Upload"
                                    name="govid"
                                    placeholder="Gov ID"
                                    stateValue={staffdata}
                                    setState={setstaffdata}
                                  //topTitle="true"
                                  />

                                </div>
                                <div
                                  style={{
                                    marginTop: "2rem",
                                    marginBottom: "0.8rem"
                                  }}
                                >

                                  <EachCustomSelectMultiples
                                    title="Specialist"
                                    name="specialistToggle"
                                    stateValue={staffdata}
                                    setState={setstaffdata}
                                    topTitle="true"
                                    mapItems={["Yes", "No",]}
                                  />
                                </div>

                                {
                                  staffdata?.specialistToggle?.[0] == "Yes" &&
                                  <>

                                    <div
                                      style={{

                                        marginBottom: "0.8rem"
                                      }}
                                    >
                                      {/* <EachCustomSelectMultiples
                                                    title="What is the injection method?"
                                                    name="injmenthod"
                                                    stateValue={productsItem}
                                                    setState={setproductsItem}
                                                    topTitle="true"
                                                    mapItems={["Injection", "Oral tablet", "Oral liquid", "Oral capsule"]}
                                                /> */}
                                      <EachCustomSelectMultiples
                                        title="Therapist/Consultant"
                                        name="injmenthod"
                                        stateValue={staffdata}
                                        setState={setstaffdata}
                                        //topTitle="true"
                                        mapItems={["Therapist", "Consultant",]}
                                      />
                                    </div>
                                    {/* <div>
                                      {
                                        staffdata?.specialistIds?.map((item, i) => {
                                          return (
                                            <>
                                              <div>{item?.name}</div>
                                            </>
                                          )
                                        })
                                      }
                                    </div> */}


                                    <Multiselect
                                      className="mulitselectOption"
                                      options={sepcialdata} // Options to display in the dropdown
                                      placeholder="Select Service"
                                      //  selectedValues={selectedValue} // Preselected value to persist in dropdown
                                      onSelect={onSelect} // Function will trigger on select event
                                      onRemove={onRemove} // Function will trigger on remove event
                                      displayValue="name" // Property name to display in the dropdown options
                                    />
                                    {/* <div
                                      style={{
                                        marginBottom: "0rem"
                                      }}
                                    >

                                      {
                                        staffdata?.specialistIds?.map((item, i) => {
                                          return (
                                            <MultiDropdownNestedService
                                              // dropdown={sepcialdata }

                                              dropdown={sepcialdata.filter(function (cv) {
                                                return !staffdata?.specialistIds.find(function (e) {
                                                  return e.id == cv.consultationserviceid;
                                                })
                                              })}
                                              //   {sepcialdata.filter((item,i) => item?.specialistid !== offeringItem?.specialistIds[i]?.id)}
                                              key={i}
                                              indexx={i}
                                              name="specialistIds"
                                              title="Which specialists will offer this therapy?"
                                              stateValue={staffdata}
                                              setState={setstaffdata}
                                            // stateValue={services}
                                            // setState={setservices}
                                            ///  topTitle="true"
                                            />

                                          )
                                        })
                                      }

                                    </div> */}



                                    {/* <div
                                      style={{
                                        fontFamily: "Montserrat Alternates",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        textAlign: "left",
                                        textDecoration: "underline",
                                        marginBottom: "0rem",
                                        marginTop: "10px"
                                      }}

                                      onClick={addmorespicalid}
                                    >Add Another Service</div> */}

                                  </>
                                }



                                <button
                                  className={classNames.submitBtn}
                                  onClick={submitstaff}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          )}
                          {/*  */}


                          {checkBoxStage === "calendar" && roleType === "calendar" ? (
                            <div className="drowpside-right">
                              <div
                                style={{
                                  position: "absolute",
                                  right: "1rem"
                                }}
                                className={classNames.closeBtn}
                                onClick={() => {
                                  setActionBtns([]);
                                  setCheckBoxStage("");
                                }}
                              >
                                X
                              </div>
                              <div
                                className={classNames.eachFilterItem}
                                style={{ fontWeight: "500", pointerEvents: "none" }}
                              >
                                Add New
                              </div>
                              <div
                                className={classNames.eachFilterItem}
                                key={"Calendar template"}
                                onClick={() => {
                                  setCheckBoxStage("calendarTemplateForm");
                                }}
                              >
                                Calendar template
                              </div>
                            </div>
                          ) : checkBoxStage === "calendarTemplateForm" &&
                            roleType === "calendar" ? (
                            <div
                              className="drowpside-right"

                            >
                              {/* <div
                      style={{
                        position: "absolute",
                        right: "1rem"
                      }}
                      className={classNames.closeBtn}
                      onClick={() => {
                        setActionBtns([]);
                        setCheckBoxStage("");
                      }}
                    >
                      X
                    </div> */}
                              <div
                                className={classNames.eachFilterItem}
                                style={{
                                  fontWeight: "600",
                                  pointerEvents: "none",
                                  paddingLeft: "0",
                                  marginBottom: "2rem",
                                  marginTop: "0.8rem",
                                  fontSize: "18px"

                                }}
                              >
                                Add new calendar
                              </div>
                              <div
                                style={{
                                  fontWeight: "600",
                                  pointerEvents: "none",
                                  paddingLeft: "0",
                                  marginBottom: "0.8rem",
                                  fontSize: "14px"

                                }}
                              >Basic Information</div>

                              <div style={{

                                marginBottom: "0.8rem"
                              }}>
                                <MarignEachInput
                                  title="What do you want to name this calendar?"
                                  name="CalendarName"
                                  placeholder="Name..."
                                  stateValue={calendarForm}
                                  setState={setCalendarForm}
                                //  topTitle="true"
                                />

                              </div>

                              <div
                                style={{

                                  marginBottom: "0.8rem"
                                }}
                              >
                                <MarginEachCustomTextarea
                                  title="Describe the calendar"
                                  name="CalendarDescription"
                                  placeholder="Description..."
                                  stateValue={calendarForm}
                                  setState={setCalendarForm}
                                // topTitle="true"
                                />

                              </div>


                              <div
                                style={{

                                  marginBottom: "0.8rem"
                                }}
                              >
                                <EachCustomDropdownSearch
                                  dropdown={allTimezones}
                                  name="TimeZone"
                                  title="Select the timezone"
                                  stateValue={calendarForm}
                                  setState={setCalendarForm}
                                  // topTitle="true"
                                  type="obj"
                                  mapVal={{
                                    name: "StandardTimeZoneName",
                                    name1: "Abbreviation",
                                  }}
                                />
                              </div>
                              <div className={classNames.formFields}>






                                <div className={classNames.customSeperationBox}
                                  style={{
                                    marginBottom: "0px"
                                  }}
                                >
                                  <div className={classNames.title}

                                    style={{
                                      fontWeight: "600",
                                      pointerEvents: "none",
                                      paddingLeft: "0",
                                      marginBottom: "0.8rem",
                                      marginTop: "2rem",
                                      fontSize: "14px"

                                    }}
                                  >
                                    Add annual holidays
                                  </div>
                                  {calendarForm?.AnnualHolidays?.map((eachitem, index) => {
                                    return (
                                      <div
                                        className={classNames.flexBoxouter}
                                        key={"annualHoliday" + index}
                                      >
                                        <div className={classNames.longDiv}>
                                          <EachCustomDropdown
                                            dropdown={monthsArray}
                                            style={{
                                              marginBottom: "0rem"
                                            }}
                                            name="AnnualHolidays"
                                            title="Month"
                                            stateValue={calendarForm}
                                            setState={setCalendarForm}
                                            type="twoFields"
                                            indexx={index}
                                            calendarObjField="month"
                                            eachValuee={eachitem}
                                          />
                                        </div>
                                        <div className={classNames.normalDiv}>
                                          <EachCustomDropdownDate
                                            dropdown={datesArraylist}
                                            name="AnnualHolidays"
                                            title="Date"
                                            stateValue={calendarForm}
                                            setState={setCalendarForm}
                                            type="twoFields"
                                            indexx={index}
                                            calendarObjField="date"
                                            eachValuee={eachitem}
                                            setselectedindex={setselectedindex}
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>

                                <div className={classNames.customSeperationBox}>
                                  <div className={classNames.title}
                                    onClick={addmore}
                                    style={{
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                      marginTop: "10px"
                                    }}
                                  >
                                    Add another holidays
                                  </div>
                                </div>

                                <div className={classNames.customSeperationBox}>

                                  {calendarForm?.WorkingDays?.map((eachDay, index) => {
                                    console.log("eachDay", eachDay)
                                    return (
                                      <div
                                        className={classNames.flexBoxouter}
                                        style={{
                                          display: "block"
                                        }}
                                        key={eachDay + index}
                                      >
                                        <div className={classNames.titleDiv}
                                          style={{
                                            textDecoration: "underline",
                                            marginBottom: "0.8rem",
                                          }}
                                        >
                                          {eachDay?.Day}
                                        </div>

                                        <div
                                          className={classNames.flexBoxouter}
                                          key={eachDay + index}
                                          style={
                                            {
                                              display: "block"
                                            }
                                          }
                                        >



                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "1rem"
                                            }}
                                          >
                                            <div style={{
                                              fontSize: "0.8rem",
                                              fontWeight: "600"
                                            }}>
                                              Start Time:
                                            </div>
                                            <div className="Selectiontime">

                                              <select
                                                style={{
                                                  padding: "1rem 1.5rem",
                                                  borderRadius: "5px",
                                                  border: "1px solid #e5e5e5",
                                                  fontSize: "0.8rem",
                                                  background: "white",
                                                  width: "100px",


                                                }}
                                                value={eachDay.startHours}
                                                onChange={e => handleChangeWorkingStartHour(index, e.target.value)}
                                              >

                                                <option
                                                  value="" disabled selected>HH</option>

                                                {
                                                  [1, 2, 3, 3, 1, 2, 3, 3, 1, 2, 3, 3]?.map((item, i) => {
                                                    return (
                                                      <option value={i + 1}>{pad((i + 1)?.toString(), 2)}</option>
                                                    )
                                                  })
                                                }

                                              </select>

                                            </div>

                                            <div className="Selectiontime">

                                              <select
                                                style={{
                                                  padding: "1rem 1.5rem",
                                                  borderRadius: "5px",
                                                  border: "1px solid #e5e5e5",
                                                  fontSize: "0.8rem",
                                                  background: "white",
                                                  width: "100px",


                                                }}
                                                value={eachDay.startMint}
                                                onChange={e => handleChangeWorkingStartMinute(index, e.target.value)}
                                              >
                                                <option value="" disabled selected>MM</option>
                                                {
                                                  [1, 2, 3, 1, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 33, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 33, 3, 3, 3, 3, 3, 3, 3, 3, 3, 33, 3, 3, 3, 3]?.map((item, i) => {
                                                    return (
                                                      <option value={i}>{pad((i)?.toString(), 2)}</option>
                                                    )
                                                  })
                                                }

                                              </select>

                                            </div>
                                            <div className="Selectiontime">

                                              <select
                                                style={{
                                                  padding: "1rem 1.5rem",
                                                  borderRadius: "5px",
                                                  border: "1px solid #e5e5e5",
                                                  fontSize: "0.8rem",
                                                  background: "white",
                                                  width: "100px",


                                                }}
                                                value={eachDay.startAA}
                                                onChange={e => handleChangeWorkingStartAA(index, e.target.value)}
                                              >
                                                <option value="" disabled selected>AA</option>
                                                <option value="AM">AM</option>
                                                <option value="PM">PM</option>



                                              </select>

                                            </div>


                                          </div>
































                                          {
                                            eachDay?.breakstart?.map((such, f) => {
                                              console.log("ppapdas", such?.StartTimeText?.split(":")?.[f])
                                              return (
                                                <>



                                                  <div
                                                    className={classNames.flexBoxouter}
                                                    key={eachDay + index}
                                                    style={
                                                      {
                                                        display: "block"
                                                      }
                                                    }
                                                  >
                                                    <div className={classNames.titleDiv}
                                                      style={{
                                                        marginTop: "1rem",
                                                        marginBottom: "0.8rem",
                                                        fontWeight: "500"
                                                      }}
                                                    >
                                                      {`Break ${f + 1} Start`}
                                                    </div>

                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "1rem"
                                                      }}
                                                    >

                                                      <div className="Selectiontime">

                                                        <select
                                                          style={{
                                                            padding: "1rem 1.5rem",
                                                            borderRadius: "5px",
                                                            border: "1px solid #e5e5e5",
                                                            fontSize: "0.8rem",
                                                            background: "white",
                                                            width: "100px",


                                                          }}
                                                          value={such.startHours}
                                                          onChange={e => handleChangeBreakStartHour(index, e.target.value, f)}
                                                        >

                                                          <option
                                                            {...hover}
                                                            value="" disabled selected>HH</option>

                                                          {
                                                            [1, 2, 3, 3, 1, 2, 3, 3, 1, 2, 3, 3]?.map((item, i) => {
                                                              return (
                                                                <option value={i + 1}>{pad((i + 1)?.toString(), 2)}</option>
                                                              )
                                                            })
                                                          }

                                                        </select>

                                                      </div>

                                                      <div className="Selectiontime">

                                                        <select
                                                          style={{
                                                            padding: "1rem 1.5rem",
                                                            borderRadius: "5px",
                                                            border: "1px solid #e5e5e5",
                                                            fontSize: "0.8rem",
                                                            background: "white",
                                                            width: "100px",


                                                          }}
                                                          value={such.startMint}
                                                          onChange={e => handleChangeBreakStartMinutue(index, e.target.value, f)}
                                                        >
                                                          <option value="" disabled selected>MM</option>
                                                          {
                                                            [1, 2, 3, 1, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 33, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 33, 3, 3, 3, 3, 3, 3, 3, 3, 3, 33, 3, 3, 3, 3]?.map((item, i) => {
                                                              return (
                                                                <option value={i}>{pad((i)?.toString(), 2)}</option>
                                                              )
                                                            })
                                                          }

                                                        </select>

                                                      </div>
                                                      <div className="Selectiontime">

                                                        <select
                                                          style={{
                                                            padding: "1rem 1.5rem",
                                                            borderRadius: "5px",
                                                            border: "1px solid #e5e5e5",
                                                            fontSize: "0.8rem",
                                                            background: "white",
                                                            width: "100px",


                                                          }}
                                                          value={such.startAA}
                                                          onChange={e => handleChangeBreakStartAA(index, e.target.value, f)}
                                                        >
                                                          <option value="" disabled selected>AA</option>
                                                          <option value="AM">AM</option>
                                                          <option value="PM">PM</option>



                                                        </select>

                                                      </div>


                                                    </div>
                                                  </div>
                                                  <div
                                                    className={classNames.flexBoxouter}
                                                    key={eachDay + index}
                                                    style={
                                                      {
                                                        display: "block"
                                                      }
                                                    }
                                                  >
                                                    <div className={classNames.titleDiv}
                                                      style={{
                                                        marginTop: "1rem",
                                                        marginBottom: "0.8rem",
                                                        fontWeight: "500"
                                                      }}
                                                    >
                                                      {`Break ${f + 1} End`}
                                                    </div>

                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "1rem"
                                                      }}
                                                    >

                                                      <div className="Selectiontime">

                                                        <select
                                                          style={{
                                                            padding: "1rem 1.5rem",
                                                            borderRadius: "5px",
                                                            border: "1px solid #e5e5e5",
                                                            fontSize: "0.8rem",
                                                            background: "white",
                                                            width: "100px",


                                                          }}
                                                          value={such.endHours}
                                                          onChange={e => handleChangeBreakendHours(index, e.target.value, f)}
                                                        >

                                                          <option
                                                            {...hover}
                                                            value="" disabled selected>HH</option>

                                                          {
                                                            [1, 2, 3, 3, 1, 2, 3, 3, 1, 2, 3, 3]?.map((item, i) => {
                                                              return (
                                                                <option value={i + 1}>{pad((i + 1)?.toString(), 2)}</option>

                                                              )
                                                            })
                                                          }

                                                        </select>

                                                      </div>

                                                      <div className="Selectiontime">

                                                        <select
                                                          style={{
                                                            padding: "1rem 1.5rem",
                                                            borderRadius: "5px",
                                                            border: "1px solid #e5e5e5",
                                                            fontSize: "0.8rem",
                                                            background: "white",
                                                            width: "100px",


                                                          }}
                                                          value={such.endMint}
                                                          onChange={e => handleChangeBreakendMint(index, e.target.value, f)}
                                                        >
                                                          <option value="" disabled selected>MM</option>
                                                          {
                                                            [1, 2, 3, 1, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 33, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 33, 3, 3, 3, 3, 3, 3, 3, 3, 3, 33, 3, 3, 3, 3]?.map((item, i) => {
                                                              return (
                                                                <option value={i}>{pad((i)?.toString(), 2)}</option>
                                                              )
                                                            })
                                                          }

                                                        </select>

                                                      </div>
                                                      <div className="Selectiontime">

                                                        <select
                                                          style={{
                                                            padding: "1rem 1.5rem",
                                                            borderRadius: "5px",
                                                            border: "1px solid #e5e5e5",
                                                            fontSize: "0.8rem",
                                                            background: "white",
                                                            width: "100px",


                                                          }}
                                                          value={such.endAA}
                                                          onChange={e => handleChangeBreakEndAA(index, e.target.value, f)}
                                                        >
                                                          <option value="" disabled selected>AA</option>
                                                          <option value="AM">AM</option>
                                                          <option value="PM">PM</option>



                                                        </select>

                                                      </div>


                                                    </div>







                                                  </div>

                                                </>
                                              )
                                            })
                                          }

                                          <div
                                            style={{
                                              marginTop: "1rem",
                                              marginBottom: "0.8rem",
                                              fontWeight: "500",
                                              textDecoration: "underline",
                                              cursor: "pointer"
                                            }}
                                            onClick={() => addbreak(index)}
                                          >Add a break</div>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "1rem"
                                            }}
                                          >
                                            <div style={{
                                              fontSize: "0.8rem",
                                              fontWeight: "600"
                                            }}>
                                              End Time:
                                            </div>
                                            <div className="Selectiontime">

                                              <select
                                                style={{
                                                  padding: "1rem 1.5rem",
                                                  borderRadius: "5px",
                                                  border: "1px solid #e5e5e5",
                                                  fontSize: "0.8rem",
                                                  background: "white",
                                                  width: "100px",


                                                }}
                                                value={eachDay.endHours}
                                                onChange={e => handleChangeWorkingEndingHour(index, e.target.value)}
                                              >

                                                <option
                                                  value="" disabled selected>HH</option>

                                                {
                                                  [1, 2, 3, 3, 1, 2, 3, 3, 1, 2, 3, 3]?.map((item, i) => {
                                                    return (
                                                      <option value={i + 1}>{pad((i + 1)?.toString(), 2)}</option>
                                                    )
                                                  })
                                                }

                                              </select>

                                            </div>

                                            <div className="Selectiontime">

                                              <select
                                                style={{
                                                  padding: "1rem 1.5rem",
                                                  borderRadius: "5px",
                                                  border: "1px solid #e5e5e5",
                                                  fontSize: "0.8rem",
                                                  background: "white",
                                                  width: "100px",


                                                }}
                                                value={eachDay.endMint}
                                                onChange={e => handleChangeWorkingEndMinute(index, e.target.value)}
                                              >
                                                <option value="" disabled selected>MM</option>
                                                {
                                                  [1, 2, 3, 1, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 33, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 33, 3, 3, 3, 3, 3, 3, 3, 3, 3, 33, 3, 3, 3, 3]?.map((item, i) => {
                                                    return (
                                                      <option value={i}>{pad((i)?.toString(), 2)}</option>
                                                    )
                                                  })
                                                }

                                              </select>

                                            </div>
                                            <div className="Selectiontime">

                                              <select
                                                style={{
                                                  padding: "1rem 1.5rem",
                                                  borderRadius: "5px",
                                                  border: "1px solid #e5e5e5",
                                                  fontSize: "0.8rem",
                                                  background: "white",
                                                  width: "100px",


                                                }}
                                                value={eachDay.endAA}
                                                onChange={e => handleChangeWorkingEndAA(index, e.target.value)}
                                              >
                                                <option value="" disabled selected>AA</option>
                                                <option value="AM">AM</option>
                                                <option value="PM">PM</option>



                                              </select>

                                            </div>


                                          </div>

                                          {
                                            index == 0 &&
                                            <div
                                              style={{
                                                marginTop: "1rem",
                                                marginBottom: "1.5rem",
                                                fontWeight: "500",
                                                textDecoration: "underline",
                                                cursor: "pointer"
                                              }}
                                              onClick={copyforwhole}
                                            >Copy for rest of week</div>
                                          }


                                          {/* <div
                                            style={{
                                              marginTop: "1rem",
                                              marginBottom: "1.5rem",
                                              fontWeight: "500",
                                              textDecoration: "underline",
                                              cursor: "pointer"
                                            }}
                                            onClick={() => copyforwholenexday(index)}
                                          >Copy for rest of week</div> */}


                                          <div className={classNames.normalDiv}
                                            style={{
                                              minWidth: "143px"
                                            }}
                                          >
                                            {/**/}
                                          </div>
                                        </div>   </div>
                                    );
                                  })}
                                </div>
                                <button
                                  className={classNames.submitBtn}
                                  onClick={addCalendarForm}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          ) : checkBoxStage === "staffRoles" &&
                            roleType === "staffroles" ? (
                            <div className="drowpside-right">
                              <div
                                style={{
                                  position: "absolute",
                                  right: "1rem"
                                }}
                                className={classNames.closeBtn}
                                onClick={() => {
                                  setActionBtns([]);
                                  setCheckBoxStage("");
                                }}
                              >
                                X
                              </div>
                              <div
                                className={classNames.eachFilterItem}
                                style={{ fontWeight: "500", pointerEvents: "none" }}
                              >
                                Add New
                              </div>
                              <div
                                className={classNames.eachFilterItem}
                                key={"Staff Roles"}
                                onClick={() => {
                                  setCheckBoxStage("staffRolesForm");
                                }}
                              >
                                Staff Role
                              </div>
                            </div>
                          ) : checkBoxStage === "staffRolesForm" &&
                            roleType === "staffroles" ? (
                            <div
                              className="drowpside-right"

                            >
                              {/* <div
                      style={{
                        position: "absolute",
                        right: "1rem"
                      }}
                      className={classNames.closeBtn}
                      onClick={() => {
                        setActionBtns([]);
                        setCheckBoxStage("");
                      }}
                    >
                      X
                    </div> */}
                              <div
                                className={classNames.eachFilterItem}
                                style={{
                                  fontWeight: "500",
                                  pointerEvents: "none",
                                  paddingLeft: "0",
                                  marginBottom: "2rem",


                                }}
                              >
                                Add New Staff Role
                              </div>
                              <div className={classNames.formFields}>
                                {/* <EachCustomDropdown
                      dropdown={allOrganization}
                      name="teamUsername"
                      title="Select the organization"
                      stateValue={staffRolesForm}
                      setState={setStaffRolesForm}
                      topTitle="true"
                    /> */}
                                <EachInputsepfic
                                  title="What do you want to name this role"
                                  name="name"
                                  placeholder="Name..."
                                  stateValue={staffRolesForm}
                                  setState={setStaffRolesForm}
                                  topTitle="true"
                                />
                                <EachCustomTextarea
                                  title="Describe the role"
                                  name="description"
                                  placeholder="Description..."
                                  stateValue={staffRolesForm}
                                  setState={setStaffRolesForm}
                                  topTitle="true"
                                />

                                <EachCustomSelectMultiplelist
                                  title="Add staff groups"
                                  name="staffGroupIds"
                                  stateValue={staffRolesForm}
                                  setState={setStaffRolesForm}
                                  topTitle="true"
                                  mapItems={stafdropdown}
                                />
                                <button
                                  className={classNames.submitBtn}
                                  onClick={addStaffRolesForm}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          ) : checkBoxStage === "staffGroups" &&
                            roleType === "staffgroups" ? (
                            <div className="drowpside-right">
                              <div
                                style={{
                                  position: "absolute",
                                  right: "1rem"
                                }}
                                className={classNames.closeBtn}
                                onClick={() => {
                                  setActionBtns([]);
                                  setCheckBoxStage("");
                                }}
                              >
                                X
                              </div>
                              <div
                                className={classNames.eachFilterItem}
                                style={{ fontWeight: "500", pointerEvents: "none" }}
                              >
                                Add New
                              </div>
                              <div
                                className={classNames.eachFilterItem}
                                key={"Staff Group"}
                                onClick={() => {
                                  setCheckBoxStage("staffGroupsForm");
                                }}
                              >
                                Staff Group
                              </div>
                            </div>
                          ) : checkBoxStage === "staffGroupsForm" &&
                            roleType === "staffgroups" ? (
                            <div
                              className="drowpside-right"

                            >
                              {/* <div
                      style={{
                        position: "absolute",
                        right: "1rem"
                      }}
                      className={classNames.closeBtn}
                      onClick={() => {
                        setActionBtns([]);
                        setCheckBoxStage("");
                      }}
                    >
                      X
                    </div> */}
                              <div
                                className={classNames.eachFilterItem}
                                style={{
                                  fontWeight: "500",
                                  pointerEvents: "none",
                                  paddingLeft: "0",
                                  marginBottom: "2rem",


                                }}
                              >
                                Add New Staff Group
                              </div>
                              <div className={classNames.formFields}>
                                {/* <EachCustomDropdown
                      dropdown={allOrganization}
                      name="teamUsername"
                      title="Select the organization"
                      stateValue={staffGroupForm}
                      setState={setStaffGroupForm}
                      topTitle="true"
                    /> */}
                                {/* <EachCustomDropdown
                            dropdown={staffGroupFormGroupType}
                            name="groupType"
                            title="Select the group type"
                            stateValue={staffGroupForm}
                            setState={setStaffGroupForm}
                            topTitle="true"
                          /> */}
                                <EachInputsepfic
                                  title="What do you want to name this group"
                                  name="groupName"
                                  placeholder="Name..."
                                  stateValue={staffGroupForm}
                                  setState={setStaffGroupForm}
                                  topTitle="true"
                                />
                                <EachCustomTextarea
                                  title="Describe the group"
                                  name="groupDesc"
                                  placeholder="Description..."
                                  stateValue={staffGroupForm}
                                  setState={setStaffGroupForm}
                                  topTitle="true"
                                />
                                <EachCustomSelectMultiples
                                  title="Add modules"
                                  name="modules"
                                  stateValue={staffGroupForm}
                                  setState={setStaffGroupForm}
                                  topTitle="true"
                                  mapItems={staffGroupFormModules}
                                />
                                <button
                                  className={classNames.submitBtn}
                                  onClick={addStaffGroupForm}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                    }

                  </div>

                </Drawer>
              </div>
            </div >


            <div className="handle-table">
              <div className="g-table-sec">
                <div className="table-section">
                  <table style={{ minWidth: "100%" }}>
                    <thead>
                      <tr style={{ gridTemplateColumns: roleType === "staffgroups" ? "min-content repeat(4, 1fr)" : roleType === "staffroles" ? "min-content repeat(4, 1fr)" : roleType === "calendar" ? "min-content repeat(4, 1fr)" : "min-content repeat(5, 1fr)" }}>

                        <th>
                          Name <SortArrows />
                        </th>
                        {roleType != "staffgroups" && roleType !== "staffroles" && roleType != "calendar" &&
                          <th>
                            {roleType == "staffgroups"
                              ? "Group Type"
                              : roleType === "staffroles"
                                ? "ID"
                                : "Email"}
                            <SortArrows />
                          </th>
                        }

                        <th>
                          {roleType === "staffgroups"
                            ? "Description"
                            : roleType === "staffroles" || roleType === "calendar"
                              ? "Description"
                              : "Phone Number"}
                          <SortArrows />
                        </th>

                        <th>
                          {roleType === "staffgroups"
                            ? "Staff Roles"
                            : roleType === "staffroles"
                              ? "Staff Groups"
                              : roleType === "staff"
                                ? "Username"

                                : roleType === "calendar"
                                  ? "Time Zone"
                                  : "Gender"}
                          <SortArrows />
                        </th>
                        <th>
                          {roleType === "staffgroups"
                            ? "Modules"
                            : roleType === "staffroles"
                              ? "# Of Staff"
                              : roleType === "staff"
                                ? "Employment Type"
                                : roleType === "calendar"
                                  ? "# Of Employees"
                                  : "Address"}
                          <SortArrows />
                        </th>

                        {roleType == "staff" &&
                          <>
                            <th>
                              Employment Status
                            </th>
                          </>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {allLeadsLoading ? (
                        <tr
                          key={"loading"}
                          style={{ gridTemplateColumns: "min-content repeat(5, 1fr)" }}
                        >
                          <td>

                          </td>
                          <td>
                            <Skeleton width={100} height={20} />
                          </td>
                          <td>
                            <Skeleton width={100} height={20} />
                          </td>
                          <td>
                            <Skeleton width={100} height={20} />
                          </td>
                          <td>
                            <Skeleton width={100} height={20} />
                          </td>
                          <td>
                            <Skeleton width={100} height={20} />
                          </td>
                        </tr>
                      ) : (roleType === "staffroles" ||
                        roleType === "staff" ||
                        roleType === "staffgroups") &&
                        !JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername ? (
                        ""
                      ) : (
                        allLeads?.data?.length > 0 &&
                        allLeads?.data
                          ?.filter((row) => {
                            // Convert the row object values to an array and check if any value matches the search query
                            // const values = Object?.values(row);
                            // const searchQuery = globalSearchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                            // return values?.some((value) => {
                            //   if (typeof value === "string") {
                            //     return value?.toLowerCase()?.includes(searchQuery);
                            //   }
                            //   return false;
                            // });

                            const searchQuery = globalSearchQuery?.toLowerCase();
                            return roleType === "staffgroups"
                              ? row?.groupName?.toLowerCase()?.includes(searchQuery)
                              : roleType === "staffroles"
                                ? row?.name?.toLowerCase()?.includes(searchQuery)
                                : roleType === "staff"
                                  ? row?.UserDetails?.length > 0 &&
                                    row?.UserDetails[0]?.UserDetails?.length > 0
                                    ? row?.UserDetails[0]?.UserDetails[0]?.name
                                      ?.toLowerCase()
                                      ?.includes(searchQuery)
                                    : ""
                                  : roleType === "calendar"
                                    ? row?.CalendarName?.toLowerCase()?.includes(searchQuery)
                                    : row?.name?.toLowerCase()?.includes(searchQuery);
                          })
                          ?.sort((a, b) => a?.first_name?.localeCompare(b?.first_name))
                          ?.map((row, index) => (
                            <tr
                              onClick={() => {
                                if (roleType === "calendar") {
                                  setcalendarinfo(row)
                                  seteditingToggle(true)
                                  setsendcalender(row)
                                  history(`/owner/staffing/calendar/${row?._id}`)
                                  //  setcalendardrawer(true)
                                  console.log("2883842843289", row)
                                }
                                else if (roleType === "staffroles") {
                                  seteditingToggle(true)
                                  setitemsend(row)
                                  history(`/owner/staffing/staffroles/${row?._id}`)

                                }
                                else if (roleType === "staffgroups") {
                                  seteditingToggle(true)
                                  setitemsend(row)
                                  history(`/owner/staffing/staffgroups/${row?._id}`)

                                }
                                else if (roleType === "staff") {
                                  seteditingToggle(true)
                                  history(`/owner/staffing/staff/${row?.TeamPlayerID}`)
                                  //  setstaffitem(row?.TeamPlayerID)


                                }
                              }}
                              key={row?.brokerage_name + index}
                              style={{
                                gridTemplateColumns: roleType === "staffgroups" && roleType == "calendar" ? "min-content repeat(4, 1fr)" : "min-content repeat(5, 1fr)",
                                pointerEvents: sidebarOpen ? "none" : "",
                              }}
                            >

                              <td

                              >
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex"

                                  }}
                                >
                                  {/* {
                                    roleType === "staff"
                                    &&
                                    <img src={row?.UserDetails[0]?.UserDetails[0]?.ProfilePic}

                                      style={{
                                        width: "1.5rem",
                                        height: "1.5rem",
                                        borderRadius: "10rem",
                                        marginRight: "10px"
                                      }}
                                    />
                                  } */}

                                  {roleType === "staffgroups"
                                    ? row?.groupName
                                    : roleType === "staffroles"
                                      ? row?.name
                                      : roleType === "staff"
                                        ? row?.UserDetails?.length > 0 &&
                                          row?.UserDetails[0]?.UserDetails?.length > 0
                                          ? row?.UserDetails[0]?.UserDetails[0]?.name
                                          : ""
                                        : roleType === "calendar"
                                          ? row?.CalendarName
                                          : row?.name}
                                </div>
                              </td>
                              {roleType !== "staffgroups" && roleType != "staffroles" && roleType != "calendar" &&
                                <td>
                                  {roleType === "staffgroups"
                                    ? row?.groupType
                                    : roleType === "staffroles"
                                      ? row?._id
                                      : roleType === "staff"
                                        ? row?.UserDetails?.length > 0 &&
                                          row?.UserDetails[0]?.UserDetails?.length > 0
                                          ? row?.UserDetails[0]?.UserDetails[0]?.email
                                          : ""
                                        : roleType === "calendar"
                                          ? row?.TeamCalendarID
                                          : row?.email}

                                </td>
                              }
                              <td>
                                {roleType === "staffgroups"
                                  ? row?.groupDesc
                                  : roleType === "staffroles"
                                    ? row?.description
                                    : roleType === "staff"
                                      ? row?.UserDetails?.length > 0 &&
                                        row?.UserDetails[0]?.UserDetails?.length > 0
                                        ? row?.UserDetails[0]?.UserDetails[0]?.PhoneNumber
                                        : ""
                                      : roleType === "calendar"
                                        ? row?.CalendarDescription
                                        : row?.PhoneNumber}

                              </td>
                              <td>

                                {roleType === "staffgroups" &&
                                  row?.StaffRoleDetails?.length > 0
                                  ? row?.StaffRoleDetails?.map((eachItem, index) => {
                                    return (
                                      <div key={eachItem?.name + index}>
                                        {eachItem?.name}
                                      </div>
                                    );
                                  })
                                  : roleType === "staffroles"
                                    ?

                                    row?.staffGroupDetails?.map((eachItem, index) => {
                                      return (
                                        <div key={eachItem?.name + index}>
                                          {eachItem?.groupName}
                                        </div>
                                      );
                                    })

                                    : roleType === "staff"
                                      ? row?.PlayerUsername
                                      : roleType === "calendar"
                                        ? row?.TimeZone
                                        : row?.key}

                              </td>
                              <td>
                                {roleType === "staffgroups" &&
                                  row?.modules?.length > 0 ? (
                                  <div>
                                    {row?.modules?.map((eachitem, index) => {
                                      return (
                                        <div key={eachitem + index}>{eachitem}</div>
                                      );
                                    })}
                                  </div>
                                ) : roleType === "staffroles" ? (
                                  row?.staffDetails?.map((eachItem, index) => {
                                    return (
                                      <div key={eachItem?.name + index}>
                                        {eachItem?.name}
                                      </div>
                                    );
                                  })
                                ) : roleType === "calendar" ? (
                                  row?.staffCount
                                )

                                  : roleType === "staff" ? (
                                    row?.profile?.[0]?.employeeType
                                  ) : (
                                    row?.userid
                                  )}

                              </td>
                              {roleType == "staff" &&
                                <>

                                  <td>
                                    <div
                                      style={{
                                        width: "120px",
                                        borderRadius: "5px",
                                        height: "39px",
                                        fontWeight: "700",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        color: "rgb(255, 255, 255)",
                                        textTransform: "capitalize",
                                        background: row?.profile?.[0]?.employeeStatus == "Resigned" ? "rgb(242, 201, 76)" :
                                          row?.profile?.[0]?.employeeStatus == "Terminated" ? "red" :
                                            row?.profile?.[0]?.employeeStatus == "Active" ? "Green" :
                                              row?.profile?.[0]?.employeeStatus == "On Contract" ? "blue" :
                                                row?.profile?.[0]?.employeeStatus == "Deceased" ? "black" :
                                                  row?.profile?.[0]?.employeeStatus == "Long term leave" ? "pink" : ""
                                      }}
                                    >
                                      {row?.profile?.[0]?.employeeStatus}
                                    </div>

                                  </td></>
                              }
                            </tr>
                          ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div >
      }

      {
        checkBoxStage === "staffGroupDrawer" && (

          < DrawerStaffing passingObj={drawerObject}
            handleClickOutside1={handleClickOutside1}
            clickRef={clickRef}
          />



        )
      }


      <Drawer
        size={450}
        // duration={500}
        open={calendardrawer}
        onClose={() => setcalendardrawer(false)}
        direction='right'
        className='bla bla bla'
        overlayColor="#FFF"
        overlayOpacity={0}
      >
        <div className="drowpside-right">

        </div>
        <div
          className="drowpside-right"
          style={{

            width: " 100%",
            height: "calc(100vh - 2rem)",
            overflowX: "hidden",
            overflowY: "scroll",
            padding: "2rem",
          }}
        >
          <div className={classNames.togglebuttonSection}>

            <div className={classNames.toggledata}
              onClick={() => setswtchtoggle("Data")}
              style={{
                background: swtchtoggle == "Data" ? "#C57E50" : "#fff",
                color: swtchtoggle == "Data" ? "#fff" : "#5F6163"
              }}
            >
              Data
            </div>
            <div className={classNames.toggleaction}
              onClick={() => setswtchtoggle("Actions")}
              style={{
                background: swtchtoggle == "Actions" ? "#C57E50" : "#fff",
                color: swtchtoggle == "Actions" ? "#fff" : "#5F6163"
              }}
            >
              Actions
            </div>
          </div>
          {
            swtchtoggle == "Actions" &&
            <>
              <div className={classNames.deleteCalendarbox}>
                Delete calendar
              </div>
            </>


          }



          {
            swtchtoggle == "Data" &&
            <>
              <div
                className={classNames.eachFilterItem}
                style={{
                  fontWeight: "500",
                  pointerEvents: "none",
                  paddingLeft: "0",
                  marginBottom: "2rem",


                  fontSize: "20px"
                }}
              >
                {calendarinfo?.CalendarName}
              </div>
              <div
                className={classNames.eachFilterItem}
                style={{
                  // fontWeight: "500",
                  pointerEvents: "none",
                  paddingLeft: "0",
                  marginBottom: "2rem",


                  fontSize: "12px"
                }}
              >
                {calendarinfo?._id}
              </div>
              <div className={classNames.formFields}>

                {/* <EachInputsepfic
                      title="What do you want to name this calendar?"
                      name="CalendarName"
                      placeholder="Name..."
                      stateValue={calendarForm}
                      setState={setCalendarForm}
                      topTitle="true"
                    /> */}
                <EachCustomTextarea
                  title="Description"
                  name="CalendarDescription"
                  placeholder="Description..."
                  stateValue={calendarinfo}
                  // setState={setCalendarForm}
                  topTitle="true"
                />
                <div
                  style={{

                    marginTop: "2rem"
                  }}
                >
                  <EachInput
                    title="Time zone"
                    name="TimeZone"
                    placeholder=""
                    stateValue={calendarinfo}
                    // setState={setStaffRolesForm}
                    topTitle="true"
                  />
                </div>

                <div className={classNames.customSeperationBox}

                >
                  <div
                    className={classNames.flexBoxouter}
                    style={{

                      marginTop: "2rem",
                    }}
                  >


                    <div className={classNames.titleDiv}

                    >
                      Holidays
                    </div>

                    {
                      calendarinfo?.AnnualHolidays?.map(item => {
                        return (
                          <EachInput
                            title=""
                            // name={calendarinfo?.WorkingDays[index]}
                            placeholder=""
                            stateValue={`${item?.month} ${item?.date}`}
                            // setState={setStaffRolesForm}
                            topTitle="true"
                          />

                        )
                      })
                    }
                  </div>

                </div>

                <div className={classNames.customSeperationBox}

                >
                  <div
                    className={classNames.flexBoxouter}

                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "self-start",
                      gap: "1rem",
                      marginTop: "2rem",
                      justifyContent: "space-between"
                    }}
                  >


                    <div className={classNames.titleDiv}
                      style={{
                        width: "7.5rem",
                        display: "flex",
                        alignItems: "self-start",
                        gap: "1rem"
                      }}
                    >
                      Day
                    </div>
                    <div className={classNames.titleDiv}
                      style={{
                        width: "7.5rem",
                        display: "flex",
                        alignItems: "self-start",
                        gap: "1rem"
                      }}
                    >
                      Start Time
                    </div>
                    <div className={classNames.titleDiv}
                      style={{
                        width: "7.5rem",
                        display: "flex",
                        alignItems: "self-start",
                        gap: "1rem"
                      }}
                    >
                      End Time
                    </div>
                  </div>
                  {calendarinfo?.WorkingDays?.map((eachDay, index) => {
                    return (
                      <div
                        className={classNames.flexBoxouter}
                        key={eachDay + index}
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "self-start",
                          justifyContent: "space-between",
                          gap: "1rem"
                        }}
                      >


                        <div className={classNames.normalDiv}
                          style={{
                            width: "7rem",

                          }}
                        >


                          <EachInputDummy
                            title=""
                            // name={calendarinfo?.WorkingDays[index]}
                            placeholder=""
                            stateValue={eachDay?.Day}
                            // setState={setStaffRolesForm}
                            topTitle="true"
                          />
                          {/* <EachInput
                        
                        name="WorkingDays"
                        title="Start"
                        stateValue={calendarForm}
                        // setState={setCalendarForm}
                        type="twoFields"
                        indexx={index}
                        calendarObjField="StartTime"
                        eachValuee={eachDay}
                      /> */}
                        </div>
                        <div className={classNames.normalDiv}
                          style={{
                            width: "7rem",

                          }}
                        >

                          <EachInputDummy
                            title=""
                            // name={calendarinfo?.WorkingDays[index]}
                            placeholder=""
                            stateValue={eachDay?.StartTime ? eachDay?.StartTime : "NA"}
                            // setState={setStaffRolesForm}
                            topTitle="true"
                          />
                          {/* <EachInput
                        dropdown={pmArray}
                        name="WorkingDays"
                        title="End"
                        stateValue={calendarForm}
                        // setState={setCalendarForm}
                        type="twoFields"
                        indexx={index}
                        calendarObjField="EndTime"
                        eachValuee={eachDay}
                      /> */}
                        </div>

                        <div className={classNames.normalDiv}
                          style={{
                            width: "7rem",

                          }}
                        >

                          <EachInputDummy
                            title=""
                            // name={calendarinfo?.WorkingDays[index]}
                            placeholder=""
                            stateValue={eachDay?.EndTime ? eachDay?.EndTime : "NA"}

                            // setState={setStaffRolesForm}
                            topTitle="true"
                          />
                          {/* <EachInput
                        dropdown={pmArray}
                        name="WorkingDays"
                        title="End"
                        stateValue={calendarForm}
                        // setState={setCalendarForm}
                        type="twoFields"
                        indexx={index}
                        calendarObjField="EndTime"
                        eachValuee={eachDay}
                      /> */}
                        </div>
                      </div>

                    );
                  })}
                </div>

              </div>
            </>
          }


        </div>




        {/* <BookingDrawer
          setchangestatus={setchangestatus}
          changeStatusfucntion={changeStatusfucntion}
          bookingItem={bookingItem}
          bookingDrawer={bookingDrawer}

        /> */}
      </Drawer>






    </>
  );
};

export default Staffing;

const DrawerStaffing = ({ passingObj, clickRef, handleClickOutside1 }) => {
  console.log("passingObj", passingObj)
  return (

    <div className={classNames.drawerStaffing}
      ref={clickRef}
    >
      <div className={classNames.title}
        style={{
          marginBottom: "0px",
        }}
      >{passingObj?.groupName}</div>
      <div className={classNames.subTitle}
        style={{
          marginBottom: "12px",
        }}
      >{passingObj?._id}</div>
      <div className={classNames.formFields}>
        <EachCustomTextarea
          title="Description"
          name="groupDesc"
          placeholder="Description..."
          stateValue={passingObj}
          // setState={setStaffGroupForm}
          topTitle="true"
        />
        <EachInput
          title="Group type"
          name="groupType"
          placeholder="Group Type..."
          stateValue={passingObj}
          // setState={setStaffRolesForm}
          topTitle="true"
        />
        <EachInputArray
          title="Modules"
          name="modules"
          placeholder="Modules..."
          stateValue={passingObj}
          // setState={setStaffRolesForm}
          topTitle="true"
        />
        <EachInputArray
          title="Staff roles"
          name="StaffRoleDetails"
          placeholder="Modules..."
          stateValue={passingObj}
          // setState={setStaffRolesForm}
          topTitle="true"
          type="obj"
          objName="name"
        />
      </div>
    </div >

  );
};
