import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import PostLogin from "./Layout/PostLogin";
import Registration from "./pages/Registration";
import CrmUsers from "./pages/CRM/Users";
import Home from "./pages/Home";
import WaitingList from "./pages/Waitinglist";
import WalletScan from "./pages/WalletScan";
import Settings from "./components/Settings";
import Staffing from "./pages/Owner/Staffing";
import Organization from "./pages/Owner/Organization";
import Patient from "./pages/Owner/Patient";
import { useContext } from "react";
import { GlobalContext } from "./context/Globalcontext";
import loadingIcon from "./assets/images/loadingicon.svg";
import Booking from "./pages/Booking/Dashboard/Dashboard";
import Consultation from "./pages/Consultation/Consultation";
import Calendar from "./pages/Booking/Calendar";
import BookingCalendar from "./pages/Booking/Calendar";
import Sessionpage from "./pages/Sessionpage/Sessionpage";
import PreAssessment from "./pages/PreAssessment/PreAssessment";
import Patients from "./pages/Patients/Patients";
import Wedge from "./pages/Wedge/Wedge";
import ConsultationSummary from "./pages/ConsultationSummary/ConsultationSummary";
import Pharmacycat from "./pages/pharmacyCat/Catories";
import PhatamcyBrand from "./pages/pharmacybrand/Catories";
import PharamacyProduct from "./pages/PharamcyProduct/Catories";
import AccomdationRoom from "./pages/AccommodationsRooms/Catories";
import AccomdationCatories from "./pages/AccommodationsCatories/Catories";
import TherapiesCatories from "./pages/TherapiesCatories/Catories";
import TherapiesOffering from "./pages/TherapiesOffering/Catories";
import Programs from "./pages/Program/Catories";
import Kitchen from "./pages/KitechenItems/Catories";
import Diteplan from "./pages/DitePlan/Catories"
import TherapieSession from './pages/TherapaySession/Catories'
import StaffEditing from './pages/EditingPage/EditingPage'
import StaffGroupEditing from './pages/Owner/Staffing/StaffGroupEditing/EditingPage'
import StaffRoleEditing from './pages/Owner/Staffing/EditingPage/EditingPage'
import ClanderEditor from './pages/Owner/Staffing/ClendarEditing/EditingPage'
import LoctionEditor from './pages/Owner/Organization/LocationEditing/EditingPage'
import ConsultationServerTypeEditing from './pages/Owner/Organization/ConsututioncateryEditing/EditingPage'
import ConsultationServiceEditing from './pages/Owner/Organization/ServiceEditing/EditingPage'
import RoomEditor from './pages/AccommodationsRooms/EditingPage/EditingPage'
import CRm from './pages/Owner/Patient/EditingPage/EditingPage'
import SessionPageEditior from "./pages/Sessionpage/Submenu/index"
import BrandCatEditing from "./pages/pharmacyCat/EditingPage/EditingPage"
import PreAssmentEditor from "./pages/PreAssessment/EditingPage/EditingPage"
import PatentEditor from "./pages/Patients/EditingPage/EditingPage"
import Weditor from "./pages/Wedge/EditingPage/EditingPage"
import ConsultationSummaryEditor from './pages/ConsultationSummary/EditingPage/EditingPage'
import SpecalistEditor from './pages/Consultation/EditingPage/EditingPage'
import RoomCatogireEditing from './pages/AccommodationsCatories/EditingPage/EditingPage'
import TheriopycatEditing from './pages/TherapiesCatories/EditingPage/EditingPage'
import TheriopySessionEditing from './pages/TherapaySession/EditingPage/EditingPage'
import TheriopyOfferingEditing from './pages/TherapiesOffering/EditingPage/EditingPage'
import PharmashyProduct from './pages/PharamcyProduct/EditingPage/EditingPage';
import PharmashyBrandEditing from './pages/pharmacybrand/EditingPage/EditingPage'
import ProgramEditor from './pages/Program/EditingPage/EditingPage'
import KitchenItemEditing from "./pages/KitechenItems/EditingPage/EditingPage"
import Prescriptions from "./pages/Preception/Catories"
import PrescriptionEditior from "./pages/Preception/EditingPage/EditingPage"
import DieatEditing from './pages/DitePlan/EditingPage/EditingPage'
import Bookingdata from './pages/Booking/DashboardBooking/TherpyBooking'
import ConsultationSessionPage from './pages/ConsultationSessionPage/MainPage'
import Billing from "./pages/BillingInformation/Billing";
import PharamcyIntergrate from './pages/PharamcyIntergrate/Catories'
import PharamcyIntergrateEdite from './pages/PharamcyIntergrate/EditingPage/EditingPage'
import PharamcyFrequencies from './pages/PharmacyFrequencies/Catories'
import PharamcyFrequenciesEDting from './pages/PharmacyFrequencies/EditingPage/EditingPage'
import PharamacyAnuma from './pages/PharmacyAnupama/Catories'
import PharamacyAnumaEditing from './pages/PharmacyAnupama/EditingPage/EditingPage'
function App() {
  const { globalLoading } = useContext(GlobalContext);
  return (
    <>
      {globalLoading && (
        <div className="globalLoading">
          <img src={loadingIcon} alt="globalLoading" />
        </div>
      )}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<PostLogin />} />
        <Route path="/settings" element={<PostLogin child={<Settings />} />} />
        <Route
          path="/settings/:settingsType"
          element={<PostLogin child={<Settings />} />}
        />
        <Route
          path="/admin/crm/users"
          element={<PostLogin child={<CrmUsers />} />}
        />
        <Route
          path="/owner/staffing/:roleType"
          element={<PostLogin child={<Staffing />} />}
        />
        <Route
          path="/owner/organization/:organizationFilterType"
          element={<PostLogin child={<Organization />} />}
        />
        <Route
          path="/owner/consultation/:organizationFilterType"
          element={<PostLogin child={<Organization />} />}
        />

        {/* <Route
          path="/owner/staffing/:roleType"
          element={<PostLogin child={<Staffing />} />}
        /> */}

        <Route
          exact
          path="/owner/staffings/:organizationFilterType"
          element={<PostLogin child={<Organization />} />}
        />

        <Route
          path="/owner/patient/:patientRoleType"
          element={<PostLogin child={<Patient />} />}
        />
        <Route
          path="/clients/finaceured/walletscan"
          element={<PostLogin child={<WalletScan />} />}
        />
        <Route
          path="/owner/bookings/dashboard"
          element={<PostLogin child={<Booking />} />}
        />
        <Route
          path="/owner/bookings/calendars"
          element={<PostLogin child={<BookingCalendar />} />}
        />
        <Route path="/registration" element={<Registration />} />
        <Route
          path="/owner/staffing/specialists"
          element={<PostLogin child={<Consultation />} />}
        />
        <Route
          path="/owner/consultation/sessions"
          element={<PostLogin child={<Sessionpage />} />}
        />
        <Route
          path="/owner/forms/patientProfileForms"
          element={<PostLogin child={<Patients />} />}
        />
        <Route
          path="/owner/forms/widgetForms"
          element={<PostLogin child={<Wedge />} />}
        />

        <Route
          path="/owner/forms/consultationSummaryForms"
          element={<PostLogin child={<ConsultationSummary />} />}
        />
        <Route
          path="/owner/forms/preAssessment"
          element={<PostLogin child={<PreAssessment />} />}
        />
        <Route
          path="/owner/pharmacy/Categories"
          element={<PostLogin child={<Pharmacycat />} />}
        />
        <Route
          path="/owner/pharmacy/brands"
          element={<PostLogin child={<PhatamcyBrand />} />}
        />
        <Route
          path="/owner/pharmacy/products"
          element={<PostLogin child={<PharamacyProduct />} />}
        />
        <Route
          path="/owner/accommodations/rooms"
          element={<PostLogin child={<AccomdationRoom />} />}
        />
        <Route
          path="/owner/accommodations/categories"
          element={<PostLogin child={<AccomdationCatories />} />}
        />
        <Route
          path="/owner/therapies/categories"
          element={<PostLogin child={<TherapiesCatories />} />}
        />

        <Route
          path="/owner/therapies/offerings"
          element={<PostLogin child={<TherapiesOffering />} />}
        />
        <Route
          path="/owner/therapies/therapySessions"
          element={<PostLogin child={<TherapieSession />} />}
        />

        <Route
          path="/owner/packages"
          element={<PostLogin child={<Programs />} />}
        />

        <Route
          path="/owner/kitchen/items"
          element={<PostLogin child={<Kitchen />} />}
        />
        <Route
          path="/owner/kitchen/dietTemplates"
          element={<PostLogin child={<Diteplan />} />}
        />

        <Route
          path="/owner/staffing/staff/:id"
          element={<PostLogin child={<StaffEditing />} />}
        />
        <Route
          path="/owner/staffing/staffgroups/:id"
          element={<PostLogin child={<StaffGroupEditing />} />}
        />
        <Route
          path="/owner/staffing/staffroles/:id"
          element={<PostLogin child={<StaffRoleEditing />} />}
        />
        <Route
          path="/owner/staffing/calendar/:id"
          element={<PostLogin child={<ClanderEditor />} />}
        />
        <Route
          path="/owner/staffing/locations/:id"
          element={<PostLogin child={<LoctionEditor />} />}
        />
        <Route
          path="/owner/consultation/serviceTypes/:id"
          element={<PostLogin child={<ConsultationServerTypeEditing />} />}
        />
        <Route
          path="/owner/consultation/services/:id"
          element={<PostLogin child={<ConsultationServiceEditing />} />}
        />
        <Route
          path="/owner/patient/users/:id"
          element={<PostLogin child={<CRm />} />}
        />
        <Route
          path="/owner/accommodations/rooms/:id"
          element={<PostLogin child={<RoomEditor />} />}
        />
        <Route
          path="/owner/forms/preAssessment/:id"
          element={<PostLogin child={<PreAssmentEditor />} />}
        />


        <Route
          path="/owner/consultation/sessions/:id"
          element={<PostLogin child={<SessionPageEditior />} />}
        />
        <Route
          path="/owner/pharmacy/Categories/:id"
          element={<PostLogin child={<BrandCatEditing />} />}
        />
        <Route
          path="/owner/forms/patientProfileForms/:id"
          element={<PostLogin child={<PatentEditor />} />}
        />

        <Route
          path="/owner/forms/widgetForms/:id"
          element={<PostLogin child={<Weditor />} />}
        />

        <Route
          path="/owner/forms/consultationSummaryForms/:id"
          element={<PostLogin child={<ConsultationSummaryEditor />} />}
        />
        <Route
          path="/owner/staffing/specialists/:id"
          element={<PostLogin child={<SpecalistEditor />} />}
        />
        <Route
          path="/owner/accommodations/categories/:id"
          element={<PostLogin child={<RoomCatogireEditing />} />}
        />

        <Route
          path="/owner/therapies/offerings/:id"
          element={<PostLogin child={<TheriopyOfferingEditing />} />}
        />
        <Route
          path="/owner/therapies/therapySessions/:id"
          element={<PostLogin child={<TheriopySessionEditing />} />}
        />
        <Route
          path="/owner/therapies/categories/:id"
          element={<PostLogin child={<TheriopycatEditing />} />}
        />
        <Route
          path="/owner/bookings"
          element={<PostLogin child={<Bookingdata />} />}
        />

        <Route
          path="/owner/billingInformation"
          element={<PostLogin child={<Billing />} />}
        />
        <Route
          path="/owner/billingInformation/:id"
          element={<PostLogin child={<Billing />} />}
        />
        <Route
          path="/owner/pharmacy/prescriptions"
          element={<PostLogin child={<Prescriptions />} />}
        />
        <Route
          path="/owner/pharmacy/prescriptions/:id"
          element={<PostLogin child={<PrescriptionEditior />} />}
        />
        <Route
          path="/owner/pharmacy/products/:id"
          element={<PostLogin child={<PharmashyProduct />} />}
        />
        <Route
          path="/owner/pharmacy/frequencies"
          element={<PostLogin child={<PharamcyFrequencies />} />}
        />
        <Route
          path="/owner/pharmacy/frequencies/:id"
          element={<PostLogin child={<PharamcyFrequenciesEDting />} />}
        />
        <Route
          path="/owner/pharmacy/compositionMedicine"
          element={<PostLogin child={<PharamacyAnuma />} />}
        />
        <Route
          path="/owner/pharmacy/compositionMedicine/:id"
          element={<PostLogin child={<PharamacyAnumaEditing />} />}
        />
        <Route
          path="/owner/pharmacy/ingredients"
          element={<PostLogin child={<PharamcyIntergrate />} />}
        />
        <Route
          path="/owner/pharmacy/ingredients/:id"
          element={<PostLogin child={<PharamcyIntergrateEdite />} />}
        />
        <Route
          path="/owner/pharmacy/brands/:id"
          element={<PostLogin child={<PharmashyBrandEditing />} />}
        />
        <Route
          path="/owner/packages/:id"
          element={<PostLogin child={<ProgramEditor />} />}
        />
        <Route
          path="/owner/bookings/:id/:type"
          element={<PostLogin child={<ConsultationSessionPage />} />}
        />

        <Route
          path="/owner/kitchen/items/:id"
          element={<PostLogin child={<KitchenItemEditing />} />}
        />
        <Route
          path="/owner/kitchen/dietTemplates/:id"
          element={<PostLogin child={<DieatEditing />} />}
        />

        <Route path="/registration/affiliate" element={<Registration />} />
        <Route
          path="/registration/affiliate/:affiliateusername"
          element={<Registration />}
        />


        <Route path="/waitinglist" element={<WaitingList />} />
        <Route path="*" element={<Login />} />
      </Routes>
    </>
  );
}

export default App;
