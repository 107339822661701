import React, { useState, useEffect } from 'react'
import axios from 'axios';
import './Page.scss'
import {
    CopyBtn,
    CustomCheckbox,
    EachCustomDropdown, NewUIMyEachInput, MYEachCustomImageUpload, StaffEachInputDatePicker, NewDateAndPicker,
    EachCustomImageUpload, NewEachCustomDropdown,
    NewEachCustomImageUploadcustom,
    EachCustomSelectMultiple, NewMyEachCustomTextarea,
    EachInputCustom, EachInputsepfic, MyEachCustomImageUpload,
    EachCustomTextarea,
    OwnEachCustomDropdown,
    EachInput, RadioNewUIMyEachInput,
} from "../../../components/Custom";
export default function Page({ selectedPaitent }) {
    const [patientlist, setpatientlist] = useState({
        Fname: "Ram",
        Lname: "Sam",
        Birthday: "08/07/1994",
        MaritalStatus: "Single",
        gender: "Male",
        photo: "",
        weight: "34",
        height: "555",
        phone: "23464324344",
        email: "yo@gmail.com",
        country: "India",
        state: "Tamil Nadu",
        Pincode: "34224",
        Address: "fo nkuo mnhay ldkfgjdfghyb",
        city: "Tamil",
        currentmedic: "yes",
        mediAddress: "fo nkuo mnhay ldkfgjdfghyb",
        Relationship: "Mom",
        RContactNumber: "23421421323",
        EmergencyContact: "5323122134"
    })
    const [AllCountries, setAllCountries] = useState([])
    useEffect(() => {
        getAllCountries()
    }, [])
    async function getAllCountries() {

        axios
            .get(
                `https://comms.globalxchange.io/coin/vault/countries/data/get`
            )
            .then((res) => {
                if (res.data.status) {
                    setAllCountries(res?.data?.countries)
                }
                else {
                    setAllCountries([])
                }
            });



    }
    return (
        <div className='PatientInformation'>
            <div className='rightside'>
         


            
                <div className="Innerinnputsection"
                    style={{
                        flexWrap: "wrap"
                    }}
                >
                    {
                        selectedPaitent?.questions?.map(item => {
                            return (
                                <>


                                    <div className='subdivision'
                                        style={{
                                            marginBottom: "1rem"
                                        }}
                                    >
                                        <NewUIMyEachInput
                                            title={item?.name}
                                            placeholder=""
                                            stateValue={item?.answer}
                                            // name="Fname"
                                            // stateValue={patientlist}
                                            setState={setpatientlist}
                                            topTitle="true"
                                            toggle={true}
                                        />
                                    </div>

                                </>
                            )
                        })
                    }
                </div>

            </div></div>

    )
}
