import React, { useState } from 'react'
import {
    CopyBtn,
    CustomCheckbox,
    EachCustomDropdown, NewUIMyEachInput, MYEachCustomImageUpload, StaffEachInputDatePicker, NewDateAndPicker,
    EachCustomImageUpload, NewEachCustomDropdown,
    NewEachCustomImageUploadcustom,
    EachCustomSelectMultiple, NewMyEachCustomTextarea,
    EachInputCustom, EachInputsepfic, MyEachCustomImageUpload,
    EachCustomTextarea,
    OwnEachCustomDropdown,
    EachInput, RadioNewUIMyEachInput,
} from "../../../components/Custom";
import './preAssement.scss'
export default function Page({ preAssessmentDetail }) {
    const [preAssement, setpreAssement] = useState({
        Weight: "72", Height: "175", RespiratoryRate: "77", BodyTemperature: "98.4", PluseRate: "130/84", BloodPressure: "66"
    })
    return (
        <div className='preAssementTab'>

            <div className='textSectionTop'>
                <div className='infotext'>
                    Client Assesment Details
                </div>
                {/* <div
            className='editlabel'
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path d="M8.84006 2.9L3.36673 8.69333C3.16006 8.91333 2.96006 9.34667 2.92006 9.64667L2.6734 11.8067C2.58673 12.5867 3.14673 13.12 3.92006 12.9867L6.06673 12.62C6.36673 12.5667 6.78673 12.3467 6.9934 12.12L12.4667 6.32667C13.4134 5.32667 13.8401 4.18667 12.3667 2.79334C10.9001 1.41334 9.78673 1.9 8.84006 2.9Z" stroke="" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.92676 3.86664C8.21342 5.70664 9.70676 7.1133 11.5601 7.29997" stroke="" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M2 15.1667H14" stroke="" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            Edit</div> */}
            </div>
            <div className="Innerinnputsection"
                style={{
                    flexWrap: "wrap"
                }}
            >
                {
                    preAssessmentDetail?.questions?.map(item => {
                        return (
                            <div className='subdivision'
                                style={{
                                    marginBottom: "1rem"
                                }}
                            >
                                <NewUIMyEachInput
                                    title={item?.name}
                                    placeholder=""
                                    toggle={true}
                                    stateValue={item?.answer}
                                    // name="PluseRate"
                                    //stateValue={preAssement}
                                    setState={setpreAssement}
                                    topTitle="true"
                                />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
