export const APP_CODE = "amma";

export const APP_USER_TOKEN = "app_user_token";

export const COUNTRY = "India";

export const monthsArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const datesArray = Array.from({ length: 31 }, (_, index) =>
  (index + 1).toString()
);

export const amArray = Array.from(
  { length: 24 },
  (_, index) =>
    index >= 12 ?
      `${index}.00 PM` :
      `${index}.00 AM`



);

// Generate an array for PM (1 to 12)
export const pmArray = Array.from(
  { length: 24 },
  (_, index) => index >= 12 ?
    `${index}.00 PM` :
    `${index}.00 AM`
);
