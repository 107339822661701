import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./Session.module.scss";
import "./Sessionpage.scss";
//assets
import { ReactComponent as SortArrows } from "../../assets/images/icons/sort.svg";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../../context/Globalcontext";
import { useNetworkRequest } from "../../hooks/networkRequest";
import { deleteData, getData } from "../../assets/functions/apiCalls";
import axios from "axios";
import { toastify } from "../../assets/functions/toastify";
import { filterDetails } from "../../assets/functions";
import { APP_CODE } from "../../assets/data/constant";

import moment from "moment/moment";
import PipelineSubmenu from "./Submenu";
import Drawer from "react-modern-drawer";
import { EachCustomDropdown, EachInput } from "../../components/Custom";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

const Organization = () => {
  const {
    email,
    actionBtns,
    setActionBtns,
    globalSearchQuery,
    dropDowndownvalue,
    // isSpecialist,
  } = useContext(GlobalContext);
  const [selectedForm, setSelectedForm] = useState(null);
  const [isOpen, setIsOpen] = useState(null);
  const [formErr, setFormErr] = useState(null);
  // const [answerName, setAnswerName] = useState([]);
  const [answerName, setAnswerName] = useState([]);
  const [checkBoxStage, setCheckBoxStage] = useState("");
  const [sidebarStep, setSidebarStep] = useState(0);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [session, setsession] = useState([]);
  const [consulation, setconsulation] = useState({
    constultandName: [],
    constultandid: [],
    locationid: "",
    locationName: "",
    spaceid: "",
    spaceName: "",
    name: "",
    description: "",
    coverPhoto: "",
    duration: "",

    cost: "",
  });
  console.log("0000", consulation);

  const [specialistlistitem, setspecialistlistitem] = useState("");

  const removeMe = (index) => {
    const temp = [...consulation?.constultandName];
    temp.splice(index, 1);
    setconsulation({
      constultandName: temp,
    });
  };

  const [wedgedata, setwedgedata] = useState([]);
  const [isSpecialist, setIsSpecialist] = useState(null);
  useEffect(() => {
    tablefucntion();
  }, [dropDowndownvalue, isSpecialist]);
  const [locationdata, setlocationdata] = useState({});
  const history = useNavigate();
  useEffect(() => {
    axios
      .get(
        `https://uatamma.apimachine.com/api/getspecialistdetail?email=${email}`
      )
      .then(({ data }) => {
        if (data.success) {
          setIsSpecialist(data?.data[0]?.specialistDetails[0]);
        } else {
          setIsSpecialist(false);
        }
      });
  }, []);

  const tablefucntion = () => {
    if (isSpecialist === false) {
      axios
        .get(
          `https://uatamma.apimachine.com/api/getconsultsessions?teamusername=${
            JSON.parse(localStorage?.getItem("orginzationdetail"))
              ?.TeamUsername || ""
          }`
        )
        .then((res) => {
          console.log("cxczxczxc", res?.data?.data);
          setsession(res?.data?.data);
        });
    } else if (isSpecialist !== null && isSpecialist !== false) {
      axios
        .get(
          `https://uatamma.apimachine.com/api/getconsultsessions?specialistid=${isSpecialist?.specialistid}`
        )
        .then((res) => {
          console.log("cxczxczxc", res?.data?.data);
          setsession(res?.data?.data);
        });
    }
  };

  const [player, setplayer] = useState([]);

  const submitservicessFucntion = () => {
    let pop = consulation?.constultandName?.map((item) => {
      return item?.id;
    });
    console.log("PoP", pop);

    axios
      .post("https://uatamma.apimachine.com/api/addconsultationservice", {
        teamplayerid: consulation?.spaceid,
        locationid: consulation?.locationid,
        serviceids: pop,
      })
      .then(({ data }) => {
        if (data.status) {
          toastify("Added succesfully!", "success");
          setCheckBoxStage("");
          // setTimeout(() => {
          //     window.location.reload();
          // }, 2000);
        } else {
          toastify(data?.message, "error");
        }
      });
  };

  function ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }
  const monthNames = [
    "",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  console.log("cxc66666zxczxc", player);
  const [active, setactive] = useState("Pre Assessment Form");

  const [page, setpage] = useState({ title: "main" });
  const [getsesion, setgetsesion] = useState({});
  const [show, setshow] = useState(null);
  useEffect(() => {
    setpage({ title: "main" });
    setshow(null);
    setsublist("Pre Assessment Form");
    setactive("Pre Assessment Form");
    setquestionslist([]);
    setexistpreformdata([]);
  }, []);

  const [questionslist, setquestionslist] = useState([]);

  const [datalist, setdatalist] = useState([
    {
      name: "",
      anstype: "",
      requried: true,
      toggle: false,
    },
  ]);

  const [preform, setpreform] = useState([
    {
      name: "",
      anstype: "",
      ans: "",
      requried: true,
    },
  ]);
  console.log("fiusfbdssbfsn", getsesion);

  const getquestion = (e, g) => {
    axios
      .get(
        `https://uatamma.apimachine.com/api/getassessment?preassessmentid=${g}`
      )
      .then((res) => {
        //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
        if (res?.data?.success) {
          setquestionslist(res?.data?.data?.[0]?.questions);

          let pop = res?.data?.data?.[0]?.questions?.map((item, i) => {
            return {
              name: "",
              anstype: "",
              ans: "",
              requried: item?.isMandatory,
            };
          });
          console.log("pop", pop);
          setpreform(pop);
        } else {
          setpreform([
            {
              name: "",
              anstype: "",
              ans: "",
              requried: true,
            },
          ]);
          setquestionslist([]);
        }
      });

    exitinggetquestion(e, g);
  };

  const [existpreformdata, setexistpreformdata] = useState([]);
  const exitinggetquestion = (e, g) => {
    axios
      .get(
        `https://uatamma.apimachine.com/api/getuserassessment?preassessmentid=${g}&clientid=${getsesion?.clientid}&bookingid=${e}`
      )
      .then((res) => {
        console.log("12344", res?.data?.data);
        if (res?.data?.success) {
          setexistpreformdata(res?.data?.data?.[0]?.questions);

          // setquestionslist(res?.data?.data?.[0]?.questions)
        } else {
          setexistpreformdata([]);
          // setquestionslist([])
        }
      });
  };

  const [dop, setdop] = useState(null);

  const [wedgetoggle, setwedgetoggle] = useState([
    {
      toggle: false,
    },
  ]);

  const tablefucntion1 = (e) => {
    axios
      .get(`https://uatamma.apimachine.com/api/getwidget?specialistid=${e}`)
      .then((res) => {
        //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
        if (res.data?.success) {
          setwedgedata(res?.data?.data);
          let pop = res?.data?.data?.map((item, i) => {
            return {
              toggle: false,
            };
          });
          console.log("pop", pop);
          setwedgetoggle(pop);
          // addFields(res?.data?.data)
        } else {
          setwedgetoggle([
            {
              toggle: false,
            },
          ]);
          setwedgedata([]);
          // addFields(res?.data?.data?.[0]?.questions)
        }
      });
  };

  const wedgetogglefunction = (index) => {
    let data = [...wedgetoggle];
    data[index].toggle = !data[index].toggle;

    setwedgetoggle(data);
  };

  const handleFormChangePreform = (index, event, g) => {
    let data = [...preform];

    data[index].anstype = g;

    data[index][event.target.name] = event.target.value;
    console.log("ppppp", data);
    setpreform(data);
  };

  const dropdownaddedpreform = (index, item, tyep) => {
    let data = [...preform];
    data[index].anstype = tyep;
    data[index].name = item;

    setpreform(data);
    setshowdrop(false);
  };

  const handleFormChange = (index, event, g) => {
    let data = [...datalist];
    data[index].anstype = g;

    data[index][event.target.name] = event.target.value;
    setdatalist(data);
  };

  const dropdownadded = (index, item, tyep) => {
    let data = [...datalist];
    data[index].anstype = tyep;
    data[index].name = item;

    setdatalist(data);
    setshowdrop(false);
  };

  console.log("ryufsgdfsjh", wedgetoggle);

  const selected = (e, g) => {
    addFields(e?.questions);
    setwidgenumber(g);
  };
  const addFields = (e) => {
    let newfield = { name: "", anstype: "", requried: true };

    let pop = e?.map((item, i) => {
      return {
        name: "",
        anstype: "",
        requried: item?.isMandatory,
        toggle: false,
      };
    });
    console.log("pop", pop);
    setdatalist(pop);
  };
  console.log("datalist", datalist);
  const [sublist, setsublist] = useState("Pre Assessment Form");
  const [showdrop, setshowdrop] = useState(false);
  const [widgenumber, setwidgenumber] = useState(null);

  const [isToggleOn, setisToggleOn] = useState(false);
  let loc = window.location.pathname;
  let finallo = loc?.split("/");
  const sublistswtch = () => {
    switch (sublist) {
      case "Widgets":
        return (
          <div className="innerSectionSession">
            {/* <div className="buttonsave">
                            <div>Save</div>
                        </div> */}
            {wedgedata?.map((such, i) => {
              // console.log("iten34535", item)

              return (
                <>
                  {widgenumber == i ? (
                    <div className="widgetparent">
                      <div
                        className="namewidget"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => setwidgenumber(null)}
                      >
                        {such?.name}

                        <svg
                          onClick={() => setwidgenumber(null)}
                          xmlns="http://www.w3.org/2000/svg"
                          width="7"
                          height="6"
                          viewBox="0 0 7 6"
                          fill="none"
                        >
                          <path
                            d="M6.87533 4.8683L3.98854 0.304976C3.76648 -0.101659 3.27793 -0.101659 3.01146 0.304976L0.124669 4.8683C-0.186232 5.36528 0.124669 5.99785 0.613191 5.99785H6.38681C6.87533 6.04303 7.18623 5.36532 6.87533 4.8683Z"
                            fill="#010101"
                          />
                        </svg>
                      </div>

                      {wedgetoggle[i]?.toggle ? (
                        ""
                      ) : (
                        <>
                          {such?.questions?.map((item, j) => {
                            if (item?.answerType == "Dropdown") {
                              return (
                                <div className="questionsection">
                                  <div className="queTitle">{item?.name}</div>
                                  <div
                                    className="queTitledrop"
                                    onClick={() => {
                                      setshowdrop(!showdrop);
                                      setdop(j);
                                    }}
                                  >
                                    {datalist?.[j]?.name}
                                    {dop == j && showdrop ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="7"
                                        height="6"
                                        viewBox="0 0 7 6"
                                        fill="none"
                                      >
                                        <path
                                          d="M6.87533 4.8683L3.98854 0.304976C3.76648 -0.101659 3.27793 -0.101659 3.01146 0.304976L0.124669 4.8683C-0.186232 5.36528 0.124669 5.99785 0.613191 5.99785H6.38681C6.87533 6.04303 7.18623 5.36532 6.87533 4.8683Z"
                                          fill="#010101"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="7"
                                        height="6"
                                        viewBox="0 0 7 6"
                                        fill="none"
                                      >
                                        <path
                                          d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z"
                                          fill="#010101"
                                        />
                                      </svg>
                                    )}
                                  </div>
                                  {dop == j && showdrop && (
                                    <div className="dropdonwlistdata">
                                      {item?.dropdownOptions?.map((item) => {
                                        return (
                                          <div
                                            onClick={() =>
                                              dropdownadded(j, item, "dropdown")
                                            }
                                          >
                                            {item}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                </div>
                              );
                            } else {
                              return (
                                <div className="questionsection">
                                  <div className="queTitle">{item?.name}</div>
                                  <input
                                    className="queAnswerinput"
                                    name="name"
                                    value={datalist.name}
                                    onChange={(event) =>
                                      handleFormChange(j, event, "String")
                                    }
                                  />
                                </div>
                              );
                            }
                          })}
                        </>
                      )}

                      {/* 
                                                    {
                                                        wedgedata?.length > 0 &&

                                                        <div className="ToggleSwitch"
                                                            onClick={() => wedgetogglefunction(i)}
                                                        >
                                                            <div className={wedgetoggle[i]?.toggle ? 'knob active' : 'knob'} />
                                                            {wedgetoggle[i]?.toggle ? 'Existing Form' : 'New Form'}
                                                        </div>
                                                    } */}
                      <div className="saveSection">
                        <div>Save</div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="widgetparent"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => selected(such, i)}
                    >
                      <div className="namewidget">
                        {such?.name}

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="7"
                          height="6"
                          viewBox="0 0 7 6"
                          fill="none"
                        >
                          <path
                            d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z"
                            fill="#010101"
                          />
                        </svg>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        );
        break;
      case "Pre Assessment Form":
        return (
          <>
            <div className="innerSectionSession">
              {isToggleOn ? (
                <>
                  {existpreformdata?.map((s, j) => {
                    return (
                      <div className="questionsection" key={j}>
                        <div className="queTitle">{s?.name}</div>
                        <input className="queAnswerinput" value={s?.answer} />
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  {questionslist?.map((item, j) => {
                    if (item?.answerType == "dropdown") {
                      return (
                        <div className="questionsection">
                          <div className="queTitle">{item?.name}</div>
                          <div
                            className="queTitledrop"
                            onClick={() => {
                              setshowdrop(!showdrop);
                              setdop(j);
                            }}
                          >
                            {preform?.[j]?.name}
                            {dop == j && showdrop ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="7"
                                height="6"
                                viewBox="0 0 7 6"
                                fill="none"
                              >
                                <path
                                  d="M6.87533 4.8683L3.98854 0.304976C3.76648 -0.101659 3.27793 -0.101659 3.01146 0.304976L0.124669 4.8683C-0.186232 5.36528 0.124669 5.99785 0.613191 5.99785H6.38681C6.87533 6.04303 7.18623 5.36532 6.87533 4.8683Z"
                                  fill="#010101"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="7"
                                height="6"
                                viewBox="0 0 7 6"
                                fill="none"
                              >
                                <path
                                  d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z"
                                  fill="#010101"
                                />
                              </svg>
                            )}
                          </div>
                          {dop == j && showdrop && (
                            <div className="dropdonwlistdata">
                              {item?.dropdownOptions?.map((item) => {
                                return (
                                  <div
                                    onClick={() =>
                                      dropdownaddedpreform(j, item, "dropdown")
                                    }
                                  >
                                    {item}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      );
                    } else {
                      return (
                        <div className="questionsection">
                          <div className="queTitle">{item?.name}</div>
                          <input
                            className="queAnswerinput"
                            name="name"
                            value={preform?.[j]?.name}
                            onChange={(event) =>
                              handleFormChangePreform(j, event, "String")
                            }
                          />
                        </div>
                      );
                    }
                  })}
                </>
              )}

              {/* {
                                questionslist?.map(item => {
                                    return (
                                        <div className="questionsection">

                                            <div className="queTitle">
                                                {item?.name}
                                            </div>
                                            <div className="queAnswer">{item?.answer}</div>
                                        </div>
                                    )
                                })
                            } */}

              {questionslist?.length > 0 && (
                <div
                  className="ToggleSwitch"
                  onClick={() => setisToggleOn(!isToggleOn)}
                >
                  <div className={isToggleOn ? "knob active" : "knob"} />
                  {isToggleOn ? "Existing Form" : "New Form"}
                </div>
              )}
              {!isToggleOn && questionslist?.length > 0 && (
                <div
                  className="saveSection"
                  style={{
                    marginTop: "2rem",
                    position: "absolute",
                    bottom: "2rem",
                    right: "21px",
                  }}
                >
                  <div>Save</div>
                </div>
              )}
            </div>
          </>
        );
        break;

      default:
        break;
    }
  };
  const [selectedRow, setSelectedRow] = useState(null);
  const handleDropItemClick = (e, row, opt) => {
    setSelectedRow(row);
    e.stopPropagation();
    setSidebarStep(0);
    if (opt === 0) {
      setCheckBoxStage("preAssessment");
    } else if (opt === 1) {
      setCheckBoxStage("patientProfile");
    } else if (opt === 2) {
      setCheckBoxStage("consulationSummary");
    }
  };

  const pageswtch = () => {
    switch (page?.title) {
      case "main":
        return (
          <div className="global-dashboard">
            <div className="label-box-header">
              {finallo?.[3] == "sessions"
                ? "Consultation Sessions"
                : finallo?.[3]}
            </div>
            <div className="top-section-golbal">
              <div className="left-side">
                <div className="label-box">
                  <svg
                    style={{
                      marginRight: "10px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                  >
                    <path
                      d="M12.8445 8.07626L10.9486 6.58668C10.8262 6.4908 10.6594 6.47293 10.5202 6.54118C10.3804 6.60888 10.2916 6.7508 10.2916 6.90626V7.90292C8.75704 8.16184 7.58325 9.49651 7.58325 11.1042V12.4583C7.58325 12.7573 7.82592 13 8.12492 13C8.42392 13 8.66658 12.7573 8.66658 12.4583V11.1042C8.66658 10.0978 9.35992 9.25655 10.2916 9.01442V9.88542C10.2916 10.0409 10.3804 10.1828 10.5202 10.2505C10.5765 10.2781 10.6372 10.2917 10.6978 10.2917C10.7872 10.2917 10.876 10.2624 10.9486 10.205L12.8445 8.71542C12.943 8.63851 12.9999 8.52042 12.9999 8.39584C12.9999 8.27126 12.943 8.15317 12.8445 8.07626Z"
                      fill="#010101"
                    />
                    <path
                      d="M9.74999 0H1.625C0.731249 0 0 0.731249 0 1.625V8.66666C0 9.5604 0.731249 10.2917 1.625 10.2917H6.58124C6.65166 9.91249 6.77082 9.54957 6.93874 9.20832H1.625C1.32708 9.20832 1.08333 8.96457 1.08333 8.66666V2.70833H10.2917V5.47624C10.6492 5.37333 11.0392 5.41124 11.375 5.57916V1.625C11.375 0.731249 10.6437 0 9.74999 0V0Z"
                      fill="#010101"
                    />
                  </svg>
                  Export
                </div>

                <>
                  <div className="label-box">
                    <svg
                      style={{
                        marginRight: "10px",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                    >
                      <path
                        d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z"
                        fill="#010101"
                      />
                    </svg>
                    Add Filter
                  </div>
                </>
              </div>

              <div className="right-side">
                <div className="shortBy">Sort By</div>
                <div className="dropdownlist">
                  Newest
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7"
                    height="6"
                    viewBox="0 0 7 6"
                    fill="none"
                  >
                    <path
                      d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z"
                      fill="#010101"
                    />
                  </svg>
                </div>
                <div
                  className="addLabel"
                  onClick={() => setCheckBoxStage("add")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                  >
                    <path
                      d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z"
                      fill="white"
                    />
                  </svg>
                  Add New
                </div>
              </div>
            </div>

            <div className="handle-table">
              <div className="g-table-sec">
                <div className="table-section">
                  <table style={{ minWidth: "100%" }}>
                    <thead>
                      <tr
                        style={{
                          gridTemplateColumns: "min-content repeat(6, 1fr)",
                        }}
                      >
                        <th>
                          Date <SortArrows />
                        </th>
                        <th>
                          Location
                          <SortArrows />
                        </th>
                        <th>
                          Patient
                          <SortArrows />
                        </th>
                        <th>
                          Specialist
                          <SortArrows />
                        </th>

                        <th>
                          Service
                          <SortArrows />
                        </th>
                        <th>
                          Status
                          <SortArrows />
                        </th>

                        <th>
                          <SortArrows />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {session?.map((row, index) => {
                        return (
                          <tr
                            key={row?.brokerage_name + index}
                            style={{
                              gridTemplateColumns: "min-content repeat(6, 1fr)",
                              pointerEvents: sidebarOpen ? "none" : "",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              //setpage({ title: "second", data: row })
                              history(
                                `/owner/consultation/sessions/${row?.consultationsessionid}`
                              )
                            }
                          >
                            <td>
                              {row?.bookingDetails?.length > 0 &&
                              row?.bookingDetails[0]
                                ? moment(
                                    row?.bookingDetails[0]?.dateOfBooking
                                  ).format("MMM Do YY")
                                : "-"}
                            </td>
                            <td>
                              {
                                row?.bookingDetails?.[0]?.locationDetails?.[0]
                                  ?.name
                              }
                            </td>
                            <td>
                              {row?.clientdetails?.length > 0 &&
                              row?.clientdetails[0]?.name
                                ? row?.clientdetails[0]?.name
                                : "-"}
                            </td>
                            <td>
                              {row?.specialistDetails?.length > 0 &&
                              row?.specialistDetails[0]?.PlayerDetails?.length >
                                0
                                ? row?.specialistDetails[0]?.PlayerDetails[0]
                                    ?.name
                                : ""}
                            </td>

                            <td>
                              {
                                row?.bookingDetails?.[0]?.serviceDetails?.[0]
                                  ?.name
                              }
                            </td>
                            <td>
                              <div
                                style={{
                                  width: "113px",
                                  height: "39px",
                                  textTransform: "capitalize",
                                  background:
                                    row?.status == "open"
                                      ? "#C51E25"
                                      : " #010101",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: "5px",
                                  color: "#fff",
                                  fontWeight: "700",
                                }}
                              >
                                {row?.status == "open" ? "open" : "closed"}
                              </div>
                            </td>

                            <td
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                position: "relative",
                                width: "200px",
                              }}
                            >
                              {/* <div
                                className={classNames.tableBtn}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setCheckBoxStage("add");
                                }}
                              >
                                Pre-Assessment
                              </div> */}
                              <div
                                className={classNames.newDropdown}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  // setCheckBoxStage("add");
                                  setIsOpen(isOpen === null ? row : null);
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                    padding: "10px 0px",
                                    zIndex: 1,
                                  }}
                                >
                                  <div>Forms</div>
                                  {isOpen === index ? (
                                    <IoMdArrowDropup />
                                  ) : (
                                    <IoMdArrowDropdown />
                                  )}
                                </div>
                                {row?._id === isOpen?._id && (
                                  <div
                                    className={classNames.optStyle}
                                    style={{ zIndex: 2 }}
                                  >
                                    <div
                                      onClick={(e) =>
                                        handleDropItemClick(e, row, 0)
                                      }
                                    >
                                      Pre-Assessment
                                    </div>
                                    <div
                                      onClick={(e) =>
                                        handleDropItemClick(e, row, 1)
                                      }
                                    >
                                      Patient Profile
                                    </div>
                                    <div
                                      onClick={(e) =>
                                        handleDropItemClick(e, row, 2)
                                      }
                                    >
                                      Consultation Summary
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case "second":
        return (
          // <div
          //   className={classNames.crmUsers}
          //   style={{
          //     background: "#e5e5e521",
          //   }}
          // >
          //   <div className="topimagesectionsession">
          //     <div className="inner">
          //       <div className="parentimg">
          //         <div className="imagecontroller">
          //           <svg
          //             onClick={() => setpage("main")}
          //             xmlns="http://www.w3.org/2000/svg"
          //             width="33"
          //             height="33"
          //             viewBox="0 0 33 33"
          //             fill="none"
          //           >
          //             <circle
          //               cx="16.5"
          //               cy="16.5"
          //               r="16"
          //               fill="white"
          //               stroke="#E9EBF3"
          //             />
          //             <path
          //               d="M10.4496 16.8515L16.1709 22.5349C16.2768 22.64 16.4252 22.7 16.5807 22.7H19.0393C19.5281 22.7 19.782 22.1619 19.4491 21.8314L14.0779 16.5L19.4491 11.1686C19.782 10.8381 19.5281 10.3 19.0393 10.3H16.5807C16.4252 10.3 16.2768 10.3596 16.1709 10.465L10.4496 16.1484C10.2502 16.3467 10.2502 16.6533 10.4496 16.8515Z"
          //               fill="#C57E50"
          //             />
          //           </svg>
          //           <img src={getsesion?.clientdetails?.[0]?.ProfilePic} />
          //         </div>
          //         <div>
          //           <div className="name">
          //             {getsesion?.clientdetails?.[0]?.name}
          //           </div>
          //           <div className="subname">
          //             {getsesion?.consultationsessionid}
          //           </div>
          //         </div>
          //       </div>

          //       <div>
          //         <div className="parenttools">
          //           <div className="toolbox">
          //             <div>Session Status</div>
          //             <div className="line"></div>
          //             <div> {getsesion?.status}</div>
          //           </div>
          //           <div className="contents">
          //             <div
          //               className="boxes"
          //               style={{
          //                 border: "1px solid #C57E50A0",
          //                 color: "#C57E50",
          //               }}
          //               onClick={() => setactive("Pre Assessment Form")}
          //             >
          //               Close Session
          //             </div>
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //     {/* <div className="topcontent">
          //                       <div className="first"></div>
          //                       <div className="soecond">

          //                       </div>
          //                   </div> */}
          //   </div>
          //   <div className="parentsessionsetion">
          //     <div className="leftsideSession">
          //       {/* <div className="titel">Bookings</div> */}

          //       {getsesion?.bookingDetails?.map((item, i) => {
          //         console.log("cznxcbzmnxcznmxczx", getsesion);
          //         return (
          //           <div
          //             className="timebox"
          //             // style={{
          //             //     background: item?.bookingStatus == "booked" ? "#C57E502b" : "#EA0F0F0D",
          //             //     marginTop: "2rem"

          //             // }}
          //             onClick={() => {
          //               getquestion(
          //                 item?.bookingId,
          //                 item?.preassessmentDetails?.[0]?.preassessmentid
          //               );
          //               setshow(i);
          //             }}
          //             style={{
          //               background:
          //                 item?.bookingStatus == "booked"
          //                   ? " #34A8532b"
          //                   : item?.bookingStatus == "arrived"
          //                   ? "#C57E502b"
          //                   : item?.bookingStatus == "canceled"
          //                   ? "#FF00002b"
          //                   : item?.bookingStatus == "pre-assessed"
          //                   ? "#0052B42b"
          //                   : item?.bookingStatus == "consulting"
          //                   ? "#FBBC052b"
          //                   : item?.bookingStatus == "completed"
          //                   ? "#0002b"
          //                   : "#34A8532b",
          //               boxShadow: show == i && "0px 4px 4px 0px #00000040",
          //               pointerEvents:
          //                 active != "Pre Assessment Form" && "none",
          //             }}
          //           >
          //             <div
          //               className="time"
          //               style={{
          //                 color:
          //                   item?.bookingStatus == "booked"
          //                     ? " #34A853"
          //                     : item?.bookingStatus == "arrived"
          //                     ? "#C57E50"
          //                     : item?.bookingStatus == "canceled"
          //                     ? "#FF0000"
          //                     : item?.bookingStatus == "pre-assessed"
          //                     ? "#0052B4"
          //                     : item?.bookingStatus == "consulting"
          //                     ? "#FBBC05"
          //                     : item?.bookingStatus == "completed"
          //                     ? "#000"
          //                     : "#34A853",
          //               }}
          //             >
          //               {item?.timeOfBooking}
          //             </div>
          //             <div className="line"></div>
          //             <div
          //               className="time"
          //               style={{
          //                 color:
          //                   item?.bookingStatus == "booked"
          //                     ? "#34A853"
          //                     : item?.bookingStatus == "arrived"
          //                     ? "#C57E50"
          //                     : item?.bookingStatus == "canceled"
          //                     ? "#FF0000"
          //                     : item?.bookingStatus == "pre-assessed"
          //                     ? "#0052B4"
          //                     : item?.bookingStatus == "consulting"
          //                     ? "#FBBC05"
          //                     : item?.bookingStatus == "completed"
          //                     ? "#000"
          //                     : "#34A853",
          //               }}
          //             >
          //               {item?.bookingStatus}
          //             </div>
          //           </div>
          //         );
          //       })}

          //       {/* <div className="timebox"
          //                           style={{
          //                               background: "#EA0F0F0D"

          //                           }}
          //                       >
          //                           <div className="time"
          //                               style={{
          //                                   color: "red"
          //                               }}
          //                           >9:15 AM</div>
          //                           <div className="line"></div>
          //                           <div className="time"
          //                               style={{
          //                                   color: "red"
          //                               }}
          //                           >Closed</div>
          //                       </div> */}
          //       {/* <div>
          //                           <div className="titel">Bookings</div>
          //                           <input />
          //                       </div> */}
          //     </div>
          //     <div className="rightsideSession">
          //       <div className="contents">
          //         <div
          //           className="boxes"
          //           style={{
          //             background: active == "Pre Assessment Form" && "#C57E50",
          //             color:
          //               active == "Pre Assessment Form" ? "#fff" : "#010101",
          //           }}
          //           onClick={() => {
          //             setsublist("Pre Assessment Form");
          //             setactive("Pre Assessment Form");
          //           }}
          //         >
          //           Pre Assessment Form
          //         </div>
          //         <div
          //           className="boxes"
          //           style={{
          //             background: active == "Widgets" && "#C57E50",
          //             color: active == "Widgets" ? "#fff" : "#010101",
          //           }}
          //           onClick={() => {
          //             setsublist("Widgets");
          //             setactive("Widgets");
          //             setwidgenumber(null);
          //             setshow(null);
          //           }}
          //         >
          //           Widgets
          //         </div>

          //         <div
          //           className="boxes"
          //           style={{
          //             background: active == "Prescription" && "#C57E50",
          //             color: active == "Prescription" ? "#fff" : "#010101",
          //           }}
          //           onClick={() => setactive("Prescription")}
          //         >
          //           Prescription
          //         </div>
          //         <div
          //           className="boxes"
          //           style={{
          //             background: active == "Consultation Summary" && "#C57E50",
          //             color:
          //               active == "Consultation Summary" ? "#fff" : "#010101",
          //           }}
          //           onClick={() => setactive("Consultation Summary")}
          //         >
          //           Consultation Summary
          //         </div>
          //         <div
          //           className="boxes"
          //           style={{
          //             background: active == "Diet Plan" && "#C57E50",
          //             color: active == "Diet Plan" ? "#fff" : "#010101",
          //           }}
          //           onClick={() => setactive("Diet Plan")}
          //         >
          //           Diet Plan
          //         </div>
          //         <div
          //           className="boxes"
          //           style={{
          //             background: active == "Treatment" && "#C57E50",
          //             color: active == "Treatment" ? "#fff" : "#010101",
          //           }}
          //           onClick={() => setactive("Treatment")}
          //         >
          //           Treatment
          //         </div>
          //       </div>

          //       {sublistswtch()}
          //     </div>
          //   </div>
          // </div>
          <PipelineSubmenu stateValue={page} setState={setpage} />
        );
      default:
        break;
    }
  };
  const [allForms, setAllForms] = useState([]);
  const getAllForms = (checkBoxStage) => {
    const uname = JSON.parse(
      localStorage.getItem("orginzationdetail")
    )?.TeamUsername;
    if (checkBoxStage === "preAssessment") {
      axios
        .get(
          `https://uatamma.apimachine.com/api/getassessment?teamusername=${uname}`
        )
        .then(({ data }) => {
          if (data.success) {
            setAllForms(data.data);
          }
        });
    } else if (checkBoxStage === "patientProfile") {
      axios
        .get(
          `https://uatamma.apimachine.com/api/getpatientdetails?teamusername=${uname}`
        )
        .then(({ data }) => {
          if (data.success) {
            setAllForms(data.data);
          }
        });
    }
  };

  useEffect(() => {
    getAllForms(checkBoxStage);
  }, [checkBoxStage]);

  useEffect(() => {
    // Populate answerName based on selectedForm.questions
    if (selectedForm?.questions) {
      const initialAnswers = selectedForm.questions.map((item) => ({
        questionId: item._id,
        name: item.name,
        answer: "",
      }));
      setAnswerName(initialAnswers);
    }
  }, [selectedForm]);

  const handleChange = (index, newValue) => {
    const updatedAnswers = [...answerName];
    updatedAnswers[index].answer = newValue;
    setAnswerName(updatedAnswers);
  };

  useEffect(() => {
    console.log(selectedRow, "selectedForm");
  }, [answerName]);

  const handleSubmitForm = () => {
    const apiBody = {
      preassessmentid: selectedForm?.preassessmentid,
      clientid: selectedRow?.clientid,
      consultationsessionid: selectedRow?.consultationsessionid,
      questions: answerName,
    };

    const allAnswered = apiBody.questions.every((question) => question.answer);
    if (allAnswered) {
      console.log(checkBoxStage, "qkjwgdkjqwegfwef");
      if (checkBoxStage === "preAssessment") {
        axios
          .post(`https://uatamma.apimachine.com/api/adduserassessment`, {
            preassessmentid: selectedForm?.preassessmentid,
            clientid: selectedRow?.clientid,
            consultationsessionid: selectedRow?.consultationsessionid,
            questions: answerName,
            formname: selectedForm?.formname,
          })
          .then(({ data }) => {
            if (data.status) {
              setCheckBoxStage("");
              setSidebarStep("");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
              setFormErr(null);
            } else {
              setFormErr(data.message);
            }
          });
      } else if (checkBoxStage === "patientProfile") {
        console.log(
          selectedForm,
          {
            // preassessmentid: selectedForm?.preassessmentid,
            patientid: selectedForm?.patientid,
            clientid: selectedRow?.clientid,
            formname: selectedForm?.formname,
            // consultationsessionid: selectedRow?.consultationsessionid,
            questions: answerName,
          },
          "kjafkjaefhkjehfwfw"
        );

        axios
          .post(`https://uatamma.apimachine.com/api/adduserpatient`, {
            patientid: selectedForm?.patientid,
            clientid: selectedRow?.clientid,
            formname: selectedForm?.formname,
            // consultationsessionid: selectedRow?.consultationsessionid,
            questions: answerName,
          })
          .then(({ data }) => {
            if (data.status) {
              setCheckBoxStage("");
              setSidebarStep("");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
              setFormErr(null);
            } else {
              setFormErr(data.message);
            }
          });
      }
    } else {
      setFormErr("Fill all inputs");
    }
  };

  const conditionalSidebar = () => {
    switch (sidebarStep) {
      case 1:
        return (
          <>
            <div
              className={classNames.eachFilterItem}
              style={{
                fontWeight: "700",
                fontSize: "22px",
                pointerEvents: "none",
                paddingLeft: "0",
                marginBottom: "1.5rem",
                paddingTop: "5rem",
              }}
            >
              {selectedForm?.formname}
            </div>
            <div className={classNames.formList}>
              {selectedForm?.questions?.map((item, index) => (
                <div key={index}>
                  {item?.answerType === "String" ? (
                    <div className={classNames.inputgroup}>
                      <label>
                        {item?.name}&nbsp;
                        {item?.isMandatory && (
                          <span style={{ color: "orange" }}>*</span>
                        )}
                      </label>
                      <input
                        type="text"
                        placeholder="Your Answer..."
                        value={answerName[index]?.answer}
                        onChange={(e) => handleChange(index, e.target.value)}
                        required={item?.isMandatory}
                      />
                    </div>
                  ) : (
                    <div className={classNames.inputgroup}>
                      <label>
                        {item?.name}&nbsp;
                        {item?.isMandatory && (
                          <span style={{ color: "orange" }}>*</span>
                        )}
                      </label>
                      <select
                        value={answerName[index]?.answer}
                        onChange={(e) => handleChange(index, e.target.value)}
                        required={item?.isMandatory}
                      >
                        <option value="">Select an option</option>
                        {item?.dropdownOptions.map((option, optionIndex) => (
                          <option key={optionIndex} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {formErr && <h4 style={{ color: "red" }}>{formErr}</h4>}
            <div className={classNames.submitbtn} onClick={handleSubmitForm}>
              Submit
            </div>
          </>
        );

      default:
        return (
          <>
            <div
              className={classNames.eachFilterItem}
              style={{
                fontWeight: "700",
                fontSize: "22px",
                pointerEvents: "none",
                paddingLeft: "0",
                marginBottom: "1.5rem",
                paddingTop: "5rem",
              }}
            >
              Select Form
            </div>
            <div className={classNames.formList}>
              {allForms?.map((item) => (
                <div
                  className={classNames.eachForm}
                  onClick={(e) => {
                    setSidebarStep(1);
                    setSelectedForm(item);
                  }}
                >
                  {item?.formname}
                </div>
              ))}
            </div>
          </>
        );
    }
  };

  return (
    <>
      {pageswtch()}

      <Drawer
        size={450}
        // duration={500}
        open={
          checkBoxStage === "preAssessment" ||
          checkBoxStage === "patientProfile"
        }
        onClose={() => setCheckBoxStage("")}
        direction="right"
        className="bla bla bla"
        overlayColor="#FFF"
        overlayOpacity={0}
      >
        <div className="drowpside-right" style={{ padding: "0px 29px" }}>
          {conditionalSidebar()}
          {/* <EachCustomDropdown
            dropdown={["abc", "def"]}
            name="specialistList"
            title="Select specialist"
            // stateValue={selectedSpecialist}
            // setState={setSelectedSpecialist}
            topTitle="true"
          />
          <EachInput
            title="What is the name of this form?"
            name="name"
            placeholder="Name..."
            // stateValue={formName}
            // setState={setFormName}
            topTitle="true"
          /> */}

          {/* <button
            // onClick={addQuestion}
            className={classNames.addQBtn}
          >
            Add Question
          </button>

          <button>Submit</button> */}
          {/* {formErr && <h4 style={{ color: "red" }}>{formErr}</h4>} */}
        </div>
      </Drawer>
    </>
  );
};

export default Organization;
