import React, { useState, useEffect } from 'react'
import './EditNewClient.scss'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import loadingIcon from "../../../../assets/images/loadingicon.svg";
import 'react-toastify/dist/ReactToastify.css';
import {
    CopyBtn,
    CustomCheckbox,
    EachCustomDropdown, NewUIMyEachInput, MYEachCustomImageUpload, StaffEachInputDatePicker, NewDateAndPicker,
    EachCustomImageUpload, NewEachCustomDropdown,
    NewEachCustomImageUploadcustom,
    EachCustomSelectMultiple, NewMyEachCustomTextarea,
    EachInputCustom, EachInputsepfic, MyEachCustomImageUpload,
    EachCustomTextarea,
    OwnEachCustomDropdown,
    EachInput, RadioNewUIMyEachInput,
} from "../../../../components/Custom";
export default function EditNewClient({ setpagemain }) {

    const [bookinglist, setbookinglist] = useState({
        Fname: "",
        Lname: "",
        Birthday: "",
        MaritalStatus: "",
        gender: "",
        photo: "",
        weight: "",
        height: "",
        phone: "",
        email: "",
        country: "",
        state: "",
        Pincode: "",
        Address: "",
        city: "",
        currentmedic: "",
        mediAddress: "",
        Relationship: "",
        RContactNumber: "",
        EmergencyContact: ""
    })
    const [loading, setloading] = useState(false)
    const [selectedMen, setselectedMen] = useState("Settings")
    const [AllCountries, setAllCountries] = useState([])
    useEffect(() => {
        getAllCountries()
    }, [])
    const clearfunction = () => {
        setbookinglist({
            Fname: "",
            Lname: "",
            Birthday: "",
            MaritalStatus: "",
            gender: "",
            photo: "",
            weight: "",
            height: "",
            phone: "",
            email: "",
            country: "",
            state: "",
            Pincode: "",
            Address: "",
            city: "",
            currentmedic: "",
            mediAddress: "",
            Relationship: "",
            RContactNumber: "",
            EmergencyContact: ""
        })
    }
    const therpysubmitfuction = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;
        setloading(true)
        axios
            .post(
                "https://uatamma.apimachine.com/api/registerclienttoteam",
                {
                    "TeamUsername": JSON.parse(localStorage?.getItem("orginzationdetail"))
                        ?.TeamUsername || "",
                    "username": bookinglist?.email?.split('@')?.[0],
                    "firstName": bookinglist?.Fname,
                    "lastName": bookinglist?.Lname,
                    "name": `${bookinglist?.Fname} ${bookinglist?.Lname}`,
                    "email": bookinglist?.email,
                    "YearOfBirth": bookinglist?.Birthday?.split('/')?.[2],
                    "ProfilePic": bookinglist?.photo,
                    "Country": bookinglist?.country,
                    "height": bookinglist?.height,
                    "weight": bookinglist?.weight,
                    "martialStatus": bookinglist?.MaritalStatus,
                    "street": bookinglist?.Address,
                    "medications": bookinglist?.currentmedic,
                    "Emergencycontact": bookinglist?.EmergencyContact,
                    "emergencycontactnumber": bookinglist?.RContactNumber,
                    "emergencycontactrelationship": bookinglist?.Relationship,

                    "age": today?.split('/')?.[2] - bookinglist?.Birthday?.split('/')?.[2],
                    "Address": bookinglist?.Address,
                    "pincode": bookinglist?.Pincode,
                    "birthday": bookinglist?.Birthday,

                    "Gender": bookinglist?.gender,
                    "state": bookinglist?.state,
                    "PhoneNumber": bookinglist?.phone,

                    "ref_affiliate": "6535e2f65958ffca65b865ef21fde26f0b8406ca"
                }
            )
            .then(({ data }) => {
                if (data.status) {
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                } else {
                    setloading(false)
                    toast(data?.message);
                }
            });




    }

    async function getAllCountries() {
        axios
            .get(
                `https://comms.globalxchange.io/coin/vault/countries/data/get`
            )
            .then((res) => {
                if (res.data.status) {
                    setAllCountries(res?.data?.countries)
                }
                else {
                    setAllCountries([])
                }
            });
    }
    console.log("bookinglist", AllCountries)

    const submitnewdata = () => {
        // let pop = brithday?.split("/");
        // setloading(true);
        // if (specService?.name != "") {
        //     axios
        //         .post("https://uatamma.apimachine.com/api/createbooking", {
        //             teamusername:
        //                 JSON.parse(localStorage?.getItem("orginzationdetail"))
        //                     ?.TeamUsername || "",
        //             phone: clientphone,
        //             leadSource: leadsoruce?.name,
        //             specialistid: specid,
        //             dateOfBooking: appointdate,
        //             timeOfBooking: timeshow?.name,
        //             username: userNameclient,
        //             name: clientName,
        //             email: emailclient,
        //             YearOfBirth: pop[2],
        //             birthday: brithday,

        //             Country: country?.name,
        //             Address: address,
        //             pincode: pincode,
        //             state: state,
        //             city: city,
        //             Gender: gender?.name,
        //             ref_affiliate: refid,
        //             serviceId: constitid,
        //             locationId: locationid,
        //         })
        //         .then(({ data }) => {
        //             if (data.status) {
        //                 tablefucntion();
        //                 setTimeout(() => {
        //                     window.location.reload();
        //                 }, 2000);
        //                 setloading(false);
        //                 toast("SuccessFully Submited ");
        //                 setstep("main");
        //                 setlocationid("");
        //                 setspecService({
        //                     name: "",
        //                     toggle: false,
        //                 });
        //                 setclientphone("");
        //                 setleadsoruce({
        //                     name: "",
        //                     toggle: false,
        //                 });
        //                 setloctionService({
        //                     name: "",
        //                     toggle: false,
        //                 });
        //                 setspecid("");
        //                 setappointdate("");
        //                 settimeshow({
        //                     name: "",
        //                     toggle: false,
        //                 });
        //                 setclientName("");
        //                 setemailclient("");
        //                 setbrithday("");
        //                 setcountry({
        //                     name: "",
        //                     toggle: false,
        //                 });
        //                 setaddress("");
        //                 setpincode("");
        //                 setstate("");
        //                 setcity("");
        //                 setgender({
        //                     name: "",
        //                     toggle: false,
        //                 });
        //                 setservicestore({
        //                     name: "",
        //                     toggle: false,
        //                 });
        //                 setrefid("");
        //                 setconstitid("");
        //                 setlocationid("");
        //             } else {
        //                 toast(data?.message);
        //                 setloading(false);
        //             }
        //         });
        // } else {
        //     toast("Select Specialist");
        //     setloading(false);
        // }
    };




    return (
        <>

            {
                loading &&
                <div className="globalLoading"
                    style={{
                        zIndex: "99999",
                        inset: "auto",
                        height: "100%",
                        width: "100%",
                        left: "0",
                        top: "0"

                    }}
                >
                    <img src={loadingIcon} alt="globalLoading" />
                </div>
            }

            <ToastContainer />
            <div className="bookinginnputsection">

                {/* <div className='leftsidesetting'>
                    <div className='settingName'>

                        Settings
                    </div>

                    <div className='listmenuseleted'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12.1601 10.87C12.0601 10.86 11.9401 10.86 11.8301 10.87C9.45006 10.79 7.56006 8.84 7.56006 6.44C7.56006 3.99 9.54006 2 12.0001 2C14.4501 2 16.4401 3.99 16.4401 6.44C16.4301 8.84 14.5401 10.79 12.1601 10.87Z" stroke="#" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.15997 14.56C4.73997 16.18 4.73997 18.82 7.15997 20.43C9.90997 22.27 14.42 22.27 17.17 20.43C19.59 18.81 19.59 16.17 17.17 14.56C14.43 12.73 9.91997 12.73 7.15997 14.56Z" stroke="#" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <div>Accounts</div>
                    </div>
                    <div className='listmenuNotseleted'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M3.61995 8.49C5.58995 -0.169998 18.42 -0.159997 20.38 8.5C21.53 13.58 18.37 17.88 15.6 20.54C13.59 22.48 10.41 22.48 8.38995 20.54C5.62995 17.88 2.46995 13.57 3.61995 8.49Z" stroke="" stroke-width="1.5" />
                            <path d="M9.25 11.5L10.75 13L14.75 9" stroke="" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <div>Orders</div>
                    </div>
                    <div className='listmenuNotseleted'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12.62 20.81C12.28 20.93 11.72 20.93 11.38 20.81C8.48 19.82 2 15.69 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.38 3.09998 10.99 3.97998 12 5.33998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 15.69 15.52 19.82 12.62 20.81Z" stroke="" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <div>Almari</div>
                    </div>
                    <div className='listmenuNotseleted'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M8 2V5" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M16 2V5" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8 11H16" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8 16H12" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <div>Prescription</div>
                    </div>
                    <div className='listmenuNotseleted'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12 8V13" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M11.9946 16H12.0036" stroke="#7A7A7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <div>Help & Support</div>
                    </div>
                </div> */}
                <div className='rightside'>
                    <div className='topName-section'>
                        <div className='name'>Add New Client</div>

                    </div>



                    <div className='top_image-section'>
                        <div className='top_inner-image-section'>
                            <NewEachCustomImageUploadcustom
                                title="Upload"
                                name="photo"
                                placeholder="Profile picture "
                                stateValue={bookinglist}
                                setState={setbookinglist}
                                topTitle={false}
                            />
                            <div className='deletLabel'>Delete</div>
                        </div>

                        <div className="short-drop-down-orange"
                            onClick={() => setpagemain("main")}
                        > Go Back


                        </div>
                    </div>


                    <div className='textSectionTop'>
                        <div className='infotext'>
                            Personal Information:
                        </div>
                        {/* <div
            className='editlabel'
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path d="M8.84006 2.9L3.36673 8.69333C3.16006 8.91333 2.96006 9.34667 2.92006 9.64667L2.6734 11.8067C2.58673 12.5867 3.14673 13.12 3.92006 12.9867L6.06673 12.62C6.36673 12.5667 6.78673 12.3467 6.9934 12.12L12.4667 6.32667C13.4134 5.32667 13.8401 4.18667 12.3667 2.79334C10.9001 1.41334 9.78673 1.9 8.84006 2.9Z" stroke="" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.92676 3.86664C8.21342 5.70664 9.70676 7.1133 11.5601 7.29997" stroke="" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M2 15.1667H14" stroke="" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            Edit</div> */}
                    </div>
                    <div className="Innerinnputsection">

                        <div className='subdivision'>
                            <NewUIMyEachInput
                                title="First name"
                                placeholder="First Name"
                                // stateValue={bookinglist?.name}
                                name="Fname"
                                stateValue={bookinglist}
                                setState={setbookinglist}
                                topTitle="true"
                            />
                        </div>
                        <div className='subdivision'>
                            <NewUIMyEachInput
                                title="Last Name"
                                placeholder="Last Name"
                                // stateValue={bookinglist?.name}
                                name="Lname"
                                stateValue={bookinglist}
                                setState={setbookinglist}
                                topTitle="true"
                            />
                        </div>
                    </div>

                    <div className="Innerinnputsection">

                        <div className='subdivision'>

                            <NewDateAndPicker
                                title="Date of birth"
                                placeholder="DD/MM/YYYY"
                                stateValue={bookinglist}
                                setState={setbookinglist}
                                name="Birthday"
                                topTitle="true"
                            />
                        </div>
                        <div className='subdivision'>

                            <NewEachCustomDropdown
                                dropdown={[
                                    "Male",
                                    "Female",

                                ]}
                                name="gender"
                                title="Gender"
                                placeholder="Select Gender"
                                Select Gender
                                stateValue={bookinglist}
                                setState={setbookinglist}
                                topTitle="true"
                                type="single"
                            />


                        </div>
                    </div>

                    <div className="Innerinnputsection">

                        <div className='subdivision'>
                            <NewUIMyEachInput
                                title="Phone number"
                                placeholder="Ex. 0000 000 000"
                                name="phone"
                                stateValue={bookinglist}
                                setState={setbookinglist}
                                topTitle="true"
                            />
                        </div>
                        <div className='subdivision'>
                            <NewUIMyEachInput
                                title="Email"
                                placeholder="Enter Email Address"
                                // stateValue={bookinglist?.name}
                                name="email"
                                stateValue={bookinglist}
                                setState={setbookinglist}
                                topTitle="true"
                            />
                        </div>
                    </div>



                    <div className='textSectionTop'>
                        <div className='infotext'>
                            Address:
                        </div>
                        {/* <div
            className='editlabel'
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path d="M8.84006 2.9L3.36673 8.69333C3.16006 8.91333 2.96006 9.34667 2.92006 9.64667L2.6734 11.8067C2.58673 12.5867 3.14673 13.12 3.92006 12.9867L6.06673 12.62C6.36673 12.5667 6.78673 12.3467 6.9934 12.12L12.4667 6.32667C13.4134 5.32667 13.8401 4.18667 12.3667 2.79334C10.9001 1.41334 9.78673 1.9 8.84006 2.9Z" stroke="" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.92676 3.86664C8.21342 5.70664 9.70676 7.1133 11.5601 7.29997" stroke="" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M2 15.1667H14" stroke="" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            Edit</div> */}
                    </div>
                    <div className="Innerinnputsection">

                        <div className='subdivision'>
                            <NewEachCustomDropdown
                                dropdown={AllCountries}
                                name="country"
                                title="Select Country"
                                stateValue={bookinglist}
                                setState={setbookinglist}
                                topTitle="true"
                                type="obj"
                            />
                        </div>
                        <div className='subdivision'>

                            <NewUIMyEachInput
                                title="State"

                                placeholder="Enter State Name"
                                // stateValue={bookinglist?.name}
                                name="state"
                                stateValue={bookinglist}
                                setState={setbookinglist}
                                topTitle="true"
                            />
                            {/* <NewEachCustomDropdown
                dropdown={[
                    "Banglore",
                    "Hydrabad",

                ]}
                name="state"
                title="State"
                stateValue={bookinglist}
                setState={setbookinglist}
                topTitle="true"
                type="single"
            /> */}
                        </div>
                    </div>
                    <div className="Innerinnputsection"
                        style={{
                            marginTop: "1rem",
                            width: "100%"
                        }}
                    >
                        <NewMyEachCustomTextarea
                            title="Address"
                            // stateValue={bookinglist?.name}
                            name="Address"
                            placeholder="Enter Address"
                            stateValue={bookinglist}
                            setState={setbookinglist}
                            topTitle="true"
                        />
                    </div>
                    <div className="Innerinnputsection"
                        style={{
                            marginTop: "3.1rem"
                        }}
                    >

                        <div className='subdivision'>

                            <NewUIMyEachInput
                                title="City"

                                placeholder="Enter City Name"
                                // stateValue={bookinglist?.name}
                                name="city"
                                stateValue={bookinglist}
                                setState={setbookinglist}
                                topTitle="true"
                            />
                        </div>
                        <div className='subdivision'>

                            <NewUIMyEachInput
                                title="Pincode"
                                // stateValue={bookinglist?.name}
                                name="Pincode"
                                placeholder="Enter Pincode"
                                stateValue={bookinglist}
                                setState={setbookinglist}
                                topTitle="true"
                            />


                        </div>
                    </div>




                    <div className='textSectionTop'>
                        <div className='infotext'>
                            Emergency Contact:
                        </div>
                        {/* <div
            className='editlabel'
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path d="M8.84006 2.9L3.36673 8.69333C3.16006 8.91333 2.96006 9.34667 2.92006 9.64667L2.6734 11.8067C2.58673 12.5867 3.14673 13.12 3.92006 12.9867L6.06673 12.62C6.36673 12.5667 6.78673 12.3467 6.9934 12.12L12.4667 6.32667C13.4134 5.32667 13.8401 4.18667 12.3667 2.79334C10.9001 1.41334 9.78673 1.9 8.84006 2.9Z" stroke="" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.92676 3.86664C8.21342 5.70664 9.70676 7.1133 11.5601 7.29997" stroke="" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M2 15.1667H14" stroke="" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            Edit</div> */}
                    </div>
                    <div className="Innerinnputsection">

                        <div className='subdivision'>
                            <NewUIMyEachInput
                                title="Emergency Contact"
                                placeholder="Enter Name"
                                name="EmergencyContact"
                                stateValue={bookinglist}
                                setState={setbookinglist}
                                topTitle="true"
                            />
                        </div>
                        <div className='subdivision'>
                            <NewUIMyEachInput
                                title="Relationship"
                                placeholder="Enter Number"
                                // stateValue={bookinglist?.name}
                                name="Relationship"
                                stateValue={bookinglist}
                                setState={setbookinglist}
                                topTitle="true"
                            />
                        </div>
                    </div>
                    <div className="Innerinnputsection">
                        <div className='subdivision'>
                            <NewUIMyEachInput
                                title="Contact Number"
                                placeholder="Enter Number"
                                name="Remail"
                                stateValue={bookinglist}
                                setState={setbookinglist}
                                topTitle="true"
                            />
                        </div>
                        <div className='subdivision'>
                            <NewUIMyEachInput
                                title="Emergency contact email"
                                placeholder="Enter email"
                                name="RContactNumber"
                                stateValue={bookinglist}
                                setState={setbookinglist}
                                topTitle="true"
                            />
                        </div>
                    </div>

                    <div className='labelsavelist'>
                        <div className='clearlabel'
                            onClick={clearfunction}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M18 6L6 18" stroke="#272727" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M6 6L18 18" stroke="#272727" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            Clear</div>
                        <div className='savelabel'
                            onClick={therpysubmitfuction}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13" fill="none">
                                <path d="M17 1L6 12L1 7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            Save</div>
                    </div>
                </div></div>
        </>

    )
}
