import React, { useState } from 'react'

export default function CompositionData({ summyTable, listseleted }) {
    const [rightexpend, setrightexpend] = useState(true)
    return (
        <div
            className="preception-page"
            style={{
                height: "100%",
                overflow: "scroll",
                flexWrap: "wrap"
            }}
        >

            {summyTable?.map((eachPerscribe, index) => (
                <div key={eachPerscribe + index} className="eachOption"
                    // 

                    style={{
                        height: "auto",
                        display: "block"
                    }}
                >

                    <div className="closesection">
                        <div
                            style={{

                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                          

                            {eachPerscribe?.composition?.name}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center"
                            }}
                        >

                        </div>
                    </div>
                    <div
                        style={{
                            width: "100%",
                            background: "white",
                            padding: "1rem"
                        }}
                    >


                        <>

                            {
                                eachPerscribe?.composition?.ingredients?.map((item, z) => {
                                    return (
                                        <>
                                            <div className="questionsection">
                                                <div className="questionsectionName">{item?.name}</div>
                                                <div className="deitpaldroplist">
                                                    <div className="amountSection">
                                                        <div className="divingredient">
                                                            <div className="titletext">Single</div>
                                                            <div className="flex-ingedient">
                                                                <input placeholder="0.00"
                                                                    style={{
                                                                        width: "100%",
                                                                        pointerEvents: "none"
                                                                    }}
                                                                    value={item?.quanity}
                                                                //  onChange={(e) => compositionSingal(index, z, e.target.value)}
                                                                />
                                                                <div className="mllitter">{eachPerscribe?.composition?.quantity}</div>
                                                            </div>

                                                        </div>
                                                        <div className="divingredient">
                                                            <div className="titletext">Total</div>
                                                            <div className="flex-ingedient">
                                                                <input placeholder="0.00"
                                                                    style={{
                                                                        width: "100%",
                                                                        pointerEvents: "none"
                                                                    }}
                                                                    value={item?.total}
                                                                //   onChange={(e) => compositionTatal(index, z, e.target.value)}
                                                                />
                                                                <div className="mllitter">{eachPerscribe?.composition?.quantity}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>

                                        </>
                                    )
                                })
                            }
                            <div className="deitpaldroplist" >
                                <div className="amountSection">
                                    <div className="divingredient">
                                        <div className="titletext">Frequency</div>
                                        <div className="flex-ingedient">
                                            <input placeholder="0.00"
                                                style={{
                                                    width: "100%",
                                                    pointerEvents: "none"
                                                }}
                                                value={eachPerscribe?.composition?.frequency}
                                            //  onChange={(e) => compositionFrequence(index, e.target.value)}
                                            />

                                        </div>

                                    </div>
                                    <div className="divingredient">
                                        <div className="titletext">Repetitions</div>
                                        <div className="flex-ingedient">
                                            <input placeholder="0.00"
                                                style={{
                                                    width: "100%",
                                                    pointerEvents: "none"
                                                }}
                                                value={eachPerscribe?.composition?.numofdays}
                                            // onChange={(e) => compositionRepetitions(index, e.target.value)}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="questionsectionName"
                                style={{
                                    marginTop: "1rem"
                                }}
                            >Total</div>
                            <div className="divingredient"
                                style={{
                                    width: "100%"
                                }}
                            >
                                {/* <div className="titletext">Single</div> */}
                                <div className="flex-ingedient"
                                    style={{
                                        width: "100%"
                                    }}
                                >
                                    <input
                                        className="inputtectfilled"
                                        placeholder="0.00"
                                        style={{
                                            width: "100%",
                                            pointerEvents: "none"
                                        }}
                                        value={eachPerscribe?.composition?.totalquantity}
                                    //onChange={(e) => compositiontotalquantity(index, e.target.value)}
                                    // value={item?.Dunitamount}
                                    // onChange={(e) => changedietpainAmount(eachPerscribe, z, e.target.value, index)}
                                    />
                                    <div className="mllitter">{eachPerscribe?.quantity}</div>
                                </div>
                            </div>
                        </>



                    </div>


                </div>
            ))
            }
        </div>
    )
}
