
import React, { useEffect, useState, useContext, useRef } from "react";
import './Preception.scss'
import axios from "axios";
import flatpickr from "flatpickr";
import { toast } from "react-toastify";

export default function Customj({ summyTable, listseleted }) {
    const [addingdiethard, setaddingdiethard] = useState([])
    const [samevalue, setsamevalue] = useState("")
    const [rightexpend, setrightexpend] = useState(true)

    useEffect(() => {
        if (listseleted == "Diet Plans") {
            deitplanfunction()
        }

    }, [listseleted])
    const deitplanfunction = () => {
        axios

            .get(
                `https://uatamma.apimachine.com/api/getdietplan?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then((res) => {
                if (res?.data?.success) {
                    setaddingdiethard(res?.data?.data);

                }
                else {
                    setaddingdiethard([]);

                }

            });
    };
    return (
        <div
            className="preception-page"
            style={{
                height: "100%",
                overflow: "scroll",
                flexWrap: "wrap"
            }}

        >      {summyTable?.map((eachPerscribe, index) => (
            <div key={eachPerscribe + index} className="eachOption"
                // 

                style={{
                    height: rightexpend && samevalue == eachPerscribe?._id && "auto",
                    display: "block",
                    marginBottom: "1rem",
                    width: "48%"
                }}
            >

                <div className="closesection">
                    <div
                        style={{

                            display: "flex",
                            alignItems: "center"
                        }}
                    >




                        {eachPerscribe?.dietplandetails?.name}
                    </div>

                </div>
                <div
                    style={{
                        width: "100%",
                        background: "white",
                        padding: "1rem"
                    }}
                >


                    <>
                        <div className="listItem">
                            <div>Amount of days:</div>
                            <div>{eachPerscribe?.dietplandetails?.numofdays}</div>


                        </div>
                        <div className="listItem">
                            <div>Starts on:</div>
                            <div>{eachPerscribe?.dietplandetails?.startingday}</div>


                        </div>


                        <div className="listItem"

                            style={{
                                lineHeight: "25px"
                            }}

                        >
                            {eachPerscribe?.dietplandetails?.description}
                        </div>
                        {
                            eachPerscribe?.dietplandetails?.plandetails?.map((item, z) => {
                                console.log("111111111111111", eachPerscribe?.dietplandetails)
                                return (
                                    <>
                                        <div className="questionsection">
                                            <div className="questionsectionName">{item?.day}</div>
                                            {
                                                item?.dayplan?.map((such, p) => {
                                                    console.log("dietplandetails", such)
                                                    return (
                                                        <>

                                                            <div className="questionsectBox">
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "space-between"

                                                                    }}
                                                                    onClick={() => {
                                                                        // setdplandropdown(true);
                                                                        // setdietid(such?._id)
                                                                    }}
                                                                >
                                                                    <div>{such?.time}</div>
                                                                    <div>{such?.unitamount} {such?.unitname} {such?.kitchenItemName}

                                                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="7" height="5" viewBox="0 0 7 5" fill="none">
                                                                    <path d="M3.69846 4.91959L6.90683 1.84362C6.96612 1.7867 7 1.70688 7 1.62328V0.301496C7 0.0386509 6.69624 -0.0978492 6.50968 0.0811595L3.5 2.96891L0.490321 0.0811595C0.303761 -0.0978492 -4.76837e-07 0.0386509 -4.76837e-07 0.301496V1.62328C-4.76837e-07 1.70688 0.0336499 1.7867 0.0931659 1.84362L3.30154 4.91959C3.41347 5.0268 3.58653 5.0268 3.69846 4.91959Z" fill="url(#paint0_linear_145_4817)" />
                                                                    <defs>
                                                                        <linearGradient id="paint0_linear_145_4817" x1="3.50001" y1="-2.63166e-05" x2="3.50001" y2="4.99999" gradientUnits="userSpaceOnUse">
                                                                            <stop stop-color="#0B2447" />
                                                                            <stop offset="0.311" stop-color="#0D264C" />
                                                                            <stop offset="0.6768" stop-color="#122E5A" />
                                                                            <stop offset="1" stop-color="#010101" />
                                                                        </linearGradient>
                                                                    </defs>
                                                                </svg> */}
                                                                    </div>
                                                                </div>


                                                            </div>

                                                        </>
                                                    )
                                                })
                                            }

                                        </div>

                                    </>
                                )
                            })
                        }
                    </>



                </div>

            </div>
        ))}</div>
    )
}
