import axios from "axios";

export async function createOrganization( obj) {
  let response = await axios.post(
    `https://uatamma.apimachine.com/api/createteam`,
    obj
  );

  return response?.data;
}
