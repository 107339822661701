import React, { useState, useEffect } from 'react'
import axios from 'axios';
import './Page.scss'
import {
    CopyBtn,
    CustomCheckbox,
    EachCustomDropdown, NewUIMyEachInput, MYEachCustomImageUpload, StaffEachInputDatePicker, NewDateAndPicker,
    EachCustomImageUpload, NewEachCustomDropdown,
    NewEachCustomImageUploadcustom,
    EachCustomSelectMultiple, NewMyEachCustomTextarea,
    EachInputCustom, EachInputsepfic, MyEachCustomImageUpload,
    EachCustomTextarea,
    OwnEachCustomDropdown,
    EachInput, RadioNewUIMyEachInput,

} from "../../../components/Custom";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
export default function Page({ seletedtreatment, datalist, setdatalist, clientDetail, landingApi, therpylandingApi, id }) {
    const { type } = useParams()
    const [patientlist, setpatientlist] = useState({
        Addictions: "String",
        Sleep: "String",
        Bowel: "String",
        Mensturation: "String",
        Appetite: "String",

        Habits: "String",
        Endocrinology: "String",
        Endocrinology: "String",
        ent: "String",
        Diet: "String",
        Dermatology: "String",
        Lymphonde: "String",
        Tougue: "String",
        Mouth: "String",
        Edema: "String",
        Cyanosis: "String",
        Built: "String",
        Expression: "String",
        Anemia: "String",
        Hair: "String",
        Appearance: "String"
    })
    const [AllCountries, setAllCountries] = useState([])
    const [dop, setdop] = useState(null);
    const [widgenumber, setwidgenumber] = useState(0);
    const [widgenumberSaved, setwidgenumberSaved] = useState(null);
    const [togglesave, settogglesave] = useState(false)
    const [showdrop, setshowdrop] = useState(false);

    console.log("seletedtreatmentseletedtreatment", seletedtreatment)
    // const dropdownaddedpreform = (index, item, tyep) => {
    //   let data = [...preform];
    //   data[index].anstype = tyep;
    //   data[index].name = item;

    //   setpreform(data);
    //   setshowdrop(false);
    // };

    useEffect(() => {

        addFields(seletedtreatment)
    }, [seletedtreatment])
    console.log("234234234234", seletedtreatment)
    const savedata = () => {

        let dataset = datalist?.map(item => {
            return {
                "questionId": item?.id,
                "name": item?.questionslist,
                "answer": item?.name,
                "dropdownOptions": item?.dropdownOptions,
                "answerType": item?.answerType,
                "isMandatory": item?.isMandatory
            }
        })

        let WorkingDaysasdassdeleted = dataset?.filter(item => {
            return item?.answer
        })


        let add = {}
        if (type == "consultationservice") {
            add = {
                "clientid": clientDetail?.clientid,
                "consultationsessionid": id,
                "name": seletedtreatment?.name,
                "widgetid": seletedtreatment?.widgetid,
            }
        }
        else {
            add = {
                "clientid": clientDetail?.clientid,
                "theraphysessionid": id,
                "name": seletedtreatment?.name,
                "widgetid": seletedtreatment?.widgetid,
            }
        }

        console.log("WorkingDaysasdassdeleted", datalist)

        axios
            .post(`https://uatamma.apimachine.com/api/adduserwidget`, {
                ...add,
                "questions": dataset
            })

            .then(({ data }) => {
                if (data.status) {
                    // settogglesave(false)
                    // setwidgenumberSaved(null)
                    // setSelectedMenu("Saved Widgets");

                    // savedwidget()
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 1000);
                    if (type == "consultationservice") {
                        landingApi()

                    }
                    else {
                        therpylandingApi()

                    }
                    toast(data?.message)
                } else {
                    toast("error")
                }
            });
    }
    const savedataEdit = () => {

        let dataset = datalist?.map(item => {
            console.log("WorkingDaysasdassdeleted3", item)
            return {
                "questionId": item?.id,
                "name": item?.questionslist,
                "answer": item?.name,
                "dropdownOptions": item?.dropdownOptions,
                "answerType": item?.answerType,
                "isMandatory": item?.isMandatory
            }
        })

        let WorkingDaysasdassdeleted = dataset?.filter(item => {
            return item?.answer
        })




        console.log("WorkingDaysasdassdeleted", WorkingDaysasdassdeleted)
        let add = {}
        if (type == "consultationservice") {

            add = {
                "clientid": clientDetail?.clientid,
                "consultationsessionid": id,
                "name": seletedtreatment?.name,
                "widgetid": seletedtreatment?.widgetid,
            }
        }
        else {
            add = {
                "clientid": clientDetail?.clientid,
                "theraphysessionid": id,
                "name": seletedtreatment?.name,
                "widgetid": seletedtreatment?.widgetid,
            }
        }
        axios
            .put(`https://uatamma.apimachine.com/api/updateuserwidget/${seletedtreatment?._id}`, {
                ...add,
                "questions": WorkingDaysasdassdeleted
            })

            .then(({ data }) => {
                if (data.success) {

                    if (type == "consultationservice") {
                        landingApi()

                    }
                    else {
                        therpylandingApi()

                    }
                    toast(data?.message)
                } else {
                    toast("error")
                }
            });
    }
    const addFields = (e) => {
        let newfield = { name: "", anstype: "", requried: true };

        let pop = seletedtreatment?.questions?.map((item, i) => {
            console.log("cxcvxcv", item)
            return {
                name: item?.answer ? item?.answer : "",
                anstype: "",
                id: item?._id,
                questionslist: item?.name,
                requried: item?.isMandatory ? item?.isMandatory : "true",
                toggle: false,
                dropdownOptions: item?.dropdownOptions,
                answerType: item?.answerType,
                isMandatory: item?.isMandatory

            };
        });
        console.log("WorkingDaysasdassdeleted1", pop);
        setdatalist(pop);
    };

    console.log("zzzzzzzz", datalist)
    const dropdownadded = (index, item, tyep, see) => {

        console.log("11111534", item);
        let data = [...datalist];
        data[index].anstype = tyep;
        data[index].name = item;
        data[index].id = see?._id;
        data[index].questionlist = see?.name;
        // data[index].id = s?.questionId;
        setdatalist(data);
        setshowdrop(false);
        console.log("see", see)
    };

    const handleFormChange = (index, event, g, s) => {

        console.log("index, event, g, s", s)
        let data = [...datalist];
        data[index].anstype = g;
        data[index].id = s?._id;
        data[index].questionlist = s?.name;
        data[index][event.target.name] = event.target.value;
        setdatalist(data);
    };


    return (
        <div className='cliincpage'>
            <div className='rightside'>



                <div className='textSectionTop'
                    style={{
                        paddingTop: "0rem"
                    }}
                >
                    <div className='infotext'

                    >
                        {seletedtreatment?.name}
                    </div>
                    {/* <div
            className='editlabel'
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path d="M8.84006 2.9L3.36673 8.69333C3.16006 8.91333 2.96006 9.34667 2.92006 9.64667L2.6734 11.8067C2.58673 12.5867 3.14673 13.12 3.92006 12.9867L6.06673 12.62C6.36673 12.5667 6.78673 12.3467 6.9934 12.12L12.4667 6.32667C13.4134 5.32667 13.8401 4.18667 12.3667 2.79334C10.9001 1.41334 9.78673 1.9 8.84006 2.9Z" stroke="" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.92676 3.86664C8.21342 5.70664 9.70676 7.1133 11.5601 7.29997" stroke="" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M2 15.1667H14" stroke="" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            Edit</div> */}
                </div>
                <div className="Innerinnputsection"
                    style={{
                        flexWrap: "wrap"
                    }}
                >
                    <>
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "2%",
                                    width: "100%"
                                }}
                            >
                                {seletedtreatment?.questions?.map((item, j) => {

                                    if (item?.answerType?.toLowerCase() == "dropdown") {
                                        return (
                                            <div className="questionsection"
                                                style={{
                                                    // pointerEvents: seletedtreatment?.UserFormFilledCount != 1 && "none",
                                                    width: "32%",
                                                    marginBottom: "1rem"
                                                }}

                                            >
                                                <div className="queTitle">{item?.name}</div>
                                                <div
                                                    className="queTitledrop"
                                                    onClick={() => {
                                                        setshowdrop(!showdrop);
                                                        setdop(j);
                                                    }}
                                                >
                                                    {datalist?.[j]?.name}
                                                    {dop == j && showdrop ? (
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="7"
                                                            height="6"
                                                            viewBox="0 0 7 6"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M6.87533 4.8683L3.98854 0.304976C3.76648 -0.101659 3.27793 -0.101659 3.01146 0.304976L0.124669 4.8683C-0.186232 5.36528 0.124669 5.99785 0.613191 5.99785H6.38681C6.87533 6.04303 7.18623 5.36532 6.87533 4.8683Z"
                                                                fill="#010101"
                                                            />
                                                        </svg>
                                                    ) : (
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="7"
                                                            height="6"
                                                            viewBox="0 0 7 6"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z"
                                                                fill="#010101"
                                                            />
                                                        </svg>
                                                    )}
                                                </div>
                                                {dop == j && showdrop && (
                                                    <div className="dropdonwlistdata">
                                                        {item?.dropdownOptions?.map((such) => {
                                                            console.log("xzcczxczxczxczx", such)
                                                            return (
                                                                <div
                                                                    onClick={() =>
                                                                        dropdownadded(j, such, "dropdown", item)
                                                                    }
                                                                >
                                                                    {such}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    } else {
                                        console.log("asd234", seletedtreatment)
                                        return (
                                            <div className="questionsection"
                                                style={{
                                                    width: "32%",
                                                    marginBlock: "1rem"
                                                }}
                                            >
                                                <div className="queTitle">{item?.name}</div>
                                                <input
                                                    className="queAnswerinput"
                                                    name="name"
                                                    // style={{
                                                    //     pointerEvents: seletedtreatment?.UserFormFilledCount != 1 && "none"
                                                    // }}
                                                    value={datalist?.[j]?.name}
                                                    onChange={(event) =>
                                                        handleFormChange(j, event, "String", item)
                                                    }
                                                />
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </>

                    </>

                    {
                        seletedtreatment?.questions?.length > 0 && seletedtreatment?.UserFormFilledCount == 1 ?
                            <div className="saveSection">
                                <div
                                    onClick={savedata}

                                >Save</div>
                            </div>
                            :
                            <div className="saveSection">
                                <div
                                    onClick={savedataEdit}

                                >Save Changes</div>
                            </div>
                    }
                    {/* {
                        seletedtreatment?.questions?.map(item => {
                            return (
                                <div className='subdivision'
                                    style={{
                                        marginBottom: "1rem"
                                    }}
                                >
                                    <NewUIMyEachInput
                                        title={item?.name}
                                        placeholder=""
                                        // stateValue={patientlist?.name}
                                        // name=""
                                        stateValue={item?.answerType}
                                        setState={setpatientlist}
                                        topTitle="true"
                                        toggle={true}
                                    />
                                </div>
                            )
                        })
                    } */}




                </div>
            </div>
        </div>










        // {/* <div className='labelsavelist'>
        //     <div className='clearlabel'
        //     // onClick={clearfunction}
        //     >
        //         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        //             <path d="M18 6L6 18" stroke="#272727" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        //             <path d="M6 6L18 18" stroke="#272727" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        //         </svg>
        //         Clear</div>
        //     <div className='savelabel'
        //     // onClick={therpysubmitfuction}
        //     >
        //         <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13" fill="none">
        //             <path d="M17 1L6 12L1 7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        //         </svg>
        //         Save</div>
        // </div> */}


    )
}
