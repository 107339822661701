
import React, { useEffect, useState, useContext, useRef } from "react";
import './Preception.scss'
import axios from "axios";
import flatpickr from "flatpickr";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// export default function Page() {
//     const [preAssessment, setPreAssessment] = useState("");

//     //functions
//     const { id } = useParams()
//     async function getPreAssesment() {

//         try {
//             let response = await axios.get(
//                 `https://uatamma.apimachine.com/api/getuserassessment?consultationsessionid=${id}`
//             );
//             if (response?.data?.data?.length > 0) {
//                 setPreAssessment(response?.data?.data?.[0]);
//             }

//         } catch (error) {
//             console.log(error, "Get pre dsadasd response");
//         }
//     }
//     console.log("Get pre assesment response", id);
//     //rendering
//     useEffect(() => {
//         getPreAssesment();
//     }, [id]);

//     return (
//         <div className={classNames.preEnrollmentForm}>
//             {preAssessment?.questions?.length > 0
//                 ? preAssessment?.questions?.map((eachQuestion, index) => {
//                     return (
//                         <div className={classNames.eachQuestion}>
//                             <div className={classNames.title}>{eachQuestion?.name}</div>
//                             <div className={classNames.answer}>{eachQuestion?.answer}</div>
//                         </div>
//                     );
//                 })
//                 : ""}
//         </div>
//     );
// };

export default function MainPage({ choosePlan, id, clientDetail, landingApi, settabName, therpylandingApi }) {

    const [storetrement, setstoretrement] = useState([])
    const [option, setoption] = useState("main")
    const [catoriesSummary, setcatoriesSummary] = useState([])
    const { type } = useParams()
    useEffect(() => {
        setoption("main")
        deitplanfunction()
        tablefucntion()
    }, [])













    const [compositionData, setcompositionData] = useState([
        // {
        //     _id: "",
        //     compositionid: "",

        //     name: "",
        //     description: "",
        //     icon: "",
        //     quantity: "",
        //     ingredientdetails: [
        //         {
        //             _id: "",
        //             ingredientid: "",

        //             name: "",
        //             description: "",
        //             icon: "",

        //         }

        //     ]
        // }
    ])
    const [ResultcompositionData, setResultcompositionData] = useState([])
    const addcompositionData = (e) => {
        // setResultcompositionData([...ResultcompositionData, e])
        //    let filteredArray = compositionData.filter(item => item?._id !== e?._id)
        setResultcompositionData([e])
        // setthermentautobook([...thermentautobook, e])
        //let filteredArray = catoriesSummary.filter(item => item?.name !== e?.name)
        // setcompositionData(filteredArray)
        // console.log("filteredArray", filteredArray)

        // setcatoriesSummary(filteredArray)
    }




    console.log("compositionData", compositionData)
    const compositiontotalquantity = (index, item) => {


        setcompositionData(prevAdd => {
            const aasdasd = JSON.parse(JSON.stringify(compositionData))
            if (
                aasdasd[index]

            ) {
                aasdasd[index].totalquantity = item;

            } else {
                console.log("Invalid index provided");
            }
            return aasdasd;

        });


    }

    const additembackcompositionData = (e) => {

        // setcompositionData([...compositionData, e])
        let filteredArray = ResultcompositionData.filter(item => item?._id !== e?._id)
        // let add = catoriesSummary.filter((v, i, a) => storetrement.findIndex(v2 => (v2.name === v.name)) === i)
        console.log("storetrement", filteredArray)
        setResultcompositionData(filteredArray)

    }
    console.log("ResultcompositionData", ResultcompositionData)
    useEffect(() => {
        compositionDataFunction()
    }, [])



    const compositionDataFunction = () => {

        axios
            .get(
                `https://uatamma.apimachine.com/api/getcomposition?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then(({ data }) => {
                if (data.success) {

                    // const updatedPlan = res?.data?.data.map(planItem => ({
                    //     ...planItem,
                    //     plandetails: planItem.plandetails.map(dayDetail => ({
                    //         ...dayDetail,
                    //         dayplan: dayDetail.dayplan.map(item => ({
                    //             ...item,
                    //             Dunitamount: "",
                    //             Dunitname: "",
                    //             DkitchenItemName: "",
                    //             Dtime: "",
                    //         }))
                    //     }))
                    // }));
                    let asdasd = data?.compositionData?.map(item => {
                        return {
                            _id: item?._id,
                            compositionid: item?.compositionid,
                            name: item?.name,
                            description: item?.description,
                            icon: item?.icon,
                            quantity: item?.quantity,
                            numofdays: "",
                            frequency: "",
                            totalquantity: "",

                            ingredientdetails: item?.ingredientdetails?.map(such => {
                                return {
                                    _id: such?._id,
                                    ingredientid: such?.ingredientid,
                                    quanity: "",

                                    total: "",
                                    name: such?.name,
                                    description: such?.name,
                                    icon: such?.icon,
                                }
                            })
                        }
                    })
                    setcompositionData(asdasd)
                    console.log("data?.data", asdasd)

                    // setagesummy(data?.data?.[0])
                } else {
                    // setagesummy({})
                }
            });
    }





    const timpicker = [
        "12:00AM",
        "12:30AM",
        "1:00AM",
        "1:30AM",
        "2:00AM",
        "2:30AM",
        "3:00AM",
        "3:30AM",
        "4:00AM",
        "4:30AM",
        "5:00AM",
        "5:30AM",
        "6:00AM",
        "6:30AM",
        "7:00AM",
        "7:30AM",
        "8:00AM",
        "8:30AM",
        "9:00AM",
        "9:30AM",
        "10:00AM",
        "10:30AM",
        "11:00AM",
        "11:30AM",
        "12:00PM",
        "12:30PM",
        "1:00PM",
        "1:30PM",
        "2:00PM",
        "2:30PM",
        "3:00PM",
        "3:30PM",
        "4:00PM",
        "4:30PM",
        "5:00PM",
        "5:30PM",
        "6:00PM",
        "6:30PM",
        "7:00PM",
        "7:30PM",
        "8:00PM",
        "8:30PM",
        "9:00PM",
        "9:30PM",
        "10:00PM",
        "10:30PM",
        "11:00PM",
        "11:30PM"
    ]
    const [addingdiethard, setaddingdiethard] = useState([

    ])

    const [inputsection, setinputsection] = useState([])

    const lengthfuction = (e) => {
        // let eadd = e?.map((item, i) => {
        //     return {
        //         id: i,
        //         name: item?.startingday,
        //         inputbox: item?.plandetails?.map((such, j) => {
        //             return {
        //              plans:such?.pal?.map()
        //             }
        //         })
        //     }
        // })
        // setinputsection(eadd)
        // id: j,
        // quality: "",
        // unit: "",
        // time: "",
        // kitchenItem: "",
    }
    console.log("66666666666666666666666", addingdiethard)
    const deitplanfunction = () => {
        axios

            .get(
                `https://uatamma.apimachine.com/api/getdietplan?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then((res) => {
                if (res?.data?.success) {
                    setaddingdiethard(res?.data?.data);
                    let add = res?.data?.data
                    const updatedPlan = res?.data?.data.map(planItem => ({
                        ...planItem,
                        plandetails: planItem.plandetails.map(dayDetail => ({
                            ...dayDetail,
                            dayplan: dayDetail.dayplan.map(item => ({
                                ...item,
                                Dunitamount: "",
                                Dunitname: "",
                                DkitchenItemName: "",
                                DkitchenItemid: "",
                                Dtime: "",
                            }))
                        }))
                    }));
                    setaddingdiethard(updatedPlan)
                    // setaddingdiethard(add => {
                    //     const updatedPlanDetails = add.plandetails.map(dayDetail => {
                    //         const updatedDayPlan = dayDetail.dayplan.map(meal => ({
                    //             ...meal,
                    //             pop: ""
                    //         }));

                    //         return {
                    //             ...dayDetail,
                    //             dayplan: updatedDayPlan
                    //         };
                    //     });

                    //     return {
                    //         ...add,
                    //         plandetails: updatedPlanDetails
                    //     };
                    // });
                    // setaddingdiethard(add => ({
                    //     ...add,
                    //     plandetails: prevPlan.plandetails.map(detail =>
                    //         detail.day === "Monday" ? {
                    //             ...detail,
                    //             dayplan: detail.dayplan.map((item, index) =>
                    //                 index === 0 ? { ...item, popName: "New Pop Name" } : item
                    //             )
                    //         } : detail
                    //     )
                    // }));
                    lengthfuction(res?.data?.data);
                }
                else {
                    setaddingdiethard([]);

                }

            });
    };
    const [thermentautobook, setthermentautobook] = useState([])
    const [backupDeitdata, setbackupDeitdata] = useState([])

    console.log("backupDeitdata", backupDeitdata)

    const tablefucntion = () => {

        axios

            .get(
                `https://uatamma.apimachine.com/api/gettheraphy?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then((res) => {
                if (res?.data?.success) {


                    const result = res?.data?.data.map((group, z) => {

                        return {
                            ...group,
                            remandeddropdown: "Recommend"

                        };

                        return group;
                    });
                    setcatoriesSummary(result);
                }

                else {
                    setcatoriesSummary([]);

                }

            });
    };

    const theryduplicatedatastore = (e, i) => {

        const result = storetrement.map((group, z) => {
            if (z === i) {
                return {
                    ...group,

                    duplicatetherapyname: e.TeamDetails?.[0]?.PlayerDetails?.[0]?.name,
                    duplicatetherapyid: e?.specialistid

                };
            }
            return group;
        });
        settherpyService({
            toggle: false,
            name: therpyService?.name
        })
        setstoretrement(result)
    }

    const Roomdatastore = (e, i) => {

        const result = storetrement.map((group, z) => {
            if (z === i) {
                return {
                    ...group,

                    duplicateroomname: e.name,
                    duplicateroomid: e?.roomid

                };
            }
            return group;
        });


        setroomService({
            toggle: false,
            name: roomService?.name
        })
        setstoretrement(result)
    }


    const timeatastore = (e, i) => {

        const result = storetrement.map((group, z) => {
            if (z === i) {
                return {
                    ...group,

                    duplicatertime: e,


                };
            }
            return group;
        });


        settimeshow({
            toggle: false,
            name: timeshow?.name
        })
        setstoretrement(result)
    }






    const lacationdatastore = (e, i) => {

        const result = storetrement.map((group, z) => {
            if (z === i) {
                return {
                    ...group,

                    duplicatelocationname: e.name,
                    duplicatelocationid: e?._id

                };
            }
            return group;
        });


        setloctionService({
            toggle: false,
            name: loctionService?.name
        })
        setstoretrement(result)
    }


    const chnagetreatment = (e, i) => {

        const newData = storetrement.map((group, z) => {
            if (z === i) {
                return {
                    ...group,

                    des: e.target.value

                };
            }
            return group;
        });

        // let arr = [...storetrement]
        // arr[i] = {
        //   des: e.target.value
        // }
        setstoretrement(newData)
    }
    const [dietplanid, setdietplanid] = useState("")


    const [dietplan, setdietplan] = useState([])

    const [customsavediet, setcustomsavediet] = useState([])
    const adddietpla = (e) => {

        console.log("111111111111111111111111111111", backupDeitdata)
        setdietplanid(e.dietplanid)
        setcustomsavediet([...customsavediet, e])
        let filteredArray = addingdiethard.filter(item => item?.name !== e?.name)
        setaddingdiethard(filteredArray)
        setdietplan([...dietplan, e])

    }
    console.log("234234c", dietplan)

    console.log("dietplan", dietplan)


    console.log("type", type)







    const [expandable, setexpandable] = useState(false)
    const [samevalue, setsamevalue] = useState("")
    const expadingfunction = (e) => {
        setsamevalue(e?._id)
        setexpandable(true)
    }

    const closeexpadingfunction = () => {
        setsamevalue("")
        setexpandable(false)

    }

    //addnote 
    const [addnote, setaddnote] = useState({

        description: "",

    })
    const [addnoteseleted, setaddnoteseleted] = useState([])


    const addNotefucntion = async (e) => {
        await setaddnoteseleted([addnote])
        //await setstoreMedience([...storeinfoMedicance, medicationSelet])
        await setaddnote({

            description: "",
        })

    }
    console.log("2342342342", addnoteseleted)
    const removenote = (e) => {

        setaddnoteseleted([...addnoteseleted, e])

        let filteredArray = addnoteseleted.filter(item => item?.description !== e?.description)
        // let add = catoriesSummary.filter((v, i, a) => storetrement.findIndex(v2 => (v2.name === v.name)) === i)

        setaddnoteseleted(filteredArray)

    }


    //lifestyle
    const [lifestyle, setlifestyle] = useState({
        name: "",
        description: "",

    })
    const [lifestyleseleted, setlifestyleseleted] = useState([])
    const addlifestyle = async (e) => {
        await setlifestyleseleted([lifestyle, ...lifestyleseleted])
        //await setstoreMedience([...storeinfoMedicance, medicationSelet])
        await setlifestyle({
            name: "",
            description: "",
        })

    }

    const removelifestyle = (e) => {
        console.log(e, "pop")
        setlifestyleseleted([...lifestyleseleted, e])

        let filteredArray = lifestyleseleted.filter(item => item?.name != e?.name)
        // let add = catoriesSummary.filter((v, i, a) => storetrement.findIndex(v2 => (v2.name === v.name)) === i)
        console.log("123131231231313", lifestyleseleted)
        setlifestyleseleted(filteredArray)

    }

    // investigation


    const [investingaData, setinvestingaData] = useState({
        name: "",
        lab: "",
        description: "",

    })




    const [investingaSelected, setinvestingaSelected] = useState([])


    const addinvest = async (e) => {
        await setinvestingaSelected([investingaData, ...investingaSelected,])
        //await setstoreMedience([...storeinfoMedicance, medicationSelet])
        await setinvestingaData({
            name: "",
            lab: "",
            description: "",
        })

    }

    const removeInvesting = (e) => {

        setinvestingaSelected([...investingaSelected, e])

        let filteredArray = investingaSelected.filter(item => item?.name !== e?.name)
        // let add = catoriesSummary.filter((v, i, a) => storetrement.findIndex(v2 => (v2.name === v.name)) === i)
        console.log("storetrement", filteredArray)
        setinvestingaSelected(filteredArray)

    }





    console.log("lifestyleseleted", lifestyleseleted)


    // medicance
    const [medicationSelet, setmedicationSelet] = useState({
        name: "",
        dosage: "",
        frequency: "",
        lengthOfTime: "",
    })


    const [storeinfoMedicance, setstoreinfoMedicance] = useState([])

    const [storeMedience, setstoreMedience] = useState([])

    const additempop = async (e) => {










        await setstoreinfoMedicance([medicationSelet, ...storeinfoMedicance])
        await setmedicationSelet({
            name: "",
            dosage: "",
            frequency: "",
            lengthOfTime: "",
        })

    }

    console.log("medicationSelet", storeinfoMedicance)

    const addMediance = async (e) => {
        await setstoreinfoMedicance([...storeinfoMedicance, medicationSelet])
        //await setstoreMedience([...storeinfoMedicance, medicationSelet])
        await setmedicationSelet({
            name: "",
            dosage: "",
            frequency: "",
            lengthOfTime: "",
        })

    }
    console.log("1231231231231323333333333333", dietplan)
    console.log("123123123123132", customsavediet)
    console.log("12312312312313222222sssaaaaaaaaaa", addingdiethard)
    const additembackdiet = (e) => {

        let flier = customsavediet.filter(item => item?.name == e?.name)
        let flierRemove = customsavediet.filter(item => item?._id !== e?._id)

        setcustomsavediet(flierRemove)

        // console.log("123123123123132", customsavediet)
        // setaddingdiethard(flier)

        setdietplanid("")
        setcatoriesSummary([...catoriesSummary, e])
        setdietplan([...dietplan, e])
        let filteredArray = dietplan.filter(item => item?._id !== e?._id)

        setdietplan(filteredArray)

        let filteredArray1 = dietplan.filter(item => item?.name !== e?.name)

        setdietplan(filteredArray)
        setaddingdiethard([...addingdiethard, e])
        // setbackupDeitdata({})


    }
    const removemediance = (e) => {

        setstoreinfoMedicance([...storeinfoMedicance, e])

        let filteredArray = storeinfoMedicance.filter(item => item?.name !== e?.name)
        // let add = catoriesSummary.filter((v, i, a) => storetrement.findIndex(v2 => (v2.name === v.name)) === i)
        console.log("storetrement", filteredArray)
        setstoreinfoMedicance(filteredArray)

    }






    const additembackmedican = (e) => {

        setstoreinfoMedicance([...storeinfoMedicance, e])

        let filteredArray = storeinfoMedicance.filter(item => item?.name !== e?.name)
        // let add = catoriesSummary.filter((v, i, a) => storetrement.findIndex(v2 => (v2.name === v.name)) === i)
        console.log("storetrement", filteredArray)
        setstoreinfoMedicance(filteredArray)

    }



    const [locationdrop, setlocationdrop] = useState([])


    const [loctionService, setloctionService] = useState({
        name: null,
        toggle: false
    })

    const [therpyService, settherpyService] = useState({
        name: null,
        toggle: false
    })
    const [roomService, setroomService] = useState({
        name: null,
        toggle: false
    })

    const [timeshow, settimeshow] = useState({
        name: null,
        toggle: false
    })
    console.log("da3", loctionService)


    const [roomdata, setroomdata] = useState([])
    const [remandeddropdown, setremandeddropdown] = useState("Recommend")

    const [therphsydata, settherphsydata] = useState([])










    useEffect(() => {
        //setremandeddropdown("Recommend")
        locationdropfunction()
        therapistfunction()
        Roomdatafunction()
    }, [])




    const addpremandeddrop = (e, i) => {

        const result = storetrement.map((group, z) => {
            if (z === i) {
                return {
                    ...group,
                    remandeddropdown: e,
                    remandeddropdownid: i,


                };
            }
            return group;
        });

        setstoretrement(result)
    }




    // useEffect(() => {
    //   const flatpickrInstances = datepickerRef2.current.map((inputRef, index) => {
    //     return flatpickr(inputRef, {
    //       dateFormat: 'Y-m-d',
    //       onChange: (selectedDates, dateStr) => {
    //         // const updatedDates = [...dates];
    //         // updatedDates[index] = dateStr;
    //         //setDates(updatedDates);
    //         console.log(`Input at index ${index} changed to: ${dateStr}`);
    //       }
    //     });
    //   });

    //   return () => {
    //     flatpickrInstances.forEach(instance => instance.destroy()); // Cleanup Flatpickr instances
    //   };
    // }, [datepickerRef2?.current]);









    const datechange = (e) => {
        console.log("2342342", e)
    }


    const Roomdatafunction = () => {
        axios
            .get(
                `https://uatamma.apimachine.com/api/getroom?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then((res) => {
                if (res?.data?.success) {
                    setroomdata(res?.data?.data);
                }
                else {
                    setroomdata([]);
                }
            });
    };



    const locationdropfunction = () => {
        axios
            .get(
                `https://uatamma.apimachine.com/api/getlocation?teamUsername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then((res) => {
                if (res?.data?.success) {
                    setlocationdrop(res?.data?.data);
                }
                else {
                    setlocationdrop([]);
                }
            });
    };


    const therapistfunction = () => {
        axios
            .get(
                `https://uatamma.apimachine.com/api/getspecialist?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}&type=therapist`
            )
            .then((res) => {
                if (res?.data?.success) {
                    settherphsydata(res?.data?.data);
                }
                else {
                    settherphsydata([]);
                }
            });
    };










    // console.log("cccccccccc", dietplan)


    console.log("ppppppp222222222222222222222222222ppp", { ...ResultcompositionData?.[0] })
    console.log("deitplan", dietplan)

    const savdmediciance = () => {








        let deitplans = dietplan?.map(item => {
            return {
                "name": item?.name,
                "description": item?.description,
                "numofdays": item?.numofdays,
                "startingday": item?.startingday,
                "plandetails": item?.plandetails
            }
        })

        let lifystyle = lifestyleseleted?.map(item => {
            return {
                "name": item?.name,
                "description": item?.description,
            }
        })

        let investingaSelecteddata = investingaSelected?.map(item => {
            return {
                "name": item?.name,

                "lab": item?.lab,
                "description": item?.description,
            }
        })

        let storeinfoMedicancedata = storeinfoMedicance?.map(item => {
            return {
                "name": item?.name,
                "dosage": item?.dosage,
                "frequency": item?.frequency,
                "lengthOfTime": item?.lengthOfTime,
            }
        })
        let treatment = storetrement?.map(item => {
            return {
                "theraphyid": item?.theraphyId,
                "details": item?.des,

            }
        })

        // remandeddropdown == "Recommend"


        let automatetretment = storetrement?.map(item => {

            if (item?.remandeddropdown == "Recommend") {
                return {
                    "theraphyid": item?.theraphyId,
                    "details": item?.des,
                }
            }
            else {
                return {
                    // "theraphyid": item?.theraphyId,
                    // "details": item?.des,
                    // // 
                    "theraphyid": item?.theraphyId,
                    "details": "",
                    "teamusername": JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername,
                    // "clientId": sessionValue?.clientid,
                    "dateOfBooking": item?.duplicateDate,
                    "timeOfBooking": item?.duplicatertime,
                    "specialistid": item?.duplicatetherapyid,
                    "roomid": item?.duplicateroomid,
                    "serviceId": item?.theraphyId,
                    "locationId": item?.duplicatelocationid,
                    "bookingStatus": "booked"

                }
            }
        })



        let deitplandata = deitplans?.map(item => {
            return {
                "name": item?.name,
                "description": item?.description,
                "numofdays": item?.numofdays,
                "startingday": item?.startingday,
                "plandetails": item?.plandetails?.map(such => {
                    return {
                        "day": such?.day,
                        "dayplan": such?.dayplan?.map(eaxh => {
                            return {
                                "kitchenitemid": eaxh?.Dkitchenitemid ? eaxh?.Dkitchenitemid : eaxh?.kitchenitemid,
                                "kitchenItemName": eaxh?.DkitchenItemName ? eaxh?.DkitchenItemName : eaxh?.kitchenItemName,
                                "kitchenItemIcon": eaxh?.DkitchenItemIcon ? eaxh?.DkitchenItemIcon : eaxh?.kitchenItemIcon,
                                "unitname": eaxh?.Dunitname ? eaxh?.Dunitname : eaxh?.unitname,
                                "unitamount": eaxh?.Dunitamount ? eaxh?.Dunitamount : eaxh?.unitamount,
                                "time": eaxh?.Dtime ? eaxh?.Dtime : eaxh?.time
                            }
                        })
                    }
                })
            }
        })

        // let resultsavetreament = remandeddropdown == "Recommend" ? treatment : automatetretment

        let asdasd = ResultcompositionData?.map(item => {
            return {
                _id: item?._id,
                compositionid: item?.compositionid,
                name: item?.name,
                description: item?.description,
                icon: item?.icon,
                quantity: item?.quantity,
                numofdays: item?.numofdays,
                frequency: item?.frequency,
                totalquantity: item?.totalquantity,

                ingredients: item?.ingredientdetails?.map(such => {
                    return {
                        _id: such?._id,
                        ingredientid: such?.ingredientid,
                        quanity: such?.quanity,

                        total: such?.total,
                        name: such?.name,
                        description: such?.name,
                        icon: such?.icon,
                    }
                })
            }
        })


        let resut = {

            "clientid": clientDetail?.clientid,
            "dietplanids": dietplan?.map(item => {
                return item?.dietplanid
            }),
            "consultationsessionid": id,
            "bookingrequired": storetrement?.[0]?.remandeddropdown == "Recommend" ? false : true,
            "teamusername": JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername,
            "bookingrequired": storetrement?.[0]?.remandeddropdown == "Recommend" ? false : true,
            "items": [

                ...storeinfoMedicancedata


            ],


            "theraphyids": [
                ...automatetretment
            ],
            "investigationList": [

                ...investingaSelecteddata

            ],
            "lifestyleList": [
                ...lifystyle
            ],

            "notes": addnoteseleted?.[0]?.description,
            "dietplandetails": [...deitplandata],
            "composition": { ...asdasd?.[0] }

        }
        console.log("0000000000000000", resut)



        axios
            .post(`https://uatamma.apimachine.com/api/addprescription`, {
                ...resut
            })

            .then(({ data }) => {
                if (data.status) {
                    toast("Successfully Submited ");
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 1000);

                    if (type == "consultationservice") {
                        landingApi()
                        settabName("Summary")
                    }
                    else {
                        therpylandingApi()
                        settabName("Summary")
                    }

                } else {
                    toast(data?.message);
                }
            });


    }

    // console.log("medicationSelet", storeinfoMedicance)

    const [dplandropdown, setdplandropdown] = useState(false)
    const [dietid, setdietid] = useState(false)



    const changedietpainNamet = async (each, dayIndex, dayplanIndex, newUnitName, index) => {
        // let pop = {
        //   ...backupDeitdata, plandetails: [
        //     ...backupDeitdata?.plandetails,backupDeitdata?.plandetails[dayIndex] [
        //       ...backupDeitdata?.plandetails[dayIndex],dayplan[]
        //     ]
        //   ]
        // }

        // 


        // let lallasd =
        // {
        //   ...backupDeitdata,
        // }


        // console.log("pop", lallasd)
        // setaccomdationRoom({ ...accomdationRoom, coverPhoto: [...accomdationRoom?.coverPhoto, { name: "" }] });

        // setbackupDeitdata({
        //   ...backupDeitdata, plandetails: [...backupDeitdata?.plandetails?.[dayIndex], {}]
        //   //   dayplan:[...dayplan: [...backupDeitdata, plandetails?.[dayIndex]?.dayplan[dayplanIndex], {
        //   //     name: ""

        //   //   }]
        //   // ]
        // })

        //   let add = {
        //     ...backupDeitdata,{ backupDeitdata.plandetails[dayIndex].dayplan[dayplanIndex].unitname: newUnitName }
        // }
        setaddingdiethard(prevAdd => {
            const aasdasd = JSON.parse(JSON.stringify(addingdiethard))
            if (
                aasdasd[index].plandetails &&
                aasdasd[index].plandetails[dayIndex] &&
                aasdasd[index].plandetails[dayIndex].dayplan &&
                aasdasd[index].plandetails[dayIndex].dayplan[dayplanIndex]
            ) {
                aasdasd[index].plandetails[dayIndex].dayplan[dayplanIndex].Dunitname = newUnitName;

            } else {
                console.log("Invalid index provided");
            }
            return aasdasd;

        });
        await setbackupDeitdata(prevAdd => {
            // const newAdd = [...prevAdd];
            const newAdd = JSON.parse(JSON.stringify(backupDeitdata))
            if (
                newAdd &&
                newAdd.plandetails &&
                newAdd.plandetails[dayIndex] &&
                newAdd.plandetails[dayIndex].dayplan &&
                newAdd.plandetails[dayIndex].dayplan[dayplanIndex]
            ) {
                newAdd.plandetails[dayIndex].dayplan[dayplanIndex].unitname = newUnitName;
            } else {
                console.log("Invalid index provided");
            }
            return newAdd;
        });


    }

    const changedietpainTime = (each, dayIndex, dayplanIndex, newUnitName, index) => {

        setaddingdiethard(prevAdd => {
            const aasdasd = JSON.parse(JSON.stringify(addingdiethard))
            if (
                aasdasd[index].plandetails &&
                aasdasd[index].plandetails[dayIndex] &&
                aasdasd[index].plandetails[dayIndex].dayplan &&
                aasdasd[index].plandetails[dayIndex].dayplan[dayplanIndex]
            ) {
                aasdasd[index].plandetails[dayIndex].dayplan[dayplanIndex].Dtime = newUnitName;

            } else {
                console.log("Invalid index provided");
            }
            return aasdasd;

        });
        setbackupDeitdata(prevAdd => {
            const newAdd = JSON.parse(JSON.stringify(backupDeitdata))
            if (
                newAdd &&
                newAdd.plandetails &&
                newAdd.plandetails[dayIndex] &&
                newAdd.plandetails[dayIndex].dayplan &&
                newAdd.plandetails[dayIndex].dayplan[dayplanIndex]
            ) {
                newAdd.plandetails[dayIndex].dayplan[dayplanIndex].time = newUnitName;
            } else {
                console.log("Invalid index provided");
            }
            return newAdd;
        });
    }

    // console.log(catoriesSummary, "ae5e5e5d111")

    const changedietpainAmount = (each, dayIndex, dayplanIndex, newUnitName, index) => {

        console.log(dayIndex, dayplanIndex, index, "dasdasdasd")


        callqyulity(dayIndex, dayplanIndex, newUnitName)

        setaddingdiethard(prevAdd => {
            const aasdasd = JSON.parse(JSON.stringify(addingdiethard))
            if (
                aasdasd[index].plandetails &&
                aasdasd[index].plandetails[dayIndex] &&
                aasdasd[index].plandetails[dayIndex].dayplan &&
                aasdasd[index].plandetails[dayIndex].dayplan[dayplanIndex]
            ) {
                aasdasd[index].plandetails[dayIndex].dayplan[dayplanIndex].Dunitamount = newUnitName;

            } else {
                console.log("Invalid index provided");
            }
            return aasdasd;

        });


        setbackupDeitdata(prevAdd => {
            const newAdd = JSON.parse(JSON.stringify(backupDeitdata))
            if (
                newAdd.plandetails &&
                newAdd.plandetails[dayIndex] &&
                newAdd.plandetails[dayIndex].dayplan &&
                newAdd.plandetails[dayIndex].dayplan[dayplanIndex]
            ) {
                newAdd.plandetails[dayIndex].dayplan[dayplanIndex].unitamount = newUnitName;

            } else {
                console.log("Invalid index provided");
            }
            return newAdd;

        });

    }
    console.log(addingdiethard, "22222222222222222222")
    const callqyulity = (dayIndex, dayplanIndex, newUnitName) => {

        const updatedArray = inputsection?.map(item =>
            item.id == dayIndex ? {
                ...item, inputbox: item?.inputbox?.map(such =>
                    such?.id == dayplanIndex ? {
                        ...such, quality: newUnitName
                    } : such
                )
            } : item
        );



        //  let add= inputsection?.[dayIndex]?.inputbox?.[dayplanIndex]?.quality = newUnitName;
        console.log("222222222addingdiethard", updatedArray)
    }


    const [kichenitemName, setkichenitemName] = useState("")

    const changedietKitenitem = (each, dayIndex, dayplanIndex, newUnitName, index) => {

        setkichenitemName(newUnitName?.name)
        setaddingdiethard(prevAdd => {
            const aasdasd = JSON.parse(JSON.stringify(addingdiethard))
            if (
                aasdasd[index].plandetails &&
                aasdasd[index].plandetails[dayIndex] &&
                aasdasd[index].plandetails[dayIndex].dayplan &&
                aasdasd[index].plandetails[dayIndex].dayplan[dayplanIndex]
            ) {
                aasdasd[index].plandetails[dayIndex].dayplan[dayplanIndex].DkitchenItemName = newUnitName?.name;
                aasdasd[index].plandetails[dayIndex].dayplan[dayplanIndex].DkitchenItemid = newUnitName?._id;

            } else {
                console.log("Invalid index provided");
            }
            return aasdasd;

        });

        setbackupDeitdata(prevAdd => {
            const newAdd = JSON.parse(JSON.stringify(backupDeitdata))
            if (
                newAdd.plandetails &&
                newAdd.plandetails[dayIndex] &&
                newAdd.plandetails[dayIndex].dayplan &&
                newAdd.plandetails[dayIndex].dayplan[dayplanIndex]
            ) {
                newAdd.plandetails[dayIndex].dayplan[dayplanIndex].kitchenitemid = newUnitName?._id;
                newAdd.plandetails[dayIndex].dayplan[dayplanIndex].kitchenItemName = newUnitName?.name;
            } else {
                console.log("Invalid index provided");
            }
            return newAdd;
        });
        setshowselecteddropdown(false)
    }


    const [kitchenItem, setkitchenItem] = useState([])
    useEffect(() => {
        kitchenDrown()
    }, [])

    const kitchenDrown = () => {
        axios
            // .get(`https://uatamma.apimachine.com/api/getwidget`)
            .get(
                `https://uatamma.apimachine.com/api/getkitchenitem?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then((res) => {
                if (res?.data?.success) {
                    setkitchenItem(res?.data?.data);
                }
                else {
                    setkitchenItem([]);
                }
                //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))

            });
    };
    const expandedcall = (e) => {

        expadingfunction(e)
        setbackupDeitdata(e)
        // setbackupDeitdata([...backupDeitdata, e])
    }
    console.log("storetremen77777777t", storetrement)
    const [rightexpend, setrightexpend] = useState(false)

    const [showselecteddropdown, setshowselecteddropdown] = useState(false)
    const additem = (e) => {
        setstoretrement([...storetrement, e])
        setthermentautobook([...thermentautobook, e])
        let filteredArray = catoriesSummary.filter(item => item?.name !== e?.name)


        setcatoriesSummary(filteredArray)
    }
    const additemback = (e) => {
        setcatoriesSummary([...catoriesSummary, e])

        let filteredArray = storetrement.filter(item => item?.name !== e?.name)
        // let add = catoriesSummary.filter((v, i, a) => storetrement.findIndex(v2 => (v2.name === v.name)) === i)
        console.log("storetrement", filteredArray)
        setstoretrement(filteredArray)

    }
    // console.log("2222222222222222222222", deitplan)





    const compositionSingal = (index, index1, item) => {


        setcompositionData(prevAdd => {
            const aasdasd = JSON.parse(JSON.stringify(compositionData))
            if (
                aasdasd[index].ingredientdetails &&
                aasdasd[index].ingredientdetails[index1]
            ) {
                aasdasd[index].ingredientdetails[index1].quanity = item;

            } else {
                console.log("Invalid index provided");
            }
            return aasdasd;

        });


    }
    const compositionTatal = (index, index1, item) => {

        setcompositionData(prevAdd => {
            const aasdasd = JSON.parse(JSON.stringify(compositionData))
            console.log(" aasdasd[index]", aasdasd[index])
            if (
                aasdasd[index].ingredientdetails &&
                aasdasd[index].ingredientdetails[index1]
            ) {

                aasdasd[index].ingredientdetails[index1].total = item;


            } else {
                console.log("Invalid index provided");
            }
            if (
                aasdasd[index]) {
                aasdasd[index].totalquantity = [aasdasd[index]].reduce((acc, item) => {
                    return acc + item.ingredientdetails.reduce((subAcc, detail) => subAcc + Number(detail.total), 0);
                }, 0)

            }
            return aasdasd;

        });


    }

    useEffect(() => {

        // setcompositionData(updatedData)


    }, [compositionData])
    // const asdasdaasd = () => {
    //     const updatedData = compositionData.map(composition => {
    //         const totalSum = composition.ingredientdetails.reduce((sum, ingredient) => sum + Number(ingredient.total), 0);
    //         return {
    //             ...composition,
    //             quantity: Number(totalSum),
    //             ingredientdetails: item?.composition?.map((item, i) => {
    //                 return {
    //                     ...item,
    //                     total: e
    //                 }
    //             })
    //         };
    //     });


    // }
    console.log("ompositionDataompositionData", compositionData)

    const compositionRepetitions = (index, item) => {


        setcompositionData(prevAdd => {
            const aasdasd = JSON.parse(JSON.stringify(compositionData))
            if (
                aasdasd[index]

            ) {
                aasdasd[index].numofdays = item;

            } else {
                console.log("Invalid index provided");
            }
            return aasdasd;

        });


    }

    const compositionFrequence = (index, item) => {


        setcompositionData(prevAdd => {
            const aasdasd = JSON.parse(JSON.stringify(compositionData))
            if (
                aasdasd[index]

            ) {
                aasdasd[index].frequency = item;

            } else {
                console.log("Invalid index provided");
            }
            return aasdasd;

        });


    }
    console.log("compositionData", compositionData)

    const PageShow = () => {
        switch (choosePlan) {
            case "Notes":
                return (
                    <>
                        <input className="draginput" placeholder="Notes..."
                            style={{
                                marginBottom: "1rem"
                            }}
                            value={addnote.description}
                            onChange={(e) => setaddnote({

                                description: e.target.value
                            })}
                        />






                        <div className="Addto"
                            onClick={addNotefucntion}
                        >Add to Prescription </div>
                    </>
                )
            case "Life Style Advice":
                return (
                    <>
                        <input className="draginput" placeholder="Name"
                            style={{
                                marginBottom: "1rem"
                            }}
                            value={lifestyle.name}
                            onChange={(e) => setlifestyle({
                                name: e.target.value,
                                description: lifestyle.Divtitle
                            })}
                        />



                        <textarea className="draginput" placeholder="Description"
                            style={{
                                height: "90px",
                                paddingTop: '1.3rem'
                            }}
                            value={lifestyle.description}
                            onChange={(e) => setlifestyle({
                                name: lifestyle.name,
                                description: e.target.value,
                            })}
                        />

                        <div className="Addto"
                            onClick={addlifestyle}
                        >Add to Prescription </div>
                    </>
                )
            case "Investigation":
                return (
                    <>
                        <input className="draginput" placeholder="Investigation name"
                            style={{
                                marginBottom: "1rem"
                            }}
                            value={investingaData.name}
                            onChange={(e) => setinvestingaData({
                                name: e.target.value,
                                lab: investingaData.lab,
                                description: investingaData.description
                            })}
                        />

                        <input className="draginput" placeholder="Lab name"
                            style={{
                                marginBottom: "1rem"
                            }}
                            value={investingaData.lab}
                            onChange={(e) => setinvestingaData({
                                name: investingaData.name,
                                lab: e.target.value,
                                description: investingaData.description
                            })}
                        />


                        <textarea className="draginput" placeholder="Description"
                            style={{
                                height: "90px",
                                paddingTop: '1.3rem'
                            }}
                            value={investingaData.description}
                            onChange={(e) => setinvestingaData({
                                name: investingaData.name,
                                lab: investingaData?.lab,
                                description: e.target.value,
                            })}
                        />

                        <div className="Addto"
                            onClick={addinvest}
                        >Add to Prescription </div>
                    </>
                )
            case "Medication":
                return (
                    <>
                        <input className="draginput" placeholder="Drug name"
                            value={medicationSelet.name}
                            onChange={(e) => setmedicationSelet({
                                name: e.target.value,
                                dosage: medicationSelet?.dosage,
                                frequency: medicationSelet.frequency,
                                lengthOfTime: medicationSelet.lengthOfTime
                            })}
                        />
                        <div className="selectdiv" >Or select from your organizations pharmacy</div>
                        <input className="draginput" placeholder="Dosage"
                            value={medicationSelet.dosage}
                            onChange={(e) => setmedicationSelet({
                                name: medicationSelet.name,
                                dosage: e.target.value,
                                frequency: medicationSelet.frequency,
                                lengthOfTime: medicationSelet.lengthOfTime
                            })}
                        />

                        <div className="tablesection">
                            <input
                                style={
                                    {
                                        width: "48%"
                                    }
                                }
                                className="draginput" placeholder="Frequency"
                                value={medicationSelet.frequency}
                                onChange={(e) => setmedicationSelet({
                                    name: medicationSelet.name,
                                    dosage: medicationSelet.dosage,
                                    frequency: e.target.value,
                                    lengthOfTime: medicationSelet.lengthOfTime
                                })}
                            />


                            <input
                                style={
                                    {
                                        width: "48%"
                                    }
                                }
                                className="draginput" placeholder="Repetitions"
                                value={medicationSelet.lengthOfTime}
                                onChange={(e) => setmedicationSelet({
                                    name: medicationSelet.name,
                                    dosage: medicationSelet.dosage,
                                    frequency: medicationSelet.frequency,
                                    lengthOfTime: e.target.value,
                                })}
                            />

                            {/* <div
                  style={{
                    border: medicationSelet.Divtitle == "Frequency" ? "1px solid #0B2447" : "1px solid #E5E5E5"
                  }}
                  onClick={() => setmedicationSelet({
                    dNname: medicationSelet.dNname,
                    dosage: medicationSelet.dosage,
                    Divtitle: "Frequency"
                  })}
                >Frequency</div> */}
                            {/* <div
                  style={{
                    border: medicationSelet.Divtitle == "Repetitions" ? "1px solid #0B2447" : "1px solid #E5E5E5"
                  }}
                  onClick={() => setmedicationSelet({
                    dNname: medicationSelet.dNname,
                    dosage: medicationSelet.dosage,
                    Divtitle: "Repetitions"
                  })}
                >Repetitions </div> */}
                        </div>
                        <div className="Addto"
                            onClick={addMediance}
                        >Add to Prescription </div>
                    </>
                )

            case "Diet Plan":
                return (

                    <>
                        {addingdiethard?.map((eachPerscribe, index) => (
                            <div key={eachPerscribe + index} className="eachOption"
                                // 

                                style={{
                                    height: expandable && samevalue == eachPerscribe?._id && "auto",
                                    display: "block"
                                }}
                            >

                                <div className="closesection">
                                    <div
                                        style={{

                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                    >
                                        <svg
                                            style={{

                                                marginRight: "7px"
                                            }}
                                            onClick={() => {
                                                adddietpla(eachPerscribe)
                                                //setbackupDeitdata(eachPerscribe)
                                            }

                                            }
                                            xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                            <path d="M16 2.61572C16.7031 2.64176 17.2891 2.88916 17.7578 3.35791C18.2266 3.82666 18.474 4.4126 18.5 5.11572V17.6157C18.474 18.3188 18.2266 18.9048 17.7578 19.3735C17.2891 19.8423 16.7031 20.0897 16 20.1157H3.5C2.79688 20.0897 2.21094 19.8423 1.74219 19.3735C1.27344 18.9048 1.02604 18.3188 1 17.6157V5.11572C1.02604 4.4126 1.27344 3.82666 1.74219 3.35791C2.21094 2.88916 2.79688 2.64176 3.5 2.61572H16ZM13.4609 12.3032C14.0599 12.2511 14.3333 11.9386 14.2812 11.3657C14.2552 10.7928 13.9557 10.4803 13.3828 10.4282H10.5703V7.61572C10.5443 7.04281 10.2448 6.73031 9.67188 6.67822C9.09896 6.73031 8.78646 7.04281 8.73438 7.61572V10.4282H5.96094C5.38802 10.4803 5.07552 10.7928 5.02344 11.3657C5.02344 11.6261 5.11458 11.8475 5.29688 12.0298C5.50521 12.2121 5.72656 12.3032 5.96094 12.3032H8.8125V15.1157C8.86458 15.6886 9.17708 16.0011 9.75 16.0532C10.2969 16.0011 10.5964 15.6886 10.6484 15.1157V12.3032H13.4609Z" fill="#37B12B" />
                                        </svg>

                                        {eachPerscribe?.name}
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                    >



                                        {
                                            expandable && samevalue == eachPerscribe?._id ?

                                                <svg
                                                    style={{
                                                        transform: "rotate(180deg)"
                                                    }}
                                                    onClick={closeexpadingfunction}
                                                    xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
                                                    <path d="M10.9999 1.03573C10.8126 0.849476 10.5591 0.744934 10.2949 0.744934C10.0308 0.744934 9.77731 0.849476 9.58995 1.03573L5.99995 4.57573L2.45995 1.03573C2.27259 0.849476 2.01913 0.744934 1.75495 0.744934C1.49076 0.744934 1.23731 0.849476 1.04995 1.03573C0.95622 1.12869 0.881826 1.23929 0.831057 1.36115C0.780288 1.48301 0.75415 1.61371 0.75415 1.74573C0.75415 1.87774 0.780288 2.00844 0.831057 2.1303C0.881826 2.25216 0.95622 2.36276 1.04995 2.45573L5.28995 6.69573C5.38291 6.78945 5.49351 6.86385 5.61537 6.91462C5.73723 6.96539 5.86794 6.99152 5.99995 6.99152C6.13196 6.99152 6.26267 6.96539 6.38453 6.91462C6.50638 6.86385 6.61699 6.78945 6.70995 6.69573L10.9999 2.45573C11.0937 2.36276 11.1681 2.25216 11.2188 2.1303C11.2696 2.00844 11.2957 1.87774 11.2957 1.74573C11.2957 1.61371 11.2696 1.48301 11.2188 1.36115C11.1681 1.23929 11.0937 1.12869 10.9999 1.03573Z" fill="#181818" />
                                                </svg>
                                                // <div className="close"
                                                //     onClick={closeexpadingfunction}
                                                // >Close</div>
                                                :
                                                <svg
                                                    onClick={() => expandedcall(eachPerscribe)}
                                                    xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
                                                    <path d="M10.9999 1.03573C10.8126 0.849476 10.5591 0.744934 10.2949 0.744934C10.0308 0.744934 9.77731 0.849476 9.58995 1.03573L5.99995 4.57573L2.45995 1.03573C2.27259 0.849476 2.01913 0.744934 1.75495 0.744934C1.49076 0.744934 1.23731 0.849476 1.04995 1.03573C0.95622 1.12869 0.881826 1.23929 0.831057 1.36115C0.780288 1.48301 0.75415 1.61371 0.75415 1.74573C0.75415 1.87774 0.780288 2.00844 0.831057 2.1303C0.881826 2.25216 0.95622 2.36276 1.04995 2.45573L5.28995 6.69573C5.38291 6.78945 5.49351 6.86385 5.61537 6.91462C5.73723 6.96539 5.86794 6.99152 5.99995 6.99152C6.13196 6.99152 6.26267 6.96539 6.38453 6.91462C6.50638 6.86385 6.61699 6.78945 6.70995 6.69573L10.9999 2.45573C11.0937 2.36276 11.1681 2.25216 11.2188 2.1303C11.2696 2.00844 11.2957 1.87774 11.2957 1.74573C11.2957 1.61371 11.2696 1.48301 11.2188 1.36115C11.1681 1.23929 11.0937 1.12869 10.9999 1.03573Z" fill="#181818" />
                                                </svg>

                                        }




                                    </div>
                                </div>
                                {

                                    expandable && samevalue == eachPerscribe?._id &&
                                    <div
                                        style={{
                                            width: "100%",
                                            background: "white",
                                            padding: "1rem"
                                        }}
                                    >


                                        <>
                                            <div className="listItem">
                                                <div>Amount of days:</div>
                                                <div>{eachPerscribe?.numofdays}</div>


                                            </div>
                                            <div className="listItem">
                                                <div>Starts on:</div>
                                                <div>{eachPerscribe?.startingday}</div>


                                            </div>


                                            <div className="listItem"

                                                style={{
                                                    lineHeight: "25px"
                                                }}

                                            >
                                                {eachPerscribe?.description}
                                            </div>
                                            {
                                                eachPerscribe?.plandetails?.map((item, z) => {
                                                    return (
                                                        <>
                                                            <div className="questionsection">
                                                                <div className="questionsectionName">{item?.day}</div>
                                                                {
                                                                    item?.dayplan?.map((such, p) => {
                                                                        return (
                                                                            <>

                                                                                <div className="questionsectBox">
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            justifyContent: "space-between"

                                                                                        }}
                                                                                        onClick={() => {
                                                                                            setdplandropdown(!dplandropdown);
                                                                                            setdietid(such?._id)
                                                                                        }}
                                                                                    >
                                                                                        <div>{such?.time}</div>
                                                                                        <div>{such?.unitamount} {such?.unitname} {such?.kitchenItemName}

                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="5" viewBox="0 0 7 5" fill="none">
                                                                                                <path d="M3.69846 4.91959L6.90683 1.84362C6.96612 1.7867 7 1.70688 7 1.62328V0.301496C7 0.0386509 6.69624 -0.0978492 6.50968 0.0811595L3.5 2.96891L0.490321 0.0811595C0.303761 -0.0978492 -4.76837e-07 0.0386509 -4.76837e-07 0.301496V1.62328C-4.76837e-07 1.70688 0.0336499 1.7867 0.0931659 1.84362L3.30154 4.91959C3.41347 5.0268 3.58653 5.0268 3.69846 4.91959Z" fill="url(#paint0_linear_145_4817)" />
                                                                                                <defs>
                                                                                                    <linearGradient id="paint0_linear_145_4817" x1="3.50001" y1="-2.63166e-05" x2="3.50001" y2="4.99999" gradientUnits="userSpaceOnUse">
                                                                                                        <stop stop-color="#0B2447" />
                                                                                                        <stop offset="0.311" stop-color="#0D264C" />
                                                                                                        <stop offset="0.6768" stop-color="#122E5A" />
                                                                                                        <stop offset="1" stop-color="#010101" />
                                                                                                    </linearGradient>
                                                                                                </defs>
                                                                                            </svg>
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        dplandropdown && such?._id == dietid &&
                                                                                        <>
                                                                                            <div className="deitpaldroplist">
                                                                                                <div className="inner">
                                                                                                    <div className="name">New Quantity:</div>
                                                                                                    <input placeholder="0.00"
                                                                                                        value={such?.Dunitamount}
                                                                                                        onChange={(e) => changedietpainAmount(eachPerscribe, z, p, e.target.value, index)}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="deitpaldroplist">
                                                                                                <div className="inner">
                                                                                                    <div className="name">Change Unit:</div>
                                                                                                    <input placeholder="ex. Grams"
                                                                                                        value={such?.Dunitname}
                                                                                                        onChange={(e) => changedietpainNamet(eachPerscribe, z, p, e.target.value, index)}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="deitpaldroplist">
                                                                                                <div className="inner">
                                                                                                    <div className="name">New Time:</div>
                                                                                                    <input placeholder="HH:MM:AA"
                                                                                                        value={such?.Dtime}
                                                                                                        onChange={(e) => changedietpainTime(eachPerscribe, z, p, e.target.value, index)}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="deitpaldroplist">
                                                                                                <div className="inner">
                                                                                                    <div className="name">Switch kitchen item</div>
                                                                                                    <input placeholder="select"
                                                                                                        readOnly
                                                                                                        style={{
                                                                                                            cursor: "pointer",

                                                                                                        }}
                                                                                                        onClick={() => setshowselecteddropdown(!showselecteddropdown)}
                                                                                                        value={such?.DkitchenItemName}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            {/* <div className="deitpaldroplist"
                                                                                        onClick={() => setshowselecteddropdown(!showselecteddropdown)}
                                                                                    >
                                                                                        <div className="inner">
                                                                                            <div className="name">

                                                                                                {kichenitemName == "" ? "Switch kitchen item" :
                                                                                                    kichenitemName
                                                                                                }

                                                                                            </div>

                                                                                        </div>
                                                                                    </div> */}
                                                                                            {
                                                                                                showselecteddropdown &&
                                                                                                <div className="dropdownkitechitem">

                                                                                                    {kitchenItem?.map(item => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <div
                                                                                                                    onClick={() => changedietKitenitem(eachPerscribe, z, p, item, index)}
                                                                                                                >
                                                                                                                    {item?.name}
                                                                                                                </div>
                                                                                                            </>
                                                                                                        )
                                                                                                    })}

                                                                                                </div>
                                                                                            }


                                                                                        </>
                                                                                    }

                                                                                </div>

                                                                            </>
                                                                        )
                                                                    })
                                                                }

                                                            </div>

                                                        </>
                                                    )
                                                })
                                            }
                                        </>



                                    </div>
                                }


                            </div>
                        ))
                        }
                    </>
                )
                break;
            case "Composition Product":
                return (

                    <>
                        {compositionData?.map((eachPerscribe, index) => (
                            <div key={eachPerscribe + index} className="eachOption"
                                // 

                                style={{
                                    height: expandable && samevalue == eachPerscribe?._id && "auto",
                                    display: "block"
                                }}
                            >

                                <div className="closesection">
                                    <div
                                        style={{

                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                    >
                                        <svg
                                            style={{

                                                marginRight: "7px"
                                            }}
                                            onClick={() => {
                                                addcompositionData(eachPerscribe)
                                                //setbackupDeitdata(eachPerscribe)
                                            }

                                            }
                                            xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                            <path d="M16 2.61572C16.7031 2.64176 17.2891 2.88916 17.7578 3.35791C18.2266 3.82666 18.474 4.4126 18.5 5.11572V17.6157C18.474 18.3188 18.2266 18.9048 17.7578 19.3735C17.2891 19.8423 16.7031 20.0897 16 20.1157H3.5C2.79688 20.0897 2.21094 19.8423 1.74219 19.3735C1.27344 18.9048 1.02604 18.3188 1 17.6157V5.11572C1.02604 4.4126 1.27344 3.82666 1.74219 3.35791C2.21094 2.88916 2.79688 2.64176 3.5 2.61572H16ZM13.4609 12.3032C14.0599 12.2511 14.3333 11.9386 14.2812 11.3657C14.2552 10.7928 13.9557 10.4803 13.3828 10.4282H10.5703V7.61572C10.5443 7.04281 10.2448 6.73031 9.67188 6.67822C9.09896 6.73031 8.78646 7.04281 8.73438 7.61572V10.4282H5.96094C5.38802 10.4803 5.07552 10.7928 5.02344 11.3657C5.02344 11.6261 5.11458 11.8475 5.29688 12.0298C5.50521 12.2121 5.72656 12.3032 5.96094 12.3032H8.8125V15.1157C8.86458 15.6886 9.17708 16.0011 9.75 16.0532C10.2969 16.0011 10.5964 15.6886 10.6484 15.1157V12.3032H13.4609Z" fill="#37B12B" />
                                        </svg>

                                        {eachPerscribe?.name}
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                    >



                                        {
                                            expandable && samevalue == eachPerscribe?._id ?

                                                <svg
                                                    style={{
                                                        transform: "rotate(180deg)"
                                                    }}
                                                    onClick={closeexpadingfunction}
                                                    xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
                                                    <path d="M10.9999 1.03573C10.8126 0.849476 10.5591 0.744934 10.2949 0.744934C10.0308 0.744934 9.77731 0.849476 9.58995 1.03573L5.99995 4.57573L2.45995 1.03573C2.27259 0.849476 2.01913 0.744934 1.75495 0.744934C1.49076 0.744934 1.23731 0.849476 1.04995 1.03573C0.95622 1.12869 0.881826 1.23929 0.831057 1.36115C0.780288 1.48301 0.75415 1.61371 0.75415 1.74573C0.75415 1.87774 0.780288 2.00844 0.831057 2.1303C0.881826 2.25216 0.95622 2.36276 1.04995 2.45573L5.28995 6.69573C5.38291 6.78945 5.49351 6.86385 5.61537 6.91462C5.73723 6.96539 5.86794 6.99152 5.99995 6.99152C6.13196 6.99152 6.26267 6.96539 6.38453 6.91462C6.50638 6.86385 6.61699 6.78945 6.70995 6.69573L10.9999 2.45573C11.0937 2.36276 11.1681 2.25216 11.2188 2.1303C11.2696 2.00844 11.2957 1.87774 11.2957 1.74573C11.2957 1.61371 11.2696 1.48301 11.2188 1.36115C11.1681 1.23929 11.0937 1.12869 10.9999 1.03573Z" fill="#181818" />
                                                </svg>
                                                // <div className="close"
                                                //     onClick={closeexpadingfunction}
                                                // >Close</div>
                                                :
                                                <svg
                                                    onClick={() => {
                                                        setsamevalue(eachPerscribe?._id)
                                                        setexpandable(true)
                                                    }}
                                                    // onClick={() => expandedcall(eachPerscribe)}
                                                    xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
                                                    <path d="M10.9999 1.03573C10.8126 0.849476 10.5591 0.744934 10.2949 0.744934C10.0308 0.744934 9.77731 0.849476 9.58995 1.03573L5.99995 4.57573L2.45995 1.03573C2.27259 0.849476 2.01913 0.744934 1.75495 0.744934C1.49076 0.744934 1.23731 0.849476 1.04995 1.03573C0.95622 1.12869 0.881826 1.23929 0.831057 1.36115C0.780288 1.48301 0.75415 1.61371 0.75415 1.74573C0.75415 1.87774 0.780288 2.00844 0.831057 2.1303C0.881826 2.25216 0.95622 2.36276 1.04995 2.45573L5.28995 6.69573C5.38291 6.78945 5.49351 6.86385 5.61537 6.91462C5.73723 6.96539 5.86794 6.99152 5.99995 6.99152C6.13196 6.99152 6.26267 6.96539 6.38453 6.91462C6.50638 6.86385 6.61699 6.78945 6.70995 6.69573L10.9999 2.45573C11.0937 2.36276 11.1681 2.25216 11.2188 2.1303C11.2696 2.00844 11.2957 1.87774 11.2957 1.74573C11.2957 1.61371 11.2696 1.48301 11.2188 1.36115C11.1681 1.23929 11.0937 1.12869 10.9999 1.03573Z" fill="#181818" />
                                                </svg>

                                        }




                                    </div>
                                </div>
                                {

                                    expandable && samevalue == eachPerscribe?._id &&
                                    <div
                                        style={{
                                            width: "100%",
                                            background: "white",
                                            padding: "1rem"
                                        }}
                                    >


                                        <>

                                            {
                                                eachPerscribe?.ingredientdetails?.map((item, z) => {
                                                    return (
                                                        <>
                                                            <div className="questionsection">
                                                                <div className="questionsectionName">{item?.name}</div>
                                                                <div className="deitpaldroplist">
                                                                    <div className="amountSection">
                                                                        <div className="divingredient">
                                                                            <div className="titletext">Single</div>
                                                                            <div className="flex-ingedient">
                                                                                <input placeholder="0.00"
                                                                                    style={{
                                                                                        width: "100%"
                                                                                    }}
                                                                                    value={item?.quanity}
                                                                                    onChange={(e) => compositionSingal(index, z, e.target.value)}
                                                                                />
                                                                                <div className="mllitter">{eachPerscribe?.quantity}</div>
                                                                            </div>

                                                                        </div>
                                                                        <div className="divingredient">
                                                                            <div className="titletext">Total</div>
                                                                            <div className="flex-ingedient">
                                                                                <input placeholder={"0.00"}
                                                                                    style={{
                                                                                        width: "100%"
                                                                                    }}
                                                                                    value={item?.total}
                                                                                    onChange={(e) => compositionTatal(index, z, e.target.value)}
                                                                                />
                                                                                <div className="mllitter">{eachPerscribe?.quantity}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>

                                                        </>
                                                    )
                                                })
                                            }
                                            <div className="deitpaldroplist" >
                                                <div className="amountSection">
                                                    <div className="divingredient">
                                                        <div className="titletext">Frequency</div>
                                                        <div className="flex-ingedient">
                                                            <input placeholder=""
                                                                style={{
                                                                    width: "100%"
                                                                }}
                                                                value={eachPerscribe?.frequency}
                                                                onChange={(e) => compositionFrequence(index, e.target.value)}
                                                            />

                                                        </div>

                                                    </div>
                                                    <div className="divingredient">
                                                        <div className="titletext">Repetitions</div>
                                                        <div className="flex-ingedient">
                                                            <input placeholder="0"
                                                                style={{
                                                                    width: "100%"
                                                                }}
                                                                value={eachPerscribe?.numofdays}
                                                                onChange={(e) => compositionRepetitions(index, e.target.value)}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="questionsectionName"
                                                style={{
                                                    marginTop: "1rem"
                                                }}
                                            >Total</div>
                                            <div className="divingredient"
                                                style={{
                                                    width: "100%"
                                                }}
                                            >
                                                {/* <div className="titletext">Single</div> */}
                                                <div className="flex-ingedient"
                                                    style={{
                                                        width: "100%"
                                                    }}
                                                >
                                                    <input
                                                        className="inputtectfilled"
                                                        placeholder="0.00"
                                                        style={{
                                                            width: "100%",
                                                            pointerEvents: "none"
                                                        }}
                                                        value={eachPerscribe?.totalquantity}
                                                    // value={
                                                    //     compositionData.forEach(composition => {
                                                    //         composition.ingredientdetails.forEach(ingredient => {
                                                    //             totalSum += ingredient.total;
                                                    //         });
                                                    //     });
                                                    // }
                                                    //  onChange={(e) => compositiontotalquantity(index, e.target.value)}
                                                    // value={item?.Dunitamount}
                                                    // onChange={(e) => changedietpainAmount(eachPerscribe, z, e.target.value, index)}
                                                    />
                                                    <div className="mllitter">{eachPerscribe?.quantity}</div>
                                                </div>
                                            </div>
                                        </>



                                    </div>
                                }


                            </div>
                        ))
                        }
                    </>
                )
                break;
            case "Treatment":
                return (
                    <>

                        {catoriesSummary?.length > 0 &&
                            catoriesSummary?.map((eachPerscribe, index) => (
                                <div key={eachPerscribe + index} className="eachOption"
                                    onClick={() => additem(eachPerscribe)}
                                    style={{
                                        padding: "1rem"
                                    }}
                                >
                                    {eachPerscribe?.name}

                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M16.0144 16.3136C13.2601 18.562 4.65988 18.562 1.96176 16.3136C-0.792559 14.0652 -0.511511 4.45319 1.96176 1.92372C4.43503 -0.605757 13.5411 -0.605757 16.0144 1.92372C18.4877 4.45319 18.7687 14.0652 16.0144 16.3136Z" fill="url(#paint0_linear_102_3794)" />
                                        <g opacity="0.2">
                                            <path opacity="0.2" d="M9.04433 12.379C6.57106 14.3463 3.81674 15.3581 1.23106 15.4143C-0.680116 12.1541 -0.286625 4.22844 1.9618 1.92381C3.98538 -0.155971 10.3372 -0.549462 13.9909 0.743376C14.8902 4.45328 13.0353 9.17494 9.04433 12.379Z" fill="white" />
                                        </g>
                                        <path d="M14.3281 9.00631C14.3281 9.5684 13.8784 10.0181 13.3163 10.0181H9.94372V13.3907C9.94372 13.9528 9.49402 14.4025 8.93193 14.4025C8.36983 14.4025 7.92014 13.9528 7.92014 13.3907V10.0181H4.54748C3.98538 10.0181 3.53569 9.5684 3.53569 9.00631C3.53569 8.44421 3.98538 7.99452 4.54748 7.99452H7.9201V4.6219C7.9201 4.0598 8.36979 3.61011 8.93189 3.61011C9.49398 3.61011 9.94368 4.0598 9.94368 4.6219V7.99452H13.3163C13.8784 7.99452 14.3281 8.44417 14.3281 9.00631Z" fill="white" />
                                        <defs>
                                            <linearGradient id="paint0_linear_102_3794" x1="8.98844" y1="17.9997" x2="8.98844" y2="0.025855" gradientUnits="userSpaceOnUse">
                                                <stop offset="2.6639e-08" stop-color=" #C51E25" />
                                                <stop offset="1" stop-color="#C51E25" />
                                            </linearGradient>
                                        </defs>
                                    </svg>

                                </div>
                            ))}
                    </>
                )
            default:
                break;
        }
    }


    return (
        <div className="preception-page">
            <div className="rightside">
                {
                    choosePlan == "Notes" ?
                        <div className="text">Add {choosePlan}</div>
                        :
                        <div className="text">Choose {choosePlan}</div>
                }

                {PageShow()}
            </div>















            {/*  */}
            <div className="leftside">
                <div className="text">Prescription</div>
                <div className="lifesidedata">
                    <>
                        {/* asdasd */}
                        {ResultcompositionData?.map((eachPerscribe, index) => (
                            <div key={eachPerscribe + index} className="eachOption"
                                // 

                                style={{
                                    height: expandable && samevalue == eachPerscribe?._id && "auto",
                                    display: "block"
                                }}
                            >

                                <div className="closesection">
                                    <div
                                        style={{

                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                    >
                                        <svg
                                            style={{

                                                marginRight: "7px"
                                            }}
                                            onClick={() => additembackcompositionData(eachPerscribe)}
                                            xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                            <path d="M2.54492 19.0439V5.91895H17.5449V19.0439C17.5189 19.5648 17.3366 20.0075 16.998 20.3721C16.6335 20.7106 16.1908 20.8929 15.6699 20.9189H4.41992C3.89909 20.8929 3.45638 20.7106 3.0918 20.3721C2.75326 20.0075 2.57096 19.5648 2.54492 19.0439ZM13.1699 9.04395V17.7939C13.196 18.1846 13.4043 18.3929 13.7949 18.4189C14.1855 18.3929 14.3939 18.1846 14.4199 17.7939V9.04395C14.3939 8.65332 14.1855 8.44499 13.7949 8.41895C13.4043 8.44499 13.196 8.65332 13.1699 9.04395ZM9.41992 9.04395V17.7939C9.44596 18.1846 9.6543 18.3929 10.0449 18.4189C10.4355 18.3929 10.6439 18.1846 10.6699 17.7939V9.04395C10.6439 8.65332 10.4355 8.44499 10.0449 8.41895C9.6543 8.44499 9.44596 8.65332 9.41992 9.04395ZM5.66992 9.04395V17.7939C5.69596 18.1846 5.9043 18.3929 6.29492 18.4189C6.68555 18.3929 6.89388 18.1846 6.91992 17.7939V9.04395C6.89388 8.65332 6.68555 8.44499 6.29492 8.41895C5.9043 8.44499 5.69596 8.65332 5.66992 9.04395ZM18.1699 2.16895C18.5605 2.19499 18.7689 2.40332 18.7949 2.79395V4.04395C18.7689 4.43457 18.5605 4.6429 18.1699 4.66895H1.91992C1.5293 4.6429 1.32096 4.43457 1.29492 4.04395V2.79395C1.32096 2.40332 1.5293 2.19499 1.91992 2.16895H6.29492L6.76367 1.27051C6.86784 1.03613 7.05013 0.918945 7.31055 0.918945H12.7793C13.0397 0.918945 13.222 1.03613 13.3262 1.27051L13.7949 2.16895H18.1699Z" fill="#FF1400" />
                                        </svg>

                                        {eachPerscribe?.name}
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                    >

                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: "100%",
                                        background: "white",
                                        padding: "1rem"
                                    }}
                                >


                                    <>

                                        {
                                            eachPerscribe?.ingredientdetails?.map((item, z) => {
                                                return (
                                                    <>
                                                        <div className="questionsection">
                                                            <div className="questionsectionName">{item?.name}</div>
                                                            <div className="deitpaldroplist">
                                                                <div className="amountSection">
                                                                    <div className="divingredient">
                                                                        <div className="titletext">Single</div>
                                                                        <div className="flex-ingedient">
                                                                            <input placeholder="0.00"
                                                                                style={{
                                                                                    width: "100%",
                                                                                    pointerEvents: "none"
                                                                                }}
                                                                                value={item?.quanity}
                                                                            //  onChange={(e) => compositionSingal(index, z, e.target.value)}
                                                                            />
                                                                            <div className="mllitter">{eachPerscribe?.quantity}</div>
                                                                        </div>

                                                                    </div>
                                                                    <div className="divingredient">
                                                                        <div className="titletext">Total</div>
                                                                        <div className="flex-ingedient">
                                                                            <input placeholder="0.00"
                                                                                style={{
                                                                                    width: "100%",
                                                                                    pointerEvents: "none"
                                                                                }}
                                                                                value={item?.total}
                                                                            //   onChange={(e) => compositionTatal(index, z, e.target.value)}
                                                                            />
                                                                            <div className="mllitter">{eachPerscribe?.quantity}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>

                                                    </>
                                                )
                                            })
                                        }
                                        <div className="deitpaldroplist" >
                                            <div className="amountSection">
                                                <div className="divingredient">
                                                    <div className="titletext">Frequency</div>
                                                    <div className="flex-ingedient">
                                                        <input placeholder="0.00"
                                                            style={{
                                                                width: "100%",
                                                                pointerEvents: "none"
                                                            }}
                                                            value={eachPerscribe?.frequency}
                                                        //  onChange={(e) => compositionFrequence(index, e.target.value)}
                                                        />

                                                    </div>

                                                </div>
                                                <div className="divingredient">
                                                    <div className="titletext">Repetitions</div>
                                                    <div className="flex-ingedient">
                                                        <input placeholder="0.00"
                                                            style={{
                                                                width: "100%",
                                                                pointerEvents: "none"
                                                            }}
                                                            value={eachPerscribe?.numofdays}
                                                        // onChange={(e) => compositionRepetitions(index, e.target.value)}
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="questionsectionName"
                                            style={{
                                                marginTop: "1rem"
                                            }}
                                        >Total</div>
                                        <div className="divingredient"
                                            style={{
                                                width: "100%"
                                            }}
                                        >
                                            {/* <div className="titletext">Single</div> */}
                                            <div className="flex-ingedient"
                                                style={{
                                                    width: "100%"
                                                }}
                                            >
                                                <input
                                                    className="inputtectfilled"
                                                    placeholder="0.00"
                                                    style={{
                                                        width: "100%",
                                                        pointerEvents: "none"
                                                    }}
                                                    value={eachPerscribe?.totalquantity}
                                                //onChange={(e) => compositiontotalquantity(index, e.target.value)}
                                                // value={item?.Dunitamount}
                                                // onChange={(e) => changedietpainAmount(eachPerscribe, z, e.target.value, index)}
                                                />
                                                <div className="mllitter">{eachPerscribe?.quantity}</div>
                                            </div>
                                        </div>
                                    </>



                                </div>


                            </div>
                        ))
                        }

                        {addnoteseleted?.length > 0 &&
                            addnoteseleted?.map((eachPerscribe, index) => (
                                <div key={eachPerscribe + index} className="eachOptionright"
                                    style={{
                                        padding: "1rem", background: "#E8EBF0", display: "flex",
                                        marginBottom: "1rem",
                                        justifyContent: "space-between"
                                    }}
                                    onClick={() => removenote(eachPerscribe)}
                                >

                                    {eachPerscribe?.description}

                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M16.0144 16.3136C13.2601 18.562 4.65988 18.562 1.96176 16.3136C-0.792559 14.0652 -0.511511 4.45319 1.96176 1.92372C4.43503 -0.605757 13.5411 -0.605757 16.0144 1.92372C18.4877 4.45319 18.7687 14.0652 16.0144 16.3136Z" fill="url(#paint0_linear_113_4176)" />
                                        <g opacity="0.2">
                                            <path opacity="0.2" d="M9.04428 12.379C6.571 14.3463 3.81668 15.3581 1.23101 15.4143C-0.680168 12.1541 -0.286677 4.22844 1.96175 1.92381C3.98533 -0.155971 10.3371 -0.549462 13.9908 0.743376C14.8902 4.45328 13.0352 9.17494 9.04428 12.379Z" fill="white" />
                                        </g>
                                        <path d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27479L5.87553 5.15034C5.67517 4.94993 5.35073 4.94987 5.15032 5.1503C4.9499 5.35071 4.94988 5.67509 5.15032 5.87551L8.27476 9L5.15031 12.1245C4.95035 12.3244 4.95035 12.6497 5.15031 12.8497C5.3507 13.0501 5.6751 13.0501 5.87551 12.8497L8.99997 9.72517L12.1244 12.8496C12.3248 13.0501 12.6492 13.0501 12.8496 12.8496C13.05 12.6493 13.0501 12.3249 12.8496 12.1244L9.7252 9Z" fill="white" />
                                        <path d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27478V9.72522L12.1245 12.8497C12.3248 13.0501 12.6493 13.0501 12.8496 12.8497C13.0501 12.6493 13.0501 12.3249 12.8497 12.1245L9.7252 9Z" fill="white" />
                                        <defs>
                                            <linearGradient id="paint0_linear_113_4176" x1="-9.46143e-05" y1="9.01325" x2="17.9761" y2="9.01325" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#0B2447" />
                                                <stop offset="0.311" stop-color="#0D264C" />
                                                <stop offset="0.6768" stop-color="#122E5A" />
                                                <stop offset="1" stop-color="#010101" />
                                            </linearGradient>
                                        </defs>
                                    </svg>

                                </div>
                            ))}
                        {/* Nite */}

                        {/* lifestyle */}

                        {lifestyleseleted?.length > 0 &&
                            lifestyleseleted?.map((eachPerscribe, index) => (
                                <div key={eachPerscribe + index} className="eachOptionright"
                                    style={{
                                        padding: "1rem", background: "#E8EBF0", display: "flex",
                                        marginBottom: "1rem",
                                        justifyContent: "space-between"
                                    }}
                                    onClick={() => removelifestyle(eachPerscribe)}
                                >

                                    {eachPerscribe?.name} | {eachPerscribe?.description}

                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M16.0144 16.3136C13.2601 18.562 4.65988 18.562 1.96176 16.3136C-0.792559 14.0652 -0.511511 4.45319 1.96176 1.92372C4.43503 -0.605757 13.5411 -0.605757 16.0144 1.92372C18.4877 4.45319 18.7687 14.0652 16.0144 16.3136Z" fill="url(#paint0_linear_113_4176)" />
                                        <g opacity="0.2">
                                            <path opacity="0.2" d="M9.04428 12.379C6.571 14.3463 3.81668 15.3581 1.23101 15.4143C-0.680168 12.1541 -0.286677 4.22844 1.96175 1.92381C3.98533 -0.155971 10.3371 -0.549462 13.9908 0.743376C14.8902 4.45328 13.0352 9.17494 9.04428 12.379Z" fill="white" />
                                        </g>
                                        <path d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27479L5.87553 5.15034C5.67517 4.94993 5.35073 4.94987 5.15032 5.1503C4.9499 5.35071 4.94988 5.67509 5.15032 5.87551L8.27476 9L5.15031 12.1245C4.95035 12.3244 4.95035 12.6497 5.15031 12.8497C5.3507 13.0501 5.6751 13.0501 5.87551 12.8497L8.99997 9.72517L12.1244 12.8496C12.3248 13.0501 12.6492 13.0501 12.8496 12.8496C13.05 12.6493 13.0501 12.3249 12.8496 12.1244L9.7252 9Z" fill="white" />
                                        <path d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27478V9.72522L12.1245 12.8497C12.3248 13.0501 12.6493 13.0501 12.8496 12.8497C13.0501 12.6493 13.0501 12.3249 12.8497 12.1245L9.7252 9Z" fill="white" />
                                        <defs>
                                            <linearGradient id="paint0_linear_113_4176" x1="-9.46143e-05" y1="9.01325" x2="17.9761" y2="9.01325" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#0B2447" />
                                                <stop offset="0.311" stop-color="#0D264C" />
                                                <stop offset="0.6768" stop-color="#122E5A" />
                                                <stop offset="1" stop-color="#010101" />
                                            </linearGradient>
                                        </defs>
                                    </svg>

                                </div>
                            ))}

                        {/* investment */}
                        {investingaSelected?.length > 0 &&
                            investingaSelected?.map((eachPerscribe, index) => (
                                <div key={eachPerscribe + index} className="eachOptionright"
                                    style={{
                                        padding: "1rem", background: "#E8EBF0", display: "flex",
                                        marginBottom: "1rem",
                                        justifyContent: "space-between"
                                    }}
                                    onClick={() => removeInvesting(eachPerscribe)}
                                >

                                    {eachPerscribe?.name} |{eachPerscribe?.lab} | {eachPerscribe?.description}

                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M16.0144 16.3136C13.2601 18.562 4.65988 18.562 1.96176 16.3136C-0.792559 14.0652 -0.511511 4.45319 1.96176 1.92372C4.43503 -0.605757 13.5411 -0.605757 16.0144 1.92372C18.4877 4.45319 18.7687 14.0652 16.0144 16.3136Z" fill="url(#paint0_linear_113_4176)" />
                                        <g opacity="0.2">
                                            <path opacity="0.2" d="M9.04428 12.379C6.571 14.3463 3.81668 15.3581 1.23101 15.4143C-0.680168 12.1541 -0.286677 4.22844 1.96175 1.92381C3.98533 -0.155971 10.3371 -0.549462 13.9908 0.743376C14.8902 4.45328 13.0352 9.17494 9.04428 12.379Z" fill="white" />
                                        </g>
                                        <path d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27479L5.87553 5.15034C5.67517 4.94993 5.35073 4.94987 5.15032 5.1503C4.9499 5.35071 4.94988 5.67509 5.15032 5.87551L8.27476 9L5.15031 12.1245C4.95035 12.3244 4.95035 12.6497 5.15031 12.8497C5.3507 13.0501 5.6751 13.0501 5.87551 12.8497L8.99997 9.72517L12.1244 12.8496C12.3248 13.0501 12.6492 13.0501 12.8496 12.8496C13.05 12.6493 13.0501 12.3249 12.8496 12.1244L9.7252 9Z" fill="white" />
                                        <path d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27478V9.72522L12.1245 12.8497C12.3248 13.0501 12.6493 13.0501 12.8496 12.8497C13.0501 12.6493 13.0501 12.3249 12.8497 12.1245L9.7252 9Z" fill="white" />
                                        <defs>
                                            <linearGradient id="paint0_linear_113_4176" x1="-9.46143e-05" y1="9.01325" x2="17.9761" y2="9.01325" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#0B2447" />
                                                <stop offset="0.311" stop-color="#0D264C" />
                                                <stop offset="0.6768" stop-color="#122E5A" />
                                                <stop offset="1" stop-color="#010101" />
                                            </linearGradient>
                                        </defs>
                                    </svg>

                                </div>
                            ))}

                        {storeinfoMedicance?.length > 0 &&
                            storeinfoMedicance?.map((eachPerscribe, index) => (
                                <div key={eachPerscribe + index} className="eachOptionright"

                                    style={{
                                        padding: "1rem", background: "#E8EBF0", display: "flex",
                                        marginBottom: "1rem",
                                        justifyContent: "space-between"
                                    }}
                                    onClick={() => removemediance(eachPerscribe)}
                                >

                                    {eachPerscribe?.name} |{eachPerscribe?.dosage} | {eachPerscribe?.frequency}  | {eachPerscribe?.lengthOfTime}

                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M16.0144 16.3136C13.2601 18.562 4.65988 18.562 1.96176 16.3136C-0.792559 14.0652 -0.511511 4.45319 1.96176 1.92372C4.43503 -0.605757 13.5411 -0.605757 16.0144 1.92372C18.4877 4.45319 18.7687 14.0652 16.0144 16.3136Z" fill="url(#paint0_linear_113_4176)" />
                                        <g opacity="0.2">
                                            <path opacity="0.2" d="M9.04428 12.379C6.571 14.3463 3.81668 15.3581 1.23101 15.4143C-0.680168 12.1541 -0.286677 4.22844 1.96175 1.92381C3.98533 -0.155971 10.3371 -0.549462 13.9908 0.743376C14.8902 4.45328 13.0352 9.17494 9.04428 12.379Z" fill="white" />
                                        </g>
                                        <path d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27479L5.87553 5.15034C5.67517 4.94993 5.35073 4.94987 5.15032 5.1503C4.9499 5.35071 4.94988 5.67509 5.15032 5.87551L8.27476 9L5.15031 12.1245C4.95035 12.3244 4.95035 12.6497 5.15031 12.8497C5.3507 13.0501 5.6751 13.0501 5.87551 12.8497L8.99997 9.72517L12.1244 12.8496C12.3248 13.0501 12.6492 13.0501 12.8496 12.8496C13.05 12.6493 13.0501 12.3249 12.8496 12.1244L9.7252 9Z" fill="white" />
                                        <path d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27478V9.72522L12.1245 12.8497C12.3248 13.0501 12.6493 13.0501 12.8496 12.8497C13.0501 12.6493 13.0501 12.3249 12.8497 12.1245L9.7252 9Z" fill="white" />
                                        <defs>
                                            <linearGradient id="paint0_linear_113_4176" x1="-9.46143e-05" y1="9.01325" x2="17.9761" y2="9.01325" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#0B2447" />
                                                <stop offset="0.311" stop-color="#0D264C" />
                                                <stop offset="0.6768" stop-color="#122E5A" />
                                                <stop offset="1" stop-color="#010101" />
                                            </linearGradient>
                                        </defs>
                                    </svg>

                                </div>
                            ))}
                        {/* Median */}

                        {/* treatment */}
                        {storetrement?.length > 0 &&
                            storetrement?.map((eachPerscribe, index) => {

                                const keyToCheck = 'duplicatelocationname';
                                const keyExists = keyToCheck in eachPerscribe;
                                const keyToCheck1 = 'duplicatetherapyname';
                                const keyExists1 = keyToCheck1 in eachPerscribe;
                                const keyToCheck2 = 'duplicateroomname';
                                const keyExists2 = keyToCheck2 in eachPerscribe;
                                const keyToCheck3 = 'duplicatertime';
                                const keyExists3 = keyToCheck3 in eachPerscribe;

                                return (

                                    <div key={eachPerscribe + index} className="eachOptionright"
                                        style={{
                                            padding: "1rem", background: "#E8EBF0",
                                            marginBottom: "1rem"
                                        }}

                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                width: "100%"
                                            }}
                                        >
                                            {eachPerscribe?.name}

                                            <svg
                                                onClick={() => {
                                                    additemback(eachPerscribe)


                                                }}
                                                xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M16.0144 16.3136C13.2601 18.562 4.65988 18.562 1.96176 16.3136C-0.792559 14.0652 -0.511511 4.45319 1.96176 1.92372C4.43503 -0.605757 13.5411 -0.605757 16.0144 1.92372C18.4877 4.45319 18.7687 14.0652 16.0144 16.3136Z" fill="url(#paint0_linear_113_4176)" />
                                                <g opacity="0.2">
                                                    <path opacity="0.2" d="M9.04428 12.379C6.571 14.3463 3.81668 15.3581 1.23101 15.4143C-0.680168 12.1541 -0.286677 4.22844 1.96175 1.92381C3.98533 -0.155971 10.3371 -0.549462 13.9908 0.743376C14.8902 4.45328 13.0352 9.17494 9.04428 12.379Z" fill="white" />
                                                </g>
                                                <path d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27479L5.87553 5.15034C5.67517 4.94993 5.35073 4.94987 5.15032 5.1503C4.9499 5.35071 4.94988 5.67509 5.15032 5.87551L8.27476 9L5.15031 12.1245C4.95035 12.3244 4.95035 12.6497 5.15031 12.8497C5.3507 13.0501 5.6751 13.0501 5.87551 12.8497L8.99997 9.72517L12.1244 12.8496C12.3248 13.0501 12.6492 13.0501 12.8496 12.8496C13.05 12.6493 13.0501 12.3249 12.8496 12.1244L9.7252 9Z" fill="white" />
                                                <path d="M9.7252 9L12.8497 5.87552C13.0501 5.67517 13.0501 5.35074 12.8497 5.15034C12.6493 4.94991 12.3249 4.94987 12.1245 5.15032L9 8.27478V9.72522L12.1245 12.8497C12.3248 13.0501 12.6493 13.0501 12.8496 12.8497C13.0501 12.6493 13.0501 12.3249 12.8497 12.1245L9.7252 9Z" fill="white" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_113_4176" x1="-9.46143e-05" y1="9.01325" x2="17.9761" y2="9.01325" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#0B2447" />
                                                        <stop offset="0.311" stop-color="#0D264C" />
                                                        <stop offset="0.6768" stop-color="#122E5A" />
                                                        <stop offset="1" stop-color="#010101" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>

                                        <div>
                                            <div className="labelsectedTheraply">
                                                <label
                                                    onClick={() => addpremandeddrop("Recommend", index)}
                                                    style={{
                                                        background: eachPerscribe?.remandeddropdown == "Recommend" && "#C51E25",
                                                        color: eachPerscribe?.remandeddropdown == "Recommend" && "white"
                                                    }}
                                                >Recommend</label>
                                                <label

                                                    style={{
                                                        background: eachPerscribe?.remandeddropdown == "Autobook" && "#C51E25",
                                                        color: eachPerscribe?.remandeddropdown == "Autobook" && "white"
                                                    }}

                                                    onClick={() => addpremandeddrop("Autobook", index)}
                                                >Autobook</label>
                                            </div>
                                            {
                                                eachPerscribe?.remandeddropdown == "Recommend" &&
                                                <textarea
                                                    value={eachPerscribe?.des}
                                                    onChange={(e) => chnagetreatment(e, index)}
                                                    style={{
                                                        width: "100%",
                                                        fontFamily: "Montserrat Alternates",
                                                        fontSize: "11px",
                                                        fontWeight: "400",
                                                        lineHeight: "13.41px",
                                                        padding: "1rem",
                                                        border: " 1px solid #E5E5E5",
                                                        borderRadius: "5px",
                                                        marginTop: "0.7rem"
                                                    }}
                                                    placeholder="Description"

                                                />
                                            }
                                            {
                                                eachPerscribe?.remandeddropdown == "Autobook" &&
                                                <div className="slectedtheryauotbook">
                                                    {/* < div className='titleseleted' >Select location</div > */}

                                                    <div className='dropdownParentSection'
                                                        //  ref={ref1}
                                                        style={{
                                                            width: "100%"
                                                        }}
                                                    >
                                                        <div className='dropdown-planb'
                                                            onClick={() => settherpyService({
                                                                toggle: !therpyService?.toggle,
                                                                name: index
                                                            })}

                                                        >
                                                            <div

                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: 'center'
                                                                }}
                                                            >
                                                                {
                                                                    !keyExists1 &&
                                                                    "Select therapist"
                                                                }

                                                                {eachPerscribe?.duplicatetherapyname == "" ? "Select location" : eachPerscribe?.duplicatetherapyname}
                                                            </div>
                                                            {
                                                                loctionService.toggle ?
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">
                                                                        <path d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z" fill="#7D7D7D" />
                                                                    </svg>
                                                                    :

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">
                                                                        <path d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z" fill="#7D7D7D" />
                                                                    </svg>
                                                            }


                                                        </div>


                                                        {
                                                            therpyService.toggle && index == therpyService?.name &&
                                                            <div className='dropdwonlist'

                                                            >
                                                                {
                                                                    therphsydata?.map(item => {
                                                                        return (
                                                                            <div className='listitemName'
                                                                                onClick={() =>
                                                                                    theryduplicatedatastore(item, index)

                                                                                }
                                                                            >
                                                                                {item?.TeamDetails?.[0]?.PlayerDetails?.[0]?.name}
                                                                            </div>
                                                                        )
                                                                    })
                                                                }

                                                            </div>
                                                        }



                                                    </div>







                                                    <div className='dropdownParentSection'
                                                        //  ref={ref1}
                                                        style={{
                                                            width: "100%"
                                                        }}
                                                    >
                                                        <div className='dropdown-planb'
                                                            onClick={() => setloctionService({
                                                                toggle: !loctionService?.toggle,
                                                                name: index
                                                            })}

                                                        >
                                                            <div

                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: 'center'
                                                                }}
                                                            >
                                                                {
                                                                    !keyExists &&
                                                                    "Select location"
                                                                }

                                                                {eachPerscribe?.duplicatelocationname == "" ? "Select location" : eachPerscribe?.duplicatelocationname}
                                                            </div>
                                                            {
                                                                loctionService.toggle ?
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">
                                                                        <path d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z" fill="#7D7D7D" />
                                                                    </svg>
                                                                    :

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">
                                                                        <path d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z" fill="#7D7D7D" />
                                                                    </svg>
                                                            }


                                                        </div>


                                                        {
                                                            loctionService.toggle && index == loctionService?.name &&
                                                            <div className='dropdwonlist'

                                                            >
                                                                {
                                                                    locationdrop?.map(item => {
                                                                        return (
                                                                            <div className='listitemName'
                                                                                onClick={() =>
                                                                                    lacationdatastore(item, index)

                                                                                }
                                                                            >
                                                                                {item?.name}
                                                                            </div>
                                                                        )
                                                                    })
                                                                }

                                                            </div>
                                                        }



                                                    </div>






                                                    <div className='dropdownParentSection'
                                                        //  ref={ref1}
                                                        style={{
                                                            width: "100%"
                                                        }}
                                                    >
                                                        <div className='dropdown-planb'
                                                            onClick={() => setroomService({
                                                                toggle: !roomService?.toggle,
                                                                name: index
                                                            })}

                                                        >
                                                            <div

                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: 'center'
                                                                }}
                                                            >
                                                                {
                                                                    !keyExists2 &&
                                                                    "Select room"
                                                                }

                                                                {eachPerscribe?.duplicateroomname == "" ? "Select room" : eachPerscribe?.duplicateroomname}
                                                            </div>
                                                            {
                                                                roomService.toggle ?
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">
                                                                        <path d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z" fill="#7D7D7D" />
                                                                    </svg>
                                                                    :

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">
                                                                        <path d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z" fill="#7D7D7D" />
                                                                    </svg>
                                                            }


                                                        </div>


                                                        {
                                                            roomService.toggle && index == roomService?.name &&
                                                            <div className='dropdwonlist'

                                                            >
                                                                {
                                                                    roomdata?.map(item => {
                                                                        return (
                                                                            <div className='listitemName'
                                                                                onClick={() =>
                                                                                    Roomdatastore(item, index)

                                                                                }
                                                                            >
                                                                                {item?.name}
                                                                            </div>
                                                                        )
                                                                    })
                                                                }

                                                            </div>
                                                        }



                                                    </div>
                                                    <div className='dropdownParentSection'
                                                        // ref={ref1}
                                                        style={{
                                                            width: "100%"
                                                        }}
                                                    >


                                                        <div className='dropdown-planb'

                                                        // onClick={() => setlead({
                                                        //   toggle: !lead?.toggle,
                                                        //   name: lead?.name
                                                        // })}

                                                        >
                                                            <div

                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: 'center',
                                                                    width: "100%"
                                                                }}
                                                            >
                                                                <MyDatePicker
                                                                    index={index}

                                                                    storetrement={storetrement}
                                                                    setstoretrement={setstoretrement}
                                                                    value={"eachPerscribe?.duplicateDate"

                                                                    }
                                                                />

                                                                {/* <input
                                    type="text"
                                    id="date-input"
                                    key={index}
                                    ref={datepickerRef2}
                                    className="dateinput"
                                    placeholder="Click To Select"

                                  /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='dropdownParentSection'
                                                        style={{
                                                            width: "100%"
                                                        }}
                                                    >
                                                        <div className='dropdown-planb'

                                                            onClick={() => settimeshow({
                                                                toggle: !timeshow?.toggle,
                                                                name: index
                                                            })}

                                                        >
                                                            <div

                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: 'center'
                                                                }}
                                                            >

                                                                {
                                                                    !keyExists3 &&
                                                                    "Select time"
                                                                }

                                                                {eachPerscribe?.duplicatertime == "" ? "Select time" : eachPerscribe?.duplicatertime}
                                                            </div>
                                                            {
                                                                timeshow.toggle ?
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">
                                                                        <path d="M5.89314 4.05692L3.41875 0.254146C3.22841 -0.0847154 2.80965 -0.0847154 2.58125 0.254146L0.106859 4.05692C-0.159627 4.47107 0.106859 4.99821 0.525593 4.99821H5.47441C5.89314 5.03586 6.15963 4.4711 5.89314 4.05692Z" fill="#7D7D7D" />
                                                                    </svg>
                                                                    :

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5" fill="none">
                                                                        <path d="M0.106859 0.943083L2.58125 4.74585C2.77159 5.08472 3.19035 5.08472 3.41875 4.74585L5.89314 0.943083C6.15963 0.52893 5.89314 0.00178814 5.47441 0.00178814L0.525592 0.00178814C0.106859 -0.0358572 -0.159627 0.528903 0.106859 0.943083Z" fill="#7D7D7D" />
                                                                    </svg>
                                                            }


                                                        </div>


                                                        {
                                                            timeshow.toggle && index == timeshow?.name &&
                                                            <div className='dropdwonlist'
                                                                style={{
                                                                    height: "12rem",
                                                                    overflow: " scroll"
                                                                }}
                                                            >
                                                                {
                                                                    timpicker?.map(item => {
                                                                        return (
                                                                            <div className='listitemName'

                                                                                onClick={() =>
                                                                                    timeatastore(item, index)

                                                                                }
                                                                            // onClick={() =>
                                                                            //   selettimeshow(item)

                                                                            // }
                                                                            >


                                                                                {item}
                                                                            </div>
                                                                        )
                                                                    })
                                                                }

                                                            </div>
                                                        }










                                                    </div>

                                                </div>

                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        {dietplan?.map((eachPerscribe, index) => (
                            <div key={eachPerscribe + index} className="eachOption"
                                // 

                                style={{
                                    height: rightexpend && samevalue == eachPerscribe?._id && "auto",
                                    display: "block",
                                    marginBottom: "1rem"
                                }}
                            >

                                <div className="closesection">
                                    <div
                                        style={{

                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                    >


                                        <svg
                                            style={{

                                                marginRight: "7px"
                                            }}
                                            onClick={() => additembackdiet(eachPerscribe)}
                                            xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                            <path d="M2.54492 19.0439V5.91895H17.5449V19.0439C17.5189 19.5648 17.3366 20.0075 16.998 20.3721C16.6335 20.7106 16.1908 20.8929 15.6699 20.9189H4.41992C3.89909 20.8929 3.45638 20.7106 3.0918 20.3721C2.75326 20.0075 2.57096 19.5648 2.54492 19.0439ZM13.1699 9.04395V17.7939C13.196 18.1846 13.4043 18.3929 13.7949 18.4189C14.1855 18.3929 14.3939 18.1846 14.4199 17.7939V9.04395C14.3939 8.65332 14.1855 8.44499 13.7949 8.41895C13.4043 8.44499 13.196 8.65332 13.1699 9.04395ZM9.41992 9.04395V17.7939C9.44596 18.1846 9.6543 18.3929 10.0449 18.4189C10.4355 18.3929 10.6439 18.1846 10.6699 17.7939V9.04395C10.6439 8.65332 10.4355 8.44499 10.0449 8.41895C9.6543 8.44499 9.44596 8.65332 9.41992 9.04395ZM5.66992 9.04395V17.7939C5.69596 18.1846 5.9043 18.3929 6.29492 18.4189C6.68555 18.3929 6.89388 18.1846 6.91992 17.7939V9.04395C6.89388 8.65332 6.68555 8.44499 6.29492 8.41895C5.9043 8.44499 5.69596 8.65332 5.66992 9.04395ZM18.1699 2.16895C18.5605 2.19499 18.7689 2.40332 18.7949 2.79395V4.04395C18.7689 4.43457 18.5605 4.6429 18.1699 4.66895H1.91992C1.5293 4.6429 1.32096 4.43457 1.29492 4.04395V2.79395C1.32096 2.40332 1.5293 2.19499 1.91992 2.16895H6.29492L6.76367 1.27051C6.86784 1.03613 7.05013 0.918945 7.31055 0.918945H12.7793C13.0397 0.918945 13.222 1.03613 13.3262 1.27051L13.7949 2.16895H18.1699Z" fill="#FF1400" />
                                        </svg>

                                        {eachPerscribe?.name}
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                    >



                                        <svg

                                            xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
                                            <path d="M10.9999 1.03573C10.8126 0.849476 10.5591 0.744934 10.2949 0.744934C10.0308 0.744934 9.77731 0.849476 9.58995 1.03573L5.99995 4.57573L2.45995 1.03573C2.27259 0.849476 2.01913 0.744934 1.75495 0.744934C1.49076 0.744934 1.23731 0.849476 1.04995 1.03573C0.95622 1.12869 0.881826 1.23929 0.831057 1.36115C0.780288 1.48301 0.75415 1.61371 0.75415 1.74573C0.75415 1.87774 0.780288 2.00844 0.831057 2.1303C0.881826 2.25216 0.95622 2.36276 1.04995 2.45573L5.28995 6.69573C5.38291 6.78945 5.49351 6.86385 5.61537 6.91462C5.73723 6.96539 5.86794 6.99152 5.99995 6.99152C6.13196 6.99152 6.26267 6.96539 6.38453 6.91462C6.50638 6.86385 6.61699 6.78945 6.70995 6.69573L10.9999 2.45573C11.0937 2.36276 11.1681 2.25216 11.2188 2.1303C11.2696 2.00844 11.2957 1.87774 11.2957 1.74573C11.2957 1.61371 11.2696 1.48301 11.2188 1.36115C11.1681 1.23929 11.0937 1.12869 10.9999 1.03573Z" fill="#181818" />
                                        </svg>




                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: "100%",
                                        background: "white",
                                        padding: "1rem"
                                    }}
                                >


                                    <>
                                        <div className="listItem">
                                            <div>Amount of days:</div>
                                            <div>{eachPerscribe?.numofdays}</div>


                                        </div>
                                        <div className="listItem">
                                            <div>Starts on:</div>
                                            <div>{eachPerscribe?.startingday}</div>


                                        </div>


                                        <div className="listItem"

                                            style={{
                                                lineHeight: "25px"
                                            }}

                                        >
                                            {eachPerscribe?.description}
                                        </div>
                                        {
                                            eachPerscribe?.plandetails?.map((item, z) => {
                                                return (
                                                    <>
                                                        <div className="questionsection">
                                                            <div className="questionsectionName">{item?.day}</div>
                                                            {
                                                                item?.dayplan?.map((such, p) => {
                                                                    return (
                                                                        <>

                                                                            <div className="questionsectBox">
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        justifyContent: "space-between"

                                                                                    }}
                                                                                    onClick={() => {
                                                                                        // setdplandropdown(true);
                                                                                        // setdietid(such?._id)
                                                                                    }}
                                                                                >
                                                                                    <div>{such?.Dtime}</div>
                                                                                    <div>{such?.Dunitamount ? such?.Dunitamount : such?.unitamount} {such?.Dunitname ? such?.Dunitname : such?.unitname} {such?.DkitchenItemName ? such?.DkitchenItemName : such?.kitchenItemName}

                                                                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="7" height="5" viewBox="0 0 7 5" fill="none">
                                                                                        <path d="M3.69846 4.91959L6.90683 1.84362C6.96612 1.7867 7 1.70688 7 1.62328V0.301496C7 0.0386509 6.69624 -0.0978492 6.50968 0.0811595L3.5 2.96891L0.490321 0.0811595C0.303761 -0.0978492 -4.76837e-07 0.0386509 -4.76837e-07 0.301496V1.62328C-4.76837e-07 1.70688 0.0336499 1.7867 0.0931659 1.84362L3.30154 4.91959C3.41347 5.0268 3.58653 5.0268 3.69846 4.91959Z" fill="url(#paint0_linear_145_4817)" />
                                                                                        <defs>
                                                                                            <linearGradient id="paint0_linear_145_4817" x1="3.50001" y1="-2.63166e-05" x2="3.50001" y2="4.99999" gradientUnits="userSpaceOnUse">
                                                                                                <stop stop-color="#0B2447" />
                                                                                                <stop offset="0.311" stop-color="#0D264C" />
                                                                                                <stop offset="0.6768" stop-color="#122E5A" />
                                                                                                <stop offset="1" stop-color="#010101" />
                                                                                            </linearGradient>
                                                                                        </defs>
                                                                                    </svg> */}
                                                                                    </div>
                                                                                </div>


                                                                            </div>

                                                                        </>
                                                                    )
                                                                })
                                                            }

                                                        </div>

                                                    </>
                                                )
                                            })
                                        }
                                    </>



                                </div>

                            </div>
                        ))}

                        {/* deit */}



                    </>
                </div>
                <div className="flex-box-label">
                    {/* <div className="add">Push To Almari</div> */}
                    <div className="submit"
                        style={{
                            width: "100 %"

                        }}
                        onClick={savdmediciance}
                    >Submit</div>
                </div>
            </div>
        </div>
    )
}



const MyDatePicker = ({ index, setstoretrement, storetrement }) => {
    const datepickerRef2 = useRef(null);
    console.log("llkl", storetrement)

    useEffect(() => {
        if (datepickerRef2?.current) {
            // alert(index)
            const options = {
                dateFormat: "m/d/Y",
                // Other options here

                onChange: function (selectedDates, dateStr, instance) {

                    const result = storetrement.map((group, z) => {
                        console.log("czxczxczxc", group)
                        if (z == index) {
                            return {
                                ...group,

                                duplicateDate: dateStr,

                            };
                        }
                        return group;
                    })

                    setstoretrement(result)







                    console.log("Selected start date:", index);
                    // setappointdate(dateStr)

                },
            };


            flatpickr(datepickerRef2.current, options);
        }
    }, [datepickerRef2.current, index, storetrement]);
    return <input
        className="inputpicker"
        style={{
            width: "100%",
            border: "0px",
            height: "100%",
            fontSize: "13px",
            color: "#5F6163"

        }}
        key={index}
        type="text"
        placeholder="Select date"
        ref={datepickerRef2}
    //value={dates[index] || ''}
    />
};