import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./Catories.module.scss";
import '../../assets/Scsss/Global.scss'
// import './catoriesSummarypage.scss'
//assets
import { ReactComponent as FilterIcon } from "../../assets/images/filter.svg"; import Drawer from 'react-modern-drawer'

import {
    CopyBtn,
    CustomCheckbox, MarginEachCustomTextarea, TimeMRsNestEachCustomDropdown,
    EachCustomDropdown,
    EachCustomImageUpload, MRsNestEachCustomDropdown,
    EachCustomImageUploadcustom,
    EachCustomSelectMultiple, EachCustomSelectMultiples,
    EachInputCustom, EachInputsepfic, MRsEachCustomDropdown,
    EachCustomTextarea, EachCustomSelectMultiplelist,
    NestedMarignEachInput, TimeNestedMarignEachInput,
    OwnEachCustomDropdown,
    EachInput, MarignEachInput,
} from "../../components/Custom";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5";
import { ReactComponent as SortArrows } from "../../assets/images/icons/sort.svg";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../../context/Globalcontext";
import { useNetworkRequest } from "../../hooks/networkRequest";
import { deleteData, getData } from "../../assets/functions/apiCalls";
import axios from "axios";
import { toastify } from "../../assets/functions/toastify";
import { filterDetails } from "../../assets/functions";
import { APP_CODE } from "../../assets/data/constant";
import Editingpage from '../EditingPage/EditingPage'
const Organization = () => {
    const {
        email,
        actionBtns,
        setActionBtns,
        globalSearchQuery,
        dropDowndownvalue, editingToggle, seteditingToggle
    } = useContext(GlobalContext);
    const [checkBoxStage, setCheckBoxStage] = useState("");
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [catoriesSummary, setcatoriesSummary] = useState([]);
    const [kichenItem, setkichenItem] = useState({
        temperature: "",
        name: "",
        description: "",
        website: "",
        coverPhoto: "",
        food: []
    });


    useEffect(() => {
        tablefucntion();
    }, [dropDowndownvalue]);
    const [locationdata, setlocationdata] = useState({});
    useEffect(() => {
        locationdropdown();
    }, []);
    const [deitplan, setdeitplan] = useState({
        numofdays: "",
        startingday: "",
        startingdayIndex: "",
        name: "",
        des: "",

    })

    console.log("32342342fdsfvfdv", deitplan);
    const [loactionget, setloactionget] = useState([])
    const tablefucntion = () => {
        axios
            // .get(`https://uatamma.apimachine.com/api/getwidget`)
            .get(
                `https://uatamma.apimachine.com/api/getdietplan?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then((res) => {
                //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
                setcatoriesSummary(res?.data?.data);
            });
    };
    const locationdropdown = () => {
        axios
            // .get(`https://uatamma.apimachine.com/api/getwidget`)
            .get(
                `https://uatamma.apimachine.com/api/getlocation?teamUsername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then((res) => {
                if (res?.data?.success) {
                    setloactionget(res?.data?.data);

                }
                else {
                    setloactionget([]);
                }
                //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))

            });
    };

    const [player, setplayer] = useState([]);
    function toCardinal(num) {
        var ones = num % 10;
        var tens = num % 100;

        if (tens < 11 || tens > 13) {
            switch (ones) {
                case 1:
                    return num + "st";
                case 2:
                    return num + "nd";
                case 3:
                    return num + "rd";
            }
        }

        return num + "th";
    }
    function ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }
    const monthNames = [
        "",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
    ];

    console.log("777777", kichenItem);
    const [active, setactive] = useState("Pre Assessment Form");

    const [page, setpage] = useState("main");
    const [getsesion, setgetsesion] = useState({});
    // useEffect(() => {
    //     setpage("second")
    // }, [])

    // const getclientid = (e) => {

    //     axios
    //         .get(`https://uatamma.apimachine.com/api/getconsultcatoriesSummarys?consultationcatoriesSummaryid=${e}`)
    //         .then((res) => {
    //             //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))

    //             setgetsesion(res?.data?.data?.[0] || {})
    //             setpage("second")

    //         });
    // }

    const [formName, setFormName] = useState("");
    const [questions, setQuestions] = useState([
        {
            name: "",
            answerType: "",
            isMandatory: null,
        },
    ]);
    const [isOpen, setIsOpen] = useState(null);
    const [formErr, setFormErr] = useState(null);
    const [allSpecialist, setAllSpecialist] = useState([]);
    const [selectedSpecialist, setSelectedSpecialist] = useState(null);
    const [kitchenItem, setkitchenItem] = useState([])
    useEffect(() => {
        kitchenDrown()
    }, [])

    const kitchenDrown = () => {
        axios
            // .get(`https://uatamma.apimachine.com/api/getwidget`)
            .get(
                `https://uatamma.apimachine.com/api/getkitchenitem?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then((res) => {
                //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
                setkitchenItem(res?.data?.data);
            });
    };

    const handleSubmit = () => {
        let pop = arraystruture?.map(item => {
            return {
                "day": item?.days,
                "dayplan":

                    item?.dayplan?.map(such => {
                        return {
                            "kitchenitemid": such?.kitchenitemid,
                            "unitname": such?.unitname,
                            "unitamount": such?.unitamount,
                            "time": such?.time + such?.timeshulde?.toLowerCase(),

                        }
                    })




            }
        })
        console.log("mmmmm", pop)
        // kitchenitemName
        // : 
        // ""
        // kitchenitemid
        // : 
        // ""
        // time
        // : 
        // "442"
        // timeshulde
        // : 
        // "AM"
        // unitamount
        // : 
        // ""
        // unitname
        // : 
        // ""
        // axios



        axios.post(`https://uatamma.apimachine.com/api/adddietplan`, {



            "teamusername": JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername,
            "name": deitplan?.name,
            "description": deitplan?.des,
            "numofdays": deitplan?.numofdays,
            "startingday": deitplan?.startingday,
            "plandetails": [
                ...pop
            ]

        }).then(({ data }) => {
            if (data.status) {
                setCheckBoxStage("");
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
                setFormErr(null);
            } else {
                setFormErr(data.message);
            }
        });
    };

    const localclikc = () => {
        let addlocal = localStorage?.getItem("drawershow")

        localStorage?.setItem("drawershow", Number(addlocal + 1))
    }
    let loc = window.location.pathname
    let finallo = loc?.split('/')
    console.log("456546456", finallo[2])
    const [arraystruture, setarraystruture] = useState([])



    const getLoopedDays = (day, numDays) => {
        var daysOfWeek = [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
        ];
        const dayIndex = daysOfWeek.indexOf(day);
        if (dayIndex === -1 || numDays <= 0) return [];

        const result = [];
        for (let i = 0; i < numDays; i++) {
            result.push(daysOfWeek[(dayIndex + i) % daysOfWeek.length]);
        }
        return result;
    };

    const getNextThreeDays = (day) => {

        var daysOfWeek = [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
        ];
        let numberdays = Number(deitplan?.startingdayIndex) + Number(deitplan?.numofdays)

        const dayIndex = daysOfWeek.indexOf(day);
        // const dayIndex = dayIndexs
        console.log("5555555", dayIndex)
        if (dayIndex === -1) return [];

        const result = [];
        for (let i = 0; i < Number(deitplan?.numofdays); i++) {
            result.push(daysOfWeek[(dayIndex + i) % daysOfWeek.length]);
        }
        //  return result;
        console.log("1231231231111", result)
        let arraystruture1 = result?.map(item => {

            return {
                "days": item,
                "dayplan": [
                    {
                        "kitchenitemid": "",
                        "unitname": "",
                        "unitamount": "",
                        "kitchenitemName": "", "time": "",
                        "timeshulde": "AM",
                    }

                ]
            }
        })

        setarraystruture(arraystruture1)

    };



    function day() {
        // getLoopedDays()
        getNextThreeDays(deitplan?.startingday)

        //   console.log("numberdays", numberdays)
        // let i = 0
        // var day = new Date();
        // var week = new Array(
        //     "Monday",
        //     "Tuesday",
        //     "Wednesday",
        //     "Thursday",
        //     "Friday",
        //     "Saturday",
        //     "Sunday",
        // );
        // let pop = []
        // for (i = deitplan?.startingdayIndex; i < numberdays; i++) {
        //     pop = [...pop, week[(day.getDay() + 1 + i) % 7]]




        // }
        //  console.log("lol", pop)


    }
    const history = useNavigate()
    useEffect(() => {

        // const [deitplan, setdeitplan] = useState({
        //     numofdays: "",
        //     startingday: "",
        //     startingdayIndex: "",
        // })
        if (deitplan?.numofdays != "" && deitplan?.startingday != "") {
            day()
        }

    }, [deitplan])

    const addmore = (p) => {

        // setprogramitem({ ...programitem, theripyIds: [...programitem?.theripyIds, { id: "", name: "" }] });
        //e.stopPropagation();
        // dayplan: [...arraystruture?.[0]?.dayplan, { name: "" }] }
        // setarraystruture([{ ...arraystruture[0], dayplan: [...arraystruture?.[0]?.dayplan, { kitchenitemid: "3" }] }])

        let pop = arraystruture?.map((item, i) => {
            if (i == p) {
                return {
                    ...item, dayplan: [...item.dayplan, {
                        "kitchenitemid": "",
                        "unitname": "",
                        "unitamount": "",
                        "kitchenitemName": "",
                        "time": "",
                        "timeshulde": "AM",
                    }]
                }
            }
            return item
        })

        // setarraystruture({ ...arraystruture?.[0], dayplan: [...arraystruture?.dayplan, { kitchenitemid: "3" }] });
        // }
        setarraystruture(pop)
        console.log("pop", pop)

    }


    console.log("day.getDay()", arraystruture);

    return (
        <>
            {
                editingToggle ?
                    <Editingpage />
                    :


                    <div className="global-dashboard">
                        <div className="label-box-header" onClick={localclikc}>
                            Diet Templates
                        </div>
                        <div className="top-section-golbal">
                            <div className="left-side">


                                <div className="label-box">
                                    <svg
                                        style={{
                                            marginRight: "10px"
                                        }}
                                        xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                        <path d="M12.8445 8.07626L10.9486 6.58668C10.8262 6.4908 10.6594 6.47293 10.5202 6.54118C10.3804 6.60888 10.2916 6.7508 10.2916 6.90626V7.90292C8.75704 8.16184 7.58325 9.49651 7.58325 11.1042V12.4583C7.58325 12.7573 7.82592 13 8.12492 13C8.42392 13 8.66658 12.7573 8.66658 12.4583V11.1042C8.66658 10.0978 9.35992 9.25655 10.2916 9.01442V9.88542C10.2916 10.0409 10.3804 10.1828 10.5202 10.2505C10.5765 10.2781 10.6372 10.2917 10.6978 10.2917C10.7872 10.2917 10.876 10.2624 10.9486 10.205L12.8445 8.71542C12.943 8.63851 12.9999 8.52042 12.9999 8.39584C12.9999 8.27126 12.943 8.15317 12.8445 8.07626Z" fill="#010101" />
                                        <path d="M9.74999 0H1.625C0.731249 0 0 0.731249 0 1.625V8.66666C0 9.5604 0.731249 10.2917 1.625 10.2917H6.58124C6.65166 9.91249 6.77082 9.54957 6.93874 9.20832H1.625C1.32708 9.20832 1.08333 8.96457 1.08333 8.66666V2.70833H10.2917V5.47624C10.6492 5.37333 11.0392 5.41124 11.375 5.57916V1.625C11.375 0.731249 10.6437 0 9.74999 0V0Z" fill="#010101" />
                                    </svg>

                                    Export

                                </div>



                                <>

                                    <div className="label-box">
                                        <svg
                                            style={{
                                                marginRight: "10px"
                                            }}
                                            xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                            <path d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z" fill="#010101" />
                                        </svg>
                                        Add Filter
                                    </div></>


                            </div>


                            <div className="right-side">
                                <div className="shortBy">Sort By</div>
                                <div className="dropdownlist">
                                    Newest

                                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="6" viewBox="0 0 7 6" fill="none">
                                        <path d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z" fill="#010101" />
                                    </svg>

                                </div>
                                <div className="addLabel"
                                    onClick={() => setCheckBoxStage("add")}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                        <path d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z" fill="white" />
                                    </svg>
                                    Add New
                                </div>
                                <Drawer
                                    size={450}

                                    open={checkBoxStage}
                                    onClose={() => setCheckBoxStage("")}
                                    direction='right'
                                    className='bla bla bla'
                                    overlayColor="#FFF"
                                    overlayOpacity={0}
                                >
                                    {checkBoxStage == "add" && (
                                        <div
                                            className="drowpside-right"

                                        >
                                            <div
                                                style={{
                                                    marginTop: "2rem",
                                                    fontSize: "23px",
                                                    marginBottom: "2rem",
                                                    fontWeight: "700"
                                                }}
                                            >
                                                Add new diet template
                                            </div>



                                            <div
                                                style={{
                                                    marginTop: "2rem",
                                                    fontSize: "0.9rem",
                                                    marginBottom: "0.8rem",
                                                    fontWeight: "700"
                                                }}
                                            >Basic Information</div>
                                            <div
                                                style={{

                                                    marginBottom: "1rem"
                                                }}
                                            >

                                                <MarignEachInput
                                                    title="What is the name of the room?"
                                                    name="name"
                                                    placeholder="Name..."
                                                    stateValue={deitplan}
                                                    setState={setdeitplan}

                                                />


                                            </div>

                                            <div
                                                style={{

                                                    marginBottom: "0.8rem"
                                                }}
                                            >
                                                <MarginEachCustomTextarea
                                                    title="Describe the room"
                                                    name="des"
                                                    placeholder="Description..."
                                                    stateValue={deitplan}
                                                    setState={setdeitplan}
                                                // topTitle="true"
                                                />

                                            </div>

                                            <div
                                                style={{

                                                    marginBottom: "1rem"
                                                }}
                                            >

                                                <MarignEachInput
                                                    title="What is the name of the room?"
                                                    name="numofdays"
                                                    placeholder="NumberOfDays..."
                                                    stateValue={deitplan}
                                                    setState={setdeitplan}

                                                />


                                            </div>





                                            <div
                                                style={{
                                                    marginBottom: "0.8rem"
                                                }}
                                            >
                                                <MRsEachCustomDropdown
                                                    dropdown={["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]}
                                                    name="startingday"
                                                    title=""
                                                    stateValue={deitplan}
                                                    setState={setdeitplan}
                                                //topTitle="true"
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    marginBottom: "0.8rem"
                                                }}
                                            >
                                                {
                                                    arraystruture?.map((item, i) => {
                                                        return (
                                                            <div>
                                                                <div
                                                                    style={{
                                                                        marginTop: "2rem",
                                                                        fontSize: "0.9rem",
                                                                        marginBottom: "0.8rem",
                                                                        fontWeight: "700"
                                                                    }}
                                                                >Day {i + 1}: {item?.days}</div>
                                                                {
                                                                    item?.dayplan?.map((such, z) => {
                                                                        return (
                                                                            <>
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        marginBottom: "1rem"
                                                                                    }}
                                                                                >

                                                                                    <TimeNestedMarignEachInput
                                                                                        key={z}
                                                                                        title="What is the name of the room?"
                                                                                        name={"time"}
                                                                                        outerindex={i}
                                                                                        innerindex={z}
                                                                                        placeholder="Time"
                                                                                        stateValue={arraystruture}
                                                                                        setState={setarraystruture}

                                                                                    />


                                                                                    <TimeMRsNestEachCustomDropdown
                                                                                        dropdown={[
                                                                                            "AM",
                                                                                            "PM"
                                                                                            // "Mrs",
                                                                                            // "Ms",
                                                                                        ]}
                                                                                        name="dayplan"
                                                                                        title="Employment typekl"
                                                                                        stateValue={arraystruture}
                                                                                        setState={setarraystruture}
                                                                                        // topTitle="true"
                                                                                        type="single"
                                                                                        outerindex={i}
                                                                                        innerindex={z}
                                                                                    />

                                                                                </div>
                                                                                <div
                                                                                    style={{

                                                                                        marginBottom: "1rem"
                                                                                    }}
                                                                                >

                                                                                    <NestedMarignEachInput
                                                                                        key={z}
                                                                                        title="What is the name of the room?"
                                                                                        name={"unitname"}
                                                                                        outerindex={i}
                                                                                        innerindex={z}
                                                                                        placeholder="Unit name"
                                                                                        stateValue={arraystruture}
                                                                                        setState={setarraystruture}

                                                                                    /></div>
                                                                                <div
                                                                                    style={{

                                                                                        marginBottom: "1rem"
                                                                                    }}
                                                                                >
                                                                                    <NestedMarignEachInput
                                                                                        key={z}
                                                                                        title="What is the name of the room?"
                                                                                        name={"unitamount"}
                                                                                        outerindex={i}
                                                                                        innerindex={z}
                                                                                        placeholder="Unit amount"
                                                                                        stateValue={arraystruture}
                                                                                        setState={setarraystruture}

                                                                                    />
                                                                                </div>

                                                                                <div
                                                                                    style={{
                                                                                        marginBottom: "0.8rem"
                                                                                    }}
                                                                                >
                                                                                    <MRsNestEachCustomDropdown
                                                                                        dropdown={kitchenItem}
                                                                                        name="dayplan"
                                                                                        title=""
                                                                                        stateValue={arraystruture}
                                                                                        setState={setarraystruture}
                                                                                        outerindex={i}
                                                                                        innerindex={z}
                                                                                    //topTitle="true"
                                                                                    />
                                                                                </div>


                                                                            </>
                                                                        )
                                                                    })
                                                                }

                                                                <div
                                                                    style={{
                                                                        fontFamily: "Montserrat Alternates",
                                                                        fontSize: "14px",
                                                                        fontWeight: "400",
                                                                        lineHeight: "24px",
                                                                        textAlign: "left",
                                                                        textDecoration: "underline",
                                                                        marginBottom: "2.2rem"
                                                                    }}

                                                                    onClick={() => addmore(i)}
                                                                >Add another item</div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>














                                            <button
                                                className={classNames.submitBtn
                                                }
                                                onClick={handleSubmit}
                                            >
                                                Submit
                                            </button>
                                            {formErr && <h4 style={{ color: "red" }}>{formErr}</h4>}
                                        </div>
                                    )}
                                </Drawer>
                            </div>
                        </div >


                        <div className="handle-table">
                            <div className="g-table-sec">
                                <div className="table-section">
                                    <table style={{ minWidth: "100%" }}>
                                        <thead>
                                            <tr
                                                style={{ gridTemplateColumns: "min-content repeat(4, 1fr)" }}
                                            >

                                                <th>
                                                    Name
                                                </th>
                                                <th>
                                                    Description

                                                </th>
                                                <th>
                                                    # of days

                                                </th>
                                                <th>
                                                    Starting day

                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {catoriesSummary?.map((row, index) => {
                                                let date = row?.createdAt?.split("-");
                                                let finial = row?.createdAt?.split("-")?.[2]?.split("T");
                                                let resultstart = row?.startingday?.split('-')
                                                return (
                                                    <tr
                                                        key={row?.brokerage_name + index}
                                                        style={{
                                                            gridTemplateColumns: "min-content repeat(4, 1fr)",
                                                            pointerEvents: sidebarOpen ? "none" : "",
                                                            cursor: "pointer",
                                                        }}

                                                        onClick={() => history(`/owner/kitchen/dietTemplates/${row?.dietplanid}`)}
                                                    >

                                                        <td>


                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}
                                                            >

                                                                <div>
                                                                    <div>
                                                                        {row?.name}
                                                                        {/* <CopyBtn copyText={row?.name} /> */}
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>

                                                            {row?.description}

                                                        </td>

                                                        <td

                                                        >



                                                            {row?.numofdays}




                                                        </td>


                                                        <td>
                                                            {
                                                                row?.startingday
                                                            }

                                                            {/* {monthNames[resultstart[0]?.replace(/^0+/, '')]} {toCardinal(resultstart[1])} {resultstart[2]} */}




                                                        </td>





                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div >
            }
        </>
    );

};

export default Organization;
