import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./settings.module.scss";
import './Editing.scss'
//assets
import axios from "axios";
import Skeleton from "react-loading-skeleton";

import editIcon from "../../../assets/images/icons/edit.svg";
import { GlobalContext } from "../../../context/Globalcontext";
import { toastify } from "../../../assets/functions/toastify";
import { uploadFileFunc } from "../../../assets/functions";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import montageLogo from "../../../assets/images/montage.svg";
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
// import SettingDrawer from './SettingDrawer'
import flatpickr from "flatpickr";
const EdidingPage = ({ staffitem }) => {
  const [selectedItem, setSelectedItem] = useState("Patient");
  const location = useLocation();

  const menuItems = [
    "Patient",
    "Owner",
    "Specialists",
    "Admins",
    "Non-Admin Staff",
    "Super Admin",
  ];


  return (
    <div className={classNames.settings}>
      {/* <div className={classNames.leftMenu}>
        <div className={classNames.heading}>Roles</div>
        <div className={classNames.menuItems}>
          {menuItems?.length > 0 &&
            menuItems?.map((eachItem, index) => {
              return (
                <div
                  className={
                    selectedItem === eachItem ? classNames.selectedItem : ""
                  }
                  key={eachItem + index}
                  onClick={() => {
                    setSelectedItem(eachItem);
                  }}
                >
                  {eachItem}
                </div>
              );
            })}
        </div>
      </div> */}

      <div className={classNames.rightDashboard}>
        <Profile
          staffitem={staffitem}
        />
        {/* {location?.pathname?.includes("/settings/profiles") ? (
          <Profile />
        ) : location?.pathname?.includes("/settings/owner") ? (

          selectedItem === "CreateNewOrganization" ?
            <CreateNewOrganization setSelectedItem={setSelectedItem} />
            :
            <Owner setSelectedItem={setSelectedItem}

            />
        ) : (
          ""
        )} */}
      </div>
    </div>
  );
};

export default EdidingPage;


const Profile = ({ staffitem }) => {
  const location = useLocation();
  const { email, setProfileUsername, seteditingToggle } = useContext(GlobalContext);
  const [profileData, setProfileData] = useState("");
  const [profileDataLoading, setProfileDataLoading] = useState(true);

  const [dropdownItems, setDropdownItems] = useState([
    { NameOfTeam: "Vitality", Icon: montageLogo },
  ]);
  const [appDropdown, setAppDropdown] = useState("");


  const [list, setlist] = useState("profile")

  useEffect(() => {
    setlist("profile")

  }, []);
  const { id } = useParams()
  const history = useNavigate()
  const [sepcialdata, setsepcialdata] = useState({})

  useEffect(() => {
    hetconstultant()
  }, [id])
  const changeStatusfucntion = () => {

    axios.delete(`https://uatamma.apimachine.com/api/deleteprescription/${sepcialdata?._id}`).then(({ data }) => {

      history("/owner/pharmacy/prescriptions")
      setTimeout(() => {
        window.location.reload();
      }, 2000);


      // toastify(data?.message);


    })



  }
  const hetconstultant = () => {

    axios
      .get(`https://uatamma.apimachine.com/api/getprescription?prescriptionid=${id}&teamUsername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`)
      .then((res) => {
        if (res.data.success) {
          setsepcialdata(res?.data?.data?.[0])
          calldata(res?.data?.data?.[0])
          // setconstitid(res?.data?.data?.[0]?.consultationserviceid)
          // getservice(res?.data?.data?.[0]?.consultationserviceid)
        }
        else {
          setsepcialdata([])
        }
        //
        console.log("cxczczxc", res?.data?.data?.[0])


      });
  }
  const [dasd, setdasd] = useState([])

  const array1 = [
    { id: 1, name: 'John', age: 25 },
    { id: 2, name: 'Jane', age: 30 },
    { id: 3, name: 'Doe', age: 35 }
  ];

  const array2 = [
    { id: 2, occupation: 'Doctor' },
    { id: 3, occupation: 'Engineer' },
    { id: 4, occupation: 'Artist' }
  ];


  useEffect(() => {
    const combinedArray = sepcialdata?.theraphyids?.map(obj1 => {
      const matchedObj = sepcialdata?.theraphydetails?.find(obj2 => obj2.theraphyId === obj1.theraphyId);
      return { ...obj1, ...matchedObj };
    });

  }, [sepcialdata])
  // Combine arrays based on id



  const calldata = (e) => {
    let array1 = e?.theraphyids
    let array2 = e?.theraphydetails
    // console.log("vvcxzc", e)
    const combinedArray = e?.theraphyids.map(obj1 => {
      const correspondingObj = e?.theraphydetails.find(obj2 => obj2.theraphyId == obj1.theraphyid);
      // If there is a corresponding object in array2, combine them
      console.log("zzxczxc", obj1, correspondingObj)
      if (correspondingObj) {
        return { ...obj1, ...correspondingObj };
      }


    });
    setdasd(combinedArray)
    // 

  }
  console.log("vvcxzc", dasd)




  function toCardinal(num) {
    var ones = num % 10;
    var tens = num % 100;

    if (tens < 11 || tens > 13) {
      switch (ones) {
        case 1:
          return num + "st";
        case 2:
          return num + "nd";
        case 3:
          return num + "rd";
      }
    }

    return num + "th";
  }
  const [seletitem, setseletitem] = useState('')
  var monthNames = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
  let resultstart = profileData?.birthday?.split('/')
  const [actiondata, setactiondata] = useState([]);

  useEffect(() => {
    // tablefucntion()
  }, [])

  // const tablefucntion = () => {
  //   axios

  //     .get(
  //       `https://uatamma.apimachine.com/api/getTeamPlayerProfile?role=${itemsend?._id}`
  //     )
  //     .then((res) => {

  //       if (res.data.success) {
  //         setactiondata(res?.data?.data);
  //       }
  //       else {
  //         setactiondata([]);
  //       }


  //     });
  // };

  console.log("sepcialdata", sepcialdata)
  const handdletable = () => {
    switch (list) {
      // case "editingitem":
      //   return (
      //     <>

      //       {editsection()}
      //     </>
      //   )
      //   break;


      case "Edit":
        return (
          <div className="history-page">
            <div className="text">Which attribute do you want to edit?</div>
            <div className="historytable"
              onClick={() => {
                setlist("editingitem")
                setseletitem("name")
              }
              }
            >
              <div>Name</div>

            </div>
            <div className="historytable"

              onClick={() => {
                setlist("editingitem")
                setseletitem("des")
              }
              }

            >
              <div>Description</div>

            </div>
            {/* <div className="historytable"
              onClick={() => {
                setlist("editingitem")
                setseletitem("image")
              }
              }

            >
              <div>Icon</div>

            </div> */}

            <div className="historytable"
              onClick={() => setlist("actions")}
            >
              <div>Go back</div>

            </div>


          </div>
        )
      case "delete":
        return (
          <div className="history-page">
            <div
              style={{
                marginBottom: "1rem",
                fontWeight: "600"
              }}
            >Are you sure you want to delete?</div>
            <div className="historytable"
              onClick={changeStatusfucntion}
            >
              <div>Yes</div>

            </div>
            <div className="historytable"
              onClick={() => setlist("actions")}
            >
              <div>Go back</div>

            </div>


          </div>
        )

      // case "removeStaff":
      //   return (
      //     <div className="history-page">
      //       <div className="text">Which staff member do you want to remove?</div>
      //       {
      //         staffpeople?.map(item => {
      //           console.log("zvzcxvzxcvxcv",item)
      //           return (
      //             <div className="historytable"
      //               onClick={() => removestafffunction(item?._id)}
      //             // onClick={() => {
      //             //   setlist("editingitem")
      //             //   setseletitem("name")
      //             // }
      //             // }
      //             >
      //               <div>{item?.name}</div>

      //             </div>
      //           )
      //         })
      //       }


      //       {/* <div className="historytable"
      //         onClick={() => {
      //           setlist("editingitem")
      //           setseletitem("image")
      //         }
      //         }

      //       >
      //         <div>Icon</div>

      //       </div> */}

      //       <div className="bottom-label">
      //         <div className="goback"
      //           onClick={() => setlist("Edit")}
      //         >Go back</div>

      //       </div>


      //     </div>
      //   )
      //   break;

      case "actions":
        return (
          <div className="history-page">
            {/* <div className="historytable"
              onClick={() => setlist("Edit")}
            >
              <div>Edit profile</div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <circle cx="12" cy="12" r="11.5" fill="white" stroke="#E9EBF3" />
                  <path d="M15.3003 12.2472L11.24 8.2138C11.1649 8.13927 11.0595 8.09668 10.9492 8.09668H9.20443C8.85748 8.09668 8.6773 8.47855 8.91359 8.71308L12.7254 12.4967L8.91359 16.2803C8.6773 16.5148 8.85748 16.8967 9.20443 16.8967H10.9492C11.0595 16.8967 11.1649 16.8544 11.24 16.7796L15.3003 12.7462C15.4418 12.6055 15.4418 12.3879 15.3003 12.2472Z" fill="url(#paint0_linear_1988_1212)" />
                  <defs>
                    <linearGradient id="paint0_linear_1988_1212" x1="8.80642" y1="12.4967" x2="15.4064" y2="12.4967" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#0B2447" />
                      <stop offset="0.311" stop-color="#0D264C" />
                      <stop offset="0.6768" stop-color="#122E5A" />
                      <stop offset="1" stop-color="#010101" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
            <div className="historytable">
              <div>Add staff group</div>
              <div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <circle cx="12" cy="12" r="11.5" fill="white" stroke="#E9EBF3" />
                <path d="M15.3003 12.2472L11.24 8.2138C11.1649 8.13927 11.0595 8.09668 10.9492 8.09668H9.20443C8.85748 8.09668 8.6773 8.47855 8.91359 8.71308L12.7254 12.4967L8.91359 16.2803C8.6773 16.5148 8.85748 16.8967 9.20443 16.8967H10.9492C11.0595 16.8967 11.1649 16.8544 11.24 16.7796L15.3003 12.7462C15.4418 12.6055 15.4418 12.3879 15.3003 12.2472Z" fill="url(#paint0_linear_1988_1212)" />
                <defs>
                  <linearGradient id="paint0_linear_1988_1212" x1="8.80642" y1="12.4967" x2="15.4064" y2="12.4967" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0B2447" />
                    <stop offset="0.311" stop-color="#0D264C" />
                    <stop offset="0.6768" stop-color="#122E5A" />
                    <stop offset="1" stop-color="#010101" />
                  </linearGradient>
                </defs>
              </svg></div>
            </div>

            <div className="historytable"



            >
              <div>Remove staff group</div>
              <div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <circle cx="12" cy="12" r="11.5" fill="white" stroke="#E9EBF3" />
                <path d="M15.3003 12.2472L11.24 8.2138C11.1649 8.13927 11.0595 8.09668 10.9492 8.09668H9.20443C8.85748 8.09668 8.6773 8.47855 8.91359 8.71308L12.7254 12.4967L8.91359 16.2803C8.6773 16.5148 8.85748 16.8967 9.20443 16.8967H10.9492C11.0595 16.8967 11.1649 16.8544 11.24 16.7796L15.3003 12.7462C15.4418 12.6055 15.4418 12.3879 15.3003 12.2472Z" fill="url(#paint0_linear_1988_1212)" />
                <defs>
                  <linearGradient id="paint0_linear_1988_1212" x1="8.80642" y1="12.4967" x2="15.4064" y2="12.4967" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0B2447" />
                    <stop offset="0.311" stop-color="#0D264C" />
                    <stop offset="0.6768" stop-color="#122E5A" />
                    <stop offset="1" stop-color="#010101" />
                  </linearGradient>
                </defs>
              </svg></div>
            </div>
            <div className="historytable"
            //onClick={() => setlist("delete")}
            >
              <div>Add staff</div>
              <div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <circle cx="12" cy="12" r="11.5" fill="white" stroke="#E9EBF3" />
                <path d="M15.3003 12.2472L11.24 8.2138C11.1649 8.13927 11.0595 8.09668 10.9492 8.09668H9.20443C8.85748 8.09668 8.6773 8.47855 8.91359 8.71308L12.7254 12.4967L8.91359 16.2803C8.6773 16.5148 8.85748 16.8967 9.20443 16.8967H10.9492C11.0595 16.8967 11.1649 16.8544 11.24 16.7796L15.3003 12.7462C15.4418 12.6055 15.4418 12.3879 15.3003 12.2472Z" fill="url(#paint0_linear_1988_1212)" />
                <defs>
                  <linearGradient id="paint0_linear_1988_1212" x1="8.80642" y1="12.4967" x2="15.4064" y2="12.4967" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0B2447" />
                    <stop offset="0.311" stop-color="#0D264C" />
                    <stop offset="0.6768" stop-color="#122E5A" />
                    <stop offset="1" stop-color="#010101" />
                  </linearGradient>
                </defs>
              </svg></div>
            </div>
            <div className="historytable"
              onClick={() => {
                setlist("removeStaff")

                // setseletitem("removeStaff")
              }
              }
            //onClick={() => setlist("delete")}
            >
              <div>Remove staff</div>
              <div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <circle cx="12" cy="12" r="11.5" fill="white" stroke="#E9EBF3" />
                <path d="M15.3003 12.2472L11.24 8.2138C11.1649 8.13927 11.0595 8.09668 10.9492 8.09668H9.20443C8.85748 8.09668 8.6773 8.47855 8.91359 8.71308L12.7254 12.4967L8.91359 16.2803C8.6773 16.5148 8.85748 16.8967 9.20443 16.8967H10.9492C11.0595 16.8967 11.1649 16.8544 11.24 16.7796L15.3003 12.7462C15.4418 12.6055 15.4418 12.3879 15.3003 12.2472Z" fill="url(#paint0_linear_1988_1212)" />
                <defs>
                  <linearGradient id="paint0_linear_1988_1212" x1="8.80642" y1="12.4967" x2="15.4064" y2="12.4967" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0B2447" />
                    <stop offset="0.311" stop-color="#0D264C" />
                    <stop offset="0.6768" stop-color="#122E5A" />
                    <stop offset="1" stop-color="#010101" />
                  </linearGradient>
                </defs>
              </svg></div>
            </div> */}

            <div className="historytable"
              onClick={() => setlist("delete")}
            >
              <div>Delete profile</div>
              <div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <circle cx="12" cy="12" r="11.5" fill="white" stroke="#E9EBF3" />
                <path d="M15.3003 12.2472L11.24 8.2138C11.1649 8.13927 11.0595 8.09668 10.9492 8.09668H9.20443C8.85748 8.09668 8.6773 8.47855 8.91359 8.71308L12.7254 12.4967L8.91359 16.2803C8.6773 16.5148 8.85748 16.8967 9.20443 16.8967H10.9492C11.0595 16.8967 11.1649 16.8544 11.24 16.7796L15.3003 12.7462C15.4418 12.6055 15.4418 12.3879 15.3003 12.2472Z" fill="url(#paint0_linear_1988_1212)" />
                <defs>
                  <linearGradient id="paint0_linear_1988_1212" x1="8.80642" y1="12.4967" x2="15.4064" y2="12.4967" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0B2447" />
                    <stop offset="0.311" stop-color="#0D264C" />
                    <stop offset="0.6768" stop-color="#122E5A" />
                    <stop offset="1" stop-color="#010101" />
                  </linearGradient>
                </defs>
              </svg></div>
            </div>
          </div>
        )
      case "history":
        // let result = itemsend?.createdAt?.split('T')
        // let daysinfo = result?.[0]?.split('-')
        // let timeinfo = result?.[1]?.split('.')

        // let result1 = itemsend?.updatedAt?.split('T')
        // let daysinfo1 = result1?.[0]?.split('-')
        // let timeinfo1 = result1?.[1]?.split('.')
        return (
          <>
            <div className="history-page">
              <div className="historytable">
                <div>Created at</div>
                {/* <div>
                  {monthNames[daysinfo?.[1]?.replace(/^0+/, '')]} {toCardinal(daysinfo?.[2])} {daysinfo?.[0]} at {timeinfo?.[0]} IST
                </div> */}
              </div>
              {/* <div className="historytable">
                <div>Last updated at</div>
                <div>{monthNames[daysinfo1?.[1]?.replace(/^0+/, '')]} {toCardinal(daysinfo1?.[2])} {daysinfo1?.[0]} at {timeinfo1?.[0]} IST</div>
              </div> */}
            </div>
          </>
        )
      case "staffgroup":
        return (
          <>

            <div className="action-page">
              <div className="table-tab">
                {
                  sepcialdata?.roleDetails?.map(item => {

                    return (
                      item?.staffDetails?.map(such => {
                        return (
                          <div className="cardlist">
                            <div className="name">{such?.groupName?.substring(0, 1)}</div>
                            <div className="title">{such?.groupName}</div>
                          </div>
                        )
                      })


                    )
                  })
                }

              </div>
            </div>

          </>
        )
      case "module":
        return (
          <>

            <div className="action-page">
              <div className="table-tab">
                {
                  sepcialdata?.roleDetails?.[0]?.staffDetails?.[0]?.modules?.map(item => {
                    return (
                      <div className="cardlist">
                        <div className="name">{item?.substring(0, 1)}</div>
                        <div className="title">{item}</div>
                      </div>

                    )
                  })
                }

              </div>
            </div>

          </>
        )
      case "Medicine":
        return (
          <>
            {
              sepcialdata?.items?.map((item, index) => {
                return (
                  <>
                    <div
                      style={{
                        fontFamily: "Montserrat Alternates",
                        fontSize: "15px",
                        fontWeight: "600",

                        marginTop: "2rem"
                      }}
                    >Item {index + 1}</div>
                    <div className={classNames.profileDetails}
                      style={{
                        background: "#F5F5F5",
                        padding: "0rem 1.5rem",
                        borderRadius: "10px",
                        marginTop: "1rem",
                        paddingBottom: "2.3em"

                      }}
                    >


                      <div className={classNames.topContainer}
                        style={{
                          gridTemplateColumns: "1fr"
                        }}
                      >

                        <div className={classNames.valuesDiv}>
                          <div className={classNames.eachProfileItem}>
                            <div className={classNames.title}>Name</div>
                            <div className={classNames.inputValue}>
                              <EditContainer
                                value={item?.name}
                                type="white"
                              />
                            </div>
                          </div>
                          <div className={classNames.eachProfileItem}>
                            <div className={classNames.title}>Dosage</div>
                            <div className={classNames.inputValue}>
                              <EditContainer
                                value={item?.dosage}
                                type="white"
                              />
                            </div>
                          </div>


                        </div>
                      </div>

                      {/*  */}
                      <div className={classNames.topContainer}
                        style={{
                          gridTemplateColumns: "1fr 1fr"
                        }}
                      >

                        <div className={classNames.eachProfileItem}>
                          <div className={classNames.title}>Frequency</div>
                          <div className={classNames.inputValue}>
                            <EditContainer
                              value={item?.frequency}
                              type="white"
                            />
                          </div>



                        </div>


                        <div className={classNames.eachProfileItem}>
                          <div className={classNames.title}>Repetitions</div>
                          <div className={classNames.inputValue}>
                            <EditContainer
                              value={item?.lengthOfTime}
                              type="white"
                            />
                          </div>




                        </div>


                      </div>



                      {/*  */}


                    </div >
                  </>
                )
              })
            }

          </>
        )
      case "diet":
        return (
          <>



            <div className={classNames.perscribeEditior}>



              <div className={classNames.eachOption}
                // 

                style={{
                  width: "48%",
                  // height: expandable && samevalue == eachPerscribe?._id && "auto",
                  display: "block"
                }}
              >

                <div className={classNames.listItem}
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    marginBottom: "2.5rem"

                  }}
                >
                  {sepcialdata?.dietplandetails?.name}
                </div>
                <div className={classNames.listItem}>
                  <div>Amount of days:</div>
                  <div>{sepcialdata?.dietplandetails?.numofdays}</div>


                </div>
                {/* <div className={classNames.listItem}>
                  <div>Starts on:</div>
                  <div>{sepcialdata?.dietplandetails?.startingday}</div>


                </div> */}


                <div className={classNames.listItem}

                  style={{
                    lineHeight: "25px"
                  }}

                >
                  {sepcialdata?.dietplandetails?.description}
                </div>
              </div>
              <div className={classNames.eachOption}
                // 

                style={{
                  width: "48%",
                  // height: expandable && samevalue == eachPerscribe?._id && "auto",
                  display: "block"
                }}
              >
                <>

                  {
                    sepcialdata?.dietplandetails?.plandetails?.[0]?.map(item => {
                      console.log("7777", item)
                      return (
                        <>
                          <div className={classNames.questionsection}>
                            <div className={classNames.questionsectionName}>{item?.day}</div>
                            {
                              item?.dayplan?.map(such => {

                                return (
                                  <>

                                    <div className={classNames.questionsectBox}>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between"

                                        }}
                                      // onClick={() => {
                                      //   setdplandropdown(true);
                                      //   setdietid(such?._id)
                                      // }}
                                      >
                                        <div>{such?.time}</div>
                                        <div>{such?.unitamount} {such?.unitname} {such?.kitchenItemName}

                                          {/* <svg xmlns="http://www.w3.org/2000/svg" width="7" height="5" viewBox="0 0 7 5" fill="none">
                                            <path d="M3.69846 4.91959L6.90683 1.84362C6.96612 1.7867 7 1.70688 7 1.62328V0.301496C7 0.0386509 6.69624 -0.0978492 6.50968 0.0811595L3.5 2.96891L0.490321 0.0811595C0.303761 -0.0978492 -4.76837e-07 0.0386509 -4.76837e-07 0.301496V1.62328C-4.76837e-07 1.70688 0.0336499 1.7867 0.0931659 1.84362L3.30154 4.91959C3.41347 5.0268 3.58653 5.0268 3.69846 4.91959Z" fill="url(#paint0_linear_145_4817)" />
                                            <defs>
                                              <linearGradient id="paint0_linear_145_4817" x1="3.50001" y1="-2.63166e-05" x2="3.50001" y2="4.99999" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#0B2447" />
                                                <stop offset="0.311" stop-color="#0D264C" />
                                                <stop offset="0.6768" stop-color="#122E5A" />
                                                <stop offset="1" stop-color="#010101" />
                                              </linearGradient>
                                            </defs>
                                          </svg> */}
                                        </div>
                                      </div>

                                      <>
                                        {/* <div className="deitpaldroplist">
                                          <div className="inner">
                                            <div className="name">New Quantity:</div>
                                            <input placeholder="0.00" />
                                          </div>
                                        </div>
                                        <div className="deitpaldroplist">
                                          <div className="inner">
                                            <div className="name">New Time:</div>
                                            <input placeholder="0.00" />
                                          </div>
                                        </div> */}
                                      </>


                                    </div>

                                  </>
                                )
                              })
                            }

                          </div>

                        </>
                      )
                    })
                  }
                </>







              </div>
            </div>
          </>

        )

      case "lifestyle":
        return (
          <>
            {
              sepcialdata?.lifestyleList?.map((item, index) => {
                return (
                  <>
                    <div
                      style={{
                        fontFamily: "Montserrat Alternates",
                        fontSize: "15px",
                        fontWeight: "600",

                        marginTop: "2rem"
                      }}
                    >Life style advice  {index + 1}</div>
                    <div className={classNames.profileDetails}
                      style={{
                        background: "#F5F5F5",
                        padding: "0rem 1.5rem",
                        borderRadius: "10px",
                        marginTop: "1rem",
                        paddingBottom: "2.3em"

                      }}
                    >


                      <div className={classNames.topContainer}
                        style={{
                          gridTemplateColumns: "1fr"
                        }}
                      >

                        <div className={classNames.valuesDiv}>
                          <div className={classNames.eachProfileItem}>
                            <div className={classNames.title}>Name</div>
                            <div className={classNames.inputValue}>
                              <EditContainer
                                value={item?.name}
                                type="white"
                              />
                            </div>
                          </div>
                          <div className={classNames.eachProfileItem}>
                            <div className={classNames.title}>Description</div>
                            <div className={classNames.inputValue}>
                              <EditContainer
                                value={item?.description}
                                type="white"
                              />
                            </div>
                          </div>


                        </div>
                      </div>

                      {/*  */}




                      {/*  */}


                    </div >
                  </>
                )
              })
            }

          </>
        )
      case "Investigations":
        return (
          <>
            {
              sepcialdata?.investigationList?.map((item, index) => {
                return (
                  <>
                    <div
                      style={{
                        fontFamily: "Montserrat Alternates",
                        fontSize: "15px",
                        fontWeight: "600",

                        marginTop: "2rem"
                      }}
                    >Investigation {index + 1}</div>
                    <div className={classNames.profileDetails}
                      style={{
                        background: "#F5F5F5",
                        padding: "0rem 1.5rem",
                        borderRadius: "10px",
                        marginTop: "1rem",
                        paddingBottom: "2.3em"

                      }}
                    >


                      <div className={classNames.topContainer}
                        style={{
                          gridTemplateColumns: "1fr"
                        }}
                      >

                        <div className={classNames.valuesDiv}>
                          <div className={classNames.eachProfileItem}>
                            <div className={classNames.title}>Name</div>
                            <div className={classNames.inputValue}>
                              <EditContainer
                                value={item?.name}
                                type="white"
                              />
                            </div>
                          </div>
                          <div className={classNames.eachProfileItem}>
                            <div className={classNames.title}>Lab</div>
                            <div className={classNames.inputValue}>
                              <EditContainer
                                value={item?.lab}
                                type="white"
                              />
                            </div>
                          </div>


                        </div>
                      </div>

                      {/*  */}
                      <div className={classNames.topContainer}
                        style={{
                          gridTemplateColumns: "1fr 1fr"
                        }}
                      >

                        <div className={classNames.eachProfileItem}>
                          <div className={classNames.title}>Description</div>
                          <div className={classNames.inputValue}>
                            <EditContainer
                              value={item?.description}
                              type="white"
                            />
                          </div>




                        </div>





                      </div>



                      {/*  */}


                    </div >
                  </>
                )
              })
            }

          </>
        )
      case "Treatments":
        return (
          <>

            {
              dasd?.map((item, index) => {
                return (
                  <>
                    <div
                      style={{
                        fontFamily: "Montserrat Alternates",
                        fontSize: "15px",
                        fontWeight: "600",

                        marginTop: "2rem"
                      }}
                    >Therapy {index + 1}</div>
                    <div className={classNames.profileDetails}
                      style={{
                        background: "#F5F5F5",
                        padding: "0rem 1.5rem",
                        borderRadius: "10px",
                        marginTop: "1rem",
                        paddingBottom: "2.3em"

                      }}
                    >


                      <div className={classNames.topContainer}
                        style={{
                          gridTemplateColumns: "1fr"
                        }}
                      >

                        <div className={classNames.valuesDiv}>
                          <div className={classNames.eachProfileItem}>
                            <div className={classNames.title}>Name</div>
                            <div className={classNames.inputValue}>
                              <EditContainer
                                value={item?.name}
                                type="white"
                              />
                            </div>
                          </div>
                          <div className={classNames.eachProfileItem}>
                            <div className={classNames.title}>Details</div>
                            <div className={classNames.inputValue}>
                              <EditContainer
                                value={item?.details}
                                type="white"
                              />
                            </div>
                          </div>


                        </div>
                      </div>

                      {/*  */}



                      {/*  */}


                    </div >
                  </>
                )
              })
            }

          </>
        )
      case "products":
        return (
          <>
            <div className="action-page">
              <div className="table-tab">
                {
                  sepcialdata?.photos?.map(item => {
                    return (
                      <div className="cardlist">
                        <img src={item}
                          style={{
                            width: "100%",
                            height: "14rem",
                            borderRadius: "10px"
                          }}
                        />
                        {/* <div className="name">{item?.name?.substring(0, 1)}</div>
                        <div className="title">{item?.name}</div> */}
                      </div>
                    )
                  })
                }

              </div>
            </div>

          </>
        )
      case "profile":
        let date = sepcialdata?.createdAt?.split("-");
        let finial = sepcialdata?.createdAt?.split("-")?.[2]?.split("T");
        return (
          <div className={classNames.profileDetails}>
            <div className={classNames.topContainer}
              style={{
                gridTemplateColumns: "1fr"
              }}
            >

              <div className={classNames.valuesDiv}>
                <div className={classNames.eachProfileItem}>
                  <div className={classNames.title}>
                    Patient
                    {/* What is the name of the item? */}
                  </div>
                  <div className={classNames.inputValue}>
                    <EditContainer
                      value={sepcialdata?.clientdetails?.[0]?.name}
                    />
                  </div>
                </div>
                <div className={classNames.eachProfileItem}>
                  <div className={classNames.title}>Date</div>
                  <div className={classNames.inputValue}>
                    <EditContainer
                      value={`${monthNames?.[1]} ${finial?.[0]} ${date?.[0]}`}
                    />
                  </div>
                </div>


              </div>
            </div>

            {/*  */}
            <div className={classNames.topContainer}
              style={{
                gridTemplateColumns: "1fr 1fr"
              }}
            >

              <div className={classNames.eachProfileItem}>
                <div className={classNames.title}>Specialist</div>
                <div className={classNames.inputValue}>
                  <EditContainer
                    value={sepcialdata?.sessiondetails?.[0]?.specialistdata?.[0]?.TeamDetails?.[0]?.SpecialistDetails?.[0]?.name}
                  />
                </div>



              </div>


              <div className={classNames.eachProfileItem}>
                <div className={classNames.title}>Notes</div>
                <div className={classNames.inputValue}>
                  <EditContainer
                    value={sepcialdata?.notes}
                  />
                </div>




              </div>

              <div className={classNames.eachProfileItem}>
                <div className={classNames.title}>Session</div>
                <div className={classNames.inputValue}>
                  <EditContainer
                    value={sepcialdata?.consultationsessionid}
                  />
                </div>




              </div>


            </div>



            {/*  */}
            <div className={classNames.bottomProfile}>






            </div>

          </div>
        )
        break;

      default:
        break;
    }
  }

  return (
    <>


      <div className={classNames.profileParent}>
        <div className="profileTop">
          <div className={classNames.profileName}
            style={
              {
                paddingBottom: "0"
              }
            }
          >
            <svg
              onClick={() => history('/owner/pharmacy/prescriptions')}
              className="svg"
              xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
              <circle cx="16.5" cy="16.5" r="16" fill="white" stroke="#E9EBF3" />
              <path d="M10.4497 16.8516L16.171 22.535C16.2768 22.64 16.4253 22.7 16.5808 22.7H19.0393C19.5282 22.7 19.7821 22.1619 19.4491 21.8314L14.0779 16.5L19.4491 11.1686C19.7821 10.8381 19.5282 10.3 19.0393 10.3H16.5808C16.4253 10.3 16.2768 10.3596 16.171 10.465L10.4497 16.1484C10.2502 16.3467 10.2502 16.6533 10.4497 16.8516Z" fill="url(#paint0_linear_1918_382)" />
              <defs>
                <linearGradient id="paint0_linear_1918_382" x1="19.6001" y1="16.5" x2="10.3001" y2="16.5" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#0B2447" />
                  <stop offset="0.311" stop-color="#0D264C" />
                  <stop offset="0.6768" stop-color="#122E5A" />
                  <stop offset="1" stop-color="#010101" />
                </linearGradient>
              </defs>
            </svg>
            {/* <img src={itemsend?.icon} alt={profileData?.ProfilePic} /> */}
            <div>
              <span>

                {sepcialdata?.clientdetails?.[0]?.name}

              </span>
              <div
                style={{
                  fontSize: "15px",
                  fontWeight: "400"
                }}
              >{sepcialdata?.prescriptionid}</div>
            </div>

            {/* <div style={{ marginLeft: "auto" }}>
          <EachCustomDropdown
            dropdown={dropdownItems}
            name="selectedApp"
            stateValue={appDropdown}
            setState={setAppDropdown}
          />
        </div> */}
          </div>

          <div className="deletedEditingButton"
            onClick={() => setlist("actions")}

          >
            <svg
              style={{
                marginRight: "5px"
              }}
              xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15" fill="none">
              <path d="M16.3462 1.30769H7.19231C7.19231 0.5865 6.60581 0 5.88462 0H4.57692C3.85573 0 3.26923 0.5865 3.26923 1.30769H0.653846C0.292923 1.30769 0 1.59996 0 1.96154C0 2.32312 0.292923 2.61538 0.653846 2.61538H3.26923C3.26923 3.33658 3.85573 3.92308 4.57692 3.92308H5.88462C6.60581 3.92308 7.19231 3.33658 7.19231 2.61538H16.3462C16.7071 2.61538 17 2.32312 17 1.96154C17 1.59996 16.7071 1.30769 16.3462 1.30769ZM4.57692 2.61538V1.30769H5.88462L5.88527 1.95892C5.88527 1.96023 5.88462 1.96088 5.88462 1.96154C5.88462 1.96219 5.88527 1.96285 5.88527 1.96415V2.61538H4.57692Z" fill="white" />
              <path d="M16.3462 6.53841H12.4231C12.4231 5.81721 11.8366 5.23071 11.1154 5.23071H9.80769C9.0865 5.23071 8.5 5.81721 8.5 6.53841H0.653846C0.292923 6.53841 0 6.83067 0 7.19225C0 7.55383 0.292923 7.8461 0.653846 7.8461H8.5C8.5 8.56729 9.0865 9.15379 9.80769 9.15379H11.1154C11.8366 9.15379 12.4231 8.56729 12.4231 7.8461H16.3462C16.7071 7.8461 17 7.55383 17 7.19225C17 6.83067 16.7071 6.53841 16.3462 6.53841ZM9.80769 7.8461V6.53841H11.1154L11.116 7.18964C11.116 7.19094 11.1154 7.1916 11.1154 7.19225C11.1154 7.19291 11.116 7.19356 11.116 7.19487V7.8461H9.80769Z" fill="white" />
              <path d="M16.3462 11.7691H7.19231C7.19231 11.0479 6.60581 10.4614 5.88462 10.4614H4.57692C3.85573 10.4614 3.26923 11.0479 3.26923 11.7691H0.653846C0.292923 11.7691 0 12.0614 0 12.423C0 12.7845 0.292923 13.0768 0.653846 13.0768H3.26923C3.26923 13.798 3.85573 14.3845 4.57692 14.3845H5.88462C6.60581 14.3845 7.19231 13.798 7.19231 13.0768H16.3462C16.7071 13.0768 17 12.7845 17 12.423C17 12.0614 16.7071 11.7691 16.3462 11.7691ZM4.57692 13.0768V11.7691H5.88462L5.88527 12.4203C5.88527 12.4217 5.88462 12.4223 5.88462 12.423C5.88462 12.4236 5.88527 12.4243 5.88527 12.4256V13.0768H4.57692Z" fill="white" />
            </svg>
            Actions
          </div>
        </div>
        <div className="profiletab">
          <div className="items"
            onClick={() => setlist("profile")}
            style={{
              background: list == "profile" && "#3f506a"
            }}
          >
            Profile
          </div>
          <div className="items"
            style={{
              background: list == "Medicine" && "#3f506a"
            }}
            onClick={() => setlist("Medicine")}
          >
            Medicine
          </div>



          <div className="items"
            style={{
              background: list == "Treatments" && "#3f506a"
            }}
            onClick={() => setlist("Treatments")}

          >
            Treatments
          </div>
          <div className="items"

            style={{
              background: list == "Investigations" && "#3f506a"
            }}
            onClick={() => setlist("Investigations")}
          >
            Investigations
          </div>
          <div className="items"

            style={{
              background: list == "diet" && "#3f506a"
            }}
            onClick={() => setlist("diet")}

          >
            Diet Plans
          </div>
          <div className="items"

            style={{
              background: list == "lifestyle" && "#3f506a"
            }}
            onClick={() => setlist("lifestyle")}

          >
            Lifestyle Advice
          </div>
        </div>
        {handdletable()}
      </div>

    </>
  );
};



const EditContainer = ({ value, type, name, clickable }) => {
  const { email } = useContext(GlobalContext);
  const [editOpen, setEditOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [uploadFileRFQ, setUploadFileRFQ] = useState("");
  const [uploadFileRFQName, setUploadFileRFQName] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);
  const editContainerRef = useRef(null);
  const inputRef = useRef(null);
  const fileInputRef = useRef(null);
  const datepickerRef1 = useRef(null);
  console.log("inputValue", name)
  useEffect(() => {
    // Function to close the edit state when clicking outside the component
    const handleClickOutside = (event) => {
      if (
        editContainerRef.current &&
        !editContainerRef.current.contains(event.target)
      ) {
        setEditOpen(false);
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editContainerRef]);

  useEffect(() => {
    if (editOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editOpen]);

  const handleEditIconClick = () => {
    setEditOpen(true);

    // Trigger click on the file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    if (datepickerRef1?.current) {

      const options = {
        dateFormat: "m/d/Y",
        // Other options here
        onChange: function (selectedDates, dateStr, instance) {
          console.log("Selected start date:", dateStr, datepickerRef1?.current);
          // setbrithday(dateStr)
          setInputValue(dateStr)

        },
      };


      flatpickr(datepickerRef1.current, options);
    }
  }, [datepickerRef1?.current]);

  function updateProfileData() {
    axios
      .put("https://uatamma.apimachine.com/api/edituserdetails", {
        email,
        [name]: name == "governmentId" && name == "ProfilePic" ? uploadFileRFQ : inputValue

      })
      .then((response) => {
        // console.log("Response from update profile", response);
        if (response?.data?.success) {
          toastify(`Updated ${name} successfully!`, "success");
        } else {
          toastify(`Failed to update ${name}!`, "error");
        }
        setEditOpen(false);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 500);
      })
      .catch((error) => {
        console.log("Profile update error", error?.message);
        toastify(`Profile update error ${name}!`, "error");
        setEditOpen(false);
      });
  }

  return (
    <div ref={editContainerRef}>
      {type === "image" ? (
        <div className={classNames.imageBox}>
          {uploadFileRFQ ? (
            <img src={uploadFileRFQ} alt="uploadFileRFQfile" />
          ) : (
            value
          )}
        </div>
      ) : editOpen ? (


        name == "YearOfBirth" ?

          <input
            type="text"
            id="datepicker1"
            ref={datepickerRef1}
            className={classNames.inputBox}
            placeholder="Select birthday"
            value={inputValue}

          />

          :
          <input
            placeholder="Enter.."
            className={classNames.inputBox}
            ref={inputRef}
            value={inputValue}
            onChange={(event) => setInputValue(event?.target?.value)}
          />




      ) : (
        <div className={classNames.valueBox}
          style={{
            height: type == "des" && "6rem",
            background: type == "white" && "#fff"
          }}
        >
          {value === "qwe" ? (
            <Skeleton width={150} height={40} />
          ) : value ? (
            value
          ) : (
            ""
          )}
        </div>
      )}
      <div className={classNames.editContainer}>
        {
          clickable ?
            <div
              className={classNames.editIcon}
              onClick={async () => {
                if (type === "image") {

                  handleEditIconClick();
                } else {
                  setEditOpen(true);
                }
              }}
              style={{
                display: editOpen ? "none" : "",
                pointerEvents: clickable ? "" : "none",
              }}
            >
              <img src={editIcon} alt="editIcon" />
            </div>
            :
            ""

        }

        <div
          className={classNames.buttonsContainer}
          style={{ display: editOpen ? "" : "none" }}
        >
          <button
            onClick={() => {
              setEditOpen(false);
            }}
          >
            Close
          </button>
          <button >Save</button>
        </div>

        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          style={{ display: "none" }}
          onChange={async (e) => {
            let resFile = uploadFileFunc(
              e,
              setUploadFileRFQName,
              setUpdateLoading,
              "name"
            );
            console.log(await resFile, "res file link await");
            setUploadFileRFQ(await resFile);
          }}
          ref={fileInputRef}
        />
      </div>
    </div>
  );
};
