import "./custom.scss";
import classNames from "./custom.module.scss";
import { toastify } from "../../assets/functions/toastify";
import { ReactComponent as CopyIcon } from "../../assets/images/icons/copy.svg";
import { GlobalContext } from "../../context/Globalcontext";
import { useContext, useEffect, useRef, useState } from "react";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import montageLogo from "../../assets/images/montage.svg";
import uploadIcon from "../../assets/images/icons/upload.svg";
import { areAllFieldsFilled, uploadFileFunc } from "../../assets/functions";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/themes/material_orange.css";

import { IoIosEye, IoIosEyeOff } from "react-icons/io";

export const CustomCheckbox = ({
  actionBtns,
  setActionBtns,
  id,
  name,
  type,
}) => {
  const { setOverlayDeleteItemName } = useContext(GlobalContext);

  const handleCheckboxChange = () => {
    // console.log(id, " selected id");
    // if (type == "single") {
    //   setActionBtns([id]);
    // } else if (Array.isArray(actionBtns) && actionBtns?.includes(id)) {
    //   // If the ID is already in the array, remove it
    //   setActionBtns(actionBtns.filter((item) => item !== id));
    // } else {
    //   // If the ID is not in the array, add it
    //   setActionBtns([...actionBtns, id]);
    // }

    // console.log(actionBtns, "checkingg");

    if (actionBtns?.includes(id)) {
      setActionBtns([]);
    } else {
      setActionBtns([id]);
    }

    setOverlayDeleteItemName(name);
  };

  // Check if the current checkbox's ID is present in the actionBtns array
  const isChecked = actionBtns?.includes(id);

  return (
    <label className="custom-checkbox">
      <input
        type="checkbox"
        id="myCheckbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <span className={`checkmark ${isChecked ? "checked" : ""}`}>
        {isChecked && (
          // <TiTick />
          <div className="checkedBox"></div>
        )}
      </span>
    </label>
  );
};

export const CopyBtn = ({ copyText }) => {
  return (
    <CopyIcon
      style={{
        width: "12px",
        height: "12px",
        cursor: "pointer",
        marginLeft: "7.5px",
        fontFamily: "Montserrat Alternates",
      }}
      onClick={() => {
        navigator.clipboard.writeText(copyText);
        toastify("Copied", "success");
      }}
      className="copy_icon"
    />
  );
};
export const OwnEachCustomDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  topTitle,
  type,
  stateVal,
  mapVal,
  indexx,
  calendarObjField,
  eachValuee,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };
  // console.log("000", stateValue?.constultandid)

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        // minWidth: isOpen ? "250px" : "",
        zIndex: isOpen ? "3" : "",
        height: topTitle ? "5rem" : "3rem",
      }}
    >
      {topTitle && <div className={classNames.topTitle}>{title}</div>}
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: topTitle ? "2rem" : "",
          maxHeight: isOpen ? "400px" : "100%",
          borderBottom: name === "selectedAppStaff" && !isOpen ? "none" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {name === "selectedAppStaff" && stateValue?.Icon ? (
              <img
                src={stateValue?.Icon}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : name === "selectedAppStaff" ? (
              <img
                src={montageLogo}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : (
              name === "selectedApp" && (
                <img
                  src={montageLogo}
                  alt={stateValue?.NameOfTeam}
                  style={{ height: "1.25rem" }}
                />
              )
            )}
            Select an Option
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            {dropdown?.map((each, index) => {
              return (
                <li
                  onClick={(event) => {
                    event.stopPropagation();
                    // selectOption(
                    //   eachitem[mapVal?.name] +
                    //   " (" +
                    //   eachitem[mapVal?.name1] +
                    //   ")"
                    // );
                    setState({
                      ...stateValue,
                      // constultandid: [

                      // ],
                      constultandName: [
                        ...stateValue?.constultandName,
                        {
                          name: each?.name,
                          id: each?.consultationtypeid,
                        },
                      ],
                    });
                    setIsOpen(false);
                  }}
                  key={index}
                >
                  <img src={each?.icon} />
                  {each?.name}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export const MultiDropdownNested = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  topTitle,
  type,
  stateVal,
  mapVal,
  indexx,
  calendarObjField,
  eachValuee,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };
  // console.log("000", stateValue?.constultandid)
  const updateFieldChanged = (index, e) => {
    // let newArr = { ...stateValue }
    // const newItems = [newArr[index].locationsold];
    // newItems[index]?.id = 'Updated Text';
    // newData[index] = { ...newData[index], locationsold: newItems };
    // console.log("newArr", newItems[index])
  };
  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        zIndex: isOpen ? "3" : "",
        height: topTitle && indexx == 0 ? "5.25rem" : "3rem",
        marginTop: indexx > 0 && "0.5rem",
      }}
    >
      {indexx == 0 && topTitle && (
        <div className={classNames.topTitle}>{title}</div>
      )}

      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: topTitle && indexx == 0 ? "2rem" : "0rem",
          maxHeight: isOpen ? "400px" : "100%",
          borderBottom: name === "selectedAppStaff" && !isOpen ? "none" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {name === "selectedAppStaff" && stateValue?.Icon ? (
              <img
                src={stateValue?.Icon}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : name === "selectedAppStaff" ? (
              <img
                src={montageLogo}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : (
              name === "selectedApp" && (
                <img
                  src={montageLogo}
                  alt={stateValue?.NameOfTeam}
                  style={{ height: "1.25rem" }}
                />
              )
            )}

            {stateValue?.[name]?.[indexx]?.name == ""
              ? "Select an Option"
              : stateValue?.[name]?.[indexx]?.name}
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            {dropdown?.map((item, index) => {
              return item?.TeamDetails?.map((such) => {
                return such?.PlayerDetails?.map((each) => {
                  return (
                    <li
                      onClick={(event) => {
                        event.stopPropagation();

                        setState((stateValue) => {
                          const newData = { ...stateValue }; // Shallow copy of the outer array

                          const newItems = [...newData?.[name]];
                          newItems[indexx].id = item?.specialistid;
                          newItems[indexx].name = each?.name;
                          console.log("czxczxc", newItems); // Shallow copy of the inner array

                          //  // Update the text of the clicked element
                          // newData[indexx] = { ...newData[indexx], locationsold: newItems }; // Update the outer array
                          return newData; // Return the updated array
                        });

                        setIsOpen(false);
                      }}
                      key={index}
                    >
                      {/* <img src={each?.icon} /> */}
                      {each?.name}
                    </li>
                  );
                });
              });
              // const updatedAnnualHolidays = [...stateValue[name]];
              // updatedAnnualHolidays[indexx] = {
              //   ...updatedAnnualHolidays[indexx],
              //   [calendarObjField]: eachitem,
              // };

              // return {
              //   ...stateValue,
              //   [name]:
              //     (calendarObjField === "date" ||
              //       calendarObjField === "month") &&
              //       areAllFieldsFilled(updatedAnnualHolidays[indexx])
              //       ? [
              //         ...updatedAnnualHolidays,
              //         { month: "", date: "" },
              //       ]
              //       : updatedAnnualHolidays,
            })}
          </ul>
        )}
      </div>
    </div>
  );
};
export const ServiceMultiDropdownNestedService = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  topTitle,
  type,
  stateVal,
  mapVal,
  indexx,
  calendarObjField,
  eachValuee,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };
  // console.log("000", stateValue?.constultandid)
  const updateFieldChanged = (index, e) => {
    // let newArr = { ...stateValue }
    // const newItems = [newArr[index].locationsold];
    // newItems[index]?.id = 'Updated Text';
    // newData[index] = { ...newData[index], locationsold: newItems };
    // console.log("newArr", newItems[index])
  };

  // const addmorespicalid = (e) => {
  //   e.stopPropagation();
  //   if (sepcialdata.filter(function (cv) {
  //     return !staffdata?.specialistIds.find(function (e) {
  //       return e.id == cv.consultationserviceid;
  //     })
  //   })?.length > 0) {
  //     if (staffdata?.specialistIds[staffdata?.specialistIds?.length - 1]?.name != "") {
  //       setstaffdata({ ...staffdata, specialistIds: [...staffdata?.specialistIds, { id: "", name: "" }] });
  //       // }
  //       return

  //     }
  //   }


  // }


  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        zIndex: isOpen ? "3" : "",
        height: topTitle && indexx == 0 ? "5.25rem" : "3rem",
        marginTop: indexx > 0 && "0.5rem",
      }}
    >
      {indexx == 0 && topTitle && (
        <div className={classNames.topTitle}>{title}</div>
      )}

      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: topTitle && indexx == 0 ? "2rem" : "0rem",
          maxHeight: isOpen ? "400px" : "100%",
          borderBottom: name === "selectedAppStaff" && !isOpen ? "none" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {name === "selectedAppStaff" && stateValue?.Icon ? (
              <img
                src={stateValue?.Icon}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : name === "selectedAppStaff" ? (
              <img
                src={montageLogo}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : (
              name === "selectedApp" && (
                <img
                  src={montageLogo}
                  alt={stateValue?.NameOfTeam}
                  style={{ height: "1.25rem" }}
                />
              )
            )}

            {stateValue?.[name]?.[indexx]?.name == ""
              ? "Add services"
              : stateValue?.[name]?.[indexx]?.name}
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            {dropdown?.map((item, index) => {
              return (
                <li
                  onClick={(event) => {
                    event.stopPropagation();

                    setState((stateValue) => {
                      const newData = { ...stateValue }; // Shallow copy of the outer array

                      const newItems = [...newData?.[name]];
                      newItems[indexx].id = item?.consultationserviceid;
                      newItems[indexx].name = item?.name;
                      console.log("czxczxc", newItems); // Shallow copy of the inner array

                      //  // Update the text of the clicked element
                      // newData[indexx] = { ...newData[indexx], locationsold: newItems }; // Update the outer array
                      return newData; // Return the updated array
                    });

                    setIsOpen(false);
                  }}
                  key={index}
                >
                  {/* <img src={each?.icon} /> */}
                  {item?.name}
                </li>
                // const updatedAnnualHolidays = [...stateValue[name]];
                // updatedAnnualHolidays[indexx] = {
                //   ...updatedAnnualHolidays[indexx],
                //   [calendarObjField]: eachitem,
                // };

                // return {
                //   ...stateValue,
                //   [name]:
                //     (calendarObjField === "date" ||
                //       calendarObjField === "month") &&
                //       areAllFieldsFilled(updatedAnnualHolidays[indexx])
                //       ? [
                //         ...updatedAnnualHolidays,
                //         { month: "", date: "" },
                //       ]
                //       : updatedAnnualHolidays,
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};
export const MultiDropdownNestedService = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  topTitle,
  type,
  stateVal,
  mapVal,
  indexx,
  calendarObjField,
  eachValuee,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };
  // console.log("000", stateValue?.constultandid)
  const updateFieldChanged = (index, e) => {
    // let newArr = { ...stateValue }
    // const newItems = [newArr[index].locationsold];
    // newItems[index]?.id = 'Updated Text';
    // newData[index] = { ...newData[index], locationsold: newItems };
    // console.log("newArr", newItems[index])
  };
  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        zIndex: isOpen ? "3" : "",
        height: topTitle && indexx == 0 ? "5.25rem" : "3rem",
        marginTop: indexx > 0 && "0.5rem",
      }}
    >
      {indexx == 0 && topTitle && (
        <div className={classNames.topTitle}>{title}</div>
      )}

      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: topTitle && indexx == 0 ? "2rem" : "0rem",
          maxHeight: isOpen ? "400px" : "100%",
          borderBottom: name === "selectedAppStaff" && !isOpen ? "none" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {name === "selectedAppStaff" && stateValue?.Icon ? (
              <img
                src={stateValue?.Icon}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : name === "selectedAppStaff" ? (
              <img
                src={montageLogo}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : (
              name === "selectedApp" && (
                <img
                  src={montageLogo}
                  alt={stateValue?.NameOfTeam}
                  style={{ height: "1.25rem" }}
                />
              )
            )}

            {stateValue?.[name]?.[indexx]?.name == ""
              ? "Add services"
              : stateValue?.[name]?.[indexx]?.name}
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            {dropdown?.map((item, index) => {
              return (
                <li
                  onClick={(event) => {
                    event.stopPropagation();

                    setState((stateValue) => {
                      const newData = { ...stateValue }; // Shallow copy of the outer array

                      const newItems = [...newData?.[name]];
                      newItems[indexx].id = item?.consultationserviceid;
                      newItems[indexx].name = item?.name;
                      console.log("czxczxc", newItems); // Shallow copy of the inner array

                      //  // Update the text of the clicked element
                      // newData[indexx] = { ...newData[indexx], locationsold: newItems }; // Update the outer array
                      return newData; // Return the updated array
                    });

                    setIsOpen(false);
                  }}
                  key={index}
                >
                  {/* <img src={each?.icon} /> */}
                  {item?.name}
                </li>
                // const updatedAnnualHolidays = [...stateValue[name]];
                // updatedAnnualHolidays[indexx] = {
                //   ...updatedAnnualHolidays[indexx],
                //   [calendarObjField]: eachitem,
                // };

                // return {
                //   ...stateValue,
                //   [name]:
                //     (calendarObjField === "date" ||
                //       calendarObjField === "month") &&
                //       areAllFieldsFilled(updatedAnnualHolidays[indexx])
                //       ? [
                //         ...updatedAnnualHolidays,
                //         { month: "", date: "" },
                //       ]
                //       : updatedAnnualHolidays,
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export const MultiDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  topTitle,
  type,
  stateVal,
  mapVal,
  indexx,
  calendarObjField,
  eachValuee,
}) => {
  console.log("indexx", indexx);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };
  // console.log("000", stateValue?.constultandid)
  const updateFieldChanged = (index, e) => {
    // let newArr = { ...stateValue }
    // const newItems = [newArr[index].locationsold];
    // newItems[index]?.id = 'Updated Text';
    // newData[index] = { ...newData[index], locationsold: newItems };
    // console.log("newArr", newItems[index])
  };
  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        // minWidth: isOpen ? "250px" : "",
        zIndex: isOpen ? "3" : "",
        height: topTitle && indexx == 0 ? "5.25rem" : "3rem",
        marginTop: indexx > 0 && "0.5rem",
      }}
    >
      {indexx == 0 && topTitle && (
        <div className={classNames.topTitle}>{title}</div>
      )}

      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: topTitle && indexx == 0 ? "2rem" : "0rem",
          maxHeight: isOpen ? "400px" : "100%",
          borderBottom: name === "selectedAppStaff" && !isOpen ? "none" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {name === "selectedAppStaff" && stateValue?.Icon ? (
              <img
                src={stateValue?.Icon}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : name === "selectedAppStaff" ? (
              <img
                src={montageLogo}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : (
              name === "selectedApp" && (
                <img
                  src={montageLogo}
                  alt={stateValue?.NameOfTeam}
                  style={{ height: "1.25rem" }}
                />
              )
            )}

            {stateValue?.[name]?.[indexx]?.name == ""
              ? "Select an Option"
              : stateValue?.[name]?.[indexx]?.name}
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            {dropdown?.map((each, index) => {
              return (
                // const updatedAnnualHolidays = [...stateValue[name]];
                // updatedAnnualHolidays[indexx] = {
                //   ...updatedAnnualHolidays[indexx],
                //   [calendarObjField]: eachitem,
                // };

                // return {
                //   ...stateValue,
                //   [name]:
                //     (calendarObjField === "date" ||
                //       calendarObjField === "month") &&
                //       areAllFieldsFilled(updatedAnnualHolidays[indexx])
                //       ? [
                //         ...updatedAnnualHolidays,
                //         { month: "", date: "" },
                //       ]
                //       : updatedAnnualHolidays,
                <li
                  onClick={(event) => {
                    event.stopPropagation();
                    // selectOption(
                    //   eachitem[mapVal?.name] +
                    //   " (" +
                    //   eachitem[mapVal?.name1] +
                    //   ")"
                    // );
                    // updateFieldChanged(indexx, each?.name)

                    setState((stateValue) => {
                      const newData = { ...stateValue }; // Shallow copy of the outer array

                      const newItems = [...newData?.[name]];
                      newItems[indexx].id =
                        name == "theripyIds" ? each?.theraphyId : each?._id;
                      newItems[indexx].name = each?.name;
                      console.log("czxczxc", newItems); // Shallow copy of the inner array

                      //  // Update the text of the clicked element
                      // newData[indexx] = { ...newData[indexx], locationsold: newItems }; // Update the outer array
                      return newData; // Return the updated array
                    });

                    // setData(prevData => {

                    //   const newData = [...prevData]; // Shallow copy of the outer array
                    //   const newItems = [...newData[parentIndex].items]; // Shallow copy of the inner array
                    //   newItems[childIndex].text = 'Updated Text'; // Update the text of the clicked element
                    //   newData[parentIndex] = { ...newData[parentIndex], items: newItems }; // Update the outer array
                    //   return newData; // Return the updated array
                    // });

                    // setState({
                    //   ...stateValue,
                    //   [indexx]?.[locationsold] = each?.name,
                    // });
                    // console.log("lasdlasld", m)
                    // setState({

                    //   ...stateValue, locationsold: [...stateValue?.locationsold, { id: "3" }]

                    // });
                    setIsOpen(false);
                  }}
                  key={index}
                >
                  {/* <img src={each?.icon} /> */}
                  {each?.name}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export const EachCustomDropdownCountry = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  topTitle,
  type,
  stateVal,
  mapVal,
  indexx,
  calendarObjField,
  eachValuee,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  console.log("34555555555555", type);

  useEffect(() => {
    console.log(dropdown, "dropdowndropdowndropdowndropdown");
  }, [stateValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(true);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  useEffect(() => {
    setSearchQuery("");
    if (
      name === "selectedAppStaff" &&
      Array?.isArray(dropdown) &&
      dropdown?.length > 0
    ) {
      setState({
        NameOfTeam: dropdown[0]?.NameOfTeam,
        Icon: dropdown[0]?.Icon,
        TeamUsername: dropdown[0]?.TeamUsername,
      });
    }
  }, [dropdown]);

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        // minWidth: isOpen ? "250px" : "",
        zIndex: isOpen ? "3" : "",
        height: topTitle ? "5.25rem" : "3rem",
      }}
    >
      {topTitle && <div className={classNames.topTitle}>{title}</div>}
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: topTitle ? "2rem" : "",
          maxHeight: isOpen ? "400px" : "100%",
          borderBottom: name === "selectedAppStaff" && !isOpen ? "none" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {name === "selectedAppStaff" && stateValue?.Icon ? (
              <img
                src={stateValue?.Icon}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : name === "selectedAppStaff" ? (
              <img
                src={montageLogo}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : (
              name === "selectedApp" && (
                <img
                  src={montageLogo}
                  alt={stateValue?.NameOfTeam}
                  style={{ height: "1.25rem" }}
                />
              )
            )}

            {name === "locationName" ? (
              stateValue?.locationName == "" ? (
                "Click to select"
              ) : (
                stateValue?.locationName
              )
            ) : name === "constultandName" ? (
              stateValue?.constultandName == "" ? (
                "Select consultation type"
              ) : (
                stateValue?.constultandName
              )
            ) : title ===
              "Which staff member do you want to make a specialist?" ? (
              stateValue.spaceName == "" ? (
                "Click to select"
              ) : (
                stateValue.spaceName
              )
            ) : name === "specialistList" ? (
              !stateValue?.speicalistName ? (
                "Click to select"
              ) : (
                stateValue?.speicalistName
              )
            ) : (
              <>
                {name === "selectedAppStaff" && stateValue?.NameOfTeam
                  ? stateValue?.NameOfTeam
                  : stateValue?.NameOfTeam && !name === "IndustriesId"
                    ? stateValue?.NameOfTeam
                    : selectedOption
                      ? selectedOption
                      : name === "IndustriesId"
                        ? "Click to select industries"
                        : title
                          ? title
                          : name === "selectedAppStaff"
                            ? "Select an Option"
                            : dropdown[0]?.NameOfTeam}
              </>
            )}
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            {type === "obj" && dropdown?.length > 0 && (
              <>
                <input
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e?.target?.value)}
                  style={{
                    border: "none",
                    width: "100%",
                    height: "2.3rem",
                    margin: "8px 0",
                    paddingLeft: "1rem",

                  }}
                />
                {dropdown
                  ?.filter((temp) =>
                    temp?.name
                      ?.toLowerCase()
                      ?.includes(searchQuery?.toLowerCase())
                  )
                  ?.map((eachitem, index) => {
                    console.log("000", eachitem?.name);
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem[mapVal?.name]);
                          setState({
                            ...stateValue,
                            [name]: eachitem[stateVal],
                          });
                        }}
                        key={eachitem[mapVal?.name] + index}
                      >
                        {mapVal?.image && (
                          <img
                            src={eachitem[mapVal?.image]}
                            alt={eachitem[stateVal]}
                          />
                        )}

                        {eachitem[mapVal?.name]}
                      </li>
                    );
                  })}
              </>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export const EachCustomDropdownSearch = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  topTitle,
  type,
  stateVal,
  mapVal,
  indexx,
  calendarObjField,
  eachValuee,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  console.log("8888888888888", dropdown);

  useEffect(() => {
    console.log(stateValue, "ljwekgfhkwjegfkjwef");
  }, [stateValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(true);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  useEffect(() => {
    if (
      name === "selectedAppStaff" &&
      Array?.isArray(dropdown) &&
      dropdown?.length > 0
    ) {
      setState({
        NameOfTeam: dropdown[0]?.NameOfTeam,
        Icon: dropdown[0]?.Icon,
        TeamUsername: dropdown[0]?.TeamUsername,
      });
    }
  }, [dropdown]);

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        // minWidth: isOpen ? "250px" : "",
        zIndex: isOpen ? "3" : "",
        height: topTitle ? "5.25rem" : "3rem",
      }}
    >
      {topTitle && <div className={classNames.topTitle}>{title}</div>}
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: topTitle ? "2rem" : "",
          maxHeight: isOpen ? "400px" : "100%",
          borderBottom: name === "selectedAppStaff" && !isOpen ? "none" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {name === "selectedAppStaff" && stateValue?.Icon ? (
              <img
                src={stateValue?.Icon}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : name === "selectedAppStaff" ? (
              <img
                src={montageLogo}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : name === "staffrole" ? (
              stateValue?.staffrole == "" ? (
                "Staff role"
              ) : (
                stateValue?.staffrole
              )
            ) : name === "country" ? (
              stateValue?.country == "" ? (
                "Country"
              ) : (
                stateValue?.country
              )
            ) : name === "locations" ? (
              stateValue?.locations == "" ? (
                "Location"
              ) : (
                stateValue?.locations
              )
            ) : (
              name === "selectedApp" && (
                <img
                  src={montageLogo}
                  alt={stateValue?.NameOfTeam}
                  style={{ height: "1.25rem" }}
                />
              )
            )}

            {name === "locationName" ? (
              stateValue?.locationName == "" ? (
                "Click to select"
              ) : (
                stateValue?.locationName
              )
            ) : title == "Select the timezone" ? (
              stateValue?.TimeZone == "" ? (
                "Select timezone"
              )
                : (
                  stateValue?.TimeZone
                ))

              : title == "What timezone is this location in?" ? (
                stateValue?.TimeZone == "" ? (
                  "Select timezone"
                )
                  : (
                    stateValue?.TimeZone
                  ))
                : name === "calendartemplate" ? (
                  stateValue?.calendartemplate == "" ? (
                    "Calendar template"
                  ) : (
                    stateValue?.calendartemplate
                  )
                ) : name === "constultandName" ? (
                  stateValue?.constultandName == "" ? (
                    "Select consultation type"
                  ) : (
                    stateValue?.constultandName
                  )
                ) : title ===
                  "Which staff member do you want to make a specialist?" ? (
                  stateValue.spaceName == "" ? (
                    "Click to select"
                  ) : (
                    stateValue.spaceName
                  )
                ) : name === "specialistList" ? (
                  !stateValue?.speicalistName ? (
                    "Click to select"
                  ) : (
                    stateValue?.speicalistName
                  )
                ) : (
                  <>
                    {selectedOption}
                    {/* {name === "selectedAppStaff" && stateValue?.NameOfTeam
                  ? stateValue?.NameOfTeam
                  : stateValue?.NameOfTeam && !name === "IndustriesId"
                    ? stateValue?.NameOfTeam
                    : selectedOption
                      ? selectedOption
                      : name === "IndustriesId"
                        ? "Click to select industries"
                        : title
                          ? title
                          : name === "selectedAppStaff"
                            ? "Select an Option"
                            : dropdown[0]?.NameOfTeam} */}
                  </>
                )}
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            <input
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e?.target?.value)}
              style={{
                border: "1px solid #ddd",
                width: "100%",
                height: "2.3rem",
                margin: "8px 0",
                paddingLeft: "1rem",
                borderRadius: "10px",
              }}
            />
            {type === "twoFields" && dropdown?.length > 0
              ? dropdown?.map((eachitem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      // let objj = {
                      //   ...eachValuee,
                      //   [calendarObjField]: eachitem,
                      // };
                      // console.log(objj, "objj checking");
                      event.stopPropagation();
                      selectOption(eachitem);
                      setState(() => {
                        const updatedAnnualHolidays = [...stateValue[name]];
                        updatedAnnualHolidays[indexx] = {
                          ...updatedAnnualHolidays[indexx],
                          [calendarObjField]: eachitem,
                        };

                        return {
                          ...stateValue,
                          [name]:
                            (calendarObjField === "date" ||
                              calendarObjField === "month") &&
                              areAllFieldsFilled(updatedAnnualHolidays[indexx])
                              ? [
                                ...updatedAnnualHolidays,
                                { month: "", date: "" },
                              ]
                              : updatedAnnualHolidays,
                        };
                      });
                    }}
                    key={eachitem + index}
                  >
                    {eachitem}
                  </li>
                );
              })
              : title === "Location" && dropdown?.length > 0
                ? dropdown
                  ?.filter((temp) =>
                    temp?.name
                      ?.toLowerCase()
                      ?.includes(searchQuery?.toLowerCase())
                  )
                  ?.map((eachitem, index) => {
                    console.log("08x8Yhgzcxjzxc", eachitem);
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem[mapVal?.name]);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.name,
                            locationsid: eachitem?._id,
                          });
                        }}
                        key={eachitem[mapVal?.name] + index}
                      >
                        {eachitem?.name}
                      </li>
                    );
                  })
                : title === "Staff role" && dropdown?.length > 0
                  ? dropdown
                    ?.filter((temp) =>
                      temp?.name
                        ?.toLowerCase()
                        ?.includes(searchQuery?.toLowerCase())
                    )
                    ?.map((eachitem, index) => {
                      console.log("08x8Yhgzcxjzxc", eachitem);
                      return (
                        <li
                          onClick={(event) => {
                            event.stopPropagation();
                            selectOption(eachitem[mapVal?.name]);
                            setState({
                              ...stateValue,
                              [name]: eachitem?.name,
                              staffroleId: eachitem?._id,
                            });
                          }}
                          key={eachitem[mapVal?.name] + index}
                        >
                          {eachitem?.name}
                        </li>
                      );
                    })
                  : title === "Calendar template" && dropdown?.length > 0
                    ? dropdown
                      ?.filter((temp) =>
                        temp?.CalendarName?.toLowerCase()?.includes(
                          searchQuery?.toLowerCase()
                        )
                      )
                      ?.map((eachitem, index) => {
                        console.log("08x8Yhgzcxjzxc", eachitem);
                        return (
                          <li
                            onClick={(event) => {
                              event.stopPropagation();
                              selectOption(eachitem[mapVal?.name]);
                              setState({
                                ...stateValue,
                                [name]: eachitem?.CalendarName,
                                CalendarNameId: eachitem?.TeamCalendarID,
                              });
                            }}
                            key={eachitem[mapVal?.name] + index}
                          >
                            {eachitem?.CalendarName}
                          </li>
                        );
                      })
                    : title === "Country" && dropdown?.length > 0
                      ? dropdown
                        ?.filter((temp) =>
                          temp?.name
                            ?.toLowerCase()
                            ?.includes(searchQuery?.toLowerCase())
                        )
                        ?.map((eachitem, index) => {
                          console.log("08x8Yhgzcxjzxc", eachitem);
                          return (
                            <li
                              onClick={(event) => {
                                event.stopPropagation();
                                selectOption(eachitem[mapVal?.name]);
                                setState({
                                  ...stateValue,
                                  [name]: eachitem?.name,
                                });
                              }}
                              key={eachitem[mapVal?.name] + index}
                            >
                              {eachitem?.name}
                            </li>
                          );
                        })
                      : title === "What timezone is this location in?" && dropdown?.length > 0
                        ? dropdown
                          ?.filter((temp) =>
                            temp?.StandardTimeZoneName?.toLowerCase()?.includes(
                              searchQuery?.toLowerCase()
                            )
                          )
                          ?.map((eachitem, index) => {
                            console.log("08x8Yhgzcxjzxc", eachitem);
                            return (
                              <li
                                onClick={(event) => {
                                  event.stopPropagation();
                                  selectOption(
                                    eachitem[mapVal?.name] +
                                    " (" +
                                    eachitem[mapVal?.name1] +
                                    ")"
                                  );
                                  setState({
                                    ...stateValue,
                                    TimeZone: eachitem?.UTCDifference,
                                    TimeZoneAbbrevation:
                                      eachitem?.Abbreviation,
                                  });
                                }}
                                key={eachitem[mapVal?.name] + index}
                              >
                                {eachitem[mapVal?.name] +
                                  " (" +
                                  eachitem[mapVal?.name1] +
                                  ")"}
                              </li>
                            );
                          }) : title === "Select the timezone" && dropdown?.length > 0
                          ? dropdown
                            ?.filter((temp) =>
                              temp?.StandardTimeZoneName?.toLowerCase()?.includes(
                                searchQuery?.toLowerCase()
                              )
                            )
                            ?.map((eachitem, index) => {
                              console.log("08x8Yhgzcxjzxc", eachitem);
                              return (
                                <li
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    selectOption(
                                      eachitem[mapVal?.name] +
                                      " (" +
                                      eachitem[mapVal?.name1] +
                                      ")"
                                    );
                                    setState({
                                      ...stateValue,
                                      TimeZone: eachitem?.UTCDifference,
                                      TimeZoneAbbrevation:
                                        eachitem?.Abbreviation,
                                    });
                                  }}
                                  key={eachitem[mapVal?.name] + index}
                                >
                                  {eachitem[mapVal?.name] +
                                    " (" +
                                    eachitem[mapVal?.name1] +
                                    ")"}
                                </li>
                              );
                            })
                          : ""}
          </ul>
        )}
      </div>
    </div>
  );
};

export const EachCustomDropdownDate = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  topTitle,
  type,
  stateVal,
  mapVal,
  indexx,
  calendarObjField,
  eachValuee,
  setselectedindex,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  // useEffect(() => {

  // }, [indexx])
  console.log("7674247624726482634", indexx);

  useEffect(() => {
    console.log(stateValue, "ljwekgfhkwjegfkjwef");
  }, [stateValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  useEffect(() => {
    if (
      name === "selectedAppStaff" &&
      Array?.isArray(dropdown) &&
      dropdown?.length > 0
    ) {
      setState({
        NameOfTeam: dropdown[0]?.NameOfTeam,
        Icon: dropdown[0]?.Icon,
        TeamUsername: dropdown[0]?.TeamUsername,
      });
    }
  }, [dropdown]);

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        // minWidth: isOpen ? "250px" : "",
        zIndex: isOpen ? "3" : "",
        height: topTitle ? "5.25rem" : "3rem",
      }}
    >
      {topTitle && <div className={classNames.topTitle}>{title}</div>}
      <div
        className={classNames.inputContainer}
        onClick={() => {
          setselectedindex(indexx);
          toggleDropdown();
        }}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: topTitle ? "2rem" : "",
          maxHeight: isOpen ? "400px" : "100%",
          borderBottom: name === "selectedAppStaff" && !isOpen ? "none" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {name === "selectedAppStaff" && stateValue?.Icon ? (
              <img
                src={stateValue?.Icon}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : name === "selectedAppStaff" ? (
              <img
                src={montageLogo}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : (
              name === "selectedApp" && (
                <img
                  src={montageLogo}
                  alt={stateValue?.NameOfTeam}
                  style={{ height: "1.25rem" }}
                />
              )
            )}

            {name === "locationName" ? (
              stateValue?.locationName == "" ? (
                "Click to select"
              ) : (
                stateValue?.locationName
              )
            ) : name === "constultandName" ? (
              stateValue?.constultandName == "" ? (
                "Select consultation type"
              ) : (
                stateValue?.constultandName
              )
            ) : title ===
              "Which staff member do you want to make a specialist?" ? (
              stateValue.spaceName == "" ? (
                "Click to select"
              ) : (
                stateValue.spaceName
              )
            ) : name === "specialistList" ? (
              !stateValue?.speicalistName ? (
                "Click to select"
              ) : (
                stateValue?.speicalistName
              )
            ) : (
              <>
                {name === "selectedAppStaff" && stateValue?.NameOfTeam
                  ? stateValue?.NameOfTeam
                  : stateValue?.NameOfTeam && !name === "IndustriesId"
                    ? stateValue?.NameOfTeam
                    : selectedOption
                      ? selectedOption
                      : name === "IndustriesId"
                        ? "Click to select industries"
                        : title
                          ? title
                          : name === "selectedAppStaff"
                            ? "Select an Option"
                            : dropdown[0]?.NameOfTeam}
              </>
            )}
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            {type === "twoFields" && dropdown?.length > 0
              ? dropdown?.map((eachitem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      // let objj = {
                      //   ...eachValuee,
                      //   [calendarObjField]: eachitem,
                      // };
                      // console.log(objj, "objj checking");
                      event.stopPropagation();
                      selectOption(eachitem);
                      setState(() => {
                        const updatedAnnualHolidays = [...stateValue[name]];
                        updatedAnnualHolidays[indexx] = {
                          ...updatedAnnualHolidays[indexx],
                          [calendarObjField]: eachitem,
                        };

                        return {
                          ...stateValue,
                          [name]: updatedAnnualHolidays,
                        };
                      });
                    }}
                    key={eachitem + index}
                  >
                    {eachitem}
                  </li>
                );
              })
              : title === "What is the parent service type?" &&
                dropdown?.length > 0
                ? dropdown?.map((each, index) => {
                  return (
                    <li
                      onClick={(event) => {
                        event.stopPropagation();
                        // selectOption(
                        //   eachitem[mapVal?.name] +
                        //   " (" +
                        //   eachitem[mapVal?.name1] +
                        //   ")"
                        // );
                        setState({
                          ...stateValue,
                          constultandid: each?.consultationtypeid,
                          constultandName: each?.name,
                        });
                        setIsOpen(false);
                      }}
                      key={index}
                    >
                      {each?.name}
                    </li>
                  );
                })
                : title ===
                  "Which staff member do you want to make a specialist?" &&
                  dropdown?.length > 0
                  ? dropdown?.map((each, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          // selectOption(
                          //   eachitem[mapVal?.name] +
                          //   " (" +
                          //   eachitem[mapVal?.name1] +
                          //   ")"
                          // );
                          setState({
                            ...stateValue,
                            spaceName:
                              each?.TeamDetails?.[0]?.PlayerDetails?.[0]
                                ?.name,
                            spaceid: each?.teamplayerid,
                          });
                          setIsOpen(false);
                        }}
                        key={index}
                      >
                        <img
                          src={
                            each?.TeamDetails?.[0]?.PlayerDetails?.[0]
                              ?.ProfilePic
                          }
                        />
                        {each?.TeamDetails?.[0]?.PlayerDetails?.[0]?.name}
                      </li>
                    );
                  })
                  : title === "Which location will this specialist work at?" &&
                    dropdown?.length > 0
                    ? dropdown?.map((each, index) => {
                      return (
                        <li
                          onClick={(event) => {
                            event.stopPropagation();
                            // selectOption(
                            //   eachitem[mapVal?.name] +
                            //   " (" +
                            //   eachitem[mapVal?.name1] +
                            //   ")"
                            // );
                            setState({
                              ...stateValue,
                              locationid: each?._id,
                              locationName: each?.name,
                            });
                            setIsOpen(false);
                          }}
                          key={index}
                        >
                          {each?.name}
                        </li>
                      );
                    })
                    : title === "Select specialist" && dropdown?.length > 0
                      ? dropdown?.map((each, index) => {
                        return (
                          <li
                            onClick={(event) => {
                              event.stopPropagation();
                              // selectOption(
                              //   eachitem[mapVal?.name] +
                              //   " (" +
                              //   eachitem[mapVal?.name1] +
                              //   ")"
                              // );
                              setState({
                                ...stateValue,
                                speicalistId: each?._id,
                                speicalistName:
                                  each?.TeamDetails[0]?.PlayerDetails[0]
                                    ?.name,
                              });
                              setIsOpen(false);
                            }}
                            key={index}
                          >
                            {each?.TeamDetails[0]?.PlayerDetails[0]?.name}
                          </li>
                        );
                      })
                      : title ===
                        "Which location will this service be offered at?" &&
                        dropdown?.length > 0
                        ? dropdown?.map((each, index) => {
                          return (
                            <li
                              onClick={(event) => {
                                event.stopPropagation();
                                // selectOption(
                                //   eachitem[mapVal?.name] +
                                //   " (" +
                                //   eachitem[mapVal?.name1] +
                                //   ")"
                                // );
                                setState({
                                  ...stateValue,
                                  locationid: each?._id,
                                  locationName: each?.name,
                                });
                                setIsOpen(false);
                              }}
                              key={index}
                            >
                              {each?.name}
                            </li>
                          );
                        })
                        : title === "Select the timezone" &&
                          dropdown?.length > 0
                          ? dropdown?.map((eachitem, index) => {
                            return (
                              <li
                                onClick={(event) => {
                                  event.stopPropagation();
                                  selectOption(
                                    eachitem[mapVal?.name] +
                                    " (" +
                                    eachitem[mapVal?.name1] +
                                    ")"
                                  );
                                  setState({
                                    ...stateValue,
                                    TimeZone: eachitem?.UTCDifference,
                                    TimeZoneAbbrevation:
                                      eachitem?.Abbreviation,
                                  });
                                }}
                                key={eachitem[mapVal?.name] + index}
                              >
                                {eachitem[mapVal?.name] +
                                  " (" +
                                  eachitem[mapVal?.name1] +
                                  ")"}
                              </li>
                            );
                          })
                          : type === "obj" && dropdown?.length > 0
                            ? dropdown?.map((eachitem, index) => {
                              return (
                                <li
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    selectOption(eachitem[mapVal?.name]);
                                    setState({
                                      ...stateValue,
                                      [name]: eachitem[stateVal],
                                    });
                                  }}
                                  key={eachitem[mapVal?.name] + index}
                                >
                                  {mapVal?.image && (
                                    <img
                                      src={eachitem[mapVal?.image]}
                                      alt={eachitem[stateVal]}
                                    />
                                  )}
                                  {eachitem[mapVal?.name]}
                                </li>
                              );
                            })
                            : title === "All Countries"
                              ? dropdown?.length > 0 &&
                              dropdown
                                ?.filter((row) => {
                                  // Convert the row object values to an array and check if any value matches the search query
                                  const searchQueryy =
                                    searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                                  return row
                                    ?.toLowerCase()
                                    ?.includes(searchQueryy);
                                })
                                ?.map((eachitem, index) => {
                                  return (
                                    <li
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        selectOption(eachitem);
                                        setState(eachitem);
                                      }}
                                      key={eachitem + index}
                                      style={{
                                        display:
                                          eachitem === stateValue
                                            ? "none"
                                            : "",
                                      }}
                                    >
                                      {eachitem}
                                    </li>
                                  );
                                })
                              : (name === "Gender" ||
                                name === "groupType" ||
                                type === "single") &&
                                dropdown?.length > 0
                                ? dropdown?.map((eachitem, index) => {
                                  return (
                                    <li
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        selectOption(eachitem);
                                        setState({
                                          ...stateValue,
                                          [name]: eachitem,
                                        });
                                      }}
                                      key={eachitem + index}
                                    >
                                      {eachitem}
                                    </li>
                                  );
                                })
                                : name === "IndustriesId" &&
                                  dropdown?.length > 0
                                  ? dropdown?.map((eachitem, index) => {
                                    return (
                                      <li
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          selectOption(eachitem?.Name);
                                          setState({
                                            ...stateValue,
                                            [name]: eachitem?._id,
                                          });
                                        }}
                                        key={eachitem?.Name + index}
                                      >
                                        {eachitem?.Name}
                                      </li>
                                    );
                                  })
                                  : dropdown?.length > 0 &&
                                  dropdown?.map((eachitem, index) => {
                                    return (
                                      <li
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          selectOption(eachitem?.NameOfTeam);
                                          if (name === "teamUsername") {
                                            setState((prev) => {
                                              return {
                                                ...prev,
                                                [name]:
                                                  eachitem?.TeamUsername,
                                              };
                                            });
                                          } else if (
                                            name === "selectedAppStaff"
                                          ) {
                                            setState({
                                              NameOfTeam:
                                                eachitem?.NameOfTeam,
                                              Icon: eachitem?.Icon,
                                              TeamUsername:
                                                eachitem?.TeamUsername,
                                            });
                                          } else {
                                            setState({
                                              ...stateValue,
                                              [name]: {
                                                NameOfTeam:
                                                  eachitem?.NameOfTeam,
                                                Icon: eachitem?.Icon,
                                              },
                                            });
                                          }
                                        }}
                                        key={eachitem?.NameOfTeam + index}
                                      >
                                        <img
                                          src={eachitem?.Icon}
                                          alt={eachitem?.NameOfTeam}
                                        />
                                        {eachitem?.NameOfTeam}
                                      </li>
                                    );
                                  })}
          </ul>
        )}
      </div>
    </div>
  );
};

export const MRsEachCustomDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  topTitle,
  type,
  stateVal,
  mapVal,
  indexx,
  calendarObjField,
  eachValuee,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => { });
  console.log("stateValfffff", stateValue);

  useEffect(() => {
    console.log(stateValue, "ljwekgfhkwjegfkjwef");
  }, [stateValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  useEffect(() => {
    if (
      name === "selectedAppStaff" &&
      Array?.isArray(dropdown) &&
      dropdown?.length > 0
    ) {
      setState({
        NameOfTeam: dropdown[0]?.NameOfTeam,
        Icon: dropdown[0]?.Icon,
        TeamUsername: dropdown[0]?.TeamUsername,
      });
    }
  }, [dropdown]);

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        minWidth: "99px",
        // minWidth: isOpen ? "250px" : "",
        zIndex: isOpen ? "3" : "",
        height: topTitle ? "5.25rem" : "3rem",
      }}
    >
      {topTitle && <div className={classNames.topTitle}>{title}</div>}
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: topTitle ? "2rem" : "",
          maxHeight: isOpen ? "400px" : "100%",
          width: name == "peroid" && "120px",
          padding: name == "peroid" ? "10px" : name == "gendersignature" && "1rem 0.5rem",
          borderBottom: name === "selectedAppStaff" && !isOpen ? "none" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {
              name == "startingday" ?
                stateValue?.startingday == "" ? "Select starting day" :
                  stateValue?.startingday :
                stateValue?.gendersignature
            }
          </span>
          <span
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
              return;
            }}
          >
            {isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
          </span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            {dropdown?.length > 0
              ? dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      // selectOption(
                      //   eachitem[mapVal?.name] +
                      //   " (" +
                      //   eachitem[mapVal?.name1] +
                      //   ")"
                      // );
                      if (name == "startingday") {
                        setState({
                          ...stateValue,
                          startingday: each,
                          startingdayIndex: index
                        });
                      }
                      else {
                        setState({
                          ...stateValue,
                          gendersignature: each,
                        });
                      }

                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    {each}
                  </li>
                );
              })
              : ""}
          </ul>
        )}
      </div>
    </div>
  );
};










export const BreakEachCustomDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  topTitle,
  type,
  stateVal,
  mapVal,
  indexx,
  calendarObjField,
  eachValuee,
  lastindex
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => { });
  console.log("42ff", name);

  useEffect(() => {
    console.log(stateValue, "ljwekgfhkwjegfkjwef");
  }, [stateValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  useEffect(() => {
    if (
      name === "selectedAppStaff" &&
      Array?.isArray(dropdown) &&
      dropdown?.length > 0
    ) {
      setState({
        NameOfTeam: dropdown[0]?.NameOfTeam,
        Icon: dropdown[0]?.Icon,
        TeamUsername: dropdown[0]?.TeamUsername,
      });
    }
  }, [dropdown]);

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        // minWidth: isOpen ? "250px" : "",
        zIndex: isOpen ? "3" : "",
        height: topTitle ? "5.25rem" : "3rem",
        minWidth: "160px"
      }}
    >
      {topTitle && <div className={classNames.topTitle}>{title}</div>}
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: topTitle ? "2rem" : "",
          maxHeight: isOpen ? "400px" : "100%",
          width: name == "peroid" && "120px",
          padding: name == "peroid" && "10px",
          borderBottom: name === "selectedAppStaff" && !isOpen ? "none" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {name === "selectedAppStaff" && stateValue?.Icon ? (
              <img
                src={stateValue?.Icon}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : name === "rooms" ? (
              !stateValue?.rooms ? (
                "Select room category"
              ) : (
                stateValue?.roomsName
              )
            ) : name === "theraphyCategoryId" ? (
              !stateValue?.theraphyCategoryId ? (
                "Click to select"
              ) : (
                stateValue?.theraphyCategoryName
              )
            ) : name === "peroid" ? (
              !stateValue?.peroid ? (
                "Click to select"
              ) : (
                stateValue?.peroid
              )
            ) : (
              <>
                {name === "selectedAppStaff" && stateValue?.NameOfTeam
                  ? stateValue?.NameOfTeam
                  : stateValue?.NameOfTeam && !name === "IndustriesId"
                    ? stateValue?.NameOfTeam
                    : selectedOption
                      ? selectedOption
                      : name === "IndustriesId"
                        ? "Click to select industries"
                        : title
                          ? title
                          : name === "selectedAppStaff"
                            ? "Select an Option"
                            : name === "catfallunder"
                              ? "Select an Option"
                              : dropdown[0]?.NameOfTeam}
              </>
            )}
          </span>
          <span
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
              return;
            }}
          >
            {isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
          </span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            {type === "twoFields" && dropdown?.length > 0
              ? dropdown?.map((eachitem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      // let objj = {
                      //   ...eachValuee,
                      //   [calendarObjField]: eachitem,
                      // };
                      // console.log(objj, "objj checking");
                      event.stopPropagation();
                      selectOption(eachitem);
                      setState(() => {
                        const updatedAnnualHolidays = [...stateValue[name]];
                        const workinghourse = updatedAnnualHolidays[indexx]
                        const breakhours = workinghourse["breakstart"]
                        breakhours[lastindex] = {
                          ...breakhours[lastindex], [calendarObjField]: eachitem,
                        }
                        console.log("czxczxczxc", breakhours)
                        // const workinghourse = updatedAnnualHolidays[lastindex] = {
                        //   workinghourse[lastindex], [calendarObjField]: eachitem,
                        //   [calendarObjField]: eachitem,
                        // }
                        // updatedAnnualHolidays[indexx] = {
                        //   ...updatedAnnualHolidays[indexx],

                        // };
                        // const updatedAnnualHolidays = [...stateValue[name]];
                        // updatedAnnualHolidays[indexx] = {
                        //   ...updatedAnnualHolidays[indexx],
                        //   [calendarObjField]: eachitem,
                        // };
                        return {
                          ...stateValue,
                          [name]:
                            (calendarObjField === "date" ||
                              calendarObjField === "month") &&
                              areAllFieldsFilled(updatedAnnualHolidays[indexx])
                              ? [
                                ...updatedAnnualHolidays,
                                { month: "", date: "" },
                              ]
                              : updatedAnnualHolidays,
                        };
                      });
                    }}
                    key={eachitem + index}
                  >
                    {eachitem}
                  </li>
                );
              })
              : title === "Period title" && dropdown?.length > 0
                ? dropdown?.map((each, index) => {
                  return (
                    <li
                      onClick={(event) => {
                        event.stopPropagation();
                        // selectOption(
                        //   eachitem[mapVal?.name] +
                        //   " (" +
                        //   eachitem[mapVal?.name1] +
                        //   ")"
                        // );
                        setState({
                          ...stateValue,
                          peroid: each,
                        });
                        setIsOpen(false);
                      }}
                      key={index}
                    >
                      {each}
                    </li>
                  );
                })
                : title === "Which therapy category does this fall under?" &&
                  dropdown?.length > 0
                  ? dropdown?.map((each, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          // selectOption(
                          //   eachitem[mapVal?.name] +
                          //   " (" +
                          //   eachitem[mapVal?.name1] +
                          //   ")"
                          // );
                          setState({
                            ...stateValue,
                            theraphyCategoryId: each?._id,
                            theraphyCategoryName: each?.name,
                          });
                          setIsOpen(false);
                        }}
                        key={index}
                      >
                        {each?.name}
                      </li>
                    );
                  })
                  : title === "What room category does this room fall under?" &&
                    dropdown?.length > 0
                    ? dropdown?.map((each, index) => {
                      return (
                        <li
                          onClick={(event) => {
                            event.stopPropagation();
                            // selectOption(
                            //   eachitem[mapVal?.name] +
                            //   " (" +
                            //   eachitem[mapVal?.name1] +
                            //   ")"
                            // );
                            setState({
                              ...stateValue,
                              rooms: each?.roomcategoryid,
                              roomsName: each?.name,
                            });
                            setIsOpen(false);
                          }}
                          key={index}
                        >
                          {each?.name}
                        </li>
                      );
                    })
                    : title === "Which location will this be sold at ?" ||
                      (title ===
                        "At which location is this kitchen item served?" &&
                        dropdown?.length > 0)
                      ? dropdown?.map((each, index) => {
                        return (
                          <li
                            onClick={(event) => {
                              event.stopPropagation();
                              // selectOption(
                              //   eachitem[mapVal?.name] +
                              //   " (" +
                              //   eachitem[mapVal?.name1] +
                              //   ")"
                              // );
                              setState({
                                ...stateValue,
                                location: each?._id,
                                locationName: each?.name,
                              });
                              setIsOpen(false);
                            }}
                            key={index}
                          >
                            {each?.name}
                          </li>
                        );
                      })
                      : title ===
                        "Which category does this product fall under?" &&
                        dropdown?.length > 0
                        ? dropdown?.map((each, index) => {
                          return (
                            <li
                              onClick={(event) => {
                                event.stopPropagation();
                                // selectOption(
                                //   eachitem[mapVal?.name] +
                                //   " (" +
                                //   eachitem[mapVal?.name1] +
                                //   ")"
                                // );
                                setState({
                                  ...stateValue,
                                  catfallunder: each?.productcategoryid,
                                  catfallunderName: each?.name,
                                });
                                setIsOpen(false);
                              }}
                              key={index}
                            >
                              {each?.name}
                            </li>
                          );
                        })
                        : title === "What is the parent service type?" &&
                          dropdown?.length > 0
                          ? dropdown?.map((each, index) => {
                            return (
                              <li
                                onClick={(event) => {
                                  event.stopPropagation();
                                  // selectOption(
                                  //   eachitem[mapVal?.name] +
                                  //   " (" +
                                  //   eachitem[mapVal?.name1] +
                                  //   ")"
                                  // );
                                  setState({
                                    ...stateValue,
                                    constultandid: each?.consultationtypeid,
                                    constultandName: each?.name,
                                  });
                                  setIsOpen(false);
                                }}
                                key={index}
                              >
                                {each?.name}
                              </li>
                            );
                          })
                          : title === "Which brand makes this product?" &&
                            dropdown?.length > 0
                            ? dropdown?.map((each, index) => {
                              return (
                                <li
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    // selectOption(
                                    //   eachitem[mapVal?.name] +
                                    //   " (" +
                                    //   eachitem[mapVal?.name1] +
                                    //   ")"
                                    // );
                                    setState({
                                      ...stateValue,
                                      brandsproduct: each?.productbrandid,
                                      brandsproductName: each?.name,
                                    });
                                    setIsOpen(false);
                                  }}
                                  key={index}
                                >
                                  <img src={each?.icon} />
                                  {each?.name}
                                </li>
                              );
                            })
                            : title ===
                              "Which staff member do you want to make a specialist?" &&
                              dropdown?.length > 0
                              ? dropdown?.map((each, index) => {
                                return (
                                  <li
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      // selectOption(
                                      //   eachitem[mapVal?.name] +
                                      //   " (" +
                                      //   eachitem[mapVal?.name1] +
                                      //   ")"
                                      // );
                                      setState({
                                        ...stateValue,
                                        spaceName:
                                          each?.TeamDetails?.[0]
                                            ?.PlayerDetails?.[0]?.name,
                                        spaceid: each?.teamplayerid,
                                      });
                                      setIsOpen(false);
                                    }}
                                    key={index}
                                  >
                                    <img
                                      src={
                                        each?.TeamDetails?.[0]
                                          ?.PlayerDetails?.[0]?.ProfilePic
                                      }
                                    />
                                    {
                                      each?.TeamDetails?.[0]
                                        ?.PlayerDetails?.[0]?.name
                                    }
                                  </li>
                                );
                              })
                              : title ===
                                "Which location will this specialist work at?" &&
                                dropdown?.length > 0
                                ? dropdown?.map((each, index) => {
                                  return (
                                    <li
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        // selectOption(
                                        //   eachitem[mapVal?.name] +
                                        //   " (" +
                                        //   eachitem[mapVal?.name1] +
                                        //   ")"
                                        // );
                                        setState({
                                          ...stateValue,
                                          locationid: each?._id,
                                          locationName: each?.name,
                                        });
                                        setIsOpen(false);
                                      }}
                                      key={index}
                                    >
                                      {each?.name}
                                    </li>
                                  );
                                })
                                : title === "Select specialist" &&
                                  dropdown?.length > 0
                                  ? dropdown?.map((each, index) => {
                                    return (
                                      <li
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          // selectOption(
                                          //   eachitem[mapVal?.name] +
                                          //   " (" +
                                          //   eachitem[mapVal?.name1] +
                                          //   ")"
                                          // );
                                          console.log(each, "lkwheflkwhfw");
                                          setState({
                                            ...stateValue,
                                            speicalistId: each?.specialistid,
                                            speicalistName:
                                              each?.TeamDetails[0]
                                                ?.PlayerDetails[0]?.name,
                                          });
                                          setIsOpen(false);
                                        }}
                                        key={index}
                                      >
                                        {
                                          each?.TeamDetails[0]
                                            ?.PlayerDetails[0]?.name
                                        }
                                      </li>
                                    );
                                  })
                                  : title ===
                                    "Which location will this service be offered at?" &&
                                    dropdown?.length > 0
                                    ? dropdown?.map((each, index) => {
                                      return (
                                        <li
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            // selectOption(
                                            //   eachitem[mapVal?.name] +
                                            //   " (" +
                                            //   eachitem[mapVal?.name1] +
                                            //   ")"
                                            // );
                                            setState({
                                              ...stateValue,
                                              locationid: each?._id,
                                              locationName: each?.name,
                                            });
                                            setIsOpen(false);
                                          }}
                                          key={index}
                                        >
                                          {each?.name}
                                        </li>
                                      );
                                    })
                                    : title === "Select the timezone" &&
                                      dropdown?.length > 0
                                      ? dropdown?.map((eachitem, index) => {
                                        return (
                                          <li
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              selectOption(
                                                eachitem[mapVal?.name] +
                                                " (" +
                                                eachitem[mapVal?.name1] +
                                                ")"
                                              );
                                              setState({
                                                ...stateValue,
                                                TimeZone:
                                                  eachitem?.UTCDifference,
                                                TimeZoneAbbrevation:
                                                  eachitem?.Abbreviation,
                                              });
                                            }}
                                            key={
                                              eachitem[mapVal?.name] + index
                                            }
                                          >
                                            {eachitem[mapVal?.name] +
                                              " (" +
                                              eachitem[mapVal?.name1] +
                                              ")"}
                                          </li>
                                        );
                                      })
                                      : type === "obj" && dropdown?.length > 0
                                        ? dropdown?.map((eachitem, index) => {
                                          return (
                                            <li
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                selectOption(
                                                  eachitem[mapVal?.name]
                                                );
                                                setState({
                                                  ...stateValue,
                                                  [name]: eachitem[stateVal],
                                                });
                                              }}
                                              key={
                                                eachitem[mapVal?.name] + index
                                              }
                                            >
                                              {mapVal?.image && (
                                                <img
                                                  src={
                                                    eachitem[mapVal?.image]
                                                  }
                                                  alt={eachitem[stateVal]}
                                                />
                                              )}
                                              {eachitem[mapVal?.name]}
                                            </li>
                                          );
                                        })
                                        : title === "All Countries"
                                          ? dropdown?.length > 0 &&
                                          dropdown
                                            ?.filter((row) => {
                                              // Convert the row object values to an array and check if any value matches the search query
                                              const searchQueryy =
                                                searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                                              return row
                                                ?.toLowerCase()
                                                ?.includes(searchQueryy);
                                            })
                                            ?.map((eachitem, index) => {
                                              return (
                                                <li
                                                  onClick={(event) => {
                                                    event.stopPropagation();
                                                    selectOption(eachitem);
                                                    setState(eachitem);
                                                  }}
                                                  key={eachitem + index}
                                                  style={{
                                                    display:
                                                      eachitem === stateValue
                                                        ? "none"
                                                        : "",
                                                  }}
                                                >
                                                  {eachitem}
                                                </li>
                                              );
                                            })
                                          : (name === "Gender" ||
                                            name === "groupType" ||
                                            type === "single") &&
                                            dropdown?.length > 0
                                            ? dropdown?.map(
                                              (eachitem, index) => {
                                                return (
                                                  <li
                                                    onClick={(event) => {
                                                      event.stopPropagation();
                                                      selectOption(eachitem);
                                                      setState({
                                                        ...stateValue,
                                                        [name]: eachitem,
                                                      });
                                                    }}
                                                    key={eachitem + index}
                                                  >
                                                    {eachitem}
                                                  </li>
                                                );
                                              }
                                            )
                                            : name === "IndustriesId" &&
                                              dropdown?.length > 0
                                              ? dropdown?.map(
                                                (eachitem, index) => {
                                                  return (
                                                    <li
                                                      onClick={(event) => {
                                                        event.stopPropagation();
                                                        selectOption(
                                                          eachitem?.Name
                                                        );
                                                        setState({
                                                          ...stateValue,
                                                          [name]:
                                                            eachitem?._id,
                                                        });
                                                      }}
                                                      key={
                                                        eachitem?.Name + index
                                                      }
                                                    >
                                                      {eachitem?.Name}
                                                    </li>
                                                  );
                                                }
                                              )
                                              : name === "defaultDropdown" &&
                                                dropdown?.length > 0
                                                ? dropdown?.map(
                                                  (eachitem, index) => {
                                                    return (
                                                      <li
                                                        onClick={(event) => {
                                                          event.stopPropagation();
                                                          selectOption(
                                                            eachitem
                                                          );
                                                          setState(eachitem);
                                                        }}
                                                        key={index}
                                                      >
                                                        {eachitem}
                                                      </li>
                                                    );
                                                  }
                                                )
                                                : dropdown?.length > 0 &&
                                                dropdown?.map(
                                                  (eachitem, index) => {
                                                    return (
                                                      <li
                                                        onClick={(event) => {
                                                          event.stopPropagation();
                                                          selectOption(
                                                            eachitem?.NameOfTeam
                                                          );
                                                          if (
                                                            name ===
                                                            "teamUsername"
                                                          ) {
                                                            setState(
                                                              (prev) => {
                                                                return {
                                                                  ...prev,
                                                                  [name]:
                                                                    eachitem?.TeamUsername,
                                                                };
                                                              }
                                                            );
                                                          } else if (
                                                            name ===
                                                            "selectedAppStaff"
                                                          ) {
                                                            setState({
                                                              NameOfTeam:
                                                                eachitem?.NameOfTeam,
                                                              Icon: eachitem?.Icon,
                                                              TeamUsername:
                                                                eachitem?.TeamUsername,
                                                            });
                                                          } else {
                                                            setState({
                                                              ...stateValue,
                                                              [name]: {
                                                                NameOfTeam:
                                                                  eachitem?.NameOfTeam,
                                                                Icon: eachitem?.Icon,
                                                              },
                                                            });
                                                          }
                                                        }}
                                                        key={
                                                          eachitem?.NameOfTeam +
                                                          index
                                                        }
                                                      >
                                                        <img
                                                          src={eachitem?.Icon}
                                                          alt={
                                                            eachitem?.NameOfTeam
                                                          }
                                                        />
                                                        {eachitem?.NameOfTeam}
                                                      </li>
                                                    );
                                                  }
                                                )}
          </ul>
        )}
      </div>
    </div>
  );
};

















export const EachCustomDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  topTitle,
  type,
  stateVal,
  mapVal,
  indexx,
  calendarObjField,
  eachValuee,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => { });
  console.log("42ff", name);

  useEffect(() => {
    console.log(stateValue, "ljwekgfhkwjegfkjwef");
  }, [stateValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  useEffect(() => {
    if (
      name === "selectedAppStaff" &&
      Array?.isArray(dropdown) &&
      dropdown?.length > 0
    ) {
      setState({
        NameOfTeam: dropdown[0]?.NameOfTeam,
        Icon: dropdown[0]?.Icon,
        TeamUsername: dropdown[0]?.TeamUsername,
      });
    }
  }, [dropdown]);

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        // minWidth: isOpen ? "250px" : "",
        zIndex: isOpen ? "3" : "",
        height: topTitle ? "5.25rem" : "3rem",
        minWidth: "160px"
      }}
    >
      {topTitle && <div className={classNames.topTitle}>{title}</div>}
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: topTitle ? "2rem" : "",
          maxHeight: isOpen ? "400px" : "100%",
          width: name == "peroid" && "120px",
          padding: name == "peroid" && "10px",
          borderBottom: name === "selectedAppStaff" && !isOpen ? "none" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {name === "selectedAppStaff" && stateValue?.Icon ? (
              <img
                src={stateValue?.Icon}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : name === "selectedAppStaff" ? (
              <img
                src={montageLogo}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : (
              name === "selectedApp" && (
                <img
                  src={montageLogo}
                  alt={stateValue?.NameOfTeam}
                  style={{ height: "1.25rem" }}
                />
              )
            )}

            {name === "locationName" ? (
              stateValue?.locationName == "" ? (
                "Select location"
              ) : (
                stateValue?.locationName
              )
            ) : name === "constultandName" ? (
              stateValue?.constultandName == "" ? (
                "Select consultation type"
              ) : (
                stateValue?.constultandName
              )

            ) : name === "location" ? (
              stateValue?.location == "" ? (
                "Select location"
              ) : (
                stateValue?.location
              )
            ) : name === "defaultDropdown" ? (
              stateValue == "" ? (
                "Click to select"
              ) : (
                stateValue
              )
            ) : title ===
              "Which staff member do you want to make a specialist?" ? (
              stateValue.spaceName == "" ? (
                "Click to select"
              ) : (
                stateValue.spaceName
              )
            ) : name === "specialistList" ? (
              !stateValue?.speicalistName ? (
                "Click to select"
              ) : (
                stateValue?.speicalistName
              )
            ) : name === "catfallunder" ? (
              !stateValue?.catfallunder ? (
                "Click to select"
              ) : (
                stateValue?.catfallunderName
              )
            ) : name === "brandsproduct" ? (
              !stateValue?.brandsproduct ? (
                "Click to select"
              ) : (
                stateValue?.brandsproductName
              )
            ) : name === "location" ? (
              !stateValue?.location ? (
                "Click to select"
              ) :


                (
                  stateValue?.locationName
                )
            )

              : name === "rooms" ? (
                !stateValue?.rooms ? (
                  "Select room category"
                ) : (
                  stateValue?.roomsName
                )
              ) : name === "theraphyCategoryId" ? (
                !stateValue?.theraphyCategoryId ? (
                  "Click to select"
                ) : (
                  stateValue?.theraphyCategoryName
                )
              ) : name === "peroid" ? (
                !stateValue?.peroid ? (
                  "Click to select"
                ) : (
                  stateValue?.peroid
                )
              ) : (
                <>
                  {name === "selectedAppStaff" && stateValue?.NameOfTeam
                    ? stateValue?.NameOfTeam
                    : stateValue?.NameOfTeam && !name === "IndustriesId"
                      ? stateValue?.NameOfTeam
                      : selectedOption
                        ? selectedOption
                        : name === "IndustriesId"
                          ? "Click to select industries"
                          : title
                            ? title
                            : name === "selectedAppStaff"
                              ? "Select an Option"
                              : name === "catfallunder"
                                ? "Select an Option"
                                : dropdown[0]?.NameOfTeam}
                </>
              )}
          </span>
          <span
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
              return;
            }}
          >
            {isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
          </span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            {type === "twoFields" && dropdown?.length > 0
              ? dropdown?.map((eachitem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      // let objj = {
                      //   ...eachValuee,
                      //   [calendarObjField]: eachitem,
                      // };
                      // console.log(objj, "objj checking");
                      event.stopPropagation();
                      selectOption(eachitem);
                      setState(() => {
                        const updatedAnnualHolidays = [...stateValue[name]];
                        updatedAnnualHolidays[indexx] = {
                          ...updatedAnnualHolidays[indexx],
                          [calendarObjField]: eachitem,
                        };

                        return {
                          ...stateValue,
                          [name]:
                            (calendarObjField === "date" ||
                              calendarObjField === "month") &&
                              areAllFieldsFilled(updatedAnnualHolidays[indexx])
                              ? [
                                ...updatedAnnualHolidays,
                                { month: "", date: "" },
                              ]
                              : updatedAnnualHolidays,
                        };
                      });
                    }}
                    key={eachitem + index}
                  >
                    {eachitem}
                  </li>
                );
              })
              : title === "Period title" && dropdown?.length > 0
                ? dropdown?.map((each, index) => {
                  return (
                    <li
                      onClick={(event) => {
                        event.stopPropagation();
                        // selectOption(
                        //   eachitem[mapVal?.name] +
                        //   " (" +
                        //   eachitem[mapVal?.name1] +
                        //   ")"
                        // );
                        setState({
                          ...stateValue,
                          peroid: each,
                        });
                        setIsOpen(false);
                      }}
                      key={index}
                    >
                      {each}
                    </li>
                  );
                })
                : title === "Which therapy category does this fall under?" &&
                  dropdown?.length > 0
                  ? dropdown?.map((each, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          // selectOption(
                          //   eachitem[mapVal?.name] +
                          //   " (" +
                          //   eachitem[mapVal?.name1] +
                          //   ")"
                          // );
                          setState({
                            ...stateValue,
                            theraphyCategoryId: each?._id,
                            theraphyCategoryName: each?.name,
                          });
                          setIsOpen(false);
                        }}
                        key={index}
                      >
                        {each?.name}
                      </li>
                    );
                  })
                  : title === "What room category does this room fall under?" &&
                    dropdown?.length > 0
                    ? dropdown?.map((each, index) => {
                      return (
                        <li
                          onClick={(event) => {
                            event.stopPropagation();
                            // selectOption(
                            //   eachitem[mapVal?.name] +
                            //   " (" +
                            //   eachitem[mapVal?.name1] +
                            //   ")"
                            // );
                            setState({
                              ...stateValue,
                              rooms: each?.roomcategoryid,
                              roomsName: each?.name,
                            });
                            setIsOpen(false);
                          }}
                          key={index}
                        >
                          {each?.name}
                        </li>
                      );
                    })
                    : title === "Which location will this be sold at ?" ||
                      (title ===
                        "At which location is this kitchen item served?" &&
                        dropdown?.length > 0)
                      ? dropdown?.map((each, index) => {
                        return (
                          <li
                            onClick={(event) => {
                              event.stopPropagation();
                              // selectOption(
                              //   eachitem[mapVal?.name] +
                              //   " (" +
                              //   eachitem[mapVal?.name1] +
                              //   ")"
                              // );
                              setState({
                                ...stateValue,
                                location: each?._id,
                                locationName: each?.name,
                              });
                              setIsOpen(false);
                            }}
                            key={index}
                          >
                            {each?.name}
                          </li>
                        );
                      })
                      : title ===
                        "Which category does this product fall under?" &&
                        dropdown?.length > 0
                        ? dropdown?.map((each, index) => {
                          return (
                            <li
                              onClick={(event) => {
                                event.stopPropagation();
                                // selectOption(
                                //   eachitem[mapVal?.name] +
                                //   " (" +
                                //   eachitem[mapVal?.name1] +
                                //   ")"
                                // );
                                setState({
                                  ...stateValue,
                                  catfallunder: each?.productcategoryid,
                                  catfallunderName: each?.name,
                                });
                                setIsOpen(false);
                              }}
                              key={index}
                            >
                              {each?.name}
                            </li>
                          );
                        })
                        : title === "What is the parent service type?" &&
                          dropdown?.length > 0
                          ? dropdown?.map((each, index) => {
                            return (
                              <li
                                onClick={(event) => {
                                  event.stopPropagation();
                                  // selectOption(
                                  //   eachitem[mapVal?.name] +
                                  //   " (" +
                                  //   eachitem[mapVal?.name1] +
                                  //   ")"
                                  // );
                                  setState({
                                    ...stateValue,
                                    constultandid: each?.consultationtypeid,
                                    constultandName: each?.name,
                                  });
                                  setIsOpen(false);
                                }}
                                key={index}
                              >
                                {each?.name}
                              </li>
                            );
                          })
                          : title === "Which brand makes this product?" &&
                            dropdown?.length > 0
                            ? dropdown?.map((each, index) => {
                              return (
                                <li
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    // selectOption(
                                    //   eachitem[mapVal?.name] +
                                    //   " (" +
                                    //   eachitem[mapVal?.name1] +
                                    //   ")"
                                    // );
                                    setState({
                                      ...stateValue,
                                      brandsproduct: each?.productbrandid,
                                      brandsproductName: each?.name,
                                    });
                                    setIsOpen(false);
                                  }}
                                  key={index}
                                >
                                  <img src={each?.icon} />
                                  {each?.name}
                                </li>
                              );
                            })
                            : title ===
                              "Which staff member do you want to make a specialist?" &&
                              dropdown?.length > 0
                              ? dropdown?.map((each, index) => {
                                return (
                                  <li
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      // selectOption(
                                      //   eachitem[mapVal?.name] +
                                      //   " (" +
                                      //   eachitem[mapVal?.name1] +
                                      //   ")"
                                      // );
                                      setState({
                                        ...stateValue,
                                        spaceName:
                                          each?.TeamDetails?.[0]
                                            ?.PlayerDetails?.[0]?.name,
                                        spaceid: each?.teamplayerid,
                                      });
                                      setIsOpen(false);
                                    }}
                                    key={index}
                                  >
                                    <img
                                      src={
                                        each?.TeamDetails?.[0]
                                          ?.PlayerDetails?.[0]?.ProfilePic
                                      }
                                    />
                                    {
                                      each?.TeamDetails?.[0]
                                        ?.PlayerDetails?.[0]?.name
                                    }
                                  </li>
                                );
                              })
                              : title ===
                                "ingredients type" &&
                                dropdown?.length > 0
                                ? dropdown?.map((each, index) => {
                                  return (
                                    <li
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        // selectOption(
                                        //   eachitem[mapVal?.name] +
                                        //   " (" +
                                        //   eachitem[mapVal?.name1] +
                                        //   ")"
                                        // );
                                        setState({
                                          ...stateValue,
                                          locationid: each?._id,
                                          locationName: each?.name,
                                        });
                                        setIsOpen(false);
                                      }}
                                      key={index}
                                    >
                                      {each?.name}
                                    </li>
                                  );
                                }) :

                                title ===
                                  "Which location will this specialist work at?" &&
                                  dropdown?.length > 0
                                  ? dropdown?.map((each, index) => {
                                    return (
                                      <li
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          // selectOption(
                                          //   eachitem[mapVal?.name] +
                                          //   " (" +
                                          //   eachitem[mapVal?.name1] +
                                          //   ")"
                                          // );
                                          setState({
                                            ...stateValue,
                                            locationid: each?._id,
                                            locationName: each?.name,
                                          });
                                          setIsOpen(false);
                                        }}
                                        key={index}
                                      >
                                        {each?.name}
                                      </li>
                                    );
                                  })
                                  : title === "Select specialist" &&
                                    dropdown?.length > 0
                                    ? dropdown?.map((each, index) => {
                                      return (
                                        <li
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            // selectOption(
                                            //   eachitem[mapVal?.name] +
                                            //   " (" +
                                            //   eachitem[mapVal?.name1] +
                                            //   ")"
                                            // );
                                            console.log(each, "lkwheflkwhfw");
                                            setState({
                                              ...stateValue,
                                              speicalistId: each?.specialistid,
                                              speicalistName:
                                                each?.TeamDetails[0]
                                                  ?.PlayerDetails[0]?.name,
                                            });
                                            setIsOpen(false);
                                          }}
                                          key={index}
                                        >
                                          {
                                            each?.TeamDetails[0]
                                              ?.PlayerDetails[0]?.name
                                          }
                                        </li>
                                      );
                                    })
                                    : title ===
                                      "Which location will this service be offered at?" &&
                                      dropdown?.length > 0
                                      ? dropdown?.map((each, index) => {
                                        return (
                                          <li
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              // selectOption(
                                              //   eachitem[mapVal?.name] +
                                              //   " (" +
                                              //   eachitem[mapVal?.name1] +
                                              //   ")"
                                              // );
                                              setState({
                                                ...stateValue,
                                                locationid: each?._id,
                                                locationName: each?.name,
                                              });
                                              setIsOpen(false);
                                            }}
                                            key={index}
                                          >
                                            {each?.name}
                                          </li>
                                        );
                                      })
                                      : title === "Select the timezone" &&
                                        dropdown?.length > 0
                                        ? dropdown?.map((eachitem, index) => {
                                          return (
                                            <li
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                selectOption(
                                                  eachitem[mapVal?.name] +
                                                  " (" +
                                                  eachitem[mapVal?.name1] +
                                                  ")"
                                                );
                                                setState({
                                                  ...stateValue,
                                                  TimeZone:
                                                    eachitem?.UTCDifference,
                                                  TimeZoneAbbrevation:
                                                    eachitem?.Abbreviation,
                                                });
                                              }}
                                              key={
                                                eachitem[mapVal?.name] + index
                                              }
                                            >
                                              {eachitem[mapVal?.name] +
                                                " (" +
                                                eachitem[mapVal?.name1] +
                                                ")"}
                                            </li>
                                          );
                                        })
                                        : type === "obj" && dropdown?.length > 0
                                          ? dropdown?.map((eachitem, index) => {
                                            return (
                                              <li
                                                onClick={(event) => {
                                                  event.stopPropagation();
                                                  selectOption(
                                                    eachitem[mapVal?.name]
                                                  );
                                                  setState({
                                                    ...stateValue,
                                                    [name]: eachitem[stateVal],
                                                  });
                                                }}
                                                key={
                                                  eachitem[mapVal?.name] + index
                                                }
                                              >
                                                {mapVal?.image && (
                                                  <img
                                                    src={
                                                      eachitem[mapVal?.image]
                                                    }
                                                    alt={eachitem[stateVal]}
                                                  />
                                                )}
                                                {eachitem[mapVal?.name]}
                                              </li>
                                            );
                                          })
                                          : title === "All Countries"
                                            ? dropdown?.length > 0 &&
                                            dropdown
                                              ?.filter((row) => {
                                                // Convert the row object values to an array and check if any value matches the search query
                                                const searchQueryy =
                                                  searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                                                return row
                                                  ?.toLowerCase()
                                                  ?.includes(searchQueryy);
                                              })
                                              ?.map((eachitem, index) => {
                                                return (
                                                  <li
                                                    onClick={(event) => {
                                                      event.stopPropagation();
                                                      selectOption(eachitem);
                                                      setState(eachitem);
                                                    }}
                                                    key={eachitem + index}
                                                    style={{
                                                      display:
                                                        eachitem === stateValue
                                                          ? "none"
                                                          : "",
                                                    }}
                                                  >
                                                    {eachitem}
                                                  </li>
                                                );
                                              })
                                            : (name === "Gender" ||
                                              name === "groupType" ||
                                              type === "single") &&
                                              dropdown?.length > 0
                                              ? dropdown?.map(
                                                (eachitem, index) => {
                                                  return (
                                                    <li
                                                      onClick={(event) => {
                                                        event.stopPropagation();
                                                        selectOption(eachitem);
                                                        setState({
                                                          ...stateValue,
                                                          [name]: eachitem,
                                                        });
                                                      }}
                                                      key={eachitem + index}
                                                    >
                                                      {eachitem}
                                                    </li>
                                                  );
                                                }
                                              )
                                              : name === "IndustriesId" &&
                                                dropdown?.length > 0
                                                ? dropdown?.map(
                                                  (eachitem, index) => {
                                                    return (
                                                      <li
                                                        onClick={(event) => {
                                                          event.stopPropagation();
                                                          selectOption(
                                                            eachitem?.Name
                                                          );
                                                          setState({
                                                            ...stateValue,
                                                            [name]:
                                                              eachitem?._id,
                                                          });
                                                        }}
                                                        key={
                                                          eachitem?.Name + index
                                                        }
                                                      >
                                                        {eachitem?.Name}
                                                      </li>
                                                    );
                                                  }
                                                )
                                                : name === "defaultDropdown" &&
                                                  dropdown?.length > 0
                                                  ? dropdown?.map(
                                                    (eachitem, index) => {
                                                      return (
                                                        <li
                                                          onClick={(event) => {
                                                            event.stopPropagation();
                                                            selectOption(
                                                              eachitem
                                                            );
                                                            setState(eachitem);
                                                          }}
                                                          key={index}
                                                        >
                                                          {eachitem}
                                                        </li>
                                                      );
                                                    }
                                                  )
                                                  : dropdown?.length > 0 &&
                                                  dropdown?.map(
                                                    (eachitem, index) => {
                                                      return (
                                                        <li
                                                          onClick={(event) => {
                                                            event.stopPropagation();
                                                            selectOption(
                                                              eachitem?.NameOfTeam
                                                            );
                                                            if (
                                                              name ===
                                                              "teamUsername"
                                                            ) {
                                                              setState(
                                                                (prev) => {
                                                                  return {
                                                                    ...prev,
                                                                    [name]:
                                                                      eachitem?.TeamUsername,
                                                                  };
                                                                }
                                                              );
                                                            } else if (
                                                              name ===
                                                              "selectedAppStaff"
                                                            ) {
                                                              setState({
                                                                NameOfTeam:
                                                                  eachitem?.NameOfTeam,
                                                                Icon: eachitem?.Icon,
                                                                TeamUsername:
                                                                  eachitem?.TeamUsername,
                                                              });
                                                            } else {
                                                              setState({
                                                                ...stateValue,
                                                                [name]: {
                                                                  NameOfTeam:
                                                                    eachitem?.NameOfTeam,
                                                                  Icon: eachitem?.Icon,
                                                                },
                                                              });
                                                            }
                                                          }}
                                                          key={
                                                            eachitem?.NameOfTeam +
                                                            index
                                                          }
                                                        >
                                                          <img
                                                            src={eachitem?.Icon}
                                                            alt={
                                                              eachitem?.NameOfTeam
                                                            }
                                                          />
                                                          {eachitem?.NameOfTeam}
                                                        </li>
                                                      );
                                                    }
                                                  )}
          </ul>
        )}
      </div>
    </div>
  );
};

export const EachInputCustom = ({
  title,
  topTitle,
  name,

  placeholder,
  stateValue,
  setState,
  label,
  setlabelName,
}) => {
  const [toogle, settoogle] = useState(false);
  console.log("namename", title);
  return (
    <div
      className={classNames.eachCustomInput}
      style={{
        pointerEvents: setState ? "" : "none",
        margin: "0px"
      }}
    >
      {topTitle && <div className={classNames.topTitle}>{title}</div>}
      <div
        style={{
          display: "flex",
          position: "relative",
          alignItems: "center",
          width: "100%",
        }}
      >
        <input
          style={{
            padding: "1rem 1.5rem",
            borderRadius: "5px",
            border: "1px solid #e5e5e5",
            fontSize: "0.8rem",
            background: "white",
            width: "100%",
            maxWidth: "500px",
            maxHeight: "400px",
            overflowY: "scroll",
            outline: "none",
            transition: "all ease-in 0.2s",
          }}
          className={classNames.inputContainer}
          placeholder={placeholder}
          value={name ? stateValue[name] : stateValue}
          onChange={(event) => {
            if (name) {
              setState((prev) => {
                return {
                  ...prev,
                  [name]: event?.target?.value,
                };
              });
            } else {
              setState(event?.target?.value);
            }
          }}
        />
        <div
          style={{
            position: "absolute",
            fontSize: "0.8rem",
            right: "10px",
            borderLeft: "1px solid #e5e5e5",
            padding: "0 1rem",
            height: "100%",
            display: "flex",
            alignItems: "center",
            fontWeight: "600"
          }}
          onClick={() => settoogle(!toogle)}
        >
          {label}
        </div>
        {title == "Price Per Day" && (
          <>
            {toogle && (
              <div
                style={{
                  position: "absolute",
                  fontSize: "0.8rem",
                  right: "0px",
                  border: "1px solid #e5e5e5",
                  padding: "0 1rem",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  top: "47px",
                  width: "99px",
                }}
                onClick={() => {
                  setlabelName(label == "Per Hour" ? "Per Day" : "Per Hour");
                  settoogle(false);
                }}
              >
                {label == "Per Hour" ? "Per Day" : "Per Hour"}
              </div>
            )}
          </>
        )}
        {title == "Price Per Hour" && (
          <>
            {toogle && (
              <div
                style={{
                  position: "absolute",
                  fontSize: "0.8rem",
                  right: "0px",
                  border: "1px solid #e5e5e5",
                  padding: "0 1rem",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  top: "47px",
                  width: "99px",
                }}
                onClick={() => {
                  setlabelName(label == "Per Hour" ? "Per Day" : "Per Hour");
                  settoogle(false);
                }}
              >
                {label == "Per Hour" ? "Per Day" : "Per Hour"}
              </div>
            )}
          </>
        )}

        {title == "What is the duration of this service?" ? (
          <>
            {toogle && (
              <div
                style={{
                  position: "absolute",
                  fontSize: "0.8rem",
                  right: "0px",
                  border: "1px solid #e5e5e5",
                  padding: "0 1rem",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  top: "47px",
                  width: "89px",
                }}
                onClick={() => {
                  // setlabelName(label == "Hours" ? "Minutes" : "Hours");
                  settoogle(false);
                }}
              >
                Mins
              </div>
            )}
          </>
        ) : (
          title == "What is the duration of the therapy?" && (
            <>
              {toogle && (
                <div
                  style={{
                    position: "absolute",
                    fontSize: "0.8rem",
                    right: "0px",
                    border: "1px solid #e5e5e5",
                    padding: "0 1rem",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    top: "47px",
                    width: "89px",
                  }}
                  onClick={() => {
                    // setlabelName(label == "Hours" ? "Minutes" : "Hours");
                    settoogle(false);
                  }}
                >
                  Mins
                </div>
              )}
            </>
          )
        )}
      </div>
    </div>
  );
};

export const EachInputsepfic = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
}) => {
  return (
    <div
      className={classNames.eachCustomInput}
      style={{
        pointerEvents: setState ? "" : "none",
      }}
    >
      {topTitle && <div className={classNames.topTitle}>{title}</div>}
      <input
        className={classNames.inputContainer}
        placeholder={placeholder}
        value={name ? stateValue[name] : stateValue}
        onChange={(event) => {
          const regex = /^[a-zA-Z0-9_]+$/;
          const { value } = event.target;

          if (
            regex.test(value) && title == "What is the name of the category?"
              ? value?.length > 0
              : value?.length < 30
          ) {
            if (name) {
              setState((prev) => {
                return {
                  ...prev,
                  [name]: event?.target?.value?.replace(/[^\w\s]/gi, ""),
                };
              });
            } else {
              setState(event?.target?.value);
            }
          }
        }}
      />
    </div>
  );
};

export const MyEachInput = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
}) => {
  return (
    <div
      className={classNames.eachCustomInput}
      style={{
        pointerEvents: setState ? "" : "none",
      }}
    >
      {topTitle && (
        <div
          style={{
            fontWeight: "500",
          }}
          className={classNames.topTitle}
        >
          {title}
        </div>
      )}
      <input
        className={classNames.inputContainer}
        style={{
          maxWidth: "100%",
        }}
        placeholder={placeholder}
        value={name ? stateValue[name] : stateValue}
        onChange={(event) => {
          if (name) {
            setState((prev) => {
              return {
                ...prev,
                [name]: event?.target?.value,
              };
            });
          } else {
            setState(event?.target?.value);
          }
        }}
      />
    </div>
  );
};

export const EachInput = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
  required,
}) => {
  return (
    <div
      className={classNames.eachCustomInput}
      style={{
        pointerEvents: setState ? "" : "none",
      }}
    >
      {topTitle && (
        <div className={classNames.topTitle}>
          {title}&nbsp;
          {required && <span className={classNames.astric}>*</span>}
        </div>
      )}
      <input
        className={classNames.inputContainer}
        placeholder={placeholder}
        value={name ? stateValue[name] : stateValue}
        onChange={(event) => {
          if (name) {
            setState((prev) => {
              return {
                ...prev,
                [name]: event?.target?.value,
              };
            });
          } else {
            setState(event?.target?.value);
          }
        }}
      />
    </div>
  );
};

export const EndDayMarignEachInput = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
  indexx
}) => {
  // const handleTextChange = (categoryId, itemId, newText) => {
  //   setData(prevData =>
  //     prevData.map(category =>
  //       category.id === categoryId
  //         ? {
  //           ...category,
  //           items: category.items.map(item =>
  //             item.id === itemId ? { ...item, text: newText } : item
  //           )
  //         }
  //         : category
  //     )
  //   );
  // };
  const handleChange = (id, newText) => {
    setState(prevData => {
      return {
        ...prevData,
        WorkingDays: prevData.WorkingDays.map((item, i) =>
          i === id ? { ...item, EndTimeText: newText?.replace(/[^0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g, '') } : item
        )
      }
    }
      // [prevData].map((category, z) =>
      //   z == 0
      //     ? {
      //       ...category,
      //       WorkingDays: category.WorkingDays.map((item, i) =>
      //         i === id ? { ...item, StartTime: newText } : item
      //       )
      //     }
      //     : category
      // )
    );


  };
  return (
    <div
      className={classNames.eachCustomInput}
      style={{
        pointerEvents: setState ? "" : "none",
        margin: "0rem",
        width: title == "cutom?" ? "297px" : "100%",
      }}
    >
      {topTitle && (
        <div
          className={classNames.topTitle}
          style={{
            fontWeight: "700",
          }}
        >
          {title}
        </div>
      )}
      <input
        className={classNames.inputContainer}
        placeholder={placeholder}
        value={stateValue?.WorkingDays?.[indexx]?.EndTimeText}
        onChange={e => handleChange(indexx, e.target.value)}

      // onChange={(event) => {
      //   setState((prev) => {

      //     // const updatedAnnualHolidays = prev;
      //     console.log("1233333333333333333333", [...prev])

      //   })
      //   // updatedAnnualHolidays[indexx] = {
      //   //   ...updatedAnnualHolidays[indexx],
      //   //   [name]: event?.target?.value,
      //   // };
      //   // return {
      //   //   ...stateValue,
      //   //   [name]: updatedAnnualHolidays,
      //   // };
      // }}
      // [stateValue?.WorkingDays[indexx]?.[name]]: event?.target?.value,


      // onChange={(event) => {
      //    const updatedAnnualHolidays = [...stateValue[name]]
      //   console.log("[name]", stateValue?.WorkingDays[indexx]?.[name])

      //     setState((prev) => {
      //       console.log("1233333333333333333333", [prev?.WorkingDays?.[indexx]?.Day])
      //       return {
      //         ...prev,
      //         [prev?.WorkingDays?.[indexx]?.Day]: event?.target?.value,
      //       };
      //     });

      // }}
      />
    </div>
  );
};
export const BreakEndDayMarignEachInput = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
  indexx,
  lastindex
}) => {
  // const handleTextChange = (categoryId, itemId, newText) => {
  //   setData(prevData =>
  //     prevData.map(category =>
  //       category.id === categoryId
  //         ? {
  //           ...category,
  //           items: category.items.map(item =>
  //             item.id === itemId ? { ...item, text: newText } : item
  //           )
  //         }
  //         : category
  //     )
  //   );
  // };
  const handleChange = (id, newText, lastindex) => {
    setState(prevData => {
      return {
        ...prevData,
        WorkingDays: prevData.WorkingDays.map((item, i) =>
          i === id ? {
            ...item, breakstart: item?.breakstart?.map((such, z) =>
              z == lastindex ? { ...such, EndTimeText: newText?.replace(/[^0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g, '') } : such

            )

          } : item
        )
      }
    }
      // [prevData].map((category, z) =>
      //   z == 0
      //     ? {
      //       ...category,
      //       WorkingDays: category.WorkingDays.map((item, i) =>
      //         i === id ? { ...item, StartTime: newText } : item
      //       )
      //     }
      //     : category
      // )
    );


  };
  return (
    <div
      className={classNames.eachCustomInput}
      style={{
        pointerEvents: setState ? "" : "none",
        margin: "0rem",
        width: title == "cutom?" ? "297px" : "100%",
      }}
    >
      {topTitle && (
        <div
          className={classNames.topTitle}
          style={{
            fontWeight: "700",
          }}
        >
          {title}
        </div>
      )}
      <input
        className={classNames.inputContainer}
        placeholder={placeholder}
        value={stateValue?.WorkingDays?.[indexx]?.breakstart?.[lastindex]?.EndTimeText}

        onChange={e => handleChange(indexx, e.target.value, lastindex)}

      // onChange={(event) => {
      //   setState((prev) => {

      //     // const updatedAnnualHolidays = prev;
      //     console.log("1233333333333333333333", [...prev])

      //   })
      //   // updatedAnnualHolidays[indexx] = {
      //   //   ...updatedAnnualHolidays[indexx],
      //   //   [name]: event?.target?.value,
      //   // };
      //   // return {
      //   //   ...stateValue,
      //   //   [name]: updatedAnnualHolidays,
      //   // };
      // }}
      // [stateValue?.WorkingDays[indexx]?.[name]]: event?.target?.value,


      // onChange={(event) => {
      //    const updatedAnnualHolidays = [...stateValue[name]]
      //   console.log("[name]", stateValue?.WorkingDays[indexx]?.[name])

      //     setState((prev) => {
      //       console.log("1233333333333333333333", [prev?.WorkingDays?.[indexx]?.Day])
      //       return {
      //         ...prev,
      //         [prev?.WorkingDays?.[indexx]?.Day]: event?.target?.value,
      //       };
      //     });

      // }}
      />
    </div>
  );
};
export const DateBreakDayMarignEachInput = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
  indexx,
  lastindex
}) => {
  // const handleTextChange = (categoryId, itemId, newText) => {
  //   setData(prevData =>
  //     prevData.map(category =>
  //       category.id === categoryId
  //         ? {
  //           ...category,
  //           items: category.items.map(item =>
  //             item.id === itemId ? { ...item, text: newText } : item
  //           )
  //         }
  //         : category
  //     )
  //   );
  // };
  console.log("stateValue?.WorkingDays?.[indexx]?.breakstart?.[lastindex]?.StartTime", stateValue?.WorkingDays?.[indexx]?.breakstart?.[lastindex]?.StartTimeText)
  const handleChange = (id, newText, lastindex) => {
    alert(newText)
    setState(prevData => {
      return {
        ...prevData,
        WorkingDays: prevData.WorkingDays.map((item, i) =>
          i === id ? {
            ...item, breakstart: item?.breakstart?.map((such, z) =>
              z == lastindex ? { ...such, StartTimeText: `${newText?.replace(/[^0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g, '')}` } : such

            )

          } : item
        )
      }
    }
      // [prevData].map((category, z) =>
      //   z == 0
      //     ? {
      //       ...category,
      //       WorkingDays: category.WorkingDays.map((item, i) =>
      //         i === id ? { ...item, StartTime: newText } : item
      //       )
      //     }
      //     : category
      // )
    );


  };
  const inputRef = useRef(null);
  const handleFocus = (event) => {

    inputRef.current.select();
  };
  return (
    <div
      className={classNames.eachCustomInput}
      style={{
        pointerEvents: setState ? "" : "none",
        margin: "0rem",
        width: title == "cutom?" ? "297px" : "100%",
      }}
    >
      {topTitle && (
        <div
          className={classNames.topTitle}
          style={{
            fontWeight: "700",
          }}
        >
          {title}
        </div>
      )}


      <div className="Selectiontime">

        <select
          style={{
            padding: "1rem 1.5rem",
            borderRadius: "5px",
            border: "1px solid #e5e5e5",
            fontSize: "0.8rem",
            background: "white",
            width: "100px",


          }}
          value={stateValue?.WorkingDays?.[indexx]?.breakstart?.[lastindex]?.StartTimeText}
          onChange={e => handleChange(indexx, e.target.value, lastindex)}
        >
          <option value="" disabled selected>HH</option>
          {
            [1, 2, 3, 3]?.map((item, i) => {
              return (
                <option value={i + 1}>{i + 1}</option>
              )
            })
          }

        </select>

      </div>






      {/* <input
        className={classNames.inputContainer}
        placeholder={placeholder}
        value={stateValue?.WorkingDays?.[indexx]?.breakstart?.[lastindex]?.StartTimeText}
        type="time"
        onChange={e => handleChange(indexx, e.target.value, lastindex)}
        ref={inputRef}
        onFocus={e => e.target.select()}

      /> */}
      <div  >
        <div>

        </div>
      </div>
    </div>
  );
};


export const BreakDayMarignEachInput = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
  indexx,
  lastindex
}) => {
  // const handleTextChange = (categoryId, itemId, newText) => {
  //   setData(prevData =>
  //     prevData.map(category =>
  //       category.id === categoryId
  //         ? {
  //           ...category,
  //           items: category.items.map(item =>
  //             item.id === itemId ? { ...item, text: newText } : item
  //           )
  //         }
  //         : category
  //     )
  //   );
  // };
  console.log("stateValue?.WorkingDays?.[indexx]?.breakstart?.[lastindex]?.StartTime", stateValue?.WorkingDays?.[indexx]?.breakstart?.[lastindex]?.StartTimeText)
  const handleChange = (id, newText, lastindex) => {
    setState(prevData => {
      return {
        ...prevData,
        WorkingDays: prevData.WorkingDays.map((item, i) =>
          i === id ? {
            ...item, breakstart: item?.breakstart?.map((such, z) =>
              z == lastindex ? { ...such, StartTimeText: newText?.replace(/[^0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g, '') } : such

            )

          } : item
        )
      }
    }
      // [prevData].map((category, z) =>
      //   z == 0
      //     ? {
      //       ...category,
      //       WorkingDays: category.WorkingDays.map((item, i) =>
      //         i === id ? { ...item, StartTime: newText } : item
      //       )
      //     }
      //     : category
      // )
    );


  };
  return (
    <div
      className={classNames.eachCustomInput}
      style={{
        pointerEvents: setState ? "" : "none",
        margin: "0rem",
        width: title == "cutom?" ? "297px" : "100%",
      }}
    >
      {topTitle && (
        <div
          className={classNames.topTitle}
          style={{
            fontWeight: "700",
          }}
        >
          {title}
        </div>
      )}
      <input
        className={classNames.inputContainer}
        placeholder={placeholder}
        value={stateValue?.WorkingDays?.[indexx]?.breakstart?.[lastindex]?.StartTime}

        onChange={e => handleChange(indexx, e.target.value, lastindex)}

      // onChange={(event) => {
      //   setState((prev) => {

      //     // const updatedAnnualHolidays = prev;
      //     console.log("1233333333333333333333", [...prev])

      //   })
      //   // updatedAnnualHolidays[indexx] = {
      //   //   ...updatedAnnualHolidays[indexx],
      //   //   [name]: event?.target?.value,
      //   // };
      //   // return {
      //   //   ...stateValue,
      //   //   [name]: updatedAnnualHolidays,
      //   // };
      // }}
      // [stateValue?.WorkingDays[indexx]?.[name]]: event?.target?.value,


      // onChange={(event) => {
      //    const updatedAnnualHolidays = [...stateValue[name]]
      //   console.log("[name]", stateValue?.WorkingDays[indexx]?.[name])

      //     setState((prev) => {
      //       console.log("1233333333333333333333", [prev?.WorkingDays?.[indexx]?.Day])
      //       return {
      //         ...prev,
      //         [prev?.WorkingDays?.[indexx]?.Day]: event?.target?.value,
      //       };
      //     });

      // }}
      />
    </div>
  );
};

export const DayMarignEachInput = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
  indexx
}) => {
  // const handleTextChange = (categoryId, itemId, newText) => {
  //   setData(prevData =>
  //     prevData.map(category =>
  //       category.id === categoryId
  //         ? {
  //           ...category,
  //           items: category.items.map(item =>
  //             item.id === itemId ? { ...item, text: newText } : item
  //           )
  //         }
  //         : category
  //     )
  //   );
  // };
  const handleChange = (id, newText) => {


    setState(prevData => {
      return {
        ...prevData,
        WorkingDays: prevData.WorkingDays.map((item, i) =>
          i === id ? { ...item, StartTimeText: newText?.replace(/[^0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g, '') } : item
        )
      }

    }
      // [prevData].map((category, z) =>
      //   z == 0
      //     ? {
      //       ...category,
      //       WorkingDays: category.WorkingDays.map((item, i) =>
      //         i === id ? { ...item, StartTime: newText } : item
      //       )
      //     }
      //     : category
      // )
    );


  };
  console.log("stataaaaaaaaaaaaaaeValue", stateValue?.WorkingDays?.[indexx], "dcg"[name])
  const handleInputChangasde = (event) => {
    const value = event.target.value;
    const regex = /^[0-9+\-*/]*$/; // Regular expression to allow numbers and +, -, *, /
    if (regex.test(value)) {
      // setInputValue(value);
    }
  };
  return (
    <div
      className={classNames.eachCustomInput}
      style={{
        pointerEvents: setState ? "" : "none",
        margin: "0rem",
        width: title == "cutom?" ? "297px" : "100%",
      }}
    >
      {topTitle && (
        <div
          className={classNames.topTitle}
          style={{
            fontWeight: "700",
          }}
        >
          {title}
        </div>
      )}
      <input
        className={classNames.inputContainer}
        placeholder={placeholder}
        value={stateValue?.WorkingDays?.[indexx]?.StartTimeText}

        onChange={e => handleChange(indexx, e.target.value)}

      // onChange={(event) => {
      //   setState((prev) => {

      //     // const updatedAnnualHolidays = prev;
      //     console.log("1233333333333333333333", [...prev])

      //   })
      //   // updatedAnnualHolidays[indexx] = {
      //   //   ...updatedAnnualHolidays[indexx],
      //   //   [name]: event?.target?.value,
      //   // };
      //   // return {
      //   //   ...stateValue,
      //   //   [name]: updatedAnnualHolidays,
      //   // };
      // }}
      // [stateValue?.WorkingDays[indexx]?.[name]]: event?.target?.value,


      // onChange={(event) => {
      //    const updatedAnnualHolidays = [...stateValue[name]]
      //   console.log("[name]", stateValue?.WorkingDays[indexx]?.[name])

      //     setState((prev) => {
      //       console.log("1233333333333333333333", [prev?.WorkingDays?.[indexx]?.Day])
      //       return {
      //         ...prev,
      //         [prev?.WorkingDays?.[indexx]?.Day]: event?.target?.value,
      //       };
      //     });

      // }}
      />
    </div>
  );
};

export const PhoneEachInput = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
}) => {




  const validatePhoneNumber = (event) => {
    event.preventDefault();
    const phoneRegex = /^[+]?[0-9]*$/;
    let asd = event?.target?.value
    const phoneRege1 = /^[+]?[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/;
    if (asd?.length <= 12) {
      if (name) {
        setState((prev) => {
          return {
            ...prev,
            [name]: event?.target?.value.replace(/[^\d.-]+/g, ''),
          };
        });
      }
    }
    // if (asd?.length <= 12) {
    //   if (phoneRegex.test(asd)) {

    //   }
    //   else {

    //     toastify('Invalid phone number. Please enter a valid phone number.', "error");
    //   }

    // }
    // if (phoneRegex.test(asd)) {

    // } else {

    // }


  };

  return (
    <div
      className={classNames.eachCustomInput}
      style={{
        pointerEvents: setState ? "" : "none",
        margin: "0rem",
        width: title == "cutom?" ? "297px" : "100%",
      }}
    >
      {topTitle && (
        <div
          className={classNames.topTitle}
          style={{
            fontWeight: "700",
          }}
        >
          {title}
        </div>
      )}
      <input
        className={classNames.inputContainer}
        placeholder={placeholder}
        value={name ? stateValue[name] : stateValue}
        onChange={(event) => {
          validatePhoneNumber(event)
        }}
      />
    </div>
  );
};


export const MarignEachInput = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
}) => {

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^[+]?[1-9]\d{1,14}$/; // E.164 format
    return phoneRegex.test(number);
  };

  return (
    <div
      className={classNames.eachCustomInput}
      style={{
        pointerEvents: setState ? "" : "none",
        margin: "0rem",
        width: title == "cutom?" ? "297px" : "100%",
      }}
    >
      {topTitle && (
        <div
          className={classNames.topTitle}
          style={{
            fontWeight: "700",
          }}
        >
          {title}
        </div>
      )}
      <input
        className={classNames.inputContainer}
        placeholder={placeholder}
        value={name ? stateValue[name] : stateValue}
        onChange={(event) => {
          if (name) {
            setState((prev) => {
              return {
                ...prev,
                [name]: event?.target?.value,
              };
            });
          } else {
            setState(event?.target?.value);
          }
        }}
      />
    </div>
  );
};
export const TimeNestedMarignEachInput = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
  outerindex,
  innerindex
}) => {



  const handleInputChange = (outerindex, innerindex, event) => {
    let pop = stateValue?.map((item, i) => {
      if (i == outerindex) {
        return {
          ...item,
          dayplan: item?.dayplan?.map((such, z) => {
            if (z == innerindex) {
              return {
                ...such, [name]: event.target.value
              }
            }
            return such;
          })
        }
      }
      return item;
    })
    // let lol=[...stateValue,pop]
    //   const newInputs = [...stateValue];
    //   newInputs?.[outerindex]?.dayplan?.[innerindex]?.unitname = "oo";
    //  setState(newInputs);

    setState(pop)
    console.log("lololo", pop)
  };
  return (
    <div
      className={classNames.eachCustomInput}
      style={{
        pointerEvents: setState ? "" : "none",
        margin: "0rem",
        width: title == "cutom?" ? "297px" : "100%",
      }}
    >
      {topTitle && (
        <div
          className={classNames.topTitle}
          style={{
            fontWeight: "700",
          }}
        >
          {title}
        </div>
      )}
      <input
        className={classNames.inputContainer}
        placeholder={placeholder}
        value={name ? stateValue[name] : stateValue}
        onChange={(event) => handleInputChange(outerindex, innerindex, event)}
      // onChange={(event) => {
      //   if (name) {
      //     setState((prev) => {
      //       return {
      //         ...prev,
      //         [name]: event?.target?.value,
      //       };
      //     });
      //   } else {
      //     setState(event?.target?.value);
      //   }
      // }}
      />
    </div>
  );
};
export const NestedMarignEachInput = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
  outerindex,
  innerindex
}) => {



  const handleInputChange = (outerindex, innerindex, event) => {
    let pop = stateValue?.map((item, i) => {
      if (i == outerindex) {
        return {
          ...item,
          dayplan: item?.dayplan?.map((such, z) => {
            if (z == innerindex) {
              return {
                ...such, [name]: event.target.value
              }
            }
            return such;
          })
        }
      }
      return item;
    })
    // let lol=[...stateValue,pop]
    //   const newInputs = [...stateValue];
    //   newInputs?.[outerindex]?.dayplan?.[innerindex]?.unitname = "oo";
    //  setState(newInputs);

    setState(pop)
    console.log("lololo", pop)
  };
  return (
    <div
      className={classNames.eachCustomInput}
      style={{
        pointerEvents: setState ? "" : "none",
        margin: "0rem",
        width: title == "cutom?" ? "297px" : "100%",
      }}
    >
      {topTitle && (
        <div
          className={classNames.topTitle}
          style={{
            fontWeight: "700",
          }}
        >
          {title}
        </div>
      )}
      <input
        className={classNames.inputContainer}
        placeholder={placeholder}
        value={name ? stateValue[name] : stateValue}
        onChange={(event) => handleInputChange(outerindex, innerindex, event)}
      // onChange={(event) => {
      //   if (name) {
      //     setState((prev) => {
      //       return {
      //         ...prev,
      //         [name]: event?.target?.value,
      //       };
      //     });
      //   } else {
      //     setState(event?.target?.value);
      //   }
      // }}
      />
    </div>
  );
};

export const TimeMRsNestEachCustomDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  topTitle,
  type,
  stateVal,
  mapVal,
  indexx,
  calendarObjField,
  eachValuee,
  outerindex,
  innerindex
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => { });
  console.log("stateValfffff", stateValue);

  useEffect(() => {
    console.log(stateValue, "ljwekgfhkwjegfkjwef");
  }, [stateValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  useEffect(() => {
    if (
      name === "selectedAppStaff" &&
      Array?.isArray(dropdown) &&
      dropdown?.length > 0
    ) {
      setState({
        NameOfTeam: dropdown[0]?.NameOfTeam,
        Icon: dropdown[0]?.Icon,
        TeamUsername: dropdown[0]?.TeamUsername,
      });
    }
  }, [dropdown]);

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        minWidth: "99px",
        // minWidth: isOpen ? "250px" : "",
        zIndex: isOpen ? "3" : "",
        height: topTitle ? "5.25rem" : "3rem",
      }}
    >
      {topTitle && <div className={classNames.topTitle}>{title}</div>}
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: topTitle ? "2rem" : "",
          maxHeight: isOpen ? "400px" : "100%",
          width: name == "peroid" && "120px",
          padding: name == "peroid" ? "10px" : name == "gendersignature" && "1rem 0.5rem",
          borderBottom: name === "selectedAppStaff" && !isOpen ? "none" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {

              stateValue?.[outerindex]?.dayplan[innerindex]?.timeshulde
            }
          </span>
          <span
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
              return;
            }}
          >
            {isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
          </span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            {dropdown?.length > 0
              ? dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event,) => {
                      event.stopPropagation();

                      let pop = stateValue?.map((item, i) => {
                        if (i == outerindex) {
                          return {
                            ...item,
                            dayplan: item?.dayplan?.map((such, z) => {
                              if (z == innerindex) {
                                return {
                                  ...such, timeshulde: each,

                                }
                              }
                              return such;
                            })
                          }
                        }
                        return item;
                      })
                      // let lol=[...stateValue,pop]
                      //   const newInputs = [...stateValue];
                      //   newInputs?.[outerindex]?.dayplan?.[innerindex]?.unitname = "oo";
                      //  setState(newInputs);
                      console.log("rwefsdsfdfd", pop)
                      setState(pop)

                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    {each}
                  </li>
                );
              })
              : ""}
          </ul>
        )}
      </div>
    </div>
  );
};

export const MRsNestEachCustomDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  topTitle,
  type,
  stateVal,
  mapVal,
  indexx,
  calendarObjField,
  eachValuee,
  outerindex,
  innerindex
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => { });
  console.log("stateValfffff", stateValue);

  useEffect(() => {
    console.log(stateValue, "ljwekgfhkwjegfkjwef");
  }, [stateValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  useEffect(() => {
    if (
      name === "selectedAppStaff" &&
      Array?.isArray(dropdown) &&
      dropdown?.length > 0
    ) {
      setState({
        NameOfTeam: dropdown[0]?.NameOfTeam,
        Icon: dropdown[0]?.Icon,
        TeamUsername: dropdown[0]?.TeamUsername,
      });
    }
  }, [dropdown]);

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        minWidth: "99px",
        // minWidth: isOpen ? "250px" : "",
        zIndex: isOpen ? "3" : "",
        height: topTitle ? "5.25rem" : "3rem",
      }}
    >
      {topTitle && <div className={classNames.topTitle}>{title}</div>}
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: topTitle ? "2rem" : "",
          maxHeight: isOpen ? "400px" : "100%",
          width: name == "peroid" && "120px",
          padding: name == "peroid" ? "10px" : name == "gendersignature" && "1rem 0.5rem",
          borderBottom: name === "selectedAppStaff" && !isOpen ? "none" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {

              stateValue?.[outerindex]?.dayplan[innerindex]?.kitchenitemName == "" ? "Select kitchen item" : stateValue?.[outerindex]?.dayplan[innerindex]?.kitchenitemName
            }
          </span>
          <span
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
              return;
            }}
          >
            {isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
          </span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            {dropdown?.length > 0
              ? dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event,) => {
                      event.stopPropagation();

                      let pop = stateValue?.map((item, i) => {
                        if (i == outerindex) {
                          return {
                            ...item,
                            dayplan: item?.dayplan?.map((such, z) => {
                              if (z == innerindex) {
                                return {
                                  ...such, kitchenitemName: each?.name,
                                  kitchenitemid: each?.kitchenitemid,
                                }
                              }
                              return such;
                            })
                          }
                        }
                        return item;
                      })
                      // let lol=[...stateValue,pop]
                      //   const newInputs = [...stateValue];
                      //   newInputs?.[outerindex]?.dayplan?.[innerindex]?.unitname = "oo";
                      //  setState(newInputs);
                      console.log("rwefsdsfdfd", pop)
                      setState(pop)

                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    {each?.name}
                  </li>
                );
              })
              : ""}
          </ul>
        )}
      </div>
    </div>
  );
};

export const EachInputDummy = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
}) => {
  return (
    <div
      className={classNames.eachCustomInput}
      style={{
        pointerEvents: setState ? "" : "none",
      }}
    >
      {topTitle && <div className={classNames.topTitle}>{title}</div>}
      <input
        className={classNames.inputContainer}
        placeholder={placeholder}
        style={{
          width: "7rem",
        }}
        value={name ? stateValue[name] : stateValue}
        onChange={(event) => {
          if (name) {
            setState((prev) => {
              return {
                ...prev,
                [name]: event?.target?.value,
              };
            });
          } else {
            setState(event?.target?.value);
          }
        }}
      />
    </div>
  );
};

export const MyEachCustomTextarea = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
}) => {
  return (
    <div className={classNames.eachCustomTextarea}>
      {topTitle && (
        <div
          style={{
            fontWeight: "500",
          }}
          className={classNames.topTitle}
        >
          {title}
        </div>
      )}
      <textarea
        className={classNames.inputContainer}
        style={{
          maxWidth: "100%",
        }}
        placeholder={placeholder}
        value={name ? stateValue[name] : stateValue}
        onChange={(event) => {
          if (setState) {
            if (name) {
              setState((prev) => {
                return {
                  ...prev,
                  [name]: event?.target?.value,
                };
              });
            } else {
              setState(event?.target?.value);
            }
          }
        }}
      />
    </div>
  );
};

export const MarginEachCustomTextarea = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
}) => {
  return (
    <div className={classNames.eachCustomTextarea}
      style={{
        marginTop: "0rem",
        marginBottom: "0",
        height: "63px"
      }}
    >
      {topTitle && <div className={classNames.topTitle}>{title}</div>}
      <textarea
        className={classNames.inputContainer}
        placeholder={placeholder}
        value={name ? stateValue[name] : stateValue}
        onChange={(event) => {
          if (setState) {
            if (name) {
              setState((prev) => {
                return {
                  ...prev,
                  [name]: event?.target?.value,
                };
              });
            } else {
              setState(event?.target?.value);
            }
          }
        }}
      />
    </div>
  );
};

export const EachCustomTextarea = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
}) => {
  return (
    <div className={classNames.eachCustomTextarea}>
      {topTitle && <div className={classNames.topTitle}>{title}</div>}
      <textarea
        // style={{
        //   height: "100%"
        // }}
        className={classNames.inputContainer}
        placeholder={placeholder}
        value={name ? stateValue[name] : stateValue}
        onChange={(event) => {
          if (setState) {
            if (name) {
              setState((prev) => {
                return {
                  ...prev,
                  [name]: event?.target?.value,
                };
              });
            } else {
              setState(event?.target?.value);
            }
          }
        }}
      />
    </div>
  );
};
export const EachCustomImageUploadcustom = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
}) => {
  //image update form
  const fileInputRefNewUser = useRef(null);
  const [uploadFileRFQ, setUploadFileRFQ] = useState("");
  const [uploadFileRFQName, setUploadFileRFQName] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);

  return (
    <div className={classNames.eachCustomImageUpload}>
      <div className={classNames.topTitle}>{title}</div>
      <div
        className={classNames.imageInput}
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {uploadFileRFQ ? (
          <img
            src={uploadFileRFQ}
            alt={uploadFileRFQName}
            style={{
              width: "5.25rem",
              height: "5.25rem",
              borderRadius: "55rem",
            }}
          />
        ) : (
          <div
            className={classNames.emptyProfilecustom}
            onClick={() => {
              if (fileInputRefNewUser.current) {
                fileInputRefNewUser.current.click();
              }
            }}
          >
            <img
              src={uploadIcon}
              alt="uploadIcon"
              style={{
                animation: updateLoading ? "heartbeat 1s infinite" : "",
              }}
            />
          </div>
        )}
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          style={{ display: "none" }}
          onChange={async (e) => {
            let resFile = uploadFileFunc(
              e,
              setUploadFileRFQName,
              setUpdateLoading,
              "name"
            );
            console.log(await resFile, "res file link await");
            setUploadFileRFQ(await resFile);
            let profileFile = await resFile;
            setState((prev) => {
              return {
                ...prev,
                [name]: profileFile,
              };
            });
          }}
          ref={fileInputRefNewUser}
        />
        <div
          className={classNames.imageLabelcustom}
          onClick={() => {
            if (fileInputRefNewUser.current) {
              fileInputRefNewUser.current.click();
            }
          }}
        >
          Upload Photo
        </div>
      </div>
    </div>
  );
};

export const EachCustomImageUpload = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
}) => {
  //image update form
  const fileInputRefNewUser = useRef(null);
  const [uploadFileRFQ, setUploadFileRFQ] = useState("");
  const [uploadFileRFQName, setUploadFileRFQName] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);

  return (
    <div className={classNames.eachCustomImageUpload}>
      {topTitle && <div className={classNames.topTitle}>{title}</div>}
      <div className={classNames.imageInput}>
        {uploadFileRFQ ? (
          <img src={uploadFileRFQ} alt={uploadFileRFQName} />
        ) : (
          <div
            className={classNames.emptyProfile}
            onClick={() => {
              if (fileInputRefNewUser.current) {
                fileInputRefNewUser.current.click();
              }
            }}
          >
            <img
              src={uploadIcon}
              alt="uploadIcon"
              style={{
                animation: updateLoading ? "heartbeat 1s infinite" : "",
              }}
            />
          </div>
        )}
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          style={{ display: "none" }}
          onChange={async (e) => {
            let resFile = uploadFileFunc(
              e,
              setUploadFileRFQName,
              setUpdateLoading,
              "name"
            );
            console.log(await resFile, "res file link await");
            setUploadFileRFQ(await resFile);
            let profileFile = await resFile;
            setState((prev) => {
              return {
                ...prev,
                [name]: profileFile,
              };
            });
          }}
          ref={fileInputRefNewUser}
        />
        <div
          className={classNames.imageLabel}
          onClick={() => {
            if (fileInputRefNewUser.current) {
              fileInputRefNewUser.current.click();
            }
          }}
        >
          {placeholder}
        </div>
      </div>
    </div>
  );
};

export const MyEachCustomImageUpload = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
}) => {
  //image update form
  const fileInputRefNewUser = useRef(null);
  const [uploadFileRFQ, setUploadFileRFQ] = useState("");
  const [uploadFileRFQName, setUploadFileRFQName] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);

  return (
    <div className={classNames.eachCustomImageUpload}>
      {topTitle && (
        <div
          className={classNames.topTitle}
          style={{
            fontWeight: "700",
          }}
        >
          {title}
        </div>
      )}
      <div className={classNames.imageInput}>
        {uploadFileRFQ ? (
          <img
            src={uploadFileRFQ}
            alt={uploadFileRFQName}
            onClick={() => {
              if (fileInputRefNewUser.current) {
                fileInputRefNewUser.current.click();
              }
            }}
          />
        ) : (
          <div
            className={classNames.emptyProfile}
            onClick={() => {
              if (fileInputRefNewUser.current) {
                fileInputRefNewUser.current.click();
              }
            }}
          >
            <img
              src={uploadIcon}
              alt="uploadIcon"
              style={{
                animation: updateLoading ? "heartbeat 1s infinite" : "",
              }}
            />
          </div>
        )}
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          style={{ display: "none" }}
          onChange={async (e) => {
            let resFile = uploadFileFunc(
              e,
              setUploadFileRFQName,
              setUpdateLoading,
              "name"
            );
            console.log(await resFile, "res file link await");
            setUploadFileRFQ(await resFile);
            let profileFile = await resFile;
            setState((prev) => {
              return {
                ...prev,
                [name]: profileFile,
              };
            });
          }}
          ref={fileInputRefNewUser}
        />
        <div
          className={classNames.imageLabel}
          style={{
            marginTop: "0.8rem",
            color: "rgba(0, 0, 0, 0.5)",
          }}
        >
          {placeholder}
        </div>
      </div>
    </div>
  );
};

export const MYEachCustomImageUpload = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
}) => {
  //image update form
  const fileInputRefNewUser = useRef(null);
  const [uploadFileRFQ, setUploadFileRFQ] = useState("");
  const [uploadFileRFQName, setUploadFileRFQName] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);

  return (
    <div className={classNames.eachCustomImageUpload}>
      {topTitle && (
        <div
          style={{
            fontWeight: "500",
          }}
          className={classNames.topTitle}
        >
          {title}
        </div>
      )}
      <div className={classNames.imageInput}>
        {title == "Current icon" ? (
          <img
            src={stateValue}
            style={{
              objectFit: "contain",
              minWidth: "162px",
            }}
          />
        ) : (
          <>
            {uploadFileRFQ ? (
              <img
                style={{
                  objectFit: "contain",
                  minWidth: "162px",
                }}
                src={uploadFileRFQ}
                alt={uploadFileRFQName}
                onClick={() => {
                  if (fileInputRefNewUser.current) {
                    fileInputRefNewUser.current.click();
                  }
                }}
              />
            ) : (
              <div
                className={classNames.emptyProfile}
                onClick={() => {
                  if (fileInputRefNewUser.current) {
                    fileInputRefNewUser.current.click();
                  }
                }}
              >
                <img
                  src={uploadIcon}
                  alt="uploadIcon"
                  style={{
                    animation: updateLoading ? "heartbeat 1s infinite" : "",
                    minWidth: "162px",
                  }}
                />
              </div>
            )}
          </>
        )}

        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          style={{ display: "none" }}
          onChange={async (e) => {
            let resFile = uploadFileFunc(
              e,
              setUploadFileRFQName,
              setUpdateLoading,
              "name"
            );
            console.log(await resFile, "res file link await");
            setUploadFileRFQ(await resFile);
            let profileFile = await resFile;
            setState((prev) => {
              return {
                ...prev,
                [name]: profileFile,
              };
            });
          }}
          ref={fileInputRefNewUser}
        />
      </div>
    </div>
  );
};

export const MulitImage = ({
  title,
  topTitle,
  name,
  indexx,
  placeholder,
  stateValue,
  setState,
}) => {
  //image update form
  const fileInputRefNewUser = useRef(null);
  const [uploadFileRFQ, setUploadFileRFQ] = useState("");
  const [uploadFileRFQName, setUploadFileRFQName] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);
  console.log("sta222222222teValue", stateValue)
  return (
    <div className={classNames.eachCustomImageUpload}>
      {topTitle && <div className={classNames.topTitle}>{title}</div>}
      <div className={classNames.imageInput}>
        {uploadFileRFQ ? (
          <img
            src={uploadFileRFQ}
            alt={uploadFileRFQName}
            onClick={() => {
              if (fileInputRefNewUser.current) {
                fileInputRefNewUser.current.click();
              }
            }}
          />
        ) : (
          <div
            className={classNames.emptyProfile}
            onClick={() => {
              if (fileInputRefNewUser.current) {
                fileInputRefNewUser.current.click();
              }
            }}
          >
            <img
              src={uploadIcon}
              alt="uploadIcon"
              style={{
                animation: updateLoading ? "heartbeat 1s infinite" : "",
              }}
            />
          </div>
        )}
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          style={{ display: "none" }}
          onChange={async (e) => {
            let resFile = uploadFileFunc(
              e,
              setUploadFileRFQName,
              setUpdateLoading,
              "name"
            );
            console.log(await resFile, "res file link await");
            setUploadFileRFQ(await resFile);
            let profileFile = await resFile;

            setState((stateValue) => {
              const newData = { ...stateValue }; // Shallow copy of the outer array

              const newItems = [...newData?.coverPhoto];
              // newItems[indexx].id = each?._id;
              newItems[indexx].name = profileFile;
              console.log("czxczxc", newItems); // Shallow copy of the inner array

              //  // Update the text of the clicked element
              // newData[indexx] = { ...newData[indexx], locationsold: newItems }; // Update the outer array
              return newData; // Return the updated array
            });

            // setState((prev) => {
            //   return {
            //     ...prev,
            //     [name]: profileFile,
            //   };
            // });
          }}
          ref={fileInputRefNewUser}
        />
        <div
          className={classNames.imageLabel}
          onClick={() => {
            if (fileInputRefNewUser.current) {
              fileInputRefNewUser.current.click();
            }
          }}
        >
          {placeholder}
        </div>
      </div>
    </div>
  );
};

export const EachCustomSelectMultiplelist = ({
  title,
  topTitle,
  name,
  stateValue,
  setState,
  mapItems,
}) => {
  const [storedata, setstoredata] = useState([]);

  const additem = (e) => {
    if ([...stateValue[name]]?.length > 0) {
      if ([...stateValue[name]].includes(e)) {
        let s = [...stateValue[name]]?.indexOf(e);
        console.log(s, "12343");
        const temp = [...stateValue[name]];

        temp.splice(s, 1);

        setState((prev) => {
          return {
            ...prev,
            [name]: [...temp],
          };
        });
        return;
      }
      {
        setState((prev) => {
          return {
            ...prev,
            [name]: [...stateValue[name], e],
          };
        });
        return;
      }
    } else {
      setState((prev) => {
        return {
          ...prev,
          [name]: [...stateValue[name], e],
        };
      });
    }
  };

  console.log("stateValue[name]121231", stateValue);

  return (
    <div className={classNames.eachCustomSelectMultiple}>
      {topTitle && <div className={classNames.topTitle}>{title}</div>}
      <div className={classNames.mapItems}>
        {mapItems?.length > 0 &&
          mapItems?.map((eachItem, index) => {
            return (
              <div
                key={eachItem + index}
                className={
                  name &&
                    Array.isArray(stateValue[name]) &&
                    stateValue[name].includes(eachItem?._id)
                    ? classNames.selectedItem
                    : Array.isArray(stateValue) &&
                      stateValue.includes(eachItem?._id)
                      ? classNames.selectedItem
                      : ""
                }
                onClick={() => {
                  additem(eachItem?._id);
                }}
              >
                {eachItem?.groupName}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const EachCustomSelectMultiples = ({
  title,
  topTitle,
  name,
  stateValue,
  setState,
  mapItems,
}) => {
  const [storedata, setstoredata] = useState([]);

  const additem = (e) => {
    if ([...stateValue[name]]?.length > 0) {
      if ([...stateValue[name]].includes(e)) {
        let s = [...stateValue[name]]?.indexOf(e);
        console.log(s, "12343");
        const temp = [...stateValue[name]];

        temp.splice(s, 1);

        setState((prev) => {
          return {
            ...prev,
            [name]: [...temp],
          };
        });
        return;
      }
      {
        setState((prev) => {
          return {
            ...prev,
            [name]: [...stateValue[name], e],
          };
        });
        return;
      }
    } else {
      setState((prev) => {
        return {
          ...prev,
          [name]: [...stateValue[name], e],
        };
      });
    }
  };

  console.log("stateValue[name]", stateValue);

  console.log("namesArr3", stateValue?.modules);

  return (
    <div
      className={classNames.eachCustomSelectMultiple}

    >
      {topTitle && (
        <div
          className={classNames.topTitle}
          style={{
            fontWeight: name == "specialistToggle" && "700",
          }}
        >
          {title}
        </div>
      )}
      <div className={classNames.mapItems}

        style={{
          width: name == "gendertype" && "90px",
        }}
      >
        {mapItems?.length > 0 &&
          mapItems?.map((eachItem, index) => {
            return (
              <div
                key={eachItem + index}
                style={{
                  width: name == "gendertype" && "90px",
                  display: name == "gendertype" && "flex",
                  alignItems: name == "gendertype" && "center",
                  justifyContent: name == "gendertype" && "center",
                }}
                className={
                  name &&
                    Array.isArray(stateValue[name]) &&
                    stateValue[name].includes(eachItem)
                    ? classNames.selectedItem
                    : Array.isArray(stateValue) && stateValue.includes(eachItem)
                      ? classNames.selectedItem
                      : ""
                }
                onClick={() => {
                  if (
                    name == "servedin" ||
                    name == "injmenthod" ||
                    name == "temperature" ||
                    name == "specialistToggle" ||
                    name == "gendertype" ||
                    name == "offeringtye" ||
                    name == "food"
                  ) {
                    setState((prev) => {
                      return {
                        ...prev,
                        [name]: [eachItem],
                      };
                    });
                  } else {
                    if (name) {
                      additem(eachItem);
                    }
                  }
                }}
              >
                {eachItem}
              </div>
            );
          })}
      </div>
    </div>
  );
};


export const MyEachCustomSelectMultiples = ({
  title,
  topTitle,
  name,
  stateValue,
  setState,
  mapItems,
}) => {
  const [storedata, setstoredata] = useState([]);

  const additem = (e) => {
    if ([...stateValue[name]]?.length > 0) {
      if ([...stateValue[name]].includes(e)) {
        let s = [...stateValue[name]]?.indexOf(e);
        console.log(s, "12343");
        const temp = [...stateValue[name]];

        temp.splice(s, 1);

        setState((prev) => {
          return {
            ...prev,
            [name]: [...temp],
          };
        });
        return;
      }
      {
        setState((prev) => {
          return {
            ...prev,
            [name]: [...stateValue[name], e],
          };
        });
        return;
      }
    } else {
      setState((prev) => {
        return {
          ...prev,
          [name]: [...stateValue[name], e],
        };
      });
    }
  };

  console.log("stateValue[name]", stateValue);

  console.log("namesArr3", stateValue?.modules);

  return (
    <div
      className={classNames.eachCustomSelectMultiple}
      style={{

      }}
    >
      {topTitle && (
        <div
          className={classNames.topTitle}
          style={{
            fontWeight: name == "specialistToggle" && "700",
          }}
        >
          {title}
        </div>
      )}
      <div className={classNames.mapItems}>
        {mapItems?.length > 0 &&
          mapItems?.map((eachItem, index) => {
            return (
              <div
                key={eachItem + index}
                style={{

                  display: name == "gendertype" && "flex",
                  alignItems: name == "gendertype" && "center",
                  justifyContent: name == "gendertype" && "center",
                }}
                className={
                  name &&
                    Array.isArray(stateValue[name]) &&
                    stateValue[name].includes(eachItem)
                    ? classNames.selectedItem
                    : Array.isArray(stateValue) && stateValue.includes(eachItem)
                      ? classNames.selectedItem
                      : ""
                }
                onClick={() => {
                  if (
                    name == "servedin" ||
                    name == "injmenthod" ||
                    name == "temperature" ||
                    name == "specialistToggle" ||
                    name == "gendertype" ||
                    name == "offeringtye" ||
                    name == "food"
                  ) {
                    setState((prev) => {
                      return {
                        ...prev,
                        [name]: [eachItem],
                      };
                    });
                  } else {
                    if (name) {
                      additem(eachItem);
                    }
                  }
                }}
              >
                {eachItem}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const EachCustomSelectMultiple = ({
  title,
  topTitle,
  name,
  stateValue,
  setState,
  mapItems,
}) => {
  return (
    <div className={classNames.eachCustomSelectMultiple}>
      {topTitle && <div className={classNames.topTitle}>{title}</div>}
      <div className={classNames.mapItems}>
        {mapItems?.length > 0 &&
          mapItems?.map((eachItem, index) => {
            return (
              <div
                key={eachItem + index}
                className={
                  name &&
                    Array.isArray(stateValue[name]) &&
                    stateValue[name].includes(eachItem)
                    ? classNames.selectedItem
                    : Array.isArray(stateValue) && stateValue.includes(eachItem)
                      ? classNames.selectedItem
                      : ""
                }
                onClick={() => {
                  if (name) {
                    setState((prev) => {
                      return {
                        ...prev,
                        [name]:
                          stateValue[name]?.length > 0
                            ? [...stateValue[name], eachItem]
                            : [eachItem],
                      };
                    });
                  } else {
                    setState(
                      stateValue?.length > 0
                        ? [...stateValue, eachItem]
                        : [eachItem]
                    );
                  }
                }}
              >
                {eachItem}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const CustomCheckboxMultiple = ({
  actionBtns,
  setActionBtns,
  id,
  name,
  type,
  stateValue,
  existing,
}) => {
  const { setOverlayDeleteItemName } = useContext(GlobalContext);

  const handleCheckboxChange = () => {
    if (actionBtns?.includes(id)) {
      setActionBtns((prev) => {
        // Remove the id from the array
        return prev.filter((btnId) => btnId !== id);
      });
    } else {
      setActionBtns((prev) => {
        // Add the id to the array
        return [...prev, id];
      });
    }

    setOverlayDeleteItemName(name);
  };

  useEffect(() => {
    if (stateValue?.name && existing) {
      if (actionBtns?.includes(id)) {
        setActionBtns((prev) => {
          // Remove the id from the array
          return prev.filter((btnId) => btnId !== id);
        });
      } else {
        setActionBtns((prev) => {
          // Add the id to the array
          return [...prev, id];
        });
      }
    }
  }, [stateValue?.name]);

  // Check if the current checkbox's ID is present in the actionBtns array
  const isChecked =
    stateValue?.name && actionBtns?.includes(id) && existing
      ? stateValue?.name
      : actionBtns?.includes(id);

  return (
    <div className="custom-checkbox-Parent">
      {stateValue?.name && (
        <div className="custom-checkbox-title">{stateValue?.name}</div>
      )}
      <label className="custom-checkbox custom-checkbox-straight">
        <input
          type="checkbox"
          id="myCheckbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <span className={`checkmark ${isChecked ? "checked" : ""}`}>
          {isChecked && (
            // <TiTick />
            <div className="checkedBox"></div>
          )}
        </span>
      </label>
    </div>
  );
};

export const StaffEachInputDatePicker = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  mandatory,
  topTitle,
}) => {
  const datepickerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };
  useEffect(() => {
    if (datepickerRef.current) {
      const options = {
        dateFormat: "d/m/Y",
        // Other options here
        onChange: function (selectedDates, dateStr, instance) {
          // console.log("Selected start date:", dateStr, datepickerRef.current);
          var today = new Date();
          var dd = String(today.getDate()).padStart(2, "0");
          var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
          var yyyy = today.getFullYear();

          today = dd + "/" + mm + "/" + yyyy;
          // document.write(today);



          var selectedday = dateStr?.split('/')
          let reulut = selectedday?.[1] + "/" + selectedday?.[0] + "/" + selectedday?.[2]
          var todaydays = today?.split('/')
          let reuluttoday = todaydays?.[1] + "/" + todaydays?.[0] + "/" + todaydays?.[2]







          const selectedDate = new Date(reulut);



          const currentDate = new Date(reuluttoday)
          console.log("czxczxc", selectedDate, "hasd", today);
          if (name == "Dateofjoining") {
            setState((prev) => {
              return { ...prev, [name]: dateStr };
            });
          } else {


            // const parseDate = (dateString) => {
            //   const parts = dateString.split('/');
            //   return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
            // };
            // const validateDate = (inputDate) => {
            //   console.log("cxczxczxc",inputDate)
            //       const selectedDateObj = parseDate(inputDate);
            //       console.log("cxczxczxc1",selectedDateObj)
            //       const today = new Date();

            //       if (!isValidDate(selectedDateObj)) {
            //         setMessage('Invalid date format.');
            //       } else if (selectedDateObj > today) {
            //         setMessage('Selected date cannot be in the future.');
            //       } else {
            //         setMessage('');
            //       }
            //     };



            if (selectedDate < currentDate) {
              // alert()
              if (name) {
                setState((prev) => {
                  return { ...prev, [name]: dateStr };
                });
              } else {
                setState(dateStr);
              }
              setIsOpen(false);
            } else {
              toastify(
                "Birthday field it should not be current or future date ",
                "error"
              );
              setState((prev) => {
                return { ...prev, [name]: "" };
              });
            }
          }
        },
      };

      flatpickr(datepickerRef.current, options);
    }
  }, []);

  return (
    <div
      className={classNames.eachInputDatePicker}
      style={{
        margin: "0rem",
        position: "relative",
      }}
      ref={dropdownRef}
      onClick={() => setIsOpen(true)}
    >
      {topTitle && <div className={classNames.topTitle}>{title}</div>}

      <span
        style={{
          position: "absolute",
          top: "15px",
          right: "1.5rem",
        }}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
          return;
        }}
      >
        {isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
      </span>
      <input
        type="text"
        id="datepicker"
        ref={datepickerRef}
        className={classNames.inputContainer}
        placeholder={placeholder}
        value={name ? stateValue[name] : stateValue}
        max={
          new Date().getFullYear() +
          "/" +
          (new Date().getMonth() + 1) +
          "/" +
          new Date().getDate()
        }
        // onChange={(event) => {
        //   setState({ ...stateValue, [name]: event?.target?.value });
        // }}
        style={{
          margin: "0rem",
          background: placeholder === "Select birthday" ? "#f5f5f5" : "",
        }}
      />
    </div>
  );
};

export const EachInputDatePicker = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  mandatory,
  topTitle,
}) => {
  const datepickerRef = useRef(null);

  useEffect(() => {
    if (datepickerRef.current) {
      const options = {
        dateFormat: "d/m/Y",
        // Other options here
        onChange: function (selectedDates, dateStr, instance) {
          // console.log("Selected start date:", dateStr, datepickerRef.current);
          var today = new Date();
          var dd = String(today.getDate()).padStart(2, "0");
          var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
          var yyyy = today.getFullYear();

          today = dd + "/" + mm + "/" + yyyy;
          // document.write(today);
          console.log("czxczxc", stateValue[name]);
          if (dateStr < today) {
            // alert()
            if (name) {
              setState((prev) => {
                return { ...prev, [name]: dateStr };
              });
            } else {
              setState(dateStr);
            }
          } else {
            toastify(
              "Birthday field it should not be current or future date ",
              "error"
            );
            setState((prev) => {
              return { ...prev, [name]: "" };
            });
          }
        },
      };

      flatpickr(datepickerRef.current, options);
    }
  }, []);

  return (
    <div className={classNames.eachInputDatePicker}>
      {topTitle && <div className={classNames.topTitle}>{title}</div>}
      <input
        type="text"
        id="datepicker"
        ref={datepickerRef}
        className={classNames.inputContainer}
        placeholder={placeholder}
        value={name ? stateValue[name] : stateValue}
        max={
          new Date().getFullYear() +
          "/" +
          (new Date().getMonth() + 1) +
          "/" +
          new Date().getDate()
        }
        // onChange={(event) => {
        //   setState({ ...stateValue, [name]: event?.target?.value });
        // }}
        style={{
          margin: placeholder === "Select birthday" ? "0" : "",
          background: placeholder === "Select birthday" ? "#f5f5f5" : "",
        }}
      />
    </div>
  );
};

export const EachInputArray = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
  type,
  objName,
}) => {
  return (
    <div
      className={classNames.eachCustomInput}
      style={{
        height: setState ? "" : "unset",
        pointerEvents: setState ? "" : "none",
      }}
    >
      {topTitle && <div className={classNames.topTitle}>{title}</div>}
      <div className={classNames.arrayContainer}>
        {name && stateValue[name] && stateValue[name]?.length > 0 ? (
          stateValue[name]?.map((eachItem, index) => {
            return (
              <input
                className={classNames.inputContainer}
                placeholder={placeholder}
                value={type === "obj" ? eachItem[objName] : eachItem}
                onChange={(event) => {
                  if (setState) {
                    if (name) {
                      setState((prev) => {
                        return {
                          ...prev,
                          [name]: event?.target?.value,
                        };
                      });
                    } else {
                      setState(event?.target?.value);
                    }
                  }
                }}
              />
            );
          })
        ) : (
          <input
            className={classNames.inputContainer}
            placeholder={placeholder}
          />
        )}
      </div>
    </div>
  );
};

export const CustomPasswordInputpassword = ({
  placeholder,
  ref,
  name,
  stateValue,
  setState,
  func,
  widthh,
  maxWidth,
  id,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  console.log(ref, "refref", id);
  return (
    <div
      className={classNames.passwordBox}
      style={{
        width: widthh ? widthh : "",
        maxWidth: maxWidth ? maxWidth : "",
      }}
    >
      <input
        className={classNames.inputContainer}
        placeholder={placeholder}
        type={showPassword ? "text" : "password"}
        ref={ref}
        id={id}
        value={name ? stateValue[name] : stateValue}
        onChange={(event) => {
          if (name) {
            setState((prev) => {
              return { ...prev, [name]: event?.target.value?.trim() };
            });
          } else {
            setState(event?.target.value);
          }
        }}
        onKeyDown={func}
      />
      {showPassword ? (
        <IoIosEye onClick={() => setShowPassword(!showPassword)} />
      ) : (
        <IoIosEyeOff onClick={() => setShowPassword(!showPassword)} />
      )}
    </div>
  );
};

export const CustomPasswordInput = ({
  placeholder,
  ref,
  name,
  stateValue,
  setState,
  func,
  widthh,
  maxWidth,
  id,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  console.log(ref, "refref", id);
  return (
    <div
      className={classNames.passwordBox}
    // style={{
    //   width: widthh ? widthh : "",
    //   maxWidth: maxWidth ? maxWidth : "",
    // }}
    >
      <input
        className={classNames.inputContainer}
        placeholder={placeholder}
        type={showPassword ? "text" : "password"}
        ref={ref}
        id={id}
        value={name ? stateValue[name] : stateValue}
        onChange={(event) => {
          if (name) {
            setState((prev) => {
              return { ...prev, [name]: event?.target.value };
            });
          } else {
            setState(event?.target.value);
          }
        }}
        onKeyDown={func}
      />
      {showPassword ? (
        <IoIosEye onClick={() => setShowPassword(!showPassword)} />
      ) : (
        <IoIosEyeOff onClick={() => setShowPassword(!showPassword)} />
      )}
    </div>
  );
};








export const NewUIMyEachInput = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
  toggle
}) => {
  return (
    <div
      className={classNames.eachCustomInput}
      style={{
        pointerEvents: toggle ? "none" : "",
        margin: "0"
      }}
    >
      {topTitle && (
        <div
          style={{
            fontWeight: "500",
            color: "#7A7A7A",
            marginTop: "0px",
            fontSize: "12px",
            marginBottom: "8px",
          }}
          className={classNames.topTitle}
        >
          {title}
        </div>
      )}
      <input
        className={classNames.inputContainer}
        style={{
          maxWidth: "100%",
          padding: "0.6rem 1.5rem",
          color: '#0F0609',
          fontSize: "14px",
          margin: "0",
          paddingLeft: "10px"
        }}

        placeholder={placeholder}
        value={name ? stateValue[name] : stateValue}
        onChange={(event) => {
          if (name) {
            setState((prev) => {
              return {
                ...prev,
                [name]: event?.target?.value,
              };
            });
          } else {
            setState(event?.target?.value);
          }
        }}
      />
    </div>
  );
};



export const NewDateAndPicker = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  mandatory,
  topTitle,
}) => {
  const datepickerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };
  useEffect(() => {
    if (datepickerRef.current) {
      const options = {
        dateFormat: "d/m/Y",
        // Other options here
        onChange: function (selectedDates, dateStr, instance) {
          // console.log("Selected start date:", dateStr, datepickerRef.current);
          var today = new Date();
          var dd = String(today.getDate()).padStart(2, "0");
          var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
          var yyyy = today.getFullYear();

          today = dd + "/" + mm + "/" + yyyy;
          // document.write(today);



          var selectedday = dateStr?.split('/')
          let reulut = selectedday?.[1] + "/" + selectedday?.[0] + "/" + selectedday?.[2]
          var todaydays = today?.split('/')
          let reuluttoday = todaydays?.[1] + "/" + todaydays?.[0] + "/" + todaydays?.[2]







          const selectedDate = new Date(reulut);



          const currentDate = new Date(reuluttoday)
          console.log("czxczxc", selectedDate, "hasd", today);
          if (name == "Dateofjoining") {
            setState((prev) => {
              return { ...prev, [name]: dateStr };
            });
          } else {


            // const parseDate = (dateString) => {
            //   const parts = dateString.split('/');
            //   return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
            // };
            // const validateDate = (inputDate) => {
            //   console.log("cxczxczxc",inputDate)
            //       const selectedDateObj = parseDate(inputDate);
            //       console.log("cxczxczxc1",selectedDateObj)
            //       const today = new Date();

            //       if (!isValidDate(selectedDateObj)) {
            //         setMessage('Invalid date format.');
            //       } else if (selectedDateObj > today) {
            //         setMessage('Selected date cannot be in the future.');
            //       } else {
            //         setMessage('');
            //       }
            //     };



            if (selectedDate < currentDate) {
              // alert()
              if (name) {
                setState((prev) => {
                  return { ...prev, [name]: dateStr };
                });
              } else {
                setState(dateStr);
              }
              setIsOpen(false);
            } else {
              toastify(
                "Birthday field it should not be current or future date ",
                "error"
              );
              setState((prev) => {
                return { ...prev, [name]: "" };
              });
            }
          }
        },
      };

      flatpickr(datepickerRef.current, options);
    }
  }, []);

  return (
    <div
      className={classNames.eachInputDatePicker}
      style={{
        margin: "0rem",
        position: "relative",
      }}
      ref={dropdownRef}
      onClick={() => setIsOpen(true)}
    >
      {topTitle &&
        <div
          style={{
            fontWeight: "500",
            color: "#7A7A7A",
            marginTop: "0px",
            fontSize: "12px",
            marginBottom: "8px",
          }}
          className={classNames.topTitle}
        >
          {title}
        </div>
      }

      <span
        style={{
          position: "absolute",
          top: "33px",
          right: "10px",
        }}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
          return;
        }}
      >
        <svg
          style={{
            height: "15px"
          }}
          xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M8 2V5" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M16 2V5" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M3.5 9.08997H20.5" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M11.9955 13.7H12.0045" stroke="#7A7A7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M8.29431 13.7H8.30329" stroke="#7A7A7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M8.29431 16.7H8.30329" stroke="#7A7A7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </span>
      <input
        type="text"
        id="datepicker"
        style={{
          maxWidth: "100%",
          padding: "0.6rem 1.5rem",
          color: '#0F0609',
          fontSize: "14px",
          margin: "0",
          background: placeholder === "Select birthday" ? "#f5f5f5" : "",
          margin: "0rem",
          paddingLeft: "10px"
        }}
        ref={datepickerRef}
        className={classNames.inputContainer}
        placeholder={placeholder}
        value={name ? stateValue[name] : stateValue}
        max={
          new Date().getFullYear() +
          "/" +
          (new Date().getMonth() + 1) +
          "/" +
          new Date().getDate()
        }
      // onChange={(event) => {
      //   setState({ ...stateValue, [name]: event?.target?.value });
      // }}

      />
    </div>
  );
};


export const NewEachCustomDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  topTitle,
  type,
  stateVal,
  mapVal,
  indexx,
  calendarObjField,
  eachValuee,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => { });
  console.log("dropdowndropdown", dropdown);

  useEffect(() => {
    console.log(stateValue, "ljwekgfhkwjegfkjwef");
  }, [stateValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };
  const [countrysearch, setcountrysearch] = useState("")
  useEffect(() => {
    if (
      name === "selectedAppStaff" &&
      Array?.isArray(dropdown) &&
      dropdown?.length > 0
    ) {
      setState({
        NameOfTeam: dropdown[0]?.NameOfTeam,
        Icon: dropdown[0]?.Icon,
        TeamUsername: dropdown[0]?.TeamUsername,
      });
    }
    setcountrysearch("")
  }, [dropdown]);

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        // minWidth: isOpen ? "250px" : "",
        zIndex: isOpen ? "3" : "",
        height: "60px",
        minWidth: "160px"
      }}
    >
      {topTitle && <div className={classNames.topTitle}

        style={{
          fontWeight: "500",
          color: "#7A7A7A",
          marginTop: "0px",
          fontSize: "12px",
          marginBottom: "8px",
        }}
      >{title}</div>}
      <div
        className={classNames.inputContainer}
        onClick={() => setIsOpen(true)}
        style={{
          pointerEvents: dropdown ? "" : "none",


          maxWidth: "100%",
          padding: "0.6rem 1.5rem",
          color: '#0F0609',
          fontSize: "14px",
          margin: "0",

          margin: "0rem",
          paddingLeft: "10px",
          marginTop: "23px"
        }}
        ref={dropdownRef}
      >
        <span>
          <span style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {name === "selectedAppStaff" && stateValue?.Icon ? (
              <img
                src={stateValue?.Icon}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : name === "selectedAppStaff" ? (
              <img
                src={montageLogo}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : name === "gender" ? (
              !stateValue?.gender ? (
                <div

                  style={{
                    fontSize: "14px",
                    maxWidth: "100%",

                    color: "#AFAFAF"

                  }}

                >
                  Select Gender
                </div>

              ) : (
                stateValue?.gender
              )
            ) : name === "MaritalStatus" ? (
              !stateValue?.MaritalStatus ? (
                <div

                  style={{
                    fontSize: "14px",
                    maxWidth: "100%",

                    color: "#AFAFAF"

                  }}

                >
                  Select Marital Status
                </div>
              ) : (
                stateValue?.MaritalStatus
              )
            ) : name === "PaymentMethod" ? (
              !stateValue?.PaymentMethod ? (
                <div

                  style={{
                    fontSize: "14px",
                    maxWidth: "100%",

                    color: "#AFAFAF"

                  }}

                >
                  Select Payment Method:
                </div>
              ) : (
                stateValue?.PaymentMethod
              )
            ) : name === "TransactionType" ? (
              !stateValue?.TransactionType ? (
                <div

                  style={{
                    fontSize: "14px",
                    maxWidth: "100%",

                    color: "#AFAFAF"

                  }}

                >
                  Select Transaction Type:
                </div>
              ) : (
                stateValue?.TransactionType
              )
            )
              : name === "country" ? (
                !stateValue?.country ? (
                  <div

                    style={{
                      fontSize: "14px",
                      maxWidth: "100%",

                      color: "#AFAFAF"

                    }}

                  >
                    Select Country
                  </div>

                ) : (
                  stateValue?.country
                )
              )
                : name === "state" ? (
                  !stateValue?.state ? (
                    <div

                      style={{
                        fontSize: "14px",
                        maxWidth: "100%",

                        color: "#AFAFAF"

                      }}

                    >
                      Select State
                    </div>

                  ) : (
                    stateValue?.state
                  )
                )

                  : (
                    <>
                      {name === "selectedAppStaff" && stateValue?.NameOfTeam
                        ? stateValue?.NameOfTeam
                        : stateValue?.NameOfTeam && !name === "IndustriesId"
                          ? stateValue?.NameOfTeam
                          : selectedOption
                            ? selectedOption
                            : name === "IndustriesId"
                              ? "Click to select industries"
                              : title
                                ? title
                                : name === "selectedAppStaff"
                                  ? "Select an Option"
                                  : name === "catfallunder"
                                    ? "Select an Option"
                                    : dropdown[0]?.NameOfTeam}
                    </>
                  )}
          </span>
          <span
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
              return;
            }}
          >
            {isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
          </span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            {type === "twoFields" && dropdown?.length > 0
              ? dropdown?.map((eachitem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      // let objj = {
                      //   ...eachValuee,
                      //   [calendarObjField]: eachitem,
                      // };
                      // console.log(objj, "objj checking");
                      event.stopPropagation();
                      selectOption(eachitem);
                      setState(() => {
                        const updatedAnnualHolidays = [...stateValue[name]];
                        updatedAnnualHolidays[indexx] = {
                          ...updatedAnnualHolidays[indexx],
                          [calendarObjField]: eachitem,
                        };

                        return {
                          ...stateValue,
                          [name]:
                            (calendarObjField === "date" ||
                              calendarObjField === "month") &&
                              areAllFieldsFilled(updatedAnnualHolidays[indexx])
                              ? [
                                ...updatedAnnualHolidays,
                                { month: "", date: "" },
                              ]
                              : updatedAnnualHolidays,
                        };
                      });
                    }}
                    key={eachitem + index}
                  >
                    {eachitem}
                  </li>
                );
              })
              : title === "Gender" && dropdown?.length > 0
                ? dropdown?.map((each, index) => {
                  return (
                    <li
                      onClick={(event) => {
                        event.stopPropagation();
                        // selectOption(
                        //   eachitem[mapVal?.name] +
                        //   " (" +
                        //   eachitem[mapVal?.name1] +
                        //   ")"
                        // );
                        setState({
                          ...stateValue,
                          gender: each,
                        });
                        setIsOpen(false);
                      }}
                      key={index}
                    >
                      {each}
                    </li>
                  );
                }) :
                title === "Marital Status" && dropdown?.length > 0
                  ? dropdown?.map((each, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          // selectOption(
                          //   eachitem[mapVal?.name] +
                          //   " (" +
                          //   eachitem[mapVal?.name1] +
                          //   ")"
                          // );
                          setState({
                            ...stateValue,
                            MaritalStatus: each,
                          });
                          setIsOpen(false);
                        }}
                        key={index}
                      >
                        {each}
                      </li>
                    );
                  })
                  : title === "Which therapy category does this fall under?" &&
                    dropdown?.length > 0
                    ? dropdown?.map((each, index) => {
                      return (
                        <li
                          onClick={(event) => {
                            event.stopPropagation();
                            // selectOption(
                            //   eachitem[mapVal?.name] +
                            //   " (" +
                            //   eachitem[mapVal?.name1] +
                            //   ")"
                            // );
                            setState({
                              ...stateValue,
                              theraphyCategoryId: each?._id,
                              theraphyCategoryName: each?.name,
                            });
                            setIsOpen(false);
                          }}
                          key={index}
                        >
                          {each?.name}
                        </li>
                      );
                    })
                    : title === "What room category does this room fall under?" &&
                      dropdown?.length > 0
                      ? dropdown?.map((each, index) => {
                        return (
                          <li
                            onClick={(event) => {
                              event.stopPropagation();
                              // selectOption(
                              //   eachitem[mapVal?.name] +
                              //   " (" +
                              //   eachitem[mapVal?.name1] +
                              //   ")"
                              // );
                              setState({
                                ...stateValue,
                                rooms: each?.roomcategoryid,
                                roomsName: each?.name,
                              });
                              setIsOpen(false);
                            }}
                            key={index}
                          >
                            {each?.name}
                          </li>
                        );
                      })
                      : title === "Which location will this be sold at ?" ||
                        (title ===
                          "At which location is this kitchen item served?" &&
                          dropdown?.length > 0)
                        ? dropdown?.map((each, index) => {
                          return (
                            <li
                              onClick={(event) => {
                                event.stopPropagation();
                                // selectOption(
                                //   eachitem[mapVal?.name] +
                                //   " (" +
                                //   eachitem[mapVal?.name1] +
                                //   ")"
                                // );
                                setState({
                                  ...stateValue,
                                  location: each?._id,
                                  locationName: each?.name,
                                });
                                setIsOpen(false);
                              }}
                              key={index}
                            >
                              {each?.name}
                            </li>
                          );
                        })
                        : title ===
                          "Which category does this product fall under?" &&
                          dropdown?.length > 0
                          ? dropdown?.map((each, index) => {
                            return (
                              <li
                                onClick={(event) => {
                                  event.stopPropagation();
                                  // selectOption(
                                  //   eachitem[mapVal?.name] +
                                  //   " (" +
                                  //   eachitem[mapVal?.name1] +
                                  //   ")"
                                  // );
                                  setState({
                                    ...stateValue,
                                    catfallunder: each?.productcategoryid,
                                    catfallunderName: each?.name,
                                  });
                                  setIsOpen(false);
                                }}
                                key={index}
                              >
                                {each?.name}
                              </li>
                            );
                          })
                          : title === "What is the parent service type?" &&
                            dropdown?.length > 0
                            ? dropdown?.map((each, index) => {
                              return (
                                <li
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    // selectOption(
                                    //   eachitem[mapVal?.name] +
                                    //   " (" +
                                    //   eachitem[mapVal?.name1] +
                                    //   ")"
                                    // );
                                    setState({
                                      ...stateValue,
                                      constultandid: each?.consultationtypeid,
                                      constultandName: each?.name,
                                    });
                                    setIsOpen(false);
                                  }}
                                  key={index}
                                >
                                  {each?.name}
                                </li>
                              );
                            })
                            : title === "Which brand makes this product?" &&
                              dropdown?.length > 0
                              ? dropdown?.map((each, index) => {
                                return (
                                  <li
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      // selectOption(
                                      //   eachitem[mapVal?.name] +
                                      //   " (" +
                                      //   eachitem[mapVal?.name1] +
                                      //   ")"
                                      // );
                                      setState({
                                        ...stateValue,
                                        brandsproduct: each?.productbrandid,
                                        brandsproductName: each?.name,
                                      });
                                      setIsOpen(false);
                                    }}
                                    key={index}
                                  >
                                    <img src={each?.icon} />
                                    {each?.name}
                                  </li>
                                );
                              })

                              : title ===
                                "Which staff member do you want to make a specialist?" &&
                                dropdown?.length > 0
                                ? dropdown?.map((each, index) => {
                                  return (
                                    <li
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        // selectOption(
                                        //   eachitem[mapVal?.name] +
                                        //   " (" +
                                        //   eachitem[mapVal?.name1] +
                                        //   ")"
                                        // );
                                        setState({
                                          ...stateValue,
                                          spaceName:
                                            each?.TeamDetails?.[0]
                                              ?.PlayerDetails?.[0]?.name,
                                          spaceid: each?.teamplayerid,
                                        });
                                        setIsOpen(false);
                                      }}
                                      key={index}
                                    >
                                      <img
                                        src={
                                          each?.TeamDetails?.[0]
                                            ?.PlayerDetails?.[0]?.ProfilePic
                                        }
                                      />
                                      {
                                        each?.TeamDetails?.[0]
                                          ?.PlayerDetails?.[0]?.name
                                      }
                                    </li>
                                  );
                                }) : title === "Select Country" && dropdown?.length > 0 ?
                                  <>
                                    <input placeholder="Search..."
                                      onClick={() => toggleDropdown(true)}
                                      value={countrysearch}
                                      style={{
                                        color: "#7587A7",
                                        background: "#F5F7F9",
                                        border: "1px solid #D1D7E1",
                                        height: "35px",
                                        padding: "0 1.3rem",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        width: "100%",
                                        borderRadius: "6px",
                                        marginBottom: "10px"
                                      }
                                      }
                                      onChange={(e) => setcountrysearch(e.target.value)}
                                    />
                                    {dropdown?.filter((temp) =>
                                      temp?.name
                                        ?.toLowerCase()
                                        ?.includes(countrysearch?.toLowerCase())
                                    )?.map((eachitem, index) => {
                                      console.log("08x8Yhgzcxjzxc", eachitem);
                                      return (

                                        <li
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            selectOption(eachitem[mapVal?.name]);
                                            setState({
                                              ...stateValue,
                                              [name]: eachitem?.name,
                                            });
                                          }}
                                          key={eachitem[mapVal?.name] + index}
                                        >
                                          {eachitem?.name}
                                        </li>
                                      );
                                    })}
                                  </>
                                  : title ===
                                    "Which location will this specialist work at?" &&
                                    dropdown?.length > 0
                                    ? dropdown?.map((each, index) => {
                                      return (
                                        <li
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            // selectOption(
                                            //   eachitem[mapVal?.name] +
                                            //   " (" +
                                            //   eachitem[mapVal?.name1] +
                                            //   ")"
                                            // );
                                            setState({
                                              ...stateValue,
                                              locationid: each?._id,
                                              locationName: each?.name,
                                            });
                                            setIsOpen(false);
                                          }}
                                          key={index}
                                        >
                                          {each?.name}
                                        </li>
                                      );
                                    })

                                    : title === "Select specialist" &&
                                      dropdown?.length > 0
                                      ? dropdown?.map((each, index) => {
                                        return (
                                          <li
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              // selectOption(
                                              //   eachitem[mapVal?.name] +
                                              //   " (" +
                                              //   eachitem[mapVal?.name1] +
                                              //   ")"
                                              // );
                                              console.log(each, "lkwheflkwhfw");
                                              setState({
                                                ...stateValue,
                                                speicalistId: each?.specialistid,
                                                speicalistName:
                                                  each?.TeamDetails[0]
                                                    ?.PlayerDetails[0]?.name,
                                              });
                                              setIsOpen(false);
                                            }}
                                            key={index}
                                          >
                                            {
                                              each?.TeamDetails[0]
                                                ?.PlayerDetails[0]?.name
                                            }
                                          </li>
                                        );
                                      })
                                      : title ===
                                        "Which location will this service be offered at?" &&
                                        dropdown?.length > 0
                                        ? dropdown?.map((each, index) => {
                                          return (
                                            <li
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                // selectOption(
                                                //   eachitem[mapVal?.name] +
                                                //   " (" +
                                                //   eachitem[mapVal?.name1] +
                                                //   ")"
                                                // );
                                                setState({
                                                  ...stateValue,
                                                  locationid: each?._id,
                                                  locationName: each?.name,
                                                });
                                                setIsOpen(false);
                                              }}
                                              key={index}
                                            >
                                              {each?.name}
                                            </li>
                                          );
                                        })
                                        : title === "Select the timezone" &&
                                          dropdown?.length > 0
                                          ? dropdown?.map((eachitem, index) => {
                                            return (
                                              <li
                                                onClick={(event) => {
                                                  event.stopPropagation();
                                                  selectOption(
                                                    eachitem[mapVal?.name] +
                                                    " (" +
                                                    eachitem[mapVal?.name1] +
                                                    ")"
                                                  );
                                                  setState({
                                                    ...stateValue,
                                                    TimeZone:
                                                      eachitem?.UTCDifference,
                                                    TimeZoneAbbrevation:
                                                      eachitem?.Abbreviation,
                                                  });
                                                }}
                                                key={
                                                  eachitem[mapVal?.name] + index
                                                }
                                              >
                                                {eachitem[mapVal?.name] +
                                                  " (" +
                                                  eachitem[mapVal?.name1] +
                                                  ")"}
                                              </li>
                                            );
                                          })
                                          : type === "obj" && dropdown?.length > 0
                                            ? dropdown?.map((eachitem, index) => {
                                              return (
                                                <li
                                                  onClick={(event) => {
                                                    event.stopPropagation();
                                                    selectOption(
                                                      eachitem[mapVal?.name]
                                                    );
                                                    setState({
                                                      ...stateValue,
                                                      [name]: eachitem[stateVal],
                                                    });
                                                  }}
                                                  key={
                                                    eachitem[mapVal?.name] + index
                                                  }
                                                >
                                                  {mapVal?.image && (
                                                    <img
                                                      src={
                                                        eachitem[mapVal?.image]
                                                      }
                                                      alt={eachitem[stateVal]}
                                                    />
                                                  )}
                                                  {eachitem[mapVal?.name]}
                                                </li>
                                              );
                                            })
                                            : (name === "Gender" ||
                                              name === "groupType" ||
                                              type === "single") &&
                                              dropdown?.length > 0
                                              ? dropdown?.map(
                                                (eachitem, index) => {
                                                  return (
                                                    <li
                                                      onClick={(event) => {
                                                        event.stopPropagation();
                                                        selectOption(eachitem);
                                                        setState({
                                                          ...stateValue,
                                                          [name]: eachitem,
                                                        });
                                                      }}
                                                      key={eachitem + index}
                                                    >
                                                      {eachitem}
                                                    </li>
                                                  );
                                                }
                                              )
                                              : name === "IndustriesId" &&
                                                dropdown?.length > 0
                                                ? dropdown?.map(
                                                  (eachitem, index) => {
                                                    return (
                                                      <li
                                                        onClick={(event) => {
                                                          event.stopPropagation();
                                                          selectOption(
                                                            eachitem?.Name
                                                          );
                                                          setState({
                                                            ...stateValue,
                                                            [name]:
                                                              eachitem?._id,
                                                          });
                                                        }}
                                                        key={
                                                          eachitem?.Name + index
                                                        }
                                                      >
                                                        {eachitem?.Name}
                                                      </li>
                                                    );
                                                  }
                                                )
                                                : name === "defaultDropdown" &&
                                                  dropdown?.length > 0
                                                  ? dropdown?.map(
                                                    (eachitem, index) => {
                                                      return (
                                                        <li
                                                          onClick={(event) => {
                                                            event.stopPropagation();
                                                            selectOption(
                                                              eachitem
                                                            );
                                                            setState(eachitem);
                                                          }}
                                                          key={index}
                                                        >
                                                          {eachitem}
                                                        </li>
                                                      );
                                                    }
                                                  )
                                                  : dropdown?.length > 0 &&
                                                  dropdown?.map(
                                                    (eachitem, index) => {
                                                      return (
                                                        <li
                                                          onClick={(event) => {
                                                            event.stopPropagation();
                                                            selectOption(
                                                              eachitem?.NameOfTeam
                                                            );
                                                            if (
                                                              name ===
                                                              "teamUsername"
                                                            ) {
                                                              setState(
                                                                (prev) => {
                                                                  return {
                                                                    ...prev,
                                                                    [name]:
                                                                      eachitem?.TeamUsername,
                                                                  };
                                                                }
                                                              );
                                                            } else if (
                                                              name ===
                                                              "selectedAppStaff"
                                                            ) {
                                                              setState({
                                                                NameOfTeam:
                                                                  eachitem?.NameOfTeam,
                                                                Icon: eachitem?.Icon,
                                                                TeamUsername:
                                                                  eachitem?.TeamUsername,
                                                              });
                                                            } else {
                                                              setState({
                                                                ...stateValue,
                                                                [name]: {
                                                                  NameOfTeam:
                                                                    eachitem?.NameOfTeam,
                                                                  Icon: eachitem?.Icon,
                                                                },
                                                              });
                                                            }
                                                          }}
                                                          key={
                                                            eachitem?.NameOfTeam +
                                                            index
                                                          }
                                                        >
                                                          <img
                                                            src={eachitem?.Icon}
                                                            alt={
                                                              eachitem?.NameOfTeam
                                                            }
                                                          />
                                                          {eachitem?.NameOfTeam}
                                                        </li>
                                                      );
                                                    }
                                                  )}
          </ul>
        )}
      </div>
    </div>
  );
};



export const RadioNewUIMyEachInput = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
}) => {
  return (
    <div
      className={classNames.eachCustomInput}
      style={{
        //pointerEvents: setState ? "" : "none",
        margin: "0"
      }}
    >
      {topTitle && (
        <div
          style={{
            fontWeight: "500",
            color: "#7A7A7A",

            fontSize: "12px",
            marginBottom: "8px",
            marginTop: "0px"
          }}
          className={classNames.topTitle}
        >
          {title}
        </div>
      )}


      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: '5px'
        }}
      >
        <input type="radio"


          checked={stateValue?.currentmedic == "Yes"}
          onChange={(event) => {
            if (name) {
              setState((prev) => {
                return {
                  ...prev,
                  [name]: event?.target?.value,
                };
              });
            } else {
              setState(event?.target?.value);
            }
          }} value="Yes" /> Yes
        <input
          style={{
            marginLeft: "14px"
          }}
          type="radio" checked={stateValue?.currentmedic == "No"}
          onChange={(event) => {
            if (name) {
              setState((prev) => {
                return {
                  ...prev,
                  [name]: event?.target?.value,
                };
              });
            } else {
              setState(event?.target?.value);
            }
          }} value="No" /> No
      </div>

      {/* <input
        className={classNames.inputContainer}
        style={{
          maxWidth: "100%",
          padding: "0.6rem 1.5rem",
          color: '#0F0609',
          fontSize: "14px",
          margin: "0",
          paddingLeft: "10px"
        }}

        placeholder={placeholder}
        value={name ? stateValue[name] : stateValue}
        onChange={(event) => {
          if (name) {
            setState((prev) => {
              return {
                ...prev,
                [name]: event?.target?.value,
              };
            });
          } else {
            setState(event?.target?.value);
          }
        }}
      /> */}
    </div>
  );
};



export const NewEachCustomImageUploadcustom = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
}) => {
  //image update form
  const fileInputRefNewUser = useRef(null);
  const [uploadFileRFQ, setUploadFileRFQ] = useState("");
  const [uploadFileRFQName, setUploadFileRFQName] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);

  return (
    <div className={classNames.eachCustomImageUpload}>
      {/* <div className={classNames.topTitle}>{title}</div> */}
      <div
        className={classNames.imageInput}
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {uploadFileRFQ ? (
          <img
            src={uploadFileRFQ}
            alt={uploadFileRFQName}
            style={{
              width: "5.25rem",
              height: "5.25rem",
              borderRadius: "55rem",
            }}
          />
        ) : (
          <div
            className={classNames.emptyProfilecustom}
            onClick={() => {
              if (fileInputRefNewUser.current) {
                fileInputRefNewUser.current.click();
              }
            }}
          >
            <img
              src={uploadIcon}
              alt="uploadIcon"
              style={{
                animation: updateLoading ? "heartbeat 1s infinite" : "",
              }}
            />
          </div>
        )}
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          style={{ display: "none" }}
          onChange={async (e) => {
            let resFile = uploadFileFunc(
              e,
              setUploadFileRFQName,
              setUpdateLoading,
              "name"
            );
            console.log(await resFile, "res file link await");
            setUploadFileRFQ(await resFile);
            let profileFile = await resFile;
            setState((prev) => {
              return {
                ...prev,
                [name]: profileFile,
              };
            });
          }}
          ref={fileInputRefNewUser}
        />
        <div
          style={{
            color: " #C51E25",
            fontSize: "14px",
            border: "1px solid  #C51E25",
            width: "182px",
            height: "35px",
            marginLeft: "2rem",
            marginTop: "0"
          }}
          className={classNames.imageLabelcustom}
          onClick={() => {
            if (fileInputRefNewUser.current) {
              fileInputRefNewUser.current.click();
            }
          }}
        >
          Upload new picture
        </div>
      </div>
    </div>
  );
};


export const NewMyEachCustomTextarea = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
}) => {
  return (
    <div className={classNames.eachCustomTextarea}
      style={{
        height: "75px",
        marginBottom: "0",
        marginTop: "12px",
        width: "100%",
      }}
    >
      {topTitle && (
        <div
          style={{
            fontWeight: "500",
            color: "#7A7A7A",

            fontSize: "12px",
            marginBottom: "8px",
            marginTop: "0px"
          }}
          className={classNames.topTitle}
        >
          {title}
        </div>
      )}
      <textarea
        className={classNames.inputContainer}
        style={{
          maxWidth: "100%",
          width: "100%",
          padding: "0.6rem 1.5rem",
          color: '#0F0609',
          fontSize: "14px",
          margin: "0",
          paddingLeft: "10px",
          height: "100%"
        }}
        placeholder={placeholder}
        value={name ? stateValue[name] : stateValue}
        onChange={(event) => {
          if (setState) {
            if (name) {
              setState((prev) => {
                return {
                  ...prev,
                  [name]: event?.target?.value,
                };
              });
            } else {
              setState(event?.target?.value);
            }
          }
        }}
      />
    </div>
  );
};