import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./settings.module.scss";

//assets
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { EachCustomDropdown } from "../Custom";
import profileIcon from "../../assets/images/icons/profile.svg";
import editIcon from "../../assets/images/icons/edit.svg";
import { GlobalContext } from "../../context/Globalcontext";
import { toastify } from "../../assets/functions/toastify";
import { uploadFileFunc } from "../../assets/functions";
import uploadIcon from "../../assets/images/icons/upload.svg";

import tickwithcircle from "../../assets/images/icons/tickwithcircle.svg";
import doublearrow from "../../assets/images/icons/doublearrow.svg";
import { getData } from "../../assets/functions/apiCalls";
import { useLocation, useNavigate } from "react-router-dom";
import { createOrganization } from "../../assets/functions/createApis";
import montageLogo from "../../assets/images/montage.svg";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import SettingDrawer from "./SettingDrawer";
import flatpickr from "flatpickr";
const Settings = () => {
  const { accessPages } = useContext(GlobalContext);
  const [selectedItem, setSelectedItem] = useState("Patient");
  const location = useLocation();

  const menuItems = [
    "Patient",
    "Owner",
    "Specialists",
    "Admins",
    "Non-Admin Staff",
    "Super Admin",
  ];

  return (
    <div className={classNames.settings}>
      {/* <div className={classNames.leftMenu}>
        <div className={classNames.heading}>Roles</div>
        <div className={classNames.menuItems}>
          {menuItems?.length > 0 &&
            menuItems?.map((eachItem, index) => {
              return (
                <div
                  className={
                    selectedItem === eachItem ? classNames.selectedItem : ""
                  }
                  key={eachItem + index}
                  onClick={() => {
                    setSelectedItem(eachItem);
                  }}
                >
                  {eachItem}
                </div>
              );
            })}
        </div>
      </div> */}

      <div className={classNames.rightDashboard}>
        {location?.pathname?.includes("/settings/profiles") ? (
          <Profile />
        ) : location?.pathname?.includes("/settings/owner") &&
          accessPages?.title == "teamowner" ? (
          selectedItem === "CreateNewOrganization" ? (
            <CreateNewOrganization setSelectedItem={setSelectedItem} />
          ) : (
            <Owner setSelectedItem={setSelectedItem} />
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Settings;

const Owner = ({ setSelectedItem }) => {
  const { email } = useContext(GlobalContext);
  const [ownerType, setOwnerType] = useState("");
  const [organizationResponse, setOrganizationResponse] = useState("");
  const [storid, setstorid] = useState("");
  const [bookingDrawer, setbookingDrawer] = useState(false);
  useEffect(() => {
    getOwnerType();
    getOrganizations();
  }, []);

  const callfucs = (e) => {
    setstorid(e);
    setbookingDrawer(true);
  };

  async function getOwnerType() {
    let response = await getData(
      `https://uatamma.apimachine.com/api/checkuser/${email}`
    );
    console.log(response, "owner response");
    setOwnerType(response);
  }
  async function getOrganizations() {
    let response = await getData(
      `https://uatamma.apimachine.com/api/getteamsbyowner?TeamCreatedBy=${email}`
    );
    if (response?.success) {
      setOrganizationResponse(response?.data);
    }
    console.log(response, "organization response");
  }

  async function becomeTeamOwner() {
    axios
      .post("https://uatamma.apimachine.com/api/addteamowner", {
        email: email,
      })
      .then((response) => {
        console.log((response, "become team owner response"));
        if (response?.success) {
          toastify(`User key updated to team leader`, "success");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error?.message, "become team owner error");
      });
  }
  const changeStatusfucntion = () => {
    axios
      .delete(`https://uatamma.apimachine.com/api/deleteteams/${storid}`, {
        email: email,
      })
      .then(({ data }) => {
        setTimeout(() => {
          window.location.reload();
        }, 2000);

        // toastify(data?.message);
      });
  };

  return (
    <div className={classNames.ownerContainer}>
      <Drawer
        size={450}
        // duration={500}
        open={bookingDrawer}
        onClose={() => setbookingDrawer(false)}
        direction="right"
        className="bla bla bla"
        overlayColor="#FFF"
        overlayOpacity={0}
      >
        <SettingDrawer
          changeStatusfucntion={changeStatusfucntion}
          bookingDrawer={bookingDrawer}
          setbookingDrawer={setbookingDrawer}
        />
      </Drawer>
      <div
        className={`${classNames.ownerItems} ${classNames.ownerItemsHover}`}
        onClick={() => {
          if (ownerType?.email?.key !== "TeamOwner") {
            becomeTeamOwner();
          }
        }}
      >
        <div>
          {ownerType?.email?.key === "TeamOwner"
            ? "Organization owner"
            : "Become a organization owner"}
        </div>
        {ownerType?.email?.key !== "TeamOwner" ? (
          <img src={doublearrow} alt="doublearrow" />
        ) : (
          <img src={tickwithcircle} alt="tickwithcircle" />
        )}
      </div>
      <div
        className={`${classNames.ownerItems} ${classNames.ownerItemsHover}`}
        onClick={() => setSelectedItem("CreateNewOrganization")}
        style={{ display: ownerType?.email?.key === "TeamOwner" ? "" : "none" }}
      >
        <div>Create new organization</div>
        <img src={doublearrow} alt="doublearrow" style={{ border: "none" }} />
      </div>
      <div className={classNames.line}></div>
      {organizationResponse?.length > 0 &&
        organizationResponse?.map((eachOrganization, index) => {
          return (
            <div
              className={classNames.ownerItems}
              key={"eachOrganization" + index}
            >
              <div className={classNames.profileDetails}>
                <img
                  src={eachOrganization?.Icon ? eachOrganization?.Icon : ""}
                  alt={
                    eachOrganization?.NameOfTeam
                      ? eachOrganization?.NameOfTeam
                      : ""
                  }
                />
                <span>
                  {eachOrganization?.NameOfTeam
                    ? eachOrganization?.NameOfTeam
                    : ""}
                </span>
              </div>
              <div className={classNames.buttonsContainer}>
                <div>View</div>
                <div>Edit</div>
                <div onClick={() => callfucs(eachOrganization?._id)}>
                  Delete
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

const Profile = () => {
  const location = useLocation();
  const { email, setProfileUsername } = useContext(GlobalContext);
  const [profileData, setProfileData] = useState("");
  const [profileDataLoading, setProfileDataLoading] = useState(true);

  const [dropdownItems, setDropdownItems] = useState([
    { NameOfTeam: "Vitality", Icon: montageLogo },
  ]);
  const [appDropdown, setAppDropdown] = useState("");

  useEffect(() => {
    if (email) {
      axios
        .get(`https://uatamma.apimachine.com/api/checkuser/${email}`)
        .then((response) => {
          if (response?.data?.success) {
            console.log("Profile data is:", response?.data?.email);
            localStorage.setItem(
              "profileUsername",
              response?.data?.email?.username
            );
            setProfileUsername(response?.data?.email?.username);
            setProfileData(response?.data?.email);
          }
          setProfileDataLoading(false);
        })
        .catch((error) => {
          console.log(error, "Profile data error");
          setProfileDataLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (profileData?.username) {
      axios
        .get(
          `https://uatamma.apimachine.com/api/getteamsbyplayer?PlayerUsername=${profileData?.username}`
        )
        .then((response) => {
          if (response?.data?.success) {
            let mapppingArray = response?.data?.theplayers?.map(
              (eachItem, index) => {
                return extractedData(eachItem);
              }
            );
            function extractedData(inputObj) {
              let res =
                inputObj.TeamDetails?.[0]?.TeamDetails?.map((team) => ({
                  NameOfTeam: team.NameOfTeam,
                  Icon: team.Icon,
                })) || [];
              return res[0];
            }

            console.log(mapppingArray, "mapppingArray");
            setDropdownItems([
              { NameOfTeam: "Vitality", Icon: montageLogo },
              ...mapppingArray,
            ]);
            console.log("Profile data dropdown:", response?.data?.theplayers);
            // setProfileData(response?.data?.theplayers);
          }
          // setProfileDataLoading(false);
        })
        .catch((error) => {
          console.log(error, "Profile data error");
          // setProfileDataLoading(false);
        });
    }
  }, [profileData?.username]);

  function toCardinal(num) {
    var ones = num % 10;
    var tens = num % 100;

    if (tens < 11 || tens > 13) {
      switch (ones) {
        case 1:
          return num + "st";
        case 2:
          return num + "nd";
        case 3:
          return num + "rd";
      }
    }

    return num + "th";
  }
  var monthNames = [
    "",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  let resultstart = profileData?.birthday?.split("/");
  return (
    <div className={classNames.profileParent}>
      <div className={classNames.profileName}>
        {profileDataLoading ? (
          <Skeleton width={60} height={60} />
        ) : profileData?.ProfilePic ? (
          <img src={profileData?.ProfilePic} alt={profileData?.ProfilePic} />
        ) : (
          ""
        )}
        <span>
          {profileDataLoading ? (
            <Skeleton width={150} height={40} />
          ) : profileData ? (
            profileData?.name
          ) : (
            ""
          )}
        </span>
        {/* <div style={{ marginLeft: "auto" }}>
          <EachCustomDropdown
            dropdown={dropdownItems}
            name="selectedApp"
            stateValue={appDropdown}
            setState={setAppDropdown}
          />
        </div> */}
      </div>
      <div className={classNames.profileDetails}>
        <div className={classNames.topContainer}>
          <div
            className={classNames.eachProfileItem}
            // style={{ width: "250px" }}
          >
            <div className={classNames.title}>Profile Image</div>
            <div className={classNames.inputValue}>
              <EditContainer
                value={
                  profileDataLoading ? (
                    "Loading"
                  ) : profileData?.ProfilePic ? (
                    <img src={profileData?.ProfilePic} alt="dummyProfile" />
                  ) : (
                    ""
                  )
                }
                name="ProfilePic"
                type="image"
                clickable="true"
              />
            </div>
          </div>
          <div className={classNames.valuesDiv}>
            <div className={classNames.eachProfileItem}>
              <div className={classNames.title}>Role</div>
              <div className={classNames.inputValue}>
                <EditContainer
                  value={
                    profileDataLoading
                      ? "Loading"
                      : profileData?.key === "Player"
                      ? "Patient"
                      : profileData
                      ? profileData?.key
                      : ""
                  }
                />
              </div>
            </div>
            <div className={classNames.eachProfileItem}>
              <div className={classNames.title}>Onboarding</div>
              <div className={classNames.inputValue}>
                <EditContainer
                  value={
                    profileDataLoading
                      ? "Loading"
                      : profileData
                      ? profileData?.createdAt
                      : ""
                  }
                />
              </div>
            </div>
            <div className={classNames.eachProfileItem}>
              <div className={classNames.title}>Name</div>
              <div className={classNames.inputValue}>
                <EditContainer
                  value={
                    profileDataLoading
                      ? "Loading"
                      : profileData
                      ? profileData?.name
                      : ""
                  }
                />
              </div>
            </div>
            <div className={classNames.eachProfileItem}>
              <div className={classNames.title}>Username</div>
              <div className={classNames.inputValue}>
                <EditContainer
                  value={
                    profileDataLoading
                      ? "Loading"
                      : profileData
                      ? profileData?.username
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {/*  */}
        <div className={classNames.topContainer}>
          <div className={classNames.eachProfileItem}>
            <div className={classNames.title}>Birthday</div>
            <div className={classNames.inputValue}>
              <EditContainer
                value={
                  profileDataLoading ? (
                    "Loading"
                  ) : profileData ? (
                    <>
                      {monthNames[resultstart?.[0]?.replace(/^0+/, "")]}{" "}
                      {toCardinal(resultstart?.[1])} {resultstart?.[2]}
                    </>
                  ) : (
                    ""
                  )
                }
                name="YearOfBirth"
                clickable="true"
              />
            </div>
          </div>

          <div className={classNames.valuesDiv}>
            <div className={classNames.eachProfileItem}>
              <div className={classNames.title}>Country</div>
              <div className={classNames.inputValue}>
                <EditContainer
                  value={
                    profileDataLoading
                      ? "Loading"
                      : profileData
                      ? profileData?.Country
                      : ""
                  }
                  name="Country"
                  clickable="true"
                />
              </div>
            </div>

            <div className={classNames.eachProfileItem}>
              <div className={classNames.title}>Address</div>
              <div className={classNames.inputValue}>
                <EditContainer
                  value={
                    profileDataLoading
                      ? "Loading"
                      : profileData
                      ? profileData?.Address
                      : ""
                  }
                  name="Address"
                  clickable="true"
                />
              </div>
            </div>
          </div>
          <div className={classNames.eachProfileItem}>
            <div className={classNames.title}>Gender</div>
            <div className={classNames.inputValue}>
              <EditContainer
                value={
                  profileDataLoading
                    ? "Loading"
                    : profileData
                    ? profileData?.Gender
                    : ""
                }
                name="Gender"
                clickable="true"
              />
            </div>
          </div>
          <div className={classNames.valuesDiv}>
            <div className={classNames.eachProfileItem}>
              <div className={classNames.title}>Phone Number</div>
              <div className={classNames.inputValue}>
                <EditContainer
                  value={
                    profileDataLoading
                      ? "Loading"
                      : profileData
                      ? profileData?.PhoneNumber
                      : ""
                  }
                  name="PhoneNumber"
                  clickable="true"
                />
              </div>
            </div>
            <div className={classNames.eachProfileItem}>
              <div className={classNames.title}>Email</div>
              <div className={classNames.inputValue}>
                <EditContainer
                  value={
                    profileDataLoading
                      ? "Loading"
                      : profileData
                      ? profileData?.email
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {/*  */}
        <div className={classNames.bottomProfile}></div>
        <div className={classNames.topContainer}>
          <div
            className={classNames.eachProfileItem}
            // style={{ width: "250px" }}
          >
            <div className={classNames.title}>Government ID</div>
            <div className={classNames.inputValue}>
              <EditContainer
                value={
                  profileDataLoading ? (
                    "Loading"
                  ) : profileData?.governmentId ? (
                    <img src={profileData?.governmentId} alt="dummyProfile" />
                  ) : (
                    ""
                  )
                }
                name="governmentId"
                type="image"
                clickable="true"
              />
            </div>
          </div>
          <div className={classNames.valuesDiv}>
            <div className={classNames.eachProfileItem}>
              <div className={classNames.title}>State</div>
              <div className={classNames.inputValue}>
                <EditContainer
                  value={
                    profileDataLoading
                      ? "Loading"
                      : profileData
                      ? profileData?.state
                      : ""
                  }
                  name="PhoneNumber"
                  clickable="true"
                />
              </div>
            </div>
            <div className={classNames.eachProfileItem}>
              <div className={classNames.title}>Pincode</div>
              <div className={classNames.inputValue}>
                <EditContainer
                  value={
                    profileDataLoading
                      ? "Loading"
                      : profileData
                      ? profileData?.pincode
                      : ""
                  }
                  name="PhoneNumber"
                  clickable="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CreateNewOrganization = ({ setSelectedItem }) => {
  const navigate = useNavigate();
  const { email, profileUsername } = useContext(GlobalContext);
  const fileInputRefNewUser = useRef(null);
  const fileInputRefNewUserGovId = useRef(null);

  const [userRegistrationDetails, setUserRegistrationDetails] = useState({
    OtherData: "",
    NameOfTeam: "",
    TeamUsername: "",
    Description: "",
    Icon: "",
    Logo: "",
    IndustriesId: "",
  });
  const [userRegistrationLoading, setUserRegistrationLoading] = useState(false);
  const [allIndustries, setAllIndustries] = useState("");

  //image upload
  const [uploadFileRFQ, setUploadFileRFQ] = useState("");
  const [uploadFileRFQName, setUploadFileRFQName] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);
  const [uploadFileRFQ1, setUploadFileRFQ1] = useState("");
  const [uploadFileRFQName1, setUploadFileRFQName1] = useState("");
  const [updateLoading1, setUpdateLoading1] = useState(false);

  useEffect(() => {
    getAllIndustries();
  }, []);

  async function getAllIndustries() {
    let response = await getData(
      "https://uatamma.apimachine.com/api/getallindustries"
    );
    if (response?.success) {
      setAllIndustries(response?.data);
    }
    console.log(response, "industry response");
  }
  console.log("userRegistrationDetails", userRegistrationDetails);
  return (
    <div className={classNames.createNewOrganization}>
      <div className={`${classNames.leftCard} ${classNames.userRegistration}`}>
        <div
          className={`${classNames.credentialsContainer} ${classNames.userRegistrationContainer}`}
        >
          <div className={classNames.title}>Create new organization</div>
          <div className={classNames.flexParent}>
            <input
              className={classNames.inputContainer}
              placeholder="Organization name"
              value={userRegistrationDetails?.NameOfTeam}
              onChange={(event) => {
                setUserRegistrationDetails((prev) => {
                  return {
                    ...prev,
                    NameOfTeam: event?.target?.value?.replace(
                      /[^a-zA-Z0-9\s]/g,
                      ""
                    ),
                  };
                });
              }}
            />
            <input
              className={classNames.inputContainer}
              placeholder="Organization username"
              value={userRegistrationDetails?.TeamUsername}
              onChange={(event) => {
                const regex = /^[a-zA-Z0-9]+$/;
                const value = event?.target?.value?.replace(
                  /[^a-zA-Z0-9]/g,
                  ""
                );

                setUserRegistrationDetails((prev) => {
                  return {
                    ...prev,
                    TeamUsername: event?.target?.value?.replace(
                      /[^a-zA-Z0-9]/g,
                      ""
                    ),
                  };
                });
              }}
            />
          </div>

          <div
            className={classNames.flexParent}
            style={{ alignItems: "unset" }}
          >
            <textarea
              className={classNames.inputContainer}
              placeholder="Description"
              value={userRegistrationDetails?.Description}
              onChange={(event) => {
                setUserRegistrationDetails((prev) => {
                  return {
                    ...prev,
                    Description: event?.target.value,
                  };
                });
              }}
            />
            <div className={classNames.profileContainer}>
              <div className={classNames.imageInput}>
                {uploadFileRFQ1 ? (
                  <img src={uploadFileRFQ1} alt={uploadFileRFQName1} />
                ) : (
                  <div
                    className={classNames.emptyProfileLength}
                    onClick={() => {
                      if (fileInputRefNewUserGovId.current) {
                        fileInputRefNewUserGovId.current.click();
                      }
                    }}
                  >
                    <img
                      src={uploadIcon}
                      alt="uploadIcon"
                      style={{
                        animation: updateLoading1
                          ? "heartbeat 1s infinite"
                          : "",
                      }}
                    />
                  </div>
                )}
                <input
                  className={classNames.uploadNewPicPlanB}
                  type="file"
                  style={{ display: "none" }}
                  onChange={async (e) => {
                    let resFile = uploadFileFunc(
                      e,
                      setUploadFileRFQName1,
                      setUpdateLoading1,
                      "name"
                    );
                    console.log(await resFile, "res file link await");
                    setUploadFileRFQ1(await resFile);
                    let govId = await resFile;
                    setUserRegistrationDetails((prev) => {
                      return {
                        ...prev,
                        Logo: govId,
                      };
                    });
                  }}
                  ref={fileInputRefNewUserGovId}
                />
                <div
                  className={classNames.imageLabel}
                  onClick={() => {
                    if (fileInputRefNewUserGovId.current) {
                      fileInputRefNewUserGovId.current.click();
                    }
                  }}
                >
                  Upload Logo
                </div>
              </div>
            </div>
          </div>
          <div className={classNames.flexParent}>
            <EachCustomDropdown
              dropdown={allIndustries?.length > 0 ? allIndustries : []}
              name="IndustriesId"
              stateValue={userRegistrationDetails}
              setState={setUserRegistrationDetails}
            />
            <div className={classNames.profileContainer}>
              <div className={classNames.imageInput} style={{ width: "12rem" }}>
                {uploadFileRFQ ? (
                  <img src={uploadFileRFQ} alt={uploadFileRFQName} />
                ) : (
                  <div
                    className={classNames.emptyProfile}
                    onClick={() => {
                      if (fileInputRefNewUser.current) {
                        fileInputRefNewUser.current.click();
                      }
                    }}
                  >
                    <img
                      src={uploadIcon}
                      alt="uploadIcon"
                      style={{
                        animation: updateLoading ? "heartbeat 1s infinite" : "",
                      }}
                    />
                  </div>
                )}
                <input
                  className={classNames.uploadNewPicPlanB}
                  type="file"
                  style={{ display: "none" }}
                  onChange={async (e) => {
                    let resFile = uploadFileFunc(
                      e,
                      setUploadFileRFQName,
                      setUpdateLoading,
                      "name"
                    );
                    console.log(await resFile, "res file link await");
                    setUploadFileRFQ(await resFile);
                    let profileFile = await resFile;
                    setUserRegistrationDetails((prev) => {
                      return {
                        ...prev,
                        Icon: profileFile,
                      };
                    });
                  }}
                  ref={fileInputRefNewUser}
                />
                <div
                  className={classNames.imageLabel}
                  onClick={() => {
                    if (fileInputRefNewUser.current) {
                      fileInputRefNewUser.current.click();
                    }
                  }}
                >
                  Upload Icon
                </div>
              </div>
            </div>
          </div>

          <div
            className={classNames.flexParent}
            style={{ marginTop: "2rem", justifyContent: "space-between" }}
          >
            <button
              className={classNames.transparentBtn}
              style={{
                opacity: userRegistrationLoading ? "0.5" : "",
                pointerEvents: userRegistrationLoading ? "none" : "",
                background: "#010101",
                color: "white",
                width: "max-content",
                padding: "1rem 2rem",
              }}
              onClick={() => {
                setSelectedItem("Owner");
              }}
            >
              Back
            </button>
            <button
              className={classNames.submitBtn}
              style={{
                opacity: userRegistrationLoading ? "0.5" : "",
                pointerEvents: userRegistrationLoading ? "none" : "",
                width: "max-content",
                padding: "1rem 2rem",
              }}
              onClick={async () => {
                if (
                  userRegistrationDetails?.NameOfTeam != "" &&
                  userRegistrationDetails?.TeamUsername != "" &&
                  userRegistrationDetails?.Description != "" &&
                  userRegistrationDetails?.Icon != "" &&
                  userRegistrationDetails?.Logo != "" &&
                  userRegistrationDetails?.IndustriesId != ""
                ) {
                  let obj = {
                    ...userRegistrationDetails,
                    TeamCreatedBy: email,
                    TeamUsername: userRegistrationDetails?.TeamUsername,
                    IndustriesId: [userRegistrationDetails?.IndustriesId],
                  };
                  console.log(obj, "ocreate a team obj");
                  setUserRegistrationLoading(true);

                  let response = await createOrganization(obj);
                  console.log(response, "Created organization succesfully");
                  if (response?.success) {
                    toastify(`Created organization succesfully`, "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 2000);
                  } else {
                    toastify(`Error while creating organization`, "error");
                    // setUserRegistration(response);
                  }
                  setUserRegistrationLoading(false);
                  console.log(response, "user registration response");
                } else {
                  toastify(
                    `Please Fill In Organization ${
                      userRegistrationDetails?.NameOfTeam == "" ? "Name," : ""
                    }
                    ${
                      userRegistrationDetails?.TeamUsername == ""
                        ? "UserName,"
                        : ""
                    }
                    ${
                      userRegistrationDetails?.Description == ""
                        ? "Description,"
                        : ""
                    }
                    ${userRegistrationDetails?.Logo == "" ? "Logo," : ""}
                    ${userRegistrationDetails?.Icon == "" ? "Icon," : ""}
                    ${
                      userRegistrationDetails?.IndustriesId == ""
                        ? "Type Of Industries,"
                        : ""
                    }

                    
                    Field`,
                    "error"
                  );
                }
              }}
            >
              {userRegistrationLoading ? "Loading..." : "Next"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const EditContainer = ({ value, type, name, clickable }) => {
  const { email } = useContext(GlobalContext);
  const [editOpen, setEditOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [uploadFileRFQ, setUploadFileRFQ] = useState("");
  const [uploadFileRFQName, setUploadFileRFQName] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);
  const editContainerRef = useRef(null);
  const inputRef = useRef(null);
  const fileInputRef = useRef(null);
  const datepickerRef1 = useRef(null);
  console.log("inputValue", name);
  useEffect(() => {
    // Function to close the edit state when clicking outside the component
    const handleClickOutside = (event) => {
      if (
        editContainerRef.current &&
        !editContainerRef.current.contains(event.target)
      ) {
        setEditOpen(false);
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editContainerRef]);

  useEffect(() => {
    if (editOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editOpen]);

  const handleEditIconClick = () => {
    setEditOpen(true);

    // Trigger click on the file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    if (datepickerRef1?.current) {
      const options = {
        dateFormat: "m/d/Y",
        // Other options here
        onChange: function (selectedDates, dateStr, instance) {
          console.log("Selected start date:", dateStr, datepickerRef1?.current);
          // setbrithday(dateStr)
          setInputValue(dateStr);
        },
      };

      flatpickr(datepickerRef1.current, options);
    }
  }, [datepickerRef1?.current]);

  function updateProfileData() {
    axios
      .put("https://uatamma.apimachine.com/api/edituserdetails", {
        email,
        [name]:
          name == "governmentId" && name == "ProfilePic"
            ? uploadFileRFQ
            : inputValue,
      })
      .then((response) => {
        // console.log("Response from update profile", response);
        if (response?.data?.success) {
          toastify(`Updated ${name} successfully!`, "success");
        } else {
          toastify(`Failed to update ${name}!`, "error");
        }
        setEditOpen(false);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 500);
      })
      .catch((error) => {
        console.log("Profile update error", error?.message);
        toastify(`Profile update error ${name}!`, "error");
        setEditOpen(false);
      });
  }

  return (
    <div ref={editContainerRef}>
      {type === "image" ? (
        <div className={classNames.imageBox}>
          {uploadFileRFQ ? (
            <img src={uploadFileRFQ} alt="uploadFileRFQfile" />
          ) : (
            value
          )}
        </div>
      ) : editOpen ? (
        name == "YearOfBirth" ? (
          <input
            type="text"
            id="datepicker1"
            ref={datepickerRef1}
            className={classNames.inputBox}
            placeholder="Select birthday"
            value={inputValue}
          />
        ) : (
          <input
            placeholder="Enter.."
            className={classNames.inputBox}
            ref={inputRef}
            value={inputValue}
            onChange={(event) => setInputValue(event?.target?.value)}
          />
        )
      ) : (
        <div className={classNames.valueBox}>
          {value === "Loading" ? (
            <Skeleton width={150} height={40} />
          ) : value ? (
            value
          ) : (
            ""
          )}
        </div>
      )}
      <div className={classNames.editContainer}>
        {clickable ? (
          <div
            className={classNames.editIcon}
            onClick={async () => {
              if (type === "image") {
                handleEditIconClick();
              } else {
                setEditOpen(true);
              }
            }}
            style={{
              display: editOpen ? "none" : "",
              pointerEvents: clickable ? "" : "none",
            }}
          >
            <img src={editIcon} alt="editIcon" />
          </div>
        ) : (
          ""
        )}

        <div
          className={classNames.buttonsContainer}
          style={{ display: editOpen ? "" : "none" }}
        >
          <button
            onClick={() => {
              setEditOpen(false);
            }}
          >
            Close
          </button>
          <button onClick={updateProfileData}>Save</button>
        </div>

        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          style={{ display: "none" }}
          onChange={async (e) => {
            let resFile = uploadFileFunc(
              e,
              setUploadFileRFQName,
              setUpdateLoading,
              "name"
            );
            console.log(await resFile, "res file link await");
            setUploadFileRFQ(await resFile);
          }}
          ref={fileInputRef}
        />
      </div>
    </div>
  );
};
