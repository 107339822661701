import React, { useContext, useEffect, useState } from "react";
import classNames from "./registration.module.scss";
import OtpInput from "react-otp-input";
import axios from "axios";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";

//assets
import fullLogo from "../../assets/images/logo.svg";
import { IoCheckmarkCircleSharp } from "react-icons/io5";

import { registrationItems } from "../../assets/data/mapItems";
import {
  confirmEmailWithOTP,
  preRegistrationLogin,
  preRegistrationUpdateUserPassword,
} from "../../assets/functions/authentication";
import { toastify } from "../../assets/functions/toastify";
import dummyProfile from "../../assets/images/registration/dummyprofile.svg";
import { areAllFieldsFilled, hideEmail, isEmail } from "../../assets/functions";
import { getData } from "../../assets/functions/apiCalls";
import { GlobalContext } from "../../context/Globalcontext";
import { CustomPasswordInput, CustomPasswordInputpassword } from "../../components/Custom";

const Registration = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const { affiliateusername } = useParams();
  const { setGlobalLoading } = useContext(GlobalContext);

  //page values
  const [emailCredentials, setEmailCredentials] = useState("");
  const [passwordCredentials, setPasswordCredentials] = useState("");
  const [resetPassword, setResetPassword] = useState({});
  const [registrationStep, setRegistrationStep] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [sessionResponse, setSessionResponse] = useState("");
  const [userOtp, setUserOtp] = useState("");

  //via affiliate
  const [affiliateUser, setAffiliateUser] = useState({
    username: "",
    email: "",
    password: "",
    ref_affiliate: "",
    account_type: "Personal",
    signedup_app: "amma",
  });
  const [allAffiliateTemp, setAllAffiliateTemp] = useState("");
  const [affiliateProfileData, setAffiliateProfileData] = useState("");

  useEffect(() => {
    if (location?.pathname == "/registration/affiliate") {
      setRegistrationStep("Loading");
      setRegistrationStep("Affiliate");
    }

    if (
      location?.pathname?.includes(
        "/registration/affiliate/" && affiliateusername
      )
    ) {
      setRegistrationStep("Loading");
      checkAffiliateExist();
    }
  }, [navigate]);

  async function checkAffiliateExist() {
    if (isEmail(affiliateusername)) {
      let response = await getData(
        `https://comms.globalxchange.io/user/profile/data/get?email=${affiliateusername}`
      );
      // console.log(response, "email response");
      if (response?.status && response?.usersData?.length > 0) {
        setAffiliateProfileData(response?.usersData[0]);
        setRegistrationStep("Affiliate-Step1");
      } else {
        toastify("Affiliate profile not found", "error");
        setRegistrationStep("");
        navigate("/registration");
      }
    } else if (affiliateusername) {
      let response = await getData(
        `https://comms.globalxchange.io/user/profile/data/get?username=${affiliateusername}`
      );
      // console.log(response, "username response");
      if (response?.status && response?.usersData?.length > 0) {
        setAffiliateProfileData(response?.usersData[0]);
        setRegistrationStep("Affiliate-Step1");
      } else {
        toastify("Affiliate profile not found", "error");
        setRegistrationStep("");
        navigate("/registration");
      }
    }
  }

  async function updatePasswordAPI() {
    setIsLoggingIn(true);
    setGlobalLoading(true);
    let response = await preRegistrationUpdateUserPassword(
      sessionResponse?.email,
      resetPassword?.confirm_password,
      sessionResponse?.authChallenge_id,
      sessionResponse?.username,
      sessionResponse?.challengeName,
      sessionResponse?.session
    );
    setIsLoggingIn(true);
    setGlobalLoading(true);
    if (
      response?.status === true &&
      response?.message === "Successfully Logged In"
    ) {
      // setRegistrationStep("Pre-Registered-Step3");
      toastify("Password updated successfully!", "success");
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } else {
      toastify("Error while updating password", "error");
    }
    setIsLoggingIn(false);
    setGlobalLoading(false);
  }

  async function confirmEmailOTP() {
    setRegistrationStep("Affiliate-Step-Success");
    setIsLoggingIn(true);
    setGlobalLoading(true);
    let response = await confirmEmailWithOTP(affiliateUser?.email, userOtp);
    if (response?.status === true) {
      toastify("User registration successful!", "success");
    } else {
      toastify("OTP entered is wrong!", "error");
    }
    setIsLoggingIn(false);
    setGlobalLoading(false);
    setTimeout(() => {
      navigate("/");
    }, 3000);
  }

  async function getAffiliateProfile(queryTypeValue, type) {
    // setGlobalLoading(true);
    setIsLoggingIn(true);
    let response = await axios.get(
      `https://comms.globalxchange.io/user/profile/data/get?${queryTypeValue}`
    );
    console.log(response?.data, "getAffiliateProfile");
    if (type === "isExist" && response?.data?.count > 0) {
      setAllAffiliateTemp((prev) => {
        return { ...prev, isExist: true };
      });
    } else if (type === "isExist") {
      setAllAffiliateTemp((prev) => {
        return { ...prev, isExist: false };
      });
    } else if (type !== "isExist") {
      setAffiliateProfileData(response?.data?.usersData[0]);
      setRegistrationStep("Affiliate-Step1");
    }
    setIsLoggingIn(false);
    // setGlobalLoading(false);
  }

  function isValidEmail(email) {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regex
    return emailRegex.test(email);
  }

  function userRegistration() {
    setGlobalLoading(true);
    setIsLoggingIn(true);
    axios
      .post("https://gxauth.apimachine.com/gx/user/signup", affiliateUser)
      .then((response) => {
        console.log(response, "userRegistration");
        if (response?.data?.status) {
          setRegistrationStep("Affiliate-Step6");
          // toastify("User registration successful!", "success");

          // setTimeout(() => {
          //   navigate("/");
          // }, 2000);
        } else {
          toastify(response?.data?.message, "error");
        }
        setGlobalLoading(false);
        setIsLoggingIn(false);
      })
      .catch((error) => {
        console.log(error?.message, "userRegistration");
        toastify("User registration failed!", "error");
        setIsLoggingIn(false);
        setGlobalLoading(false);
      });
  }

  const [passwordValidation, setPasswordValidation] = useState({
    hasCapitalLetter: false,
    hasSpecialCharacter: false,
    hasNumber: false,
    hasMinLength: false,
  });

  //functions

  function handleChange(password) {
    const capitalRegex = /[A-Z]/;
    const specialRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /\d/;

    console.log(password, "password validation");

    if (capitalRegex.test(password)) {
      setPasswordValidation((prev) => {
        return { ...prev, hasCapitalLetter: true };
      });
    } else {
      setPasswordValidation((prev) => {
        return { ...prev, hasCapitalLetter: false };
      });
    }

    if (specialRegex.test(password)) {
      setPasswordValidation((prev) => {
        return { ...prev, hasSpecialCharacter: true };
      });
    } else {
      setPasswordValidation((prev) => {
        return { ...prev, hasSpecialCharacter: false };
      });
    }

    if (numberRegex.test(password)) {
      setPasswordValidation((prev) => {
        return { ...prev, hasNumber: true };
      });
    } else {
      setPasswordValidation((prev) => {
        return { ...prev, hasNumber: false };
      });
    }

    if (password?.length >= 10) {
      setPasswordValidation((prev) => {
        return { ...prev, hasMinLength: true };
      });
    } else {
      setPasswordValidation((prev) => {
        return { ...prev, hasMinLength: false };
      });
    }
  }

  async function preRegisterStep1Func(event) {
    console.log(event, "eventtt");
    console.log(event?.key, "eventkey");
    if (event.key === "Enter") {
      setGlobalLoading(true);
      setIsLoggingIn(true);
      let response = await preRegistrationLogin(
        emailCredentials,
        passwordCredentials
      );
      setIsLoggingIn(false);
      setGlobalLoading(false);
      if (
        response?.message === "Reset User Password!" ||
        response?.challengeName === "NEW_PASSWORD_REQUIRED"
      ) {
        setRegistrationStep("Pre-Registered-Step1");
      }
      setSessionResponse(response);
    }
  }

  async function preRegisterStep2Func(event) {
    if (event.key === "Enter") {
      setRegistrationStep("Pre-Registered-Step2");
    }
  }

  async function preRegisterStep3Func(event) {
    if (event.key === "Enter") {
      if (resetPassword?.password === resetPassword?.confirm_password) {
        updatePasswordAPI();
      } else {
        setRegistrationStep("Password dosen't match!");
      }
    }
  }

  //useeeffect

  useEffect(() => {
    handleChange(
      registrationStep === "Affiliate-Step4"
        ? allAffiliateTemp?.password
        : registrationStep === "Pre-Registered-Step1"
          ? resetPassword?.password
          : ""
    );
  }, [allAffiliateTemp?.password, resetPassword]);

  // Debouncing logic using useEffect
  useEffect(() => {
    let debounceTimer;
    if (registrationStep === "Affiliate-Step2") {
      debounceTimer = setTimeout(() => {
        getAffiliateProfile("email=" + affiliateUser?.email, "isExist");
      }, 100); // 100 milliseconds
    } else if (registrationStep === "Affiliate-Step3") {
      debounceTimer = setTimeout(() => {
        getAffiliateProfile("username=" + affiliateUser?.username, "isExist");
      }, 100); // 100 milliseconds
    }

    return () => clearTimeout(debounceTimer); // Cleanup on unmount or input change
  }, [affiliateUser?.email, affiliateUser?.username]);

  return (
    <div className={classNames.loginPage}>
      <div className={classNames.topNav}>
        <Link
          to="/"
          style={{
            display:
              registrationStep === "Affiliate-Step-Success" ||
                registrationStep === "Affiliate-Step6"
                ? "none"
                : "",
          }}
        >
          Go Back To Login
        </Link>
      </div>
      <div className={classNames.loginCard}>
        {registrationStep === "Loading" ? (
          <div
            className={classNames.leftCard}
            style={{
              paddingRight: "3rem",
              height: "500px",
              alignItems: "center",
            }}
          >
            <img
              src={fullLogo}
              alt="fullLogo"
              className={classNames.heartBeatLoading}
            />
          </div>
        ) : registrationStep === "Pre-Registered" ? (
          <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
            <img src={fullLogo} alt="fullLogo" onClick={() => navigate("/")} />
            <div className={classNames.title}>
              Enter the temporary credentials you have recieved
            </div>
            <div className={classNames.credentialsContainer}>
              <input
                className={classNames.inputContainer}
                placeholder="Email"
                type="email"
                value={emailCredentials}
                onChange={(event) => {
                  setEmailCredentials(event?.target.value);
                }}
              />
              <CustomPasswordInput
                placeholder="Password"
                stateValue={passwordCredentials}
                setState={setPasswordCredentials}
                func={preRegisterStep1Func}
              />
              {/* <input
                className={classNames.inputContainer}
                placeholder="Password"
                type="password"
                value={passwordCredentials}
                onChange={(event) => {
                  setPasswordCredentials(event?.target.value);
                }}
                onKeyDown={async (event) => {
                  if (event.key === "Enter") {
                    setGlobalLoading(true);
                    setIsLoggingIn(true);
                    let response = await preRegistrationLogin(
                      emailCredentials,
                      passwordCredentials
                    );
                    setIsLoggingIn(false);
                    setGlobalLoading(false);
                    setSessionResponse(response);
                  }
                }}
              /> */}
              <div className={classNames.buttonsContainer}>
                <button
                  className={classNames.blueBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => setRegistrationStep("")}
                >
                  Back
                </button>
                <button
                  className={classNames.submitBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={async () => {
                    setIsLoggingIn(true);
                    setGlobalLoading(true);
                    let response = await preRegistrationLogin(
                      emailCredentials,
                      passwordCredentials
                    );
                    setIsLoggingIn(false);
                    setGlobalLoading(false);
                    if (
                      response?.message === "Reset User Password!" ||
                      response?.challengeName === "NEW_PASSWORD_REQUIRED"
                    ) {
                      setRegistrationStep("Pre-Registered-Step1");
                    }
                    setSessionResponse(response);
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        ) : registrationStep === "Pre-Registered-Step1" ? (
          <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
            <img src={fullLogo} alt="fullLogo" />
            <div className={classNames.title}>
              Create your new permanent password
            </div>
            <div
              className={classNames.credentialsContainer}
              style={{ minWidth: "550px" }}
            >
              <CustomPasswordInput
                placeholder="Password"
                name="password"
                stateValue={resetPassword}
                setState={setResetPassword}
                func={preRegisterStep2Func}
              />
              {/* <input
                className={classNames.inputContainer}
                placeholder="Password"
                type="password"
                value={resetPassword?.password}
                onChange={(event) => {
                  setResetPassword((prev) => {
                    return { ...prev, password: event?.target.value };
                  });
                }}
                onKeyDown={async (event) => {
                  if (event.key === "Enter") {
                    setRegistrationStep("Pre-Registered-Step2");
                  }
                }}
              /> */}
              <div className={classNames.conditions}>
                <div className={classNames.eachCondition}>
                  Minimum One Capital Letter
                  <IoCheckmarkCircleSharp
                    className={classNames.indicator}
                    style={{
                      color: passwordValidation?.hasCapitalLetter
                        ? "#122E5A "
                        : "var(--font-color)",
                    }}
                  />
                </div>
                <div className={classNames.eachCondition}>
                  Minimum One Special Character
                  <IoCheckmarkCircleSharp
                    className={classNames.indicator}
                    style={{
                      color: passwordValidation?.hasSpecialCharacter
                        ? "#122E5A"
                        : "var(--font-color)",
                    }}
                  />
                </div>
                <div className={classNames.eachCondition}>
                  Minimum Ten Characters
                  <IoCheckmarkCircleSharp
                    className={classNames.indicator}
                    style={{
                      color: passwordValidation?.hasMinLength
                        ? "#122E5A"
                        : "var(--font-color)",
                    }}
                  />
                </div>
                <div className={classNames.eachCondition}>
                  Minimum One Number
                  <IoCheckmarkCircleSharp
                    className={classNames.indicator}
                    style={{
                      color: passwordValidation?.hasNumber
                        ? "#122E5A"
                        : "var(--font-color)",
                    }}
                  />
                </div>
              </div>
              <div className={classNames.buttonsContainer}>
                <button
                  className={classNames.blueBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => setRegistrationStep("Pre-Registered")}
                >
                  Back
                </button>
                <button
                  className={classNames.submitBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => {
                    if (areAllFieldsFilled(passwordValidation)) {
                      setRegistrationStep("Pre-Registered-Step2");
                    } else {
                      toastify(
                        "Enter password that satisfy all validations!",
                        "error"
                      );
                    }
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        ) : registrationStep === "Pre-Registered-Step2" ||
          registrationStep === "Password dosen't match!" ? (
          <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
            <img src={fullLogo} alt="fullLogo" />
            <div className={classNames.title}>Confirm your new password</div>
            <div
              className={classNames.credentialsContainer}
              style={{ minWidth: "550px" }}
            >
              <CustomPasswordInput
                placeholder="Password"
                name="confirm_password"
                stateValue={resetPassword}
                setState={setResetPassword}
                func={preRegisterStep3Func}
              />
              {/* <input
                className={classNames.inputContainer}
                placeholder="Password"
                type="password"
                value={resetPassword?.confirm_password}
                onChange={(event) => {
                  setResetPassword((prev) => {
                    return { ...prev, confirm_password: event?.target.value };
                  });
                }}
                onKeyDown={async (event) => {
                  if (event.key === "Enter") {
                    if (
                      resetPassword?.password ===
                      resetPassword?.confirm_password
                    ) {
                      updatePasswordAPI();
                    } else {
                      setRegistrationStep("Password dosen't match!");
                    }
                  }
                }}
              /> */}
              <div className={classNames.conditions}>
                <div className={classNames.eachCondition}>
                  Matches with password
                  <IoCheckmarkCircleSharp
                    className={classNames.indicator}
                    style={{
                      color:
                        resetPassword?.password ===
                          resetPassword?.confirm_password
                          ? "#122E5A"
                          : "var(--font-color)",
                    }}
                  />
                </div>
              </div>
              <div className={classNames.buttonsContainer}>
                <button
                  className={classNames.blueBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => setRegistrationStep("Pre-Registered-Step1")}
                >
                  Back
                </button>
                <button
                  className={classNames.submitBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={async () => {
                    if (
                      resetPassword?.password ===
                      resetPassword?.confirm_password
                    ) {
                      updatePasswordAPI();
                    } else {
                      setRegistrationStep("Password dosen't match!");
                    }
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        ) : // ) : registrationStep === "Pre-Registered-Step3" ? (
          //   <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
          //     <img src={fullLogo} alt="fullLogo" />
          //     <div className={classNames.title}>
          //       Enter The Code That Just Went To{" "}
          //       {emailCredentials ? hideEmail(emailCredentials) : ""}
          //     </div>
          //     <div
          //       className={classNames.credentialsContainer}
          //       style={{ minWidth: "550px" }}
          //     >
          //       <div className={classNames.otpInputParent}>
          //         <OtpInput
          //           value={userOtp}
          //           onChange={setUserOtp}
          //           numInputs={6}
          //           separator={<span>&nbsp;&nbsp;&nbsp;</span>}
          //           shouldAutoFocus
          //           containerStyle={classNames.otpInputWrapper}
          //           inputStyle={classNames.otpInput}
          //         />
          //       </div>
          //       <div
          //         className={classNames.message}
          //         style={{
          //           display:
          //             registrationStep === "OTP entered is wrong!" ? "" : "none",
          //         }}
          //       >
          //         {registrationStep}
          //       </div>
          //       <div className={classNames.buttonsContainer}>
          //         <button
          //           className={classNames.blueBtn}
          //           style={{
          //             opacity: isLoggingIn ? "0.5" : "",
          //             pointerEvents: isLoggingIn ? "none" : "",
          //           }}
          //           // onClick={() => setRegistrationStep("Pre-Registered-Step1")}
          //         >
          //           Resend Code
          //         </button>
          //         <button
          //           className={classNames.submitBtn}
          //           style={{
          //             opacity: isLoggingIn ? "0.5" : "",
          //             pointerEvents: isLoggingIn ? "none" : "",
          //           }}
          //           onClick={async () => {
          //             console.log(userOtp, "userOtp");
          //             if (userOtp?.length === 6) {
          //               confirmEmailOTP();
          //             }
          //           }}
          //         >
          //           Next
          //         </button>
          //       </div>
          //     </div>
          //   </div>
          // ) : registrationStep === "Pre-Registered-Success" ? (
          //   <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
          //     <img src={fullLogo} alt="fullLogo" style={{ margin: "6rem 0" }} />
          //     <div className={classNames.title}>
          //       Your Account Is Being Created
          //     </div>
          //   </div>
          registrationStep === "Affiliate" ? (
            <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
              <img src={fullLogo} alt="fullLogo" onClick={() => navigate("/")} />
              <div className={classNames.title}>Who referred you to Vitality?</div>
              <div className={classNames.credentialsContainer}>
                <input
                  className={classNames.inputContainer}
                  placeholder="Their Username..."
                  type="text"
                  value={allAffiliateTemp?.their_username}
                  onChange={(event) =>
                    setAllAffiliateTemp((prev) => {
                      return { ...prev, their_username: event?.target?.value };
                    })
                  }
                />
                <div className={classNames.seperator}>
                  <span>OR</span>
                </div>
                <input
                  className={classNames.inputContainer}
                  placeholder="Their Email..."
                  type="email"
                  value={allAffiliateTemp?.their_email}
                  onChange={(event) =>
                    setAllAffiliateTemp((prev) => {
                      return { ...prev, their_email: event?.target?.value };
                    })
                  }
                />
                <div className={classNames.buttonsContainer}>
                  <button
                    className={classNames.blueBtn}
                    style={{
                      opacity: isLoggingIn ? "0.5" : "",
                      pointerEvents: isLoggingIn ? "none" : "",
                    }}
                    onClick={() => {
                      setRegistrationStep("");
                      navigate("/registration");
                    }}
                  >
                    Back
                  </button>
                  <button
                    className={classNames.submitBtn}
                    style={{
                      opacity: isLoggingIn ? "0.5" : "",
                      pointerEvents: isLoggingIn ? "none" : "",
                    }}
                    onClick={() => {
                      if (
                        allAffiliateTemp?.their_email &&
                        isValidEmail(allAffiliateTemp?.their_email)
                      ) {
                        // getAffiliateProfile(
                        //   "email=" + allAffiliateTemp?.their_email
                        // );
                        navigate(
                          `/registration/affiliate/${allAffiliateTemp?.their_email}`
                        );
                      } else if (allAffiliateTemp?.their_username) {
                        // getAffiliateProfile(
                        //   "username=" + allAffiliateTemp?.their_username
                        // );
                        navigate(
                          `/registration/affiliate/${allAffiliateTemp?.their_username}`
                        );
                      }
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          ) : registrationStep === "Affiliate-Step1" ? (
            <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
              <img src={fullLogo} alt="fullLogo" onClick={() => navigate("/")} />
              <div className={classNames.title}>
                Please confirm this is the person who referred you?
              </div>
              <div className={classNames.credentialsContainer}>
                <div className={classNames.profileContainer}>
                  <div>
                    <img
                      src={
                        affiliateProfileData?.dynamic?.length > 0 &&
                          affiliateProfileData?.dynamic[0]?.data?.profile_img
                          ? affiliateProfileData?.profile_img
                          : dummyProfile
                      }
                      alt=""
                    />
                  </div>
                  <div>
                    <div>
                      {affiliateProfileData?.hardCoded?.length > 0 &&
                        affiliateProfileData?.hardCoded[0]?.data?.username
                        ? affiliateProfileData?.hardCoded[0]?.data?.username
                        : ""}
                    </div>
                    <div>
                      {affiliateProfileData?.email
                        ? affiliateProfileData?.email
                        : ""}
                    </div>
                  </div>
                </div>
                <div className={classNames.buttonsContainer}>
                  <button
                    className={classNames.blueBtn}
                    style={{
                      opacity: isLoggingIn ? "0.5" : "",
                      pointerEvents: isLoggingIn ? "none" : "",
                    }}
                    onClick={() => {
                      setRegistrationStep("Affiliate");
                      navigate("/registration/affiliate");
                    }}
                  >
                    No
                  </button>
                  <button
                    className={classNames.submitBtn}
                    style={{
                      opacity: isLoggingIn ? "0.5" : "",
                      pointerEvents: isLoggingIn ? "none" : "",
                    }}
                    onClick={() => {
                      setAffiliateUser((prev) => {
                        return {
                          ...prev,
                          ref_affiliate: affiliateProfileData?.username
                            ? affiliateProfileData?.username
                            : "",
                        };
                      });
                      setRegistrationStep("Affiliate-Step2");
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          ) : registrationStep === "Affiliate-Step2" ? (
            <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
              <img src={fullLogo} alt="fullLogo" onClick={() => navigate("/")} />
              <div className={classNames.title}>What is your email?</div>
              <div className={classNames.credentialsContainer}>
                <div className={classNames.isExistContainer}>
                  <input
                    className={classNames.inputContainer}
                    placeholder="Email"
                    type="email"
                    value={affiliateUser?.email}
                    onChange={(event) =>
                      setAffiliateUser((prev) => {
                        return { ...prev, email: event?.target?.value };
                      })
                    }
                  />
                  <div
                    className={classNames.isExistLight}
                    style={{
                      background: allAffiliateTemp?.isExist
                        ? ""
                        : "#122E5A",
                    }}
                  ></div>
                </div>
                <div className={classNames.buttonsContainer}>
                  <button
                    className={classNames.blueBtn}
                    style={{
                      opacity: isLoggingIn ? "0.5" : "",
                      pointerEvents: isLoggingIn ? "none" : "",
                    }}
                    onClick={() => setRegistrationStep("Affiliate-Step1")}
                  >
                    Back
                  </button>
                  <button
                    className={classNames.submitBtn}
                    style={{
                      opacity: !affiliateUser?.email?.match(
                        /^(?=[^@]{3,}@)([\w\.-]*[a-zA-Z0-9_]@(?=.{4,}\.[^.]*$)[\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z])$/
                      ) ? "0.3" :


                        isLoggingIn ? "0.5" : "",
                      cursor: affiliateUser?.email?.match(
                        /^(?=[^@]{3,}@)([\w\.-]*[a-zA-Z0-9_]@(?=.{4,}\.[^.]*$)[\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z])$/
                      ) ? "pointer" : "not-allowed",
                      pointerEvents: isLoggingIn ? "none" : "",
                    }}
                    onClick={() => {
                      if (affiliateUser?.email?.match(
                        /^(?=[^@]{3,}@)([\w\.-]*[a-zA-Z0-9_]@(?=.{4,}\.[^.]*$)[\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z])$/
                      )) {
                        if (!allAffiliateTemp?.isExist) {
                          setAllAffiliateTemp((prev) => {
                            return { ...prev, isExist: false };
                          });
                          setRegistrationStep("Affiliate-Step3");
                        } else {
                          toastify("Email already exist", "error");
                        }
                      }
                    }
                    }
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          ) : registrationStep === "Affiliate-Step3" ? (
            <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
              <img src={fullLogo} alt="fullLogo" onClick={() => navigate("/")} />
              <div className={classNames.title}>Create a Vitality username</div>
              <div className={classNames.credentialsContainer}>
                <div className={classNames.isExistContainer}>
                  <input
                    className={classNames.inputContainer}
                    placeholder="Username"
                    type="text"
                    value={affiliateUser?.username}
                    onChange={(event) => {
                      const regex = /^[a-zA-Z]*$/;
                      
                      if (regex.test(event?.target?.value)) {
                        setAffiliateUser((prev) => {
                          return { ...prev, username: event?.target?.value };
                        })
                      }
                    }

                    }
                  />
                  <div
                    className={classNames.isExistLight}
                    style={{
                      background: allAffiliateTemp?.isExist
                        ? ""
                        : "#122E5A",
                    }}
                  ></div>
                </div>

                <div className={classNames.buttonsContainer}>
                  <button
                    className={classNames.blueBtn}
                    style={{
                      opacity: isLoggingIn ? "0.5" : "",
                      pointerEvents: isLoggingIn ? "none" : "",
                    }}
                    onClick={() => setRegistrationStep("Affiliate-Step2")}
                  >
                    Back
                  </button>
                  <button
                    className={classNames.submitBtn}
                    style={{
                      opacity: !affiliateUser?.username?.match(
                        /^(?=[^@]{3,})+(\d|\w)+$/

                      ) ? "0.3" :


                        isLoggingIn ? "0.5" : "",

                      pointerEvents: isLoggingIn ? "none" : "",
                    }}
                    onClick={() => {
                      if (affiliateUser?.username?.match(
                        /^(?=[^@]{3,})+(\d|\w)+$/

                      )) {
                        if (!allAffiliateTemp?.isExist) {
                          setAllAffiliateTemp((prev) => {
                            return { ...prev, isExist: false };
                          });
                          setRegistrationStep("Affiliate-Step4");
                        } else {
                          toastify("Username already exist", "error");
                        }
                      }
                    }
                    }
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          ) : registrationStep === "Affiliate-Step4" ? (
            <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
              <img src={fullLogo} alt="fullLogo" onClick={() => navigate("/")} />
              <div className={classNames.title}>
                Enter your desired password
              </div>
              <div className={classNames.credentialsContainer}>
                <CustomPasswordInputpassword
                  placeholder="Password"
                  name="password"

                  stateValue={allAffiliateTemp}
                  setState={setAllAffiliateTemp}
                />
                <div className={classNames.conditions}>
                  <div className={classNames.eachCondition}>
                    Minimum One Capital Letter
                    <IoCheckmarkCircleSharp
                      className={classNames.indicator}
                      style={{
                        color: passwordValidation?.hasCapitalLetter
                          ? "#122E5A"
                          : "var(--font-color)",
                      }}
                    />
                  </div>
                  <div className={classNames.eachCondition}>
                    Minimum One Special Character
                    <IoCheckmarkCircleSharp
                      className={classNames.indicator}
                      style={{
                        color: passwordValidation?.hasSpecialCharacter
                          ? "#122E5A"
                          : "var(--font-color)",
                      }}
                    />
                  </div>
                  <div className={classNames.eachCondition}>
                    Minimum Ten Characters
                    <IoCheckmarkCircleSharp
                      className={classNames.indicator}
                      style={{
                        color: passwordValidation?.hasMinLength
                          ? "#122E5A"
                          : "var(--font-color)",
                      }}
                    />
                  </div>
                  <div className={classNames.eachCondition}>
                    Minimum One Number
                    <IoCheckmarkCircleSharp
                      className={classNames.indicator}
                      style={{
                        color: passwordValidation?.hasNumber
                          ? "#122E5A"
                          : "var(--font-color)",
                      }}
                    />
                  </div>
                </div>

                <div className={classNames.buttonsContainer}>
                  <button
                    className={classNames.blueBtn}
                    style={{
                      opacity: isLoggingIn ? "0.5" : "",
                      pointerEvents: isLoggingIn ? "none" : "",
                    }}
                    onClick={() => setRegistrationStep("Affiliate-Step3")}
                  >
                    Back
                  </button>
                  <button
                    className={classNames.submitBtn}
                    style={{
                      opacity: isLoggingIn ? "0.5" : "",
                      pointerEvents: isLoggingIn ? "none" : "",
                    }}
                    onClick={() => {
                      if (areAllFieldsFilled(passwordValidation)) {
                        setRegistrationStep("Affiliate-Step5");
                      } else {
                        toastify(
                          "Enter password that satisfy all validations!",
                          "error"
                        );
                      }
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          ) : registrationStep === "Affiliate-Step5" ? (
            <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
              <img src={fullLogo} alt="fullLogo" onClick={() => navigate("/")} />
              <div className={classNames.title}>
                Please confirm your password
              </div>
              <div className={classNames.credentialsContainer}>
                <CustomPasswordInputpassword
                  placeholder="Password"
                  name="password"

                  stateValue={affiliateUser}
                  setState={setAffiliateUser}
                />
                <div className={classNames.conditions}>
                  <div className={classNames.eachCondition}>
                    {
                      allAffiliateTemp?.password === affiliateUser?.password ?
                        "Password is matched" : "The password you entered does not match"
                    }

                    <IoCheckmarkCircleSharp
                      className={classNames.indicator}
                      style={{
                        color:
                          allAffiliateTemp?.password === affiliateUser?.password
                            ? "#122E5A"
                            : "var(--font-color)",
                      }}
                    />
                  </div>
                </div>
                <div className={classNames.buttonsContainer}>
                  <button
                    className={classNames.blueBtn}
                    style={{
                      opacity: isLoggingIn ? "0.5" : "",
                      pointerEvents: isLoggingIn ? "none" : "",
                    }}
                    onClick={() => setRegistrationStep("Affiliate-Step4")}
                  >
                    Back
                  </button>
                  <button
                    className={classNames.submitBtn}
                    style={{
                      opacity: allAffiliateTemp?.password != affiliateUser?.password
                        ?
                        "0.3" :

                        isLoggingIn ? "0.5" : "",

                      pointerEvents: isLoggingIn ? "none" : "",
                    }}
                    onClick={() => {
                      if (
                        allAffiliateTemp?.password === affiliateUser?.password
                      ) {
                        userRegistration();
                        // console.log(affiliateUser, "affiliate user data");
                      } else {
                        // setRegistrationStep("Affiliate-Step5");
                        toastify("Passwords dosen't match!", "error");
                        console.log(affiliateUser, "affiliate user failed");
                      }
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          ) : registrationStep === "Affiliate-Step6" ? (
            <div className={classNames.leftCard} style={{ paddingRight: "3rem" }}>
              <img src={fullLogo} alt="fullLogo" />
              <div className={classNames.title}>
                Please enter the code that was just sent to {affiliateUser?.email ? affiliateUser?.email : ""}
              </div>
              <div
                className={classNames.credentialsContainer}
                style={{ minWidth: "550px" }}
              >
                <div className={classNames.otpInputParent}>
                  <OtpInput
                    value={userOtp}
                    onChange={setUserOtp}
                    numInputs={6}
                    separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                    shouldAutoFocus
                    containerStyle={classNames.otpInputWrapper}
                    inputStyle={classNames.otpInput}
                  />
                </div>
                <div
                  className={classNames.message}
                  style={{
                    display:
                      registrationStep === "OTP entered is wrong!" ? "" : "none",
                  }}
                >
                  {registrationStep}
                </div>
                <div className={classNames.buttonsContainer}>
                  <button
                    className={classNames.blueBtn}
                    style={{
                      opacity: isLoggingIn ? "0.5" : "",
                      pointerEvents: isLoggingIn ? "none" : "",
                    }}
                  // onClick={() => setRegistrationStep("Pre-Registered-Step1")}
                  >
                    Resend Code
                  </button>
                  <button
                    className={classNames.submitBtn}
                    style={{
                      opacity: isLoggingIn ? "0.5" : "",
                      pointerEvents: isLoggingIn ? "none" : "",
                    }}
                    onClick={async () => {
                      console.log(userOtp, "userOtp");
                      if (userOtp?.length === 6) {
                        confirmEmailOTP();
                      }
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          ) : registrationStep === "Affiliate-Step-Success" ? (
            <div
              className={classNames.leftCard}
              style={{ paddingRight: "3rem", alignItems: "center" }}
            >
              <img src={fullLogo} alt="fullLogo" style={{ margin: "6rem 0" }} />
              <div className={classNames.title}>
                Your Account Is Being Created
              </div>
            </div>
          ) : (
            <div className={classNames.leftCard}>
              <img src={fullLogo} alt="fullLogo" />
              <div className={classNames.title}>How did you hear about us?</div>
              <div className={classNames.allRegistrationOptions}>
                {registrationItems?.map((eachItem, index) => {
                  return (
                    <div
                      className={classNames.eachOption}
                      key={eachItem?.title + index}
                      onClick={() => {
                        if (eachItem?.title?.includes("Pre-Registered")) {
                          setRegistrationStep("Pre-Registered");
                        } else if (eachItem?.title?.includes("Affiliate")) {
                          navigate("/registration/affiliate");
                        }
                      }}
                    >
                      <div className={classNames.imageContainer}>
                        <img src={eachItem?.image} alt={eachItem?.title} />
                      </div>
                      <div className={classNames.title}>
                        <div>{eachItem?.title}</div>
                        <div>{eachItem?.desc}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default Registration;
