import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./patient.module.scss";
import Drawer from 'react-modern-drawer'
import Editingpage from './EditingPage/EditingPage'
//assets
import { ReactComponent as FilterIcon } from "../../../assets/images/filter.svg";
import {
  CopyBtn,
  CustomCheckbox,
  CustomCheckboxMultiple,
  EachCustomDropdown,
  EachCustomImageUpload,
  EachCustomSelectMultiple,
  EachCustomTextarea,
  EachInput,
  EachInputDatePicker,
} from "../../../components/Custom";
import { IoMdArrowDropdown } from "react-icons/io";
import { ReactComponent as SortArrows } from "../../../assets/images/icons/sort.svg";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../../../context/Globalcontext";
import { useNetworkRequest } from "../../../hooks/networkRequest";
import {
  deleteData,
  getData,
  updateData,
} from "../../../assets/functions/apiCalls";
import axios from "axios";
import { toastify } from "../../../assets/functions/toastify";
import {
  filterDetails,
  getCurrentDate,
  uploadFileFunc,
} from "../../../assets/functions";
import {
  staffGroupFormGroupType,
  staffGroupFormModules,
} from "../../../assets/data/mapItems";
import editIcon from "../../../assets/images/icons/edit.svg";
// import VitalityLogo from "../../../assets/images/Vitality.svg";

const Patient = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { roleType, patientRoleType } = useParams();
  const { email, actionBtns, setActionBtns, globalSearchQuery, editingToggle, seteditingToggle
  } =
    useContext(GlobalContext);
  const [allOrganization, setAllOrganization] = useState([]);
  const [allStaffRoles, setAllStaffRoles] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [organizationSelected, setOrganizationSelected] = useState("");
  const [userlist, setuserlist] = useState([]);
  const { allValues: allLeads, allValuesLoading: allLeadsLoading } =
    useNetworkRequest({
      URL: "",
      organizationSelected: organizationSelected ? organizationSelected : "",
    });

  //page values
  const [filterOpen, setFilterOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  // const [allLeadsLoading, setAllLeadsLoading] = useState(false);
  // const [allLeads, setAllLeads] = useState(tempLeads);
  const [allBrokeragesSearch, setAllBrokeragesSearch] = useState("");
  const [checkBoxStage, setCheckBoxStage] = useState("");
  const [tempArray, setTempArray] = useState([]);
  const [staffGroupForm, setStaffGroupForm] = useState({
    teamUsername: "",
    groupType: "",
    groupName: "",
    groupDesc: "",
    modules: [],
  });
  const [staffProfileForm, setStaffProfileForm] = useState({
    TeamPlayerID: "",
    name: "",
    email: "",
    phoneNumber: "",
    address: "",
    employeeId: "",
    employeeType: "",
    employeeStatus: "",
    location: "",
    title: "",
    role: "",
    dateOfJoining: "",
    dateOfRelieving: "",
    birthday: "",
    age: "",
    gender: "",
    resume: "",
    NationalId: "",
  });

  const [staffRolesForm, setStaffRolesForm] = useState({
    teamUsername: "",
    name: "",
    description: "",
  });
  console.log("organizationSelected", organizationSelected)

  const filterDropdownRef = useRef(null);
  const selectedItemTable = useRef(null);
  const navRightBox = useRef(null);

  const filterItems = [];

  const filterItemsUsers = [
    { name: "Add to staff", to: "confirmAddStaff" },
    { name: "Add to patients", to: "" },
    { name: "Edit user profile", to: "" },
    { name: "Delete user", to: "" },
  ];

  const filterItemsUsersCnfm = [
    { name: "Yes", to: makeUserToPlayer, type: "func" },
    { name: "Never mind", to: neverMindd, type: "func" },
  ];

  const filterItemsCRM = [
    { name: "See profile", to: "confirmSeeProfileSidebar" },
    { name: "Delete patient", to: "" },
  ];

  //functions

  function neverMindd() {
    setCheckBoxStage("");
    setActionBtns([]);
  }

  async function addStaffGroupForm() {
    let response = await axios.post(
      "https://uatamma.apimachine.com/api/addStaff",
      staffGroupForm
    );
    if (
      response?.data?.status ||
      response?.data?.message === "user staff added"
    ) {
      toastify("Added staff group succesfully!", "success");
      setCheckBoxStage("");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toastify("Error while trying to add staff group", "error");
    }
    console.log(response, "response from Add staff group");
  }

  async function addStaffRolesForm() {
    let response = await axios.post(
      "https://uatamma.apimachine.com/api/addStaffRole",
      staffRolesForm
    );
    if (
      response?.data?.status ||
      response?.data?.message === "staff role added"
    ) {
      toastify("Added staff roles succesfully!", "success");
      setCheckBoxStage("");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toastify("Error while trying to add staff roles", "error");
    }
    console.log(response, "response from Add staff roles");
  }

  async function playerToTeam(teamUsername) {
    let result = await filterDetails(allLeads?.data, actionBtns[0]);

    let response = await axios.post(
      "https://uatamma.apimachine.com/api/addplayerstoteam",
      {
        TeamOwnerEmail: email,
        PlayerUsername: result[0]?.username,
        StartDate: "",
        TeamUsername: teamUsername,
      }
    );
    if (response?.data?.success) {
      toastify("Added player to the selected team", "success");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toastify("Player data not found", "error");
    }
    console.log(response, "response from make user to player");
  }

  async function getOrganizations() {
    let response = await getData(
      `https://uatamma.apimachine.com/api/getteamsbyowner?TeamCreatedBy=${email}`
    );
    if (response?.success) {
      setAllOrganization([...response?.data]);
      // callapi(response?.data?.data?.[0]?.)
    }
    console.log(response, "organization response");
  }

  async function getStaffRoles() {
    if (JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername) {
      let response = await getData(
        `https://uatamma.apimachine.com/api/getStaffRole?teamUsername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername}`
      );
      if (response?.success) {
        setAllStaffRoles(response?.data);
      }
      console.log(response, "all staff roles response");
    }
  }

  async function getallLocations() {
    if (JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername) {
      let response = await getData(
        `https://uatamma.apimachine.com/api/getlocation?teamUsername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername}`
      );
      if (response?.success) {
        setAllLocations(response?.data);
      }
      console.log(response, "location response");
    }
  }

  async function makeUserToPlayer() {
    let result = await filterDetails(allLeads?.data, actionBtns[0]);
    // console.log(actionBtns, "playerEmail", result);
    let response = await axios.put(
      "https://uatamma.apimachine.com/api/addplayer",
      {
        email: result[0]?.email,
      }
    );
    if (response?.data?.success) {
      toastify("Updated user to player", "success");
      setTimeout(() => {
        setCheckBoxStage("playerToSpecificTeam");
      }, 500);
    } else {
      toastify("Error while converting user to player", "error");
    }
    console.log(response, "response from make user to player");
  }

  useEffect(() => {
    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target)
      ) {
        setFilterOpen(false);
      }
      if (
        selectedItemTable.current &&
        !selectedItemTable.current.contains(event.target)
      ) {
        setCheckBoxStage("");
        setActionBtns([]);
      }
      // if (navRightBox.current && !navRightBox.current.contains(event.target)) {
      //   setCheckBoxStage("");
      // }
    };

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const [dropdowntoggle, setdropdowntoggle] = useState(false)
  const [storeid, setstoreid] = useState("")
  useEffect(() => {
    getOrganizations();
    getStaffRoles();
    getallLocations();
    callapi(JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername);
  }, [JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername]);

  useEffect(() => {
    if (actionBtns?.length > 0) {
      setCheckBoxStage("");
    }
  }, [actionBtns]);

  useEffect(() => {
    setActionBtns([]);
  }, [location]);

  const callapi = (e) => {

    axios
      .get(`https://uatamma.apimachine.com/api/getallclientsforteam/${e}`)
      .then((res) => {
        setuserlist(res?.data?.data);
        // console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))
        // setprofileData(res?.data?.data)
      });
  };
  let loc = window.location.pathname
  let finallo = loc?.split('/')
  const localclikc = () => {
    let addlocal = localStorage?.getItem("drawershow")

    localStorage?.setItem("drawershow", Number(addlocal + 1))
  }
  const history = useNavigate()
  const [sentitem, setsentitem] = useState({})
  useEffect(() => {
    seteditingToggle(false)
  }, [])
  return (
    <>

      {
        editingToggle ?
          <Editingpage
            sentitem={sentitem}
          />
          :

          <div className="global-dashboard">
            <div className="label-box-header" onClick={localclikc}>
              {finallo?.[3]}

            </div>
            <div className="top-section-golbal">
              <div className="left-side">


                <div className="label-box">
                  <svg
                    style={{
                      marginRight: "10px"
                    }}
                    xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                    <path d="M12.8445 8.07626L10.9486 6.58668C10.8262 6.4908 10.6594 6.47293 10.5202 6.54118C10.3804 6.60888 10.2916 6.7508 10.2916 6.90626V7.90292C8.75704 8.16184 7.58325 9.49651 7.58325 11.1042V12.4583C7.58325 12.7573 7.82592 13 8.12492 13C8.42392 13 8.66658 12.7573 8.66658 12.4583V11.1042C8.66658 10.0978 9.35992 9.25655 10.2916 9.01442V9.88542C10.2916 10.0409 10.3804 10.1828 10.5202 10.2505C10.5765 10.2781 10.6372 10.2917 10.6978 10.2917C10.7872 10.2917 10.876 10.2624 10.9486 10.205L12.8445 8.71542C12.943 8.63851 12.9999 8.52042 12.9999 8.39584C12.9999 8.27126 12.943 8.15317 12.8445 8.07626Z" fill="#010101" />
                    <path d="M9.74999 0H1.625C0.731249 0 0 0.731249 0 1.625V8.66666C0 9.5604 0.731249 10.2917 1.625 10.2917H6.58124C6.65166 9.91249 6.77082 9.54957 6.93874 9.20832H1.625C1.32708 9.20832 1.08333 8.96457 1.08333 8.66666V2.70833H10.2917V5.47624C10.6492 5.37333 11.0392 5.41124 11.375 5.57916V1.625C11.375 0.731249 10.6437 0 9.74999 0V0Z" fill="#010101" />
                  </svg>

                  Export

                </div>



                <>

                  <div className="label-box">
                    <svg
                      style={{
                        marginRight: "10px"
                      }}
                      xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                      <path d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z" fill="#010101" />
                    </svg>
                    Add Filter
                  </div></>


              </div>

              <div className="right-side">
                <div className="shortBy">Sort By</div>
                <div className="dropdownlist">
                  Newest

                  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="6" viewBox="0 0 7 6" fill="none">
                    <path d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z" fill="#010101" />
                  </svg>

                </div>
                <div className="addLabel"
                // onClick={() => {setCheckBoxStage("add")}}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                    <path d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z" fill="white" />
                  </svg>
                  Add New
                </div>

                <Drawer
                  size={450}
                  // duration={500}
                  open={checkBoxStage}
                  onClose={() => setCheckBoxStage("")}
                  direction='right'
                  className='bla bla bla'
                  overlayColor="#FFF"
                  overlayOpacity={0}
                >
                  <>



                    {checkBoxStage === "staffRoles" && roleType === "staffroles" ? (
                      <div className="drowpside-right">
                        <div
                          className={classNames.eachFilterItem}
                          style={{ fontWeight: "500", pointerEvents: "none" }}
                        >
                          Add New
                        </div>
                        <div
                          className={classNames.eachFilterItem}
                          key={"Staff Roles"}
                          onClick={() => {
                            setCheckBoxStage("staffRolesForm");
                          }}
                        >
                          Staff Role
                        </div>
                      </div>
                    ) : checkBoxStage === "staffRolesForm" &&
                      roleType === "staffroles" ? (
                      <div
                        className="drowpside-right"

                      >
                        <div
                          className={classNames.eachFilterItem}
                          style={{
                            fontWeight: "500",
                            pointerEvents: "none",
                            paddingLeft: "0",
                            marginBottom: "1.5rem",


                          }}
                        >
                          Add New Staff Role
                        </div>
                        <div className={classNames.formFields}>
                          <EachCustomDropdown
                            dropdown={allOrganization}
                            name="teamUsername"
                            title="Select the organization"
                            stateValue={staffRolesForm}
                            setState={setStaffRolesForm}
                            topTitle="true"
                          />
                          <EachInput
                            title="What do you want to name this group"
                            name="name"
                            placeholder="Name..."
                            stateValue={staffRolesForm}
                            setState={setStaffRolesForm}
                            topTitle="true"
                          />
                          <EachCustomTextarea
                            title="Describe the group"
                            name="description"
                            placeholder="Description..."
                            stateValue={staffRolesForm}
                            setState={setStaffRolesForm}
                            topTitle="true"
                          />
                          <button
                            className={classNames.submitBtn}
                            onClick={addStaffRolesForm}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    ) : checkBoxStage === "staffGroups" &&
                      roleType === "staffgroups" ? (
                      <div className="drowpside-right">
                        <div
                          className={classNames.eachFilterItem}
                          style={{ fontWeight: "500", pointerEvents: "none" }}
                        >
                          Add New
                        </div>
                        <div
                          className={classNames.eachFilterItem}
                          key={"Staff Group"}
                          onClick={() => {
                            setCheckBoxStage("staffGroupsForm");
                          }}
                        >
                          Staff Group
                        </div>
                      </div>
                    ) : checkBoxStage === "staffGroupsForm" &&
                      roleType === "staffgroups" ? (
                      <div
                        className="drowpside-right"

                      >
                        <div
                          className={classNames.eachFilterItem}
                          style={{
                            fontWeight: "500",
                            pointerEvents: "none",
                            paddingLeft: "0",
                            marginBottom: "1.5rem",


                          }}
                        >
                          Add New Staff Group
                        </div>
                        <div className={classNames.formFields}>
                          <EachCustomDropdown
                            dropdown={allOrganization}
                            name="teamUsername"
                            title="Select the organization"
                            stateValue={staffGroupForm}
                            setState={setStaffGroupForm}
                            topTitle="true"
                          />
                          <EachCustomDropdown
                            dropdown={staffGroupFormGroupType}
                            name="groupType"
                            title="Select the group type"
                            stateValue={staffGroupForm}
                            setState={setStaffGroupForm}
                            topTitle="true"
                          />
                          <EachInput
                            title="What do you want to name this group"
                            name="groupName"
                            placeholder="Name..."
                            stateValue={staffGroupForm}
                            setState={setStaffGroupForm}
                            topTitle="true"
                          />
                          <EachCustomTextarea
                            title="Describe the group"
                            name="groupDesc"
                            placeholder="Description..."
                            stateValue={staffGroupForm}
                            setState={setStaffGroupForm}
                            topTitle="true"
                          />
                          <EachCustomSelectMultiple
                            title="Add modules"
                            name="modules"
                            stateValue={staffGroupForm}
                            setState={setStaffGroupForm}
                            topTitle="true"
                            mapItems={staffGroupFormModules}
                          />
                          <button
                            className={classNames.submitBtn}
                            onClick={addStaffGroupForm}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                </Drawer>


              </div>
            </div>


            <div className="handle-table">
              <div className="g-table-sec">
                <div className="table-section">
                  <table style={{ minWidth: "100%" }}>
                    <thead>
                      <tr style={{ gridTemplateColumns: "min-content repeat(5, 1fr)" }}>

                        <th>
                          Name <SortArrows />
                        </th>
                        <th>
                          {roleType === "staffgroups"
                            ? "Group Type"
                            : roleType === "staffroles" || roleType === "calendar"
                              ? "ID"
                              : "Email"}
                          <SortArrows />
                        </th>
                        <th>
                          {roleType === "staffgroups"
                            ? "Description"
                            : roleType === "staffroles" || roleType === "calendar"
                              ? "Description"
                              : "Phone Number"}
                          <SortArrows />
                        </th>
                        <th>
                          {roleType === "staffgroups"
                            ? "Staff Roles"
                            : roleType === "staffroles"
                              ? "Staff Groups"
                              : roleType === "calendar"
                                ? "Time Zone"
                                : patientRoleType == "users"
                                  ? "Gender"
                                  : "Role"}
                          <SortArrows />
                        </th>
                        <th>

                          Follow Up
                        </th>
                        {/* <th>
                          {roleType === "staffgroups"
                            ? "Modules"
                            : roleType === "staffroles"
                              ? "# Of Staff"
                              : roleType === "calendar"
                                ? "# Of Employees"
                                : "ID"}
                          <SortArrows />
                        </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {patientRoleType ? (
                        <>
                          {userlist?.map((item) => {
                            return (
                              <>

                                <tr
                                  key={"loading"}
                                  style={{
                                    gridTemplateColumns:
                                      "min-content repeat(6, 1fr)",
                                  }}

                                >
                                  <>
                                    {item?.UserDetails?.[0]?.UserDetails?.map((each) => {
                                      console.log("123123123123", item);
                                      return (

                                        <>

                                          <td
                                            onClick={() => {
                                              setsentitem(item)
                                              history(`/owner/patient/users/${item?.UserDetails?.[0]?.UserDetails?.[0]?.userid}`)
                                            }}
                                          >{each?.name}</td>
                                          <td
                                            onClick={() => {
                                              setsentitem(item)
                                              history(`/owner/patient/users/${item?.UserDetails?.[0]?.UserDetails?.[0]?.userid}`)
                                            }}
                                          >{each?.email}</td>
                                          <td
                                            onClick={() => {
                                              setsentitem(item)
                                              history(`/owner/patient/users/${item?.UserDetails?.[0]?.UserDetails?.[0]?.userid}`)
                                            }}
                                          >{each?.PhoneNumber}</td>
                                          <td
                                            onClick={() => {
                                              setsentitem(item)
                                              history(`/owner/patient/users/${item?.UserDetails?.[0]?.UserDetails?.[0]?.userid}`)
                                            }}
                                          >{each?.Gender}</td>
                                          <td>
                                            <div
                                              style={{
                                                width: "133px",
                                                height: "39px",
                                                background:
                                                  each?.followup == false
                                                    ? "#C51E25"
                                                    : each?.status == false && " #010101",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                padding: "0 1rem",
                                                borderRadius: "5px",
                                                color: "#fff", fontWeight: "700",
                                              }}
                                              onClick={() => {
                                                setstoreid(each?.userid)
                                                setdropdowntoggle(!dropdowntoggle)
                                              }
                                              }
                                            >

                                              {each?.followup == false ? "Not Needed" : each?.followup == true && "Needed"}
                                              {
                                                dropdowntoggle && each?.userid == storeid ?
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg" width="7" height="6" viewBox="0 0 7 6" fill="none">
                                                    <path d="M6.87533 4.8683L3.98854 0.304976C3.76648 -0.101659 3.27793 -0.101659 3.01146 0.304976L0.124669 4.8683C-0.186232 5.36528 0.124669 5.99785 0.613191 5.99785H6.38681C6.87533 6.04303 7.18623 5.36532 6.87533 4.8683Z" fill="white" />
                                                  </svg> :
                                                  <svg
                                                    style={{
                                                      transform: "rotate(60deg)"
                                                    }}
                                                    xmlns="http://www.w3.org/2000/svg" width="7" height="6" viewBox="0 0 7 6" fill="none">
                                                    <path d="M6.87533 4.8683L3.98854 0.304976C3.76648 -0.101659 3.27793 -0.101659 3.01146 0.304976L0.124669 4.8683C-0.186232 5.36528 0.124669 5.99785 0.613191 5.99785H6.38681C6.87533 6.04303 7.18623 5.36532 6.87533 4.8683Z" fill="white" />
                                                  </svg>
                                              }
                                            </div>
                                            {
                                              dropdowntoggle && each?.userid == storeid &&

                                              <div
                                                style={{
                                                  width: "133px",
                                                  fontWeight: "700",
                                                  height: "39px",
                                                  background: "#FFF",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "space-between",
                                                  borderRadius: "5px",
                                                  padding: "0 1rem",
                                                  color: "#010101",
                                                  boxShadow: "0px 4px 4px 0px hsla(0, 0%, 0%, 0.25)",
                                                  textTransform: "capitalize",
                                                  fontWeight: "700"

                                                }}
                                              //  onClick={() => handleSubmiupdatet(row?._id, row?.status == "open" ? "close" : row?.status == "close" && "open")}
                                              >
                                                {each?.followup == false ? "Needed" : each?.followup == true && "Not Needed"}
                                              </div>
                                            }
                                          </td>
                                        </>


                                      );
                                    })}
                                  </>
                                </tr>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {allLeadsLoading ? (
                            <tr
                              key={"loading"}
                              style={{
                                gridTemplateColumns: "min-content repeat(5, 1fr)",
                              }}
                            >

                              <td>
                                <Skeleton width={100} height={20} />
                              </td>
                              <td>
                                <Skeleton width={100} height={20} />
                              </td>
                              <td>
                                <Skeleton width={100} height={20} />
                              </td>
                              <td>
                                <Skeleton width={100} height={20} />
                              </td>
                              <td>
                                <Skeleton width={100} height={20} />
                              </td>
                            </tr>
                          ) : (roleType === "staffroles" ||
                            roleType === "staff" ||
                            roleType === "staffgroups") &&
                            !JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername ? (
                            ""
                          ) : (
                            allLeads?.data?.length > 0 &&
                            allLeads?.data
                              ?.filter((row) => {
                                // // Convert the row object values to an array and check if any value matches the search query
                                // const values = Object?.values(row);
                                // const searchQuery = globalSearchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                                // return values?.some((value) => {
                                //   if (typeof value === "string") {
                                //     return value?.toLowerCase()?.includes(searchQuery);
                                //   }
                                //   return false;
                                // });
                                const searchQuery = globalSearchQuery?.toLowerCase();
                                return roleType === "staffgroups"
                                  ? row?.groupName?.toLowerCase()?.includes(searchQuery)
                                  : roleType === "staffroles"
                                    ? row?.name?.toLowerCase()?.includes(searchQuery)
                                    : roleType === "staff"
                                      ? row?.UserDetails?.length > 0 &&
                                        row?.UserDetails[0]?.UserDetails?.length > 0
                                        ? row?.UserDetails[0]?.UserDetails[0]?.name
                                          ?.toLowerCase()
                                          ?.includes(searchQuery)
                                        : ""
                                      : roleType === "calendar"
                                        ? row?.CalendarName?.toLowerCase()?.includes(
                                          searchQuery
                                        )
                                        : row?.name?.toLowerCase()?.includes(searchQuery);
                              })
                              ?.sort((a, b) =>
                                a?.first_name?.localeCompare(b?.first_name)
                              )
                              ?.map((row, index) => (
                                <tr
                                  key={row?.brokerage_name + index}
                                  style={{
                                    gridTemplateColumns: "min-content repeat(5, 1fr)",
                                    pointerEvents: sidebarOpen ? "none" : "",
                                  }}

                                >
                                  <td
                                  // style={{ pointerEvents: "none" }}
                                  // ref={selectedItemTable}
                                  >
                                    <CustomCheckbox
                                      actionBtns={actionBtns}
                                      setActionBtns={setActionBtns}
                                      id={
                                        patientRoleType === "users"
                                          ? row?.userid
                                          : row?.userid
                                      }
                                      name={
                                        patientRoleType === "users"
                                          ? row?.userid
                                          : row?.userid
                                      }
                                    />
                                    {checkBoxStage === "playerToSpecificTeam" &&
                                      actionBtns?.includes(row?.userid) &&
                                      patientRoleType === "users" ? (
                                      <div className={classNames.dropdownContent}>
                                        <div
                                          className={classNames.eachFilterItem}
                                          style={{ fontWeight: "500" }}
                                        >
                                          Which of you’re teams do you want to add this
                                          staff member to?
                                        </div>
                                        {allOrganization?.length > 0 &&
                                          allOrganization
                                            ?.filter((eachOrg) => {
                                              return eachOrg?.NameOfTeam !== "Vitality";
                                            })
                                            ?.map((eachItem, index) => {
                                              return (
                                                <div
                                                  className={classNames.eachFilterItem}
                                                  key={eachItem?.NameOfTeam + index}
                                                  style={{ padding: "0" }}
                                                  onClick={() => {
                                                    playerToTeam(
                                                      eachItem?.TeamUsername
                                                    );
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      padding: "0.7rem 1.5rem",
                                                    }}
                                                  >
                                                    <img
                                                      src={eachItem?.Icon}
                                                      alt={eachItem?.NameOfTeam}
                                                    />
                                                    {eachItem?.NameOfTeam}
                                                  </div>
                                                </div>
                                              );
                                            })}
                                      </div>
                                    ) : checkBoxStage === "confirmAddStaff" &&
                                      actionBtns?.includes(row?.userid) &&
                                      patientRoleType === "users" ? (
                                      <div className={classNames.dropdownContent}>
                                        <div
                                          className={classNames.eachFilterItem}
                                          style={{
                                            fontWeight: "500",
                                            pointerEvents: "none",
                                          }}
                                        >
                                          Are you sure?
                                        </div>
                                        {filterItemsUsersCnfm?.map(
                                          (eachItem, index) => {
                                            return (
                                              <div
                                                className={classNames.eachFilterItem}
                                                key={eachItem?.name + index}
                                                onClick={() => {
                                                  if (
                                                    eachItem?.to ===
                                                    "playerToSpecificTeam"
                                                  ) {
                                                    setCheckBoxStage(eachItem?.to);
                                                  } else if (
                                                    eachItem?.type === "func"
                                                  ) {
                                                    eachItem?.to();
                                                  }
                                                }}
                                              >
                                                {eachItem?.name}
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    ) : (
                                      actionBtns?.length > 0 &&
                                      actionBtns?.includes(row?.userid) &&
                                      patientRoleType === "users" && (
                                        <div className={classNames.dropdownContent}>
                                          {filterItemsUsers?.map((eachItem, index) => {
                                            return (
                                              <div
                                                className={classNames.eachFilterItem}
                                                key={eachItem?.name + index}
                                                onClick={() => {
                                                  if (
                                                    eachItem?.to === "confirmAddStaff"
                                                  ) {
                                                    setCheckBoxStage(eachItem?.to);
                                                  }
                                                }}
                                              >
                                                {eachItem?.name}
                                              </div>
                                            );
                                          })}
                                        </div>
                                      )
                                    )}
                                  </td>
                                  <td>
                                    {roleType === "staffgroups"
                                      ? row?.groupName
                                      : roleType === "staffroles"
                                        ? row?.name
                                        : roleType === "staff"
                                          ? row?.UserDetails?.length > 0 &&
                                            row?.UserDetails[0]?.UserDetails?.length > 0
                                            ? row?.UserDetails[0]?.UserDetails[0]?.name
                                            : ""
                                          : roleType === "calendar"
                                            ? row?.CalendarName
                                            : row?.name}

                                  </td>
                                  <td>
                                    {roleType === "staffgroups"
                                      ? row?.groupType
                                      : roleType === "staffroles"
                                        ? row?._id
                                        : roleType === "staff"
                                          ? row?.UserDetails?.length > 0 &&
                                            row?.UserDetails[0]?.UserDetails?.length > 0
                                            ? row?.UserDetails[0]?.UserDetails[0]?.email
                                            : ""
                                          : roleType === "calendar"
                                            ? row?.TeamCalendarID
                                            : row?.email}

                                  </td>
                                  <td>
                                    {roleType === "staffgroups"
                                      ? row?.groupDesc
                                      : roleType === "staffroles"
                                        ? row?.description
                                        : roleType === "staff"
                                          ? row?.UserDetails?.length > 0 &&
                                            row?.UserDetails[0]?.UserDetails?.length > 0
                                            ? row?.UserDetails[0]?.UserDetails[0]
                                              ?.PhoneNumber
                                            : ""
                                          : roleType === "calendar"
                                            ? row?.CalendarDescription
                                            : row?.PhoneNumber}

                                  </td>
                                  <td>
                                    {roleType === "staffgroups"
                                      ? ""
                                      : roleType === "staffroles"
                                        ? ""
                                        : roleType === "staff"
                                          ? row?.UserDetails?.length > 0 &&
                                            row?.UserDetails[0]?.UserDetails?.length > 0
                                            ? row?.UserDetails[0]?.UserDetails[0]?.key
                                            : ""
                                          : roleType === "calendar"
                                            ? row?.TimeZoneAbbrevation
                                            : row?.key}

                                  </td>
                                  <td>
                                    {roleType === "staffgroups" &&
                                      row?.modules?.length > 0 ? (
                                      <div>
                                        {row?.modules?.map((eachitem, index) => {
                                          return (
                                            <div key={eachitem + index}>{eachitem}</div>
                                          );
                                        })}
                                      </div>
                                    ) : roleType === "staffroles" ? (
                                      ""
                                    ) : roleType === "staff" ? (
                                      row?.TeamPlayerID
                                    ) : (
                                      row?.userid
                                    )}

                                  </td>
                                </tr>
                              ))
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
      }
      {checkBoxStage === "confirmSeeProfileSidebar" && (
        <div className={classNames.popupProfile}>
          <ProfileCRM userlist={userlist} />
          <div
            className={classNames.closeDiv}
            onClick={() => {
              setCheckBoxStage("");
              setActionBtns([]);
            }}
          ></div>
        </div>
      )}

    </>
  );
};

export default Patient;

const ProfileCRM = ({ userlist }) => {
  const location = useLocation();
  const { email, setProfileUsername, actionBtns } = useContext(GlobalContext);
  const [profileData, setProfileData] = useState("");
  const [profileDataLoading, setProfileDataLoading] = useState(false);

  useEffect(() => {
    let filteredUser = userlist?.filter((eachItem, index) => {
      return eachItem?.UserDetails[0]?.UserDetails[0]?.email === actionBtns[0];
    });
    setProfileData(filteredUser[0]);
    console.log(filteredUser[0], "filteredUser");
  }, []);

  return (
    <div className={classNames.profileParent}>
      <div className={classNames.profileDetails}>
        <div className={classNames.topContainer}>
          <div
            className={classNames.eachProfileItem}
          // style={{ width: "250px" }}
          >
            <div className={classNames.title}>Profile Image</div>
            <div className={classNames.inputValue}>
              <EditContainer
                value={
                  profileDataLoading ? (
                    "Loading"
                  ) : profileData &&
                    profileData?.UserDetails[0]?.UserDetails[0].ProfilePic ? (
                    <img
                      src={
                        profileData?.UserDetails[0]?.UserDetails[0]?.ProfilePic
                      }
                      alt="dummyProfile"
                    />
                  ) : (
                    ""
                  )
                }
                name="ProfilePic"
                type="image"
                clickable="true"
              />
            </div>
          </div>
        </div>
        <div className={classNames.gridContainer}>
          <div className={classNames.eachProfileItem}>
            <div className={classNames.title}>Role</div>
            <div className={classNames.inputValue}>
              <EditContainer
                value={
                  profileDataLoading
                    ? "Loading"
                    : profileData &&
                      profileData?.UserDetails[0]?.UserDetails[0]?.key ===
                      "Player"
                      ? "Patient"
                      : profileData
                        ? profileData?.UserDetails[0]?.UserDetails[0]?.key
                        : ""
                }
              />
            </div>
          </div>
          <div className={classNames.eachProfileItem}>
            <div className={classNames.title}>Onboarding</div>
            <div className={classNames.inputValue}>
              <EditContainer
                value={
                  profileDataLoading
                    ? "Loading"
                    : profileData && profileData?.createdAt
                      ? profileData?.createdAt
                      : ""
                }
              />
            </div>
          </div>
          <div className={classNames.eachProfileItem}>
            <div className={classNames.title}>Name</div>
            <div className={classNames.inputValue}>
              <EditContainer
                value={
                  profileDataLoading
                    ? "Loading"
                    : profileData &&
                      profileData?.UserDetails[0]?.UserDetails[0]?.name
                      ? profileData?.UserDetails[0]?.UserDetails[0]?.name
                      : ""
                }
              />
            </div>
          </div>
          <div className={classNames.eachProfileItem}>
            <div className={classNames.title}>Username</div>
            <div className={classNames.inputValue}>
              <EditContainer
                value={
                  profileDataLoading
                    ? "Loading"
                    : profileData && profileData?.ClientUsername
                      ? profileData?.ClientUsername
                      : ""
                }
              />
            </div>
          </div>
          <div className={classNames.eachProfileItem}>
            <div className={classNames.title}>Email</div>
            <div className={classNames.inputValue}>
              <EditContainer
                value={
                  profileDataLoading
                    ? "Loading"
                    : profileData &&
                      profileData?.UserDetails[0]?.UserDetails[0]?.email
                      ? profileData?.UserDetails[0]?.UserDetails[0]?.email
                      : ""
                }
              />
            </div>
          </div>
          <div className={classNames.eachProfileItem}>
            <div className={classNames.title}>Phone</div>
            <div className={classNames.inputValue}>
              <EditContainer
                value={
                  profileDataLoading
                    ? "Loading"
                    : profileData &&
                      profileData?.UserDetails[0]?.UserDetails[0]?.PhoneNumber
                      ? profileData?.UserDetails[0]?.UserDetails[0]?.PhoneNumber
                      : ""
                }
              />
            </div>
          </div>
          <div className={classNames.eachProfileItem}>
            <div className={classNames.title}>Address</div>
            <div className={classNames.inputValue}>
              <EditContainer
                value={
                  profileDataLoading
                    ? "Loading"
                    : profileData &&
                      profileData?.UserDetails[0]?.UserDetails[0]?.Address
                      ? profileData?.UserDetails[0]?.UserDetails[0]?.Address
                      : ""
                }
              />
            </div>
          </div>
          <div className={classNames.eachProfileItem}>
            <div className={classNames.title}>Country</div>
            <div className={classNames.inputValue}>
              <EditContainer
                value={
                  profileDataLoading
                    ? "Loading"
                    : profileData &&
                      profileData?.UserDetails[0]?.UserDetails[0]?.Country
                      ? profileData?.UserDetails[0]?.UserDetails[0]?.Country
                      : ""
                }
              />
            </div>
          </div>
          <div className={classNames.eachProfileItem}>
            <div className={classNames.title}>Gender</div>
            <div className={classNames.inputValue}>
              <EditContainer
                value={
                  profileDataLoading
                    ? "Loading"
                    : profileData &&
                      profileData?.UserDetails[0]?.UserDetails[0]?.Gender
                      ? profileData?.UserDetails[0]?.UserDetails[0]?.Gender
                      : ""
                }
              />
            </div>
          </div>
        </div>
        <div className={classNames.gridContainer}>
          <div className={classNames.eachProfileItem}>
            <div className={classNames.title}>Id</div>
            <div className={classNames.inputValue}>
              <EditContainer
                value={
                  profileDataLoading
                    ? "Loading"
                    : profileData && profileData?._id
                      ? profileData?._id
                      : ""
                }
              />
            </div>
          </div>
          <div className={classNames.eachProfileItem}>
            <div className={classNames.title}>User ID</div>
            <div className={classNames.inputValue}>
              <EditContainer
                value={
                  profileDataLoading
                    ? "Loading"
                    : profileData &&
                      profileData?.UserDetails[0]?.UserDetails[0]?.userid
                      ? profileData?.UserDetails[0]?.UserDetails[0]?.userid
                      : ""
                }
              />
            </div>
          </div>
          <div className={classNames.eachProfileItem}>
            <div className={classNames.title}>Vitality ID</div>
            <div className={classNames.inputValue}>
              <EditContainer
                value={
                  profileDataLoading
                    ? "Loading"
                    : profileData && profileData?.ClientUsername
                      ? profileData?.ClientUsername
                      : ""
                }
              />
            </div>
          </div>
        </div>
        <div className={classNames.gridContainer}>
          <div className={classNames.eachProfileItem}>
            <div className={classNames.title}>Team Client ID</div>
            <div className={classNames.inputValue}>
              <EditContainer
                value={
                  profileDataLoading
                    ? "Loading"
                    : profileData && profileData?.TeamClientID
                      ? profileData?.TeamClientID
                      : ""
                }
              />
            </div>
          </div>
          <div className={classNames.eachProfileItem}>
            <div className={classNames.title}>Organization Owner Email</div>
            <div className={classNames.inputValue}>
              <EditContainer
                value={
                  profileDataLoading
                    ? "Loading"
                    : profileData && profileData?.TeamOwnerEmail
                      ? profileData?.TeamOwnerEmail
                      : ""
                }
              />
            </div>
          </div>
          <div className={classNames.eachProfileItem}>
            <div className={classNames.title}>Organization Username</div>
            <div className={classNames.inputValue}>
              <EditContainer
                value={
                  profileDataLoading
                    ? "Loading"
                    : profileData && profileData?.TeamUsername
                      ? profileData?.TeamUsername
                      : ""
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EditContainer = ({ value, type, name, clickable }) => {
  const { email } = useContext(GlobalContext);
  const [editOpen, setEditOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [uploadFileRFQ, setUploadFileRFQ] = useState("");
  const [uploadFileRFQName, setUploadFileRFQName] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);
  const editContainerRef = useRef(null);
  const inputRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    // Function to close the edit state when clicking outside the component
    const handleClickOutside = (event) => {
      if (
        editContainerRef.current &&
        !editContainerRef.current.contains(event.target)
      ) {
        setEditOpen(false);
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editContainerRef]);

  useEffect(() => {
    if (editOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editOpen]);

  const handleEditIconClick = () => {
    setEditOpen(true);

    // Trigger click on the file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  function updateProfileData() {
    axios
      .put("https://uatamma.apimachine.com/api/edituserdetails", {
        email,
        [name]: uploadFileRFQ,
      })
      .then((response) => {
        // console.log("Response from update profile", response);
        if (response?.data?.success) {
          toastify(`Updated ${name} successfully!`, "success");
        } else {
          toastify(`Failed to update ${name}!`, "error");
        }
        setEditOpen(false);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((error) => {
        console.log("Profile update error", error?.message);
        toastify(`Profile update error ${name}!`, "error");
        setEditOpen(false);
      });
  }

  return (
    <div ref={editContainerRef}>
      {type === "image" ? (
        <div className={classNames.imageBox}>
          {uploadFileRFQ ? (
            <img src={uploadFileRFQ} alt="uploadFileRFQfile" />
          ) : (
            value
          )}
        </div>
      ) : editOpen ? (
        <input
          placeholder="Enter.."
          className={classNames.inputBox}
          ref={inputRef}
          value={inputValue}
          onChange={(event) => setInputValue(event?.target?.value)}
        />
      ) : (
        <div className={classNames.valueBox}>
          {value === "Loading" ? (
            <Skeleton width={150} height={40} />
          ) : value ? (
            value
          ) : (
            ""
          )}
        </div>
      )}
      <div className={classNames.editContainer}>
        <div
          className={classNames.editIcon}
          onClick={async () => {
            if (type === "image") {
              handleEditIconClick();
            } else {
              setEditOpen(true);
            }
          }}
          style={{
            display: editOpen ? "none" : "",
            pointerEvents: clickable ? "" : "none",
          }}
        >
          <img src={editIcon} alt="editIcon" />
        </div>
        <div
          className={classNames.buttonsContainer}
          style={{ display: editOpen ? "" : "none" }}
        >
          <button
            onClick={() => {
              setEditOpen(false);
            }}
          >
            Close
          </button>
          <button onClick={updateProfileData}>Save</button>
        </div>

        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          style={{ display: "none" }}
          onChange={async (e) => {
            let resFile = uploadFileFunc(
              e,
              setUploadFileRFQName,
              setUpdateLoading,
              "name"
            );
            console.log(await resFile, "res file link await");
            setUploadFileRFQ(await resFile);
          }}
          ref={fileInputRef}
        />
      </div>
    </div>
  );
};
