import React from 'react'
import './SummaryPage.scss'

export default function SummaryPage({ histroyDetail }) {

    return (
        <div className='summarypage'>


            <div className='title'>Client History Details</div>
            <div class="containerpage"
                style={{
                    height: "calc(100vh - 377px)"
                }}
            >
                <div class="tWrap">
                    <div class="tWrap__head">
                        <table>
                            <thead>
                                <tr>
                                    <th>Invoice Number</th>
                                    <th>Date</th>
                                    <th>Type </th>
                                    <th>Service Type</th>
                                    <th>Service</th>
                                    <th>Amount</th>
                                    <th>Payment Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="tWrap__body">
                        <table>
                            <tbody>
                                {
                                    histroyDetail?.map(item => {
                                        return (
                                            <tr>
                                                <td>   {item?.invoicedetails?.[0]?.invoicenumber}</td>
                                                <td>{item?.dateOfBooking}</td>
                                                <td>        {item?.program == "true" ? "Package" : "Ad-hoc"}</td>

                                                <td>        {item?.type}</td>

                                                <td>{item?.type == "consultationservice" ? item?.servicedetails?.[0]?.name : item?.theraphyDetails?.[0]?.name}</td>
                                                {/* <td>{item?.servicedetails?.[0]?.name}</td> */}

                                                <td>    {item?.invoicedetails?.[0]?.amountpending}</td>
                                                <td
                                                // style={{
                                                //     display: "flex",
                                                //     alignItems: "center",

                                                // }}
                                                ><div
                                                    style={{
                                                        border: "1px solid #CCEFC0",
                                                        height: "24px",

                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        borderRadius: "6px",
                                                        padding: "5px 9px",
                                                        lineHeight: "14px",
                                                        background: "#F0FEEB",
                                                        width: "max-content",
                                                        color: "#58B039",
                                                        cursor: "pointer"
                                                    }}
                                                >
                                                        {item?.invoicedetails?.[0]?.invoicestatus}
                                                    </div>
                                                </td>
                                                <td><svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                                    <path d="M4.16667 9.13168C3.25 9.13168 2.5 9.88168 2.5 10.7983C2.5 11.715 3.25 12.465 4.16667 12.465C5.08333 12.465 5.83333 11.715 5.83333 10.7983C5.83333 9.88168 5.08333 9.13168 4.16667 9.13168Z" fill="#767A85" />
                                                    <path d="M15.8334 9.13168C14.9167 9.13168 14.1667 9.88168 14.1667 10.7983C14.1667 11.715 14.9167 12.465 15.8334 12.465C16.75 12.465 17.5 11.715 17.5 10.7983C17.5 9.88168 16.75 9.13168 15.8334 9.13168Z" fill="#767A85" />
                                                    <path d="M9.99998 9.13168C9.08331 9.13168 8.33331 9.88168 8.33331 10.7983C8.33331 11.715 9.08331 12.465 9.99998 12.465C10.9166 12.465 11.6666 11.715 11.6666 10.7983C11.6666 9.88168 10.9166 9.13168 9.99998 9.13168Z" fill="#767A85" />
                                                </svg></td>
                                            </tr>
                                        )
                                    })
                                }



                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {/* <div className='title'>Client History Details</div>
            <div className='maintabelist'>
                {
                    [1, 2, 2, 2, 2, 2, 2]?.map(item => {
                        return (
                            <div className='tablist'>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M7.49998 11.25C7.49998 10.8522 7.65801 10.4706 7.93932 10.1893C8.22062 9.90804 8.60215 9.75 8.99998 9.75H10.5C10.8978 9.75 11.2793 9.90804 11.5606 10.1893C11.8419 10.4706 12 10.8522 12 11.25V12.657L12.6 15.0555C12.6747 15.3556 12.6549 15.6715 12.5432 15.9599C12.4315 16.2482 12.2334 16.4951 11.976 16.6665L10.581 17.5965C10.3345 17.761 10.0448 17.8487 9.74848 17.8487C9.45216 17.8487 9.16247 17.761 8.91598 17.5965L7.52248 16.6665C7.26535 16.4949 7.06754 16.248 6.95611 15.9596C6.84468 15.6712 6.82507 15.3554 6.89998 15.0555L7.49998 12.657V11.25ZM10.5 11.25H8.99998V12.657C8.99997 12.7799 8.98485 12.9023 8.95498 13.0215L8.35498 15.4185L9.74998 16.3485L11.1435 15.4185L10.5435 13.0215C10.5141 12.9022 10.4995 12.7798 10.5 12.657V11.25Z" fill="#767A85" />
                                        <path d="M21 6.75V21C21 21.7956 20.6839 22.5587 20.1213 23.1213C19.5587 23.6839 18.7956 24 18 24H6C5.20435 24 4.44129 23.6839 3.87868 23.1213C3.31607 22.5587 3 21.7956 3 21V3C3 2.20435 3.31607 1.44129 3.87868 0.87868C4.44129 0.316071 5.20435 0 6 0L14.25 0L21 6.75ZM16.5 6.75C15.9033 6.75 15.331 6.51295 14.909 6.09099C14.4871 5.66903 14.25 5.09674 14.25 4.5V1.5H11.25V3H9.75V4.5H11.25V6H9.75V7.5H11.25V9H9V7.5H7.5V6H9V4.5H7.5V3H9V1.5H6C5.60218 1.5 5.22064 1.65804 4.93934 1.93934C4.65804 2.22064 4.5 2.60218 4.5 3V21C4.5 21.3978 4.65804 21.7794 4.93934 22.0607C5.22064 22.342 5.60218 22.5 6 22.5H18C18.3978 22.5 18.7794 22.342 19.0607 22.0607C19.342 21.7794 19.5 21.3978 19.5 21V6.75H16.5Z" fill="#767A85" />
                                    </svg>
                                    <div
                                        style={{
                                            marginLeft: "7px"
                                        }}
                                    >
                                        <div className='Name'>Agreement_Meditation.zip</div>
                                        <div className='digit'>2.3 mb</div>
                                    </div>

                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="12" viewBox="0 0 17 12" fill="none">
                                    <path d="M14.372 11.0601C14.9516 10.6525 15.3862 10.0708 15.6128 9.39948C15.8395 8.72815 15.8463 8.00206 15.6322 7.3266C15.4182 6.65114 14.9946 6.06145 14.4227 5.64309C13.8508 5.22474 13.1605 4.9995 12.452 5.00005H11.612C11.4115 4.21863 11.0363 3.49288 10.5147 2.87744C9.99315 2.26199 9.33875 1.77289 8.60079 1.44696C7.86283 1.12102 7.06054 0.966738 6.25433 0.99573C5.44812 1.02472 4.65899 1.23623 3.94635 1.61434C3.23371 1.99244 2.61613 2.52728 2.14011 3.17861C1.66408 3.82993 1.34202 4.58075 1.19816 5.37455C1.0543 6.16835 1.09239 6.98445 1.30957 7.7614C1.52675 8.53835 1.91735 9.25591 2.45198 9.86005" stroke="#767A85" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        )
                    })
                }

            </div> */}
        </div>
    )
}
