import React, { useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { format } from 'date-fns';
export default function Package({ setapipackagestartDate, startDate, setStartDate, endDate, setEndDate, packagestartDate, packageendDate, endingdatefunctionPackage, startingdatefunctionPackage, key, setapipackageendDate, Packagecaldnderdata }) {
    // const [startDate, setstartDate] = useState(new Date());
    // const [endDate, setendDate] = useState(null);

    // const onChange = (dates) => {
    //     const [start, end] = dates;
    //     setstartDate(start);
    //     setendDate(end);
    // };
    const [open, setOpen] = useState(true);
    const startDateRef = useRef(null);
    const endDateRef = useRef(null);
    const [isOpen, setIsOpen] = useState(true);
    console.log("endDate", startDate, "dooddod", endDate)
    const excludeDates = [
        new Date(), // Today
        //  addDays(new Date(), 1), // Tomorrow
        // addDays(new Date(), 2) // Day after tomorrow
    ];
    useEffect(() => {
        // Automatically open the date picker for the start date initially
        setIsOpen(true);
    }, []);
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);


        const formattedstart = format(start, 'dd-MM-yyyy');
        if (end != null) {
            const formattedend = format(end, 'dd-MM-yyyy')
            setapipackageendDate(formattedend)
        }
        setapipackagestartDate(formattedstart)
        console.log(end, "start")
        // const formattedend = format(end, 'dd/MM/yyyy');
        // setapipackagestartDate(formattedstart)
        // setapipackageendDate(formattedend)
    };
    return (
        <div className="main-date-section"
            style={{
                paddingTop: "27px"
            }}
        >

            {/* <DatePicker
                open={true}
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                //  excludeDates={[addDays(new Date(), 1), addDays(new Date(), 5)]}
                selectsRange
                selectsDisabledDaysInRange
                inline
            /> */}

            {/* <DatePicker
                key={`${key}-start`}
                open={true}
                selected={packagestartDate}
                onChange={Packagecaldnderdata}
                startDate={packagestartDate}
                endDate={packageendDate}
                excludeDates={excludeDates}
                selectsRange
                selectsDisabledDaysInRange
                inline
                minDate={new Date()}
                isClearable
            /> */}
            {/* */}
            {/* <DatePicker
                key={`${key}-start`}
                open={true}
                selected={packagestartDate}
                onChange={Packagecaldnderdata}
                startDate={packagestartDate}
                endDate={packageendDate}
                excludeDates={excludeDates}
                selectsRange
                selectsDisabledDaysInRange
                inline
                minDate={new Date()}
                isClearable
            /> */}
            {/* <DatePicker
                selectsStart

                selected={packagestartDate}
                ref={startDateRef}
                onChange={(date) => {
                    startingdatefunctionPackage(date);
                    setOpen(false)
                }}
                startDate={packagestartDate}
                open={open}


            />
            <DatePicker
                selectsEnd
                //  open={true}
                ref={endDateRef}
                selected={packageendDate}
                onChange={(date) => endingdatefunctionPackage(date)}
                endDate={packageendDate}
                startDate={packagestartDate}
                minDate={packagestartDate}

            /> */}


            <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
                minDate={new Date()}
                open={isOpen}
                onClickOutside={() => setIsOpen(false)}
            />
        </div>
    )
}
