import { useState, useEffect } from "react";
import { getData } from "../assets/functions/apiCalls";

export const useNetworkRequest = ({ URL, organizationSelected }) => {
  const [allValues, setAllValues] = useState([]);
  const [allValuesLoading, setAllValuesLoading] = useState(true);

  const fetchData = async () => {
    // console.log(organizationSelected, "organizationSelected fetch data");
    try {
      setAllValuesLoading(true);
      const response = await getData(URL);
      // console.log(response, "response in useNetworkRequest");
      setAllValues(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setAllValuesLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [URL, organizationSelected?.TeamUsername]);

  return { allValues, allValuesLoading };
};
