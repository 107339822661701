import Cookies from "js-cookie";
import React, { createContext, useEffect, useState } from "react";
import { APP_USER_TOKEN } from "../assets/data/constant";
import axios from "axios";
export const GlobalContext = createContext();

const GlobalContextProvider = ({ children }) => {
  const [loginStep, setLoginStep] = useState(1);
  const [globalSearchQuery, setGlobalSearchQuery] = useState("");
  const [globalLoading, setGlobalLoading] = useState(false);
  const [editingToggle, seteditingToggle] = useState(false);
  const [accessPages, setAccessPages] = useState(
    (localStorage.getItem("accessPages") &&
      JSON.parse(localStorage.getItem("accessPages"))) ||
    ""
  );
  //credentials
  const [email, setEmail] = useState(
    localStorage.getItem("nvestBankLoginAccount") || ""
  );
  const [profileUsername, setProfileUsername] = useState(
    localStorage.getItem("profileUsername") || ""
  );
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("nvestBankAccessToken") || ""
  );
  const [idToken, setIdToken] = useState(
    localStorage.getItem("nvestBankIdToken") || ""
  );
  const [isSpecialist, setIsSpecialist] = useState(null);
  useEffect(() => {
    axios
      .get(
        `https://uatamma.apimachine.com/api/getspecialistdetail?email=${email}`
      )
      .then(({ data }) => {
        if (data.success) {
          setIsSpecialist(data?.data[0]?.specialistDetails[0]);
        } else {
          setIsSpecialist(false);
        }
      });
  }, []);
  const setUserdata = (email) => {
    if (email) {
      axios
        .get(`https://uatamma.apimachine.com/api/checkuser/${email}`)
        .then((response) => {
          if (response?.data?.success) {
            console.log("Profile data is:", response?.data?.email);
            localStorage.setItem(
              "profileUsername",
              response?.data?.email?.username
            );
          }
        })
        .catch((error) => {
          console.log(error, "Profile data error");
        });
    }
  };

  const userLoginHandler = (paramEmail, paramAccessToken, paramIdToken) => {
    if (paramEmail && paramAccessToken && paramIdToken) {
      localStorage.setItem("nvestBankLoginAccount", paramEmail);
      localStorage.setItem("nvestBankAccessToken", paramAccessToken);
      localStorage.setItem("nvestBankIdToken", paramIdToken);
      callapi(paramEmail);
      setEmail(paramEmail);
      setAccessToken(paramAccessToken);
      setIdToken(paramIdToken);
      setUserdata(paramEmail);
    }
    if (!paramEmail || !paramAccessToken || !paramIdToken) {
      Cookies.remove(APP_USER_TOKEN);
    }
  };


  useEffect(() => {

    seteditingToggle(false);
  }, [window?.location?.pathname]);
  const callapi = (e) => {
    axios
      .get(
        `https://uatamma.apimachine.com/api/getteamsbyowner?TeamCreatedBy=${e}`
      )
      .then((res) => {
        if (res?.data?.success) {
          localStorage.setItem(
            "listorginzationName",
            res?.data?.data?.[0]?.NameOfTeam
          );
          localStorage.setItem(
            "listorginzationImg",
            res?.data?.data?.[0]?.Icon
          );
          localStorage.setItem(
            "orginzationdetail",
            JSON.stringify(res?.data?.data?.[0])
          );
          localStorage.setItem("dropdownlist", JSON.stringify(res?.data?.data));
        } else {
          localStorage.setItem("listorginzationName", "");
          localStorage.setItem("listorginzationImg", "");
          localStorage.setItem("orginzationdetail", JSON.stringify({}));

          localStorage.setItem("dropdownlist", JSON.stringify(res?.data?.data));
        }
      });
  };
  // CRM
  const [overlayDeleteItemName, setOverlayDeleteItemName] = useState("");
  const [actionBtns, setActionBtns] = useState([]);

  const [dropDowndownvalue, setdropDowndownvalue] = useState("");
  return (
    <GlobalContext.Provider
      value={{
        setdropDowndownvalue,
        dropDowndownvalue,
        editingToggle,
        seteditingToggle,
        loginStep,
        setLoginStep,
        userLoginHandler,
        globalSearchQuery,
        setGlobalSearchQuery,
        globalLoading,
        setGlobalLoading,
        accessPages,
        setAccessPages,
        //credentials
        email,
        setEmail,
        profileUsername,
        setProfileUsername,
        accessToken,
        setAccessToken,
        idToken,
        setIdToken,
        //CRM
        overlayDeleteItemName,
        setOverlayDeleteItemName,
        actionBtns,
        setActionBtns,
        isSpecialist,
        setIsSpecialist,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
