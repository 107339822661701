import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./Catories.module.scss";
// import './catoriesSummarypage.scss'
//assets
import { ReactComponent as FilterIcon } from "../../assets/images/filter.svg"; import Drawer from 'react-modern-drawer'
import {
    CopyBtn,
    CustomCheckbox,
    EachCustomDropdown,
    EachCustomImageUpload,
    EachCustomImageUploadcustom,
    EachCustomSelectMultiple,
    EachInputCustom, EachInputsepfic,
    EachCustomTextarea,
    OwnEachCustomDropdown,
    EachInput,
} from "../../components/Custom";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5";
import { ReactComponent as SortArrows } from "../../assets/images/icons/sort.svg";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../../context/Globalcontext";
import { useNetworkRequest } from "../../hooks/networkRequest";
import { deleteData, getData } from "../../assets/functions/apiCalls";
import axios from "axios";
import { toastify } from "../../assets/functions/toastify";
import { filterDetails } from "../../assets/functions";
import { APP_CODE } from "../../assets/data/constant";

import Editingpage from '../EditingPage/EditingPage'
const Organization = () => {
    const {
        email,
        actionBtns,
        setActionBtns,
        globalSearchQuery,
        dropDowndownvalue, editingToggle, seteditingToggle
    } = useContext(GlobalContext);
    const [checkBoxStage, setCheckBoxStage] = useState("");
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [catoriesSummary, setcatoriesSummary] = useState([]);
    const [brandItem, setbrandItem] = useState({

        name: "",
        description: "",

        coverPhoto: "",

    });
    console.log("32342342fdsfvfdv", brandItem);

    useEffect(() => {
        tablefucntion();
    }, [dropDowndownvalue]);
    const [locationdata, setlocationdata] = useState({});

    const tablefucntion = () => {
        axios
            // .get(`https://uatamma.apimachine.com/api/getwidget`)
            .get(
                `https://uatamma.apimachine.com/api/getfrequency?teamusername=${JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername || ""}`
            )
            .then((res) => {
                console.log("cxczxczxc", res?.data)
                setcatoriesSummary(res?.data?.FrequencyData);
            });
    };
    const history = useNavigate()
    const [player, setplayer] = useState([]);

    function ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }
    const monthNames = [
        "",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
    ];

    console.log("cxc66666zxczxc", player);
    const [active, setactive] = useState("Pre Assessment Form");

    const [page, setpage] = useState("main");
    const [getsesion, setgetsesion] = useState({});
    // useEffect(() => {
    //     setpage("second")
    // }, [])

    // const getclientid = (e) => {

    //     axios
    //         .get(`https://uatamma.apimachine.com/api/getconsultcatoriesSummarys?consultationcatoriesSummaryid=${e}`)
    //         .then((res) => {
    //             //console.log("cxczxczxc", localStorage?.getItem("bankerEmailNew"))

    //             setgetsesion(res?.data?.data?.[0] || {})
    //             setpage("second")

    //         });
    // }

    const [formName, setFormName] = useState("");
    const [questions, setQuestions] = useState([
        {
            name: "",
            answerType: "",
            isMandatory: null,
        },
    ]);
    const [isOpen, setIsOpen] = useState(null);
    const [formErr, setFormErr] = useState(null);
    const [allSpecialist, setAllSpecialist] = useState([]);
    const [selectedSpecialist, setSelectedSpecialist] = useState(null);


    const handleSubmit = () => {

        axios
            .post(`https://uatamma.apimachine.com/api/addfrequency`, {

                "teamusername": JSON.parse(localStorage?.getItem("orginzationdetail"))?.TeamUsername,
                "name": brandItem?.name,


            })
            .then(({ data }) => {
                if (data.status) {
                    setCheckBoxStage("");
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 1000);

                    setFormErr(null);
                } else {
                    setFormErr(data.message);
                }
            });
    };

    let loc = window.location.pathname
    let finallo = loc?.split('/')
    const localclikc = () => {
        let addlocal = localStorage?.getItem("drawershow")

        localStorage?.setItem("drawershow", Number(addlocal + 1))
    }
    console.log("cxc66666zxczxc", player)
    useEffect(() => {
        seteditingToggle(false)
    }, [])
    return (

        <>
            {
                editingToggle ?
                    <Editingpage />
                    :
                    <div className="global-dashboard">
                        <div className="label-box-header"
                            onClick={localclikc}
                        >
                            {finallo?.[3]}
                        </div>
                        <div className="top-section-golbal">
                            <div className="left-side">


                                <div className="label-box">
                                    <svg
                                        style={{
                                            marginRight: "10px"
                                        }}
                                        xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                        <path d="M12.8445 8.07626L10.9486 6.58668C10.8262 6.4908 10.6594 6.47293 10.5202 6.54118C10.3804 6.60888 10.2916 6.7508 10.2916 6.90626V7.90292C8.75704 8.16184 7.58325 9.49651 7.58325 11.1042V12.4583C7.58325 12.7573 7.82592 13 8.12492 13C8.42392 13 8.66658 12.7573 8.66658 12.4583V11.1042C8.66658 10.0978 9.35992 9.25655 10.2916 9.01442V9.88542C10.2916 10.0409 10.3804 10.1828 10.5202 10.2505C10.5765 10.2781 10.6372 10.2917 10.6978 10.2917C10.7872 10.2917 10.876 10.2624 10.9486 10.205L12.8445 8.71542C12.943 8.63851 12.9999 8.52042 12.9999 8.39584C12.9999 8.27126 12.943 8.15317 12.8445 8.07626Z" fill="#010101" />
                                        <path d="M9.74999 0H1.625C0.731249 0 0 0.731249 0 1.625V8.66666C0 9.5604 0.731249 10.2917 1.625 10.2917H6.58124C6.65166 9.91249 6.77082 9.54957 6.93874 9.20832H1.625C1.32708 9.20832 1.08333 8.96457 1.08333 8.66666V2.70833H10.2917V5.47624C10.6492 5.37333 11.0392 5.41124 11.375 5.57916V1.625C11.375 0.731249 10.6437 0 9.74999 0V0Z" fill="#010101" />
                                    </svg>

                                    Export

                                </div>



                                <>

                                    <div className="label-box">
                                        <svg
                                            style={{
                                                marginRight: "10px"
                                            }}
                                            xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                            <path d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z" fill="#010101" />
                                        </svg>
                                        Add Filter
                                    </div></>


                            </div>


                            <div className="right-side">
                                <div className="shortBy">Sort By</div>
                                <div className="dropdownlist">
                                    Newest

                                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="6" viewBox="0 0 7 6" fill="none">
                                        <path d="M0.124669 1.1317L3.01146 5.69502C3.23352 6.10166 3.72207 6.10166 3.98854 5.69502L6.87533 1.1317C7.18623 0.634716 6.87533 0.00214577 6.38681 0.00214577L0.613191 0.00214577C0.124669 -0.0430288 -0.186232 0.634684 0.124669 1.1317Z" fill="#010101" />
                                    </svg>

                                </div>
                                <div className="addLabel"
                                    onClick={() => setCheckBoxStage("add")}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                        <path d="M13 6.5C13 6.67239 12.9315 6.83772 12.8096 6.95962C12.6877 7.08152 12.5224 7.15 12.35 7.15H7.15V12.35C7.15 12.5224 7.08152 12.6877 6.95962 12.8096C6.83772 12.9315 6.67239 13 6.5 13C6.32761 13 6.16228 12.9315 6.04038 12.8096C5.91848 12.6877 5.85 12.5224 5.85 12.35V7.15H0.65C0.477609 7.15 0.312279 7.08152 0.190381 6.95962C0.0684819 6.83772 0 6.67239 0 6.5C0 6.32761 0.0684819 6.16228 0.190381 6.04038C0.312279 5.91848 0.477609 5.85 0.65 5.85H5.85V0.65C5.85 0.477609 5.91848 0.312279 6.04038 0.190381C6.16228 0.0684819 6.32761 0 6.5 0C6.67239 0 6.83772 0.0684819 6.95962 0.190381C7.08152 0.312279 7.15 0.477609 7.15 0.65V5.85H12.35C12.5224 5.85 12.6877 5.91848 12.8096 6.04038C12.9315 6.16228 13 6.32761 13 6.5Z" fill="white" />
                                    </svg>
                                    Add New
                                </div>
                                <Drawer
                                    size={450}
                                    // duration={500}
                                    open={checkBoxStage}
                                    onClose={() => setCheckBoxStage("")}
                                    direction='right'
                                    className='bla bla bla'
                                    overlayColor="#FFF"
                                    overlayOpacity={0}
                                >
                                    {checkBoxStage == "add" && (
                                        <div
                                            className="drowpside-right"

                                        >
                                            {/* <div
                                            className={classNames.closeBtn}
                                            onClick={() => {
                                                setCheckBoxStage("");
                                            }}
                                        >
                                            X
                                        </div> */}
                                            <div
                                                className={classNames.eachFilterItem}
                                                style={{
                                                    fontWeight: "600",
                                                    pointerEvents: "none",
                                                    paddingLeft: "0",
                                                    marginBottom: "1.5rem",
                                                    fontSize: "20px",
                                                    marginBottom: "1rem",
                                                }}
                                            >
                                                Add new frequency
                                            </div>
                                            <div
                                                style={{

                                                    marginBottom: "1rem"
                                                }}
                                            >
                                                <EachInputsepfic
                                                    title="Frequency per day"
                                                    name="name"
                                                    placeholder="Enter number..."
                                                    stateValue={brandItem}
                                                    setState={setbrandItem}
                                                    topTitle={true}
                                                />
                                            </div>




                                            <button
                                                className={classNames.submitBtn
                                                }
                                                onClick={handleSubmit}
                                            >
                                                Submit
                                            </button>
                                            {formErr && <h4 style={{ color: "red" }}>{formErr}</h4>}
                                        </div>
                                    )}
                                </Drawer>
                            </div>
                        </div>


                        <div className="handle-table">
                            <div className="g-table-sec">
                                <div className="table-section">
                                    <table style={{ minWidth: "100%" }}>
                                        <thead>
                                            <tr
                                                style={{ gridTemplateColumns: "min-content repeat(4, 1fr)" }}
                                            >

                                                <th>
                                                    Name <SortArrows />
                                                </th>

                                                <th>

                                                    <SortArrows />
                                                </th>
                                                <th>

                                                    <SortArrows />
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {catoriesSummary?.map((row, index) => {
                                                let date = row?.createdAt?.split("-");
                                                let finial = row?.createdAt?.split("-")?.[2]?.split("T");
                                                console.log("dsadasdasd", row)
                                                return (
                                                    <>
                                                        <tr
                                                            // key={row?.brokerage_name + index}
                                                            style={{
                                                                gridTemplateColumns: "min-content repeat(4, 1fr)",
                                                                pointerEvents: sidebarOpen ? "none" : "",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => history(`/owner/pharmacy/frequencies/${row?._id}`)}
                                                        // onClick={() => getclientid(row?.consultationcatoriesSummaryid)}
                                                        >

                                                            <td>


                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                >

                                                                    <div>
                                                                        <div>
                                                                            {row?.number} times a day

                                                                        </div>
                                                                        {/* <div>
                                                                        {row?.consultationcatoriesSummaryid}
                                                                    </div> */}
                                                                    </div>
                                                                </div>
                                                            </td>


                                                            <td

                                                            >



                                                            </td>


                                                            <td>

                                                                {/* {row?.productsCount} */}




                                                            </td>
                                                        </tr>

                                                    </>

                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
            }
        </>
    );
};

export default Organization;
